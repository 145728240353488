import React from 'react'
import styled from 'styled-components'
import { AppState } from '../types'
import { connect } from 'react-redux'

// todo: figure out how to get version included in frontend bundle, or from server

const Footer = ({ id, className }: { id: string; className: string }) => {
  const commit = process.env.COMMIT?.substring(0, 8)
  return (
    <footer id={id} className={className}>
      <span>
        <a
          href="https://virtualadviser.com"
          title="Virtual Adviser website"
          target="_blank"
          rel="noopener noreferrer"
        >
          Virtual Adviser
        </a>
      </span>{' '}
      <span>
        v5 ({commit}) &copy; 2014 - {new Date().getFullYear()}
      </span>{' '}
      <span>
        <a href="http://enrollmentresources.com" target="_blank" rel="noopener noreferrer">
          Enrollment Resources Inc
        </a>
      </span>
      <span>. All Rights Reserved.</span>{' '}
      <a
        target="_blank"
        href="http://virtualadviser.com/privacypolicy.html"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>{' '}
      -{' '}
      <a target="_blank" rel="noopener noreferrer" href="http://virtualadviser.com/termsofuse.html">
        Terms of Use
      </a>
      <div id="recaptcha-footer">
        This site is protected by reCAPTCHA, the Google{' '}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </footer>
  )
}

export const StyledFooter = styled(Footer)<any>`
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 0.65rem;
  background: #222;
  position: relative;
  z-index: 90000;

  &,
  a,
  a:hover,
  a:active,
  a:visited {
    color: #c3c3c3;
  }

  @media print {
    background: #fff;
    &,
    a,
    a:hover,
    a:active,
    a:visited {
      color: #444;
    }
  }

  #recaptcha-footer {
    display: ${(props) => {
      if (!props.response.progress || props.response.progress === 0) {
        return 'block'
      }
      return 'none'
    }};
  }
`
const mapStateToProps = (state: AppState) => ({
  response: state.response,
})

export default connect(mapStateToProps)(StyledFooter)
