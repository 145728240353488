import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import BlockToolbar from './BlockToolbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { PageRendererComponentContext } from '../PageRenderer'

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const AddButton = (props) => (
  <div className={props.className} onClick={props.onClick}>
    <FontAwesomeIcon icon={faPlus} />
  </div>
)

export const StyledAddButton = styled(AddButton)`
  background: ${(props) => (props.color ? props.color : 'var(--primary)')};
  border-radius: 100%;
  height: 32px;
  line-height: 31px;
  width: 32px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  position: absolute;
  bottom: -16px;
  left: calc(50% - 16px);
  transform: scale(1);
  transition: all 0.3s ease-out;
  z-index: 90000;

  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fadeOut;
  transition: all 0.3s ease-out;

  &:hover {
    transform: scale(1.2);
    animation-name: none;
    opacity: 1 !important;
  }
  &:active {
    transform: scale(1.1);
  }
`

class EditorSectionBlock extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    block: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.block !== nextProps.block) {
      return true
    }
    if (this.props.index !== nextProps.index) {
      return true
    }

    return false
  }
  render() {
    const { className, block, index } = this.props
    const Component = block.getComponent()

    const locationQuery = {
      section: index,
    }

    const blockProps = {
      onMouseOver: this.hoverOn,
      onMouseOut: this.hoverOff,
    }

    return (
      <PageRendererComponentContext.Consumer>
        {({ removeBlock, openBlockSettings, addSection, cloneBlock }) => (
          <Draggable draggableId={block.id} type="section" index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              >
                <div className={className}>
                  <BlockToolbar
                    block={block}
                    onHover={this.hoverToolbar}
                    onHoverOff={this.hoverOffToolbar}
                    dragHandleProps={provided.dragHandleProps}
                    cloneBlock={() => cloneBlock(locationQuery)}
                    // saveBlockTemplate={() => {
                    //   saveBlockTemplate(block, locationQuery)
                    // }}
                    openBlockSettings={() => {
                      openBlockSettings(block, locationQuery)
                    }}
                    removeBlock={() => {
                      removeBlock(locationQuery)
                    }}
                  />
                  <Component block={block} blockProps={blockProps} index={index} />
                  <StyledAddButton
                    onClick={() => {
                      addSection(locationQuery)
                    }}
                  />
                </div>
              </div>
            )}
          </Draggable>
        )}
      </PageRendererComponentContext.Consumer>
    )
  }
}

export default styled(EditorSectionBlock)`
  position: relative;
  > .block {
    transition: outline-color 0.3s ease-out;
    outline: 2px solid transparent;
    outline-offset: -1px;
    ${(props) => {
      if (!props.block.isVisible()) {
        return 'opacity:0.5;'
      }
    }}
  }

  &:focus-within {
    > ${BlockToolbar} {
      display: none !important;
    }
  }

  > ${BlockToolbar}:hover + .block {
    outline-color: rgba(0, 123, 255, 0.8);
  }

  &:hover > ${BlockToolbar}, &:hover > ${StyledAddButton} {
    animation-name: fadeIn;
  }

  > ${BlockToolbar} {
    background: var(--primary);
    left: 0 !important;
    top: 0 !important;
  }
`
