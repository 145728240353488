import Cookies from 'js-cookie'
import Log from '../log'
import qs from 'qs'

export type Path = `/${string}`

export const CREDENTIALS = {
  token: Cookies.get('authToken'),
  meteor_login_token: Cookies.get('meteor_login_token'), // eslint-disable-line
}

// Immediately rewrite the cookie so that the expiration extends
if (CREDENTIALS.token) {
  Cookies.set('authToken', CREDENTIALS.token, {
    expires: 30,
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'strict',
    domain: window.location.hostname,
  })
}

async function apiFetch(
  method: string,
  path: Path,
  body?: Record<string, any>,
  query?: Record<string, string>,
  long = false
): Promise<any> {
  const url = new URL((long ? process.env.API_URL_LONG : process.env.API_URL) + path)
  if (query) {
    for (const item in query) {
      if (!query[item]) {
        delete query[item]
      }
    }
    url.search = qs.stringify(query)
  }

  const headers: Record<string, string> = {
    'Content-Type': 'application/json', // eslint-disable-line
  }

  if (CREDENTIALS.token) {
    headers.Authorization = 'Bearer ' + CREDENTIALS.token // eslint-disable-line
  }

  const init: RequestInit = {
    method,
    mode: 'cors',
    headers,
  }

  if (body && Object.keys(body).length > 0) init.body = JSON.stringify(body)

  const result = await fetch(url.toString(), init)
  const json = await result.json()

  if (json.error) {
    throw new Error(json.error)
  } else if (json.success !== true) {
    throw new Error('Unexpected error has occured')
  }

  return json.data
}

export default apiFetch
