import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import Field, { FieldConfig, FieldData } from '../../Field'
import RowBlock from '../../row/Row'
import SectionBlock from '../../section/Section'
import SliderInput from '../../../../_shared/components/Form/Slider'
import BlockManager from '../../BlockManager'
import { getBlockStyles } from '../../../getBlockStyles'
import { FrontendContext } from '../../../../_shared/StepSubmission'

export interface SliderProps {
  block: SliderField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class Slider extends React.PureComponent<SliderProps> {
  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)

    return (
      <SliderInput
        name={block.id}
        className={classnames('block', className)}
        label={label}
        showLabel={!block.data.config?.hideLabel}
        defaultValue={block.data.config?.max / 2}
        min={block.data?.config?.min}
        max={block.data?.config?.max}
        step={1}
        leftLabel={block.data?.config?.leftLabel}
        rightLabel={block.data?.config?.rightLabel}
        tooltip={block.data?.config?.tooltip}
      />
    )
  }
}

export const StyledSlider = styled(Slider)`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'Slider'

export interface SliderFieldConfig extends FieldConfig {
  min?: number
  max?: number
  leftLabel?: string
  rightLabel?: string
  tooltip?: boolean
}

export interface SliderFieldData extends FieldData {
  config?: SliderFieldConfig
}

export default class SliderField extends Field {
  static type? = key
  static enableStatistics = true
  static enableConditionalLogic = true
  declare data?: SliderFieldData
  static template: Partial<SliderField> = {
    data: {
      config: {
        label: 'Slider',
        min: 0,
        max: 10,
        tooltip: false,
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<SliderField>) {
    super(params)
    Object.assign(this, SliderField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledSlider
  }

  getFormSchema(context: FrontendContext): Record<string, any> {
    return {
      schema: {
        title: this.getLabel(context),
        type: 'number',
      },
      required: this.data?.config?.required,
    }
  }
}

BlockManager.registerBlockClass(SliderField)
