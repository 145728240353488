import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import DraggingContext from './DraggingContext'
import Block from '../blocks/Block'

const getListStyle = (type, draggingType, isDraggingOver) => {
  const styles: Record<string, any> = {
    background: 'transparent',
    minHeight: '2rem',
  }

  if (type === 'section') {
    styles.minHeight = 'inherit'
  }

  if (type === draggingType) {
    styles.outlineOffset = '-2px'

    switch (type) {
      case 'section':
        styles.background = 'rgba(0,123,255,0.35)'
        break
      case 'row':
        styles.background = 'rgba(40, 167, 69, 0.35)'
        break
      case 'content':
        styles.background = 'rgba(220, 53, 69, .35)'

        break
    }
  }

  if (isDraggingOver) {
    switch (type) {
      case 'section':
        styles.background = 'transparent'
        break
      case 'row':
        styles.background = 'rgba(40, 167, 69, 0.65)'
        break
      case 'content':
        styles.background = 'rgba(220, 53, 69, .65)'
        break
    }
  }

  return styles
}

class RegionInner extends React.PureComponent {
  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}

interface Props {
  id: string
  type: string
  block?: Block
  children?: any
}

const Region: React.FC<Props> = (props) => (
  <DraggingContext.Consumer>
    {(draggingType) => (
      <Droppable droppableId={props.id} type={props.type}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(props.type, draggingType, snapshot.isDraggingOver)}
            {...provided.droppableProps}
          >
            <RegionInner>{props.children}</RegionInner>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )}
  </DraggingContext.Consumer>
)

export default Region
