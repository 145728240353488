import React, { ElementType, useContext } from 'react'
import styled from 'styled-components'
import Block, { BlockData } from '../Block'
import SectionBlock from '../section/Section'
import { PageRendererComponentContext } from '../../PageRenderer'
import { getBlockStyles } from '../../getBlockStyles'
import BlockManager from '../BlockManager'
import { BlockAppearanceConfig, RenderingComponents } from '../../types'
import classnames from 'classnames'

function BlockContent({
  row,
  rowIndex,
  regionIndex,
  section,
  sectionIndex,
  Content, // eslint-disable-line
}: {
  row: RowBlock
  rowIndex: number
  regionIndex: number
  section: SectionBlock
  sectionIndex: number
  Content: ElementType // eslint-disable-line
}) {
  const contentArray = row.content[regionIndex]
  if (!contentArray) {
    return null
  }

  return (
    <React.Fragment>
      {contentArray.map((block, blockIndex) => (
        <Content
          // {...context.contentProps} todo: was this used for anything?
          key={block.id}
          block={block}
          section={section}
          sectionIndex={sectionIndex}
          row={row}
          rowIndex={rowIndex}
          regionIndex={regionIndex}
          contentId={regionIndex}
          index={blockIndex}
        />
      ))}
    </React.Fragment>
  )
}

export type ColumnProps = {
  row: RowBlock
  index: number
}

const StyledColumn = styled.div<ColumnProps>`
  ${({ row, index }) =>
    getBlockStyles(row.data && row.data.config && row.data.config[`column${index}`])}
`

type EqualColumnsProps = {
  columns: number
  Region: any // eslint-disable-line
  row: RowBlock
  rowIndex: number
  section: SectionBlock
  sectionIndex: number
  className: string
  Content: ElementType // eslint-disable-line
  // blockProps: any // todo: did this actually do anything?
}
function EqualColumns({
  columns,
  Region, // eslint-disable-line
  row,
  rowIndex,
  section,
  sectionIndex,
  className,
  Content, // eslint-disable-line
}: // blockProps,
EqualColumnsProps) {
  return (
    <div
      // {...blockProps}
      className={classnames(className, 'block block-row', row.data.advanced?.cssClass)}
      id={row.data.advanced?.cssId}
    >
      <div className="row row-dynamic align-items-start">
        {Array.from({ length: columns }).map((a, index) => {
          return (
            <StyledColumn
              className={`col-md-${12 / columns}  col-number-${index}`}
              key={index}
              row={row}
              index={index}
            >
              <Region
                id={`${section.id}:${sectionIndex}-${row.id}:${rowIndex}-${index}`}
                type="content"
              >
                <BlockContent
                  section={section}
                  sectionIndex={sectionIndex}
                  row={row}
                  rowIndex={rowIndex}
                  regionIndex={index}
                  Content={Content}
                />
              </Region>
            </StyledColumn>
          )
        })}
      </div>
    </div>
  )
}

export type RowProps = {
  block: RowBlock
  index: number
  section: SectionBlock
  sectionIndex: number
  className?: string
  context?: Record<string, unknown>
}
export function Row({
  section,
  sectionIndex,
  block,
  index,
  className,
}: RowProps): React.ReactElement {
  const { Region, Content } = useContext<RenderingComponents>(PageRendererComponentContext)

  const layoutProps = {
    Region, // eslint-disable-line
    className,
    // blockProps,
    section,
    sectionIndex,
    row: block,
    rowIndex: index,
    Content, // eslint-disable-line
    // contentProps: rest,
  }

  // determine the component to render
  switch (block.data?.config?.layout) {
    case 'two':
      return <EqualColumns columns={2} {...layoutProps} />
    case 'three':
      return <EqualColumns columns={3} {...layoutProps} />
    case 'four':
      return <EqualColumns columns={4} {...layoutProps} />
    case 'six':
      return <EqualColumns columns={6} {...layoutProps} />
    case 'one':
    default:
      return <EqualColumns columns={1} {...layoutProps} />
  }
}

const StyledRow = styled(Row)`
  position: relative;
  ${(props) => getBlockStyles(props.block.data?.appearance, {
    location: props.context?.location as Location,
    block: props.block as Block,
  })}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'Row'

export interface RowBlockConfig {
  layout: 'two' | 'three' | 'four' | 'six' | 'one'
  column0?: BlockAppearanceConfig
  column1?: BlockAppearanceConfig
  column2?: BlockAppearanceConfig
  column3?: BlockAppearanceConfig
}

export interface RowBlockData extends BlockData {
  config: RowBlockConfig
}

export default class RowBlock extends Block {
  static type? = key
  declare data?: RowBlockData
  declare content?: Block[][]
  static template = {
    type: key,
    content: [],
    data: {
      config: {
        layout: 'one',
      },
      appearance: {
        width: { type: 'container' },
        padding: {
          mobile: {
            top: '2',
            topUnit: 'rem',
            left: '2',
            leftUnit: 'rem',
            bottom: '2',
            bottomUnit: 'rem',
            right: '2',
            rightUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<RowBlock>) {
    super(params)
    Object.assign(this, RowBlock.template, params)
  }

  getComponent(): React.ElementType {
    return StyledRow
  }

  toJSON(): Record<string, any> {
    const json = super.toJSON()
    json.content = this.content
    return json
  }
}
// RowBlock.key = key

BlockManager.registerBlockClass(RowBlock)
