import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DragDropContext } from 'react-beautiful-dnd'
import BlockManager from './blocks/BlockManager'
import EditorSectionBlock from './components/EditorSectionBlock'
import EditorRowBlock from './components/EditorRowBlock'
import EditorContentBlock from './components/EditorContentBlock'
import Canvas from './components/Canvas'
import './blocks/index.editor'
import Region from './components/Region'
import DraggingContext from './components/DraggingContext'
import { serialize } from './blocks/content/RichText/RichText.editor'
import AddBlockButton from './components/AddBlockButton'
import AddBlockPanel from './components/AddBlockPanel'
import { instantiateBlocks, generateNewBlockIds } from './components/utilities'
import get from 'lodash/get'
import setWith from 'lodash/setWith'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import Block from './blocks/Block'
import Form from '../_shared/components/Form'
import { debounce } from 'lodash'

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const [removed] = list.splice(startIndex, 1)
  list.splice(endIndex, 0, removed)
  return list
}

// Maps block types to components, allows us to substitute to lightweight wrappers for the frontend
const components = {
  Region,
  Section: EditorSectionBlock,
  Row: EditorRowBlock,
  Content: EditorContentBlock,
}

class PageBuilder extends React.Component {
  constructor(props) {
    super(props)

    const content = instantiateBlocks(props.content)

    this.state = {
      showAddBlockPanel: false,
      draggingType: null,
      settings: null,
      toolbars: [],
      content,
      mode: 'edit',
      showSaveBlockTemplateModal: false,
      blockTemplate: null,
    }
  }

  static propTypes = {
    className: PropTypes.string,
    getPreview: PropTypes.func,
    content: PropTypes.array.isRequired,
  }

  locationToPath(location) {
    const path = []

    if (location.section !== undefined) {
      path.push(location.section)
    }
    if (location.row !== undefined) {
      path.push('content')
      path.push(location.row)
    }
    if (location.contentId !== undefined) {
      path.push('content')
      path.push(location.contentId)
    }
    if (location.content !== undefined) {
      path.push(location.content)
    }

    return path
  }

  getBlock(path) {
    const block = get(this.state.content, path)
    if (block) {
      return block
    }
  }

  getRegion(path) {
    path = Array.from(path)
    const region = get(this.state.content, path)
    if (region) {
      return region
    }
  }

  getParentRegion(path) {
    path = Array.from(path)
    path.pop()
    if (path.length === 0) {
      return this.state.content
    }
    const region = get(this.state.content, path)
    if (region) {
      return region
    }
  }

  _setContent(path, value) {
    const content = setWith(this.state.content, path, value, (nsValue, key, nsObject) => {
      if (nsValue instanceof Block) {
        const BlockClass = BlockManager.getBlockClass(nsValue.type)
        return new BlockClass(nsValue)
      } else if (nsValue instanceof Array) {
        return Array.from(nsValue)
      }
    })

    return Array.from(content)
  }

  setBlock(path, block) {
    const BlockClass = BlockManager.getBlockClass(block.type)
    const newBlock = new BlockClass(block)
    return this._setContent(path, newBlock)
  }

  setRegion(path, region) {
    path = Array.from(path)
    return this._setContent(path, region)
  }

  setParentRegion(path, region) {
    path = Array.from(path)
    path.pop()
    if (path.length === 0) {
      return region
    }
    return this._setContent(path, region)
  }

  updateBlock = (location, data, blockContent) => {
    let content

    const path = this.locationToPath(location)
    let block = this.getBlock(path)
    if (!block) {
      console.warn('Block not found: ', { location, path })
      return
    }
    if (block.constructor.editor?.onEditorUpdate) {
      const { newData, newContent } = block.constructor.editor.onEditorUpdate(
        block,
        data,
        blockContent
      )
      data = newData
      blockContent = newContent
    }
    if (data) {
      block.data = data
    }
    if (blockContent) {
      block.content = blockContent
    }

    content = this.setBlock(path, block)

    this.setState({ content })
  }

  _deleteBlock(path) {
    let region = this.getParentRegion(path)
    region = Array.from(region)
    let spliceIndex = path[path.length - 1]
    region.splice(spliceIndex, 1)

    return this.setParentRegion(path, region)
  }

  removeBlock = (location) => {
    const path = this.locationToPath(location)
    this.closeBlockSettings()
    this.setState({
      content: this._deleteBlock(path),
    })
  }

  parseDroppableId(id) {
    const result = {}

    const [sectionPart, rowPart, contentId] = id.split('-')

    if (sectionPart) {
      const [id, index] = sectionPart.split(':')
      result.sectionId = id
      result.section = index
    }

    if (rowPart) {
      const [id, index] = rowPart.split(':')
      result.rowId = id
      result.row = index
    }

    if (contentId) {
      result.contentId = contentId
    }

    return result
  }

  handleSort(result) {
    const { destination, type } = result

    let content

    if (type === 'section') {
      const item = this.state.content[result.source.index]
      content = Array.from(this.state.content)
      content.splice(result.source.index, 1)
      content.splice(result.destination.index, 0, item)
    } else {
      const location = this.parseDroppableId(destination.droppableId)
      const path = this.locationToPath(location)

      if (type === 'row') {
        path.push('content')
      }

      const region = this.getRegion(path)
      if (!region) {
        throw new Error(`Target region not found for path ${path}`)
      }

      content = this.setRegion(
        path,
        reorder([...region], result.source.index, result.destination.index)
      )
    }

    this.closeBlockSettings()
    this.setState({
      content,
    })
  }

  handleContentDrop(result) {
    const { destination } = result

    const location = this.parseDroppableId(destination.droppableId)
    const path = this.locationToPath(location)

    const newBlock = BlockManager.newBlockInstance(result.draggableId)

    let region = this.getRegion(path)
    if (!region) {
      region = [newBlock]
    } else {
      region = [...region]
      region.splice(destination.index, 0, newBlock)
    }

    const content = this.setRegion(path, region)

    this.setState({
      content,
      showAddBlockPanel: false,
    })
  }

  moveBlock(result) {
    const { source, destination, type } = result
    let content

    const sourceLocation = this.parseDroppableId(source.droppableId)
    const sourcePath = this.locationToPath(sourceLocation)
    const destinationLocation = this.parseDroppableId(destination.droppableId)
    const destinationPath = this.locationToPath(destinationLocation)

    let blockPath
    if (type === 'row') {
      blockPath = [...sourcePath, 'content', source.index]
      destinationPath.push('content')
    } else if (type === 'content') {
      blockPath = [...sourcePath, source.index]
    }

    const block = this.getBlock(blockPath)
    content = this._deleteBlock(blockPath)

    let region = this.getRegion(destinationPath)
    if (!region) {
      region = [block]
    } else {
      region = [...region]
      region.splice(destination.index, 0, block)
    }

    content = this.setRegion(destinationPath, region)

    this.closeBlockSettings()
    this.setState({
      content,
    })
  }

  saveBlockTemplate = (block) => {
    console.log(block)
  }

  cloneBlock = (location) => {
    const path = this.locationToPath(location)
    const block = this.getBlock(path)

    if (!block) {
      throw new Error(`Failed to clone block. No block found at path ${path}`)
    }

    // Loop through every block and assign a new ID
    const BlockClass = BlockManager.getBlockClass(block.type)
    const clonedBlock = new BlockClass(cloneDeep(block))
    generateNewBlockIds(clonedBlock, true)

    let content
    if (typeof location.row !== 'undefined') {
      let region = this.getParentRegion(path)
      if (!region) {
        return
      }
      region = [...region]
      region.splice((location.content || location.row) + 1, 0, clonedBlock)
      content = this.setParentRegion(path, region)
    } else {
      content = Array.from(this.state.content)
      content.splice(location.section + 1, 0, clonedBlock)
    }

    this.closeBlockSettings()
    this.setState({
      content,
    })
  }

  onDragStart = (start, provided) => {
    this.setState({
      draggingType: start.type,
    })
  }

  onDragEnd = (result) => {
    const { source, destination } = result

    this.setState({
      draggingType: null,
    })

    // dropped outside a list
    if (!destination) {
      return
    }

    if (result.type === 'content' && source.droppableId.includes('add-')) {
      this.handleContentDrop(result)
      return
    }

    if (source.droppableId === destination.droppableId) {
      this.handleSort(result)
    } else {
      this.moveBlock(result)
    }
  }

  openBlockSettings = (block, locationQuery) => {
    window.postMessage(
      {
        key: 'pb_settings',
        settings: { block: omit(block.toJSON(), 'content'), locationQuery },
      },
      '*'
    )
  }

  closeBlockSettings = () => {
    window.postMessage({ key: 'pb_settings', settings: null }, '*')
  }

  componentDidMount() {
    window.postMessage('pb_init', '*')
    window.addEventListener('message', this.receiveMessage, false)
  }

  transmitContent = () => {
    // Loop thru content to find all rich text fields and mutate them into serialized strings
    let content = cloneDeep(this.state.content)

    // each section
    serialize(content)

    content = JSON.stringify(content)
    window.postMessage({ key: 'pb_content', content }, '*')
  }

  receiveMessage = (event) => {
    switch (event.data.key || event.data) {
      case 'pb_updateBlock':
        const { location, data, blockContent } = event.data.update
        return this.updateBlock(location, data, blockContent)
      case 'pb_mode_edit':
        return this.setState({
          mode: 'edit',
        })
      case 'pb_mode_preview':
        return this.setState({
          mode: 'preview',
        })
      case 'pb_undo':
        break
      case 'pb_redo':
        break
      case 'pb_requestContent':
        return this.transmitContent()
    }
  }

  addSection = (location) => {
    this.setState(() => {
      const newSection = BlockManager.newBlockInstance('Section')
      const newRow = BlockManager.newBlockInstance('Row')
      newSection.content = [newRow]
      const content = [...this.state.content, newSection]
      return { content }
    })
  }

  addRow = (location) => {
    const newRow = BlockManager.newBlockInstance('Row')
    const path = [location.section, 'content']

    let region = this.getRegion(path)
    if (!region) {
      return
    }
    region = [...region, newRow]
    const content = this.setRegion(path, region)
    this.setState({ content })
  }

  toggleSaveBlockTemplateModal = (blockTemplate, locationQuery) => {
    this.setState({
      blockTemplate,
      blockTemplateLocationQuery: locationQuery,
      showSaveBlockTemplateModal: !this.state.showSaveBlockTemplateModal,
    })
  }

  toggleAddBlockPanel = () => {
    this.setState({
      showAddBlockPanel: !this.state.showAddBlockPanel,
    })
  }

  render() {
    const { className, getPreview } = this.props

    return (
      <div className={className}>
        {(this.state.mode === 'preview' &&
          getPreview(serialize(cloneDeep(this.state.content)))) || (
          <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
            <DraggingContext.Provider value={this.state.draggingType}>
              <Form>
                {/* <SaveBlockTemplateModal
                  block={this.state.blockTemplate}
                  locationQuery={this.state.blockTemplateLocationQuery}
                  isOpen={this.state.showSaveBlockTemplateModal}
                  toggle={this.toggleSaveBlockTemplateModal}
                  updateBlock={this.updateBlock}
                /> */}
                <AddBlockPanel
                  isVisible={this.state.showAddBlockPanel}
                  hide={this.toggleAddBlockPanel}
                  isDragging={this.state.draggingType !== null}
                />
                <AddBlockButton onClick={this.toggleAddBlockPanel} />
                <Canvas
                  content={this.state.content}
                  // todo expose these via a context so they dont change every time?
                  components={components}
                  functions={{
                    // saveBlockTemplate: this.toggleSaveBlockTemplateModal,
                    cloneBlock: this.cloneBlock,
                    removeBlock: this.removeBlock,
                    // updateBlock: this.updateBlock,
                    updateBlock: debounce(this.updateBlock, 100),
                    openBlockSettings: this.openBlockSettings,
                    closeBlockSettings: this.closeBlockSettings,
                    addSection: this.addSection,
                    addRow: this.addRow,
                  }}
                />
              </Form>
            </DraggingContext.Provider>
          </DragDropContext>
        )}
      </div>
    )
  }
}

export default styled(PageBuilder)`
  background: #fff;
`
