import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListOl } from '@fortawesome/pro-solid-svg-icons/faListOl'
import {
  isList,
  isOrderedList,
  applyList,
  isUnorderedList,
  switchToOrderedList,
  deepRemoveList,
} from './utils'

export const toggleOrderedList = editor => {
  const { value } = editor
  // If it is not a list yet, transform it!
  if (!isList(value)) return applyList(editor, 'ordered-list')

  // If it is already a list, handle it!
  if (isOrderedList(value)) return deepRemoveList(editor)
  else if (isUnorderedList(value)) return switchToOrderedList(editor)
  else console.info('[SlateJS][ListPlugin] It is a different type of list.')
  return editor
}

export const OrderedListButton = ({ editor, className }) => (
  <button
    type="button"
    onClick={e => toggleOrderedList(editor)}
    className={classnames(
      'slate-orderedList-plugin--button',
      { active: isOrderedList(editor.value) },
      className
    )}
  >
    <FontAwesomeIcon icon={faListOl} fixedWidth />
  </button>
)

export const OrderedListPlugin = options => ({
  // onKeyDown(event, editor, next) {
  //   if (isMod(event) && event.key === 'u') {
  //     event.preventDefault()
  //     return toggleStrikethrough(editor)
  //   }
  //   next()
  // },
})

export const OrderedListNode = ({ attributes, children }) => (
  <ol {...attributes}>{children}</ol>
)
