import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import Block, { BlockData } from '../../Block'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { RGBAColor } from '../../../types'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import ScoreGauge from '../../../../backend/client/components/ScoreGauge'

export interface ScoreProps {
  block: ScoreBlock
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class Score extends React.PureComponent<ScoreProps> {
  render() {
    const { block, className, context } = this.props

    return (
      <div className={classnames('block', className)}>
        <ScoreGauge
          value={context.response.score || 0}
          color={block.data?.config?.color}
          size="xl"
          animateValue
          showPercent
        />
      </div>
    )
  }
}

const StyledScore = styled(Score)`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'Score'

export interface ScoreBlockConfig {
  color?: RGBAColor
}

export interface ScoreBlockData extends BlockData {
  config?: ScoreBlockConfig
}

export default class ScoreBlock extends Block {
  static type? = key
  declare data?: ScoreBlockData

  static template: Partial<ScoreBlock> = {
    data: {
      config: {
        color: { r: 44, g: 163, b: 11, a: 1 },
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<ScoreBlock>) {
    super(params)
    Object.assign(this, ScoreBlock.template, params)
  }

  getComponent(): React.ElementType {
    return StyledScore
  }
}

BlockManager.registerBlockClass(ScoreBlock)
