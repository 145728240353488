import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import BlockManager from '../blocks/BlockManager'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import { Tabs } from '../../backend/client/components/TabNav'
import { TabContent, TabPane } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'

const portal = document.createElement('div')
portal.classList.add('draggable-portal')
document.body.appendChild(portal)

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // change background colour if dragging
  // background: isDragging ? 'lightgreen' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle,
})

class PortalBlock extends React.PureComponent {
  render() {
    const { className, icon, label, provided, snapshot } = this.props
    const block = (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      >
        <div className={className + ' row align-items-center no-gutters'}>
          <div className="icon col-auto">
            <FontAwesomeIcon fixedWidth icon={icon} />
          </div>
          <div className="label col">{label}</div>
        </div>
      </div>
    )

    if (snapshot.isDragging) {
      return ReactDOM.createPortal(block, portal)
    }

    return block
  }
}

const Block = styled((props) => (
  <Draggable draggableId={props.type} type="content" index={props.index}>
    {(provided, snapshot) => (
      <PortalBlock
        {...props}
        isDragging={snapshot.isDragging}
        snapshot={snapshot}
        provided={provided}
      />
    )}
  </Draggable>
))`
  background: #fff;
  border-bottom: 1px solid #dde1e4;
  transition: box-shadow 0.25s ease-out;
  padding: 0.75rem 1rem;
  font-weight: 600;
  font-size: 0.875rem;
  color: #3a5f82;
  cursor: grab;
  margin: 0;
  transform: translate3d(0, 0, 0);

  .icon {
    font-size: 1.2rem;
    margin-right: 0.7em;
  }

  &:hover {
    color: var(--primary);
    box-shadow: inset 0 0 0 1px var(--primary);
  }
`
Block.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.string,
  index: PropTypes.number,
}

class Panel extends React.PureComponent {
  state = {
    activeTab: 'content',
  }

  ref = React.createRef()

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleBodyClick)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleBodyClick)
  }

  handleBodyClick = (event) => {
    if (this.ref.current.contains(event.target)) {
      return
    }

    if (this.props.isVisible) {
      this.props.hide()
    }
  }

  render() {
    const { activeTab } = this.state
    const { className, hide } = this.props
    return (
      <div className={className} ref={this.ref}>
        <div className="closePanel" onClick={hide}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="panel-title">Add Block</div>
        <Tabs
          items={[
            {
              label: 'Content',
              onClick: () => this.toggle('content'),
              isActive: activeTab === 'content',
            },
            {
              label: 'Profile',
              onClick: () => this.toggle('profile'),
              isActive: activeTab === 'profile',
            },
            {
              label: 'Form',
              onClick: () => this.toggle('form'),
              isActive: activeTab === 'form',
            },
            // {
            //   label: 'Templates',
            //   onClick: () => this.toggle('templates'),
            //   isActive: activeTab === 'templates',
            // },
          ]}
        />
        <div className="content">
          <div className="row align-items-stretch no-gutters">
            <div className="col-6 block-col">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="content">
                  <Droppable droppableId="add-content" type="content" isDropDisabled>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef}>
                        {BlockManager.getBlocksInCategory('content').map((b, index) => (
                          <Block key={b.type} {...b} index={index} />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </TabPane>
                <TabPane tabId="profile">
                  <Droppable droppableId="add-profile" type="content" isDropDisabled>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef}>
                        {BlockManager.getBlocksInCategory('profile').map((b, index) => (
                          <Block key={b.type} {...b} index={index} />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </TabPane>
                <TabPane tabId="form">
                  <Droppable droppableId="add-form" type="content" isDropDisabled>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef}>
                        {BlockManager.getBlocksInCategory('form').map((b, index) => (
                          <Block key={b.type} {...b} index={index} />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </TabPane>
                <TabPane tabId="templates">
                  <Droppable droppableId="add-form" type="content" isDropDisabled>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef}>
                        <div className="row no-gutters align-items-stretch">templates here</div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </TabPane>
              </TabContent>
            </div>
            <div className="col-6 info-col" />
          </div>
        </div>
      </div>
    )
  }
}

const AddBlockPanel = styled(Panel)`
  position: fixed;
  left: 0;
  bottom: 7rem;
  background: #fff;
  z-index: 2147483500;
  width: 600px;
  margin-left: calc(50vw - 300px);
  border: 1px solid #ccc;
  box-shadow: 0 4px 28px 3px rgba(0, 0, 0, 0.2);
  border-radius: 3px;

  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: ${(props) => (props.isVisible ? 'fadeIn' : 'fadeOutNone')};

  ${(props) => {
    if (props.isDragging) {
      return {
        animationName: 'fadeOutNone',
      }
    }
  }}

  .TabNav {
    box-shadow: inset 0 -1px 0px 0px #eef1f3;
  }

  .block-col {
    height: calc(70vh - 100px - 7rem);
    overflow: auto;
  }

  .info-col {
    background: #fff;
    border-left: 1px solid #eef1f3;
    padding: 1rem;
  }

  .panel-title {
    background: var(--primary);
    color: #fff;
    padding: 0.9rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    position: relative;
  }

  .closePanel {
    position: absolute;
    cursor: pointer;
    top: 0.25rem;
    right: 0.4rem;
    padding: 0.5rem;
    line-height: 1;
    font-size: 1.8rem;
    color: #fff;
    z-index: 1;
    &:hover {
      opacity: 0.8;
    }
  }

  .content {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #fbfbfb;
    overflow: hidden;
  }
`
export default AddBlockPanel
