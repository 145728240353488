import React from 'react'
import { faTrophy } from '@fortawesome/pro-solid-svg-icons/faTrophy'
import ScoreBlock from './Score'
import Colorpicker from '../../../../_shared/components/Form/Colorpicker'
import BlockManager from '../../BlockManager'

const Form = (props) => (
  <div className="section-content">
    <Colorpicker name="config.color" />
  </div>
)

class ScoreBlockEditor extends ScoreBlock {
  static editor = {
    label: 'Score',
    icon: faTrophy,
    category: 'content',
    settingsForm: Form,
    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        color: {
          title: 'Gauge Color',
          type: 'object',
          properties: {
            r: {
              type: 'number',
            },
            b: {
              type: 'number',
            },
            g: {
              type: 'number',
            },
            a: {
              type: 'number',
            },
          },
          additionalProperties: false,
          required: ['r', 'g', 'b', 'a'],
        },
      })
      schema.required.push('color')
    },
  }
}

BlockManager.registerBlockClass(ScoreBlockEditor)
