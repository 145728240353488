import React, { ReactNode } from 'react'
import { FormGroup } from 'reactstrap'
import withForm from '../withForm'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import classnames from 'classnames'
import RCSlider from 'rc-slider'
import styled from 'styled-components'
import { FormContextProps } from '../Form'

const Handle = RCSlider.Handle

const Tooltip = ({ className, value }: { className?: string; value: string }) => (
  <div className={className}>
    <div className="ctooltip">{value}</div>
  </div>
)
const TooltipStyled = styled(Tooltip)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 0.75rem;
  .ctooltip {
    display: none;
  }
  &:hover,
  &:focus,
  &:active {
    .ctooltip {
      display: block;
      padding: 0.1rem;
      background: #000000c4;
      color: #fff;
      border-radius: 3px;
      text-align: center;
      top: -120%;
      left: 0;
      position: absolute;
      bottom: auto;
      right: 0;
    }
  }
`

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props
  return (
    <Handle value={value} {...restProps} key={index}>
      <TooltipStyled value={value} />
    </Handle>
  )
}

export type SliderValue = number | number[]

export interface SliderBaseProps extends Partial<FormContextProps> {
  showLabel?: boolean
  label?: string
  id?: string
  placeholder?: string
  help?: string | ReactNode
  showValid?: boolean
  errors?: string[]
  validFeedback?: string
  onChange?: (value: SliderValue) => void
  name: string
  value?: SliderValue
  maxLength?: number
  disabled?: boolean
  readOnly?: boolean
  autoFocus?: boolean
  range?: boolean
  className?: string
  min?: number
  max?: number
  step?: number
  leftLabel?: string
  rightLabel?: string
  tooltip?: boolean
  defaultValue?: number
}

class SliderBase extends React.PureComponent<SliderBaseProps> {
  static defaultProps = {
    errors: [],
    showValid: false,
    showLabel: true,
  }

  componentWillUnmount() {
    if (this.props.setValue) {
      this.props.setValue(this.props, undefined)
    }
  }

  onChange = (value: SliderValue) => {
    const { name, setValue, onChange } = this.props
    if (setValue) {
      setValue(this.props, value)
    } else {
      onChange && onChange(value)
    }
  }

  render() {
    const {
      showLabel,
      className,
      name,
      label,
      formSchema,
      id,
      placeholder,
      errors,
      showValid,
      maxLength,
      help,
      autoFocus,
      validFeedback,
      value,
      disabled,
      readOnly,
      min,
      max,
      step,
      leftLabel,
      rightLabel,
      range,
      tooltip,
    } = this.props

    const SliderComponent = range ? RCSlider.Range : RCSlider

    const sliderProps: any = {}
    if (tooltip) {
      sliderProps.handle = handle
    }

    let _value: SliderValue = value // eslint-disable-line
    if (!_value) {
      if (range) {
        _value = [0, 100]
      } else {
        _value = 0
      }
    }

    const hasErrors = errors.length > 0

    return (
      <FormGroup className={classnames(getFormGroupClasses(errors, showValid), className)}>
        <FormLabel
          showLabel={showLabel}
          id={id || name}
          name={name}
          label={label}
          formSchema={formSchema}
        />

        {leftLabel || rightLabel ? (
          <div className="row slider-labels">
            <div className="col slider-left-label">{leftLabel}</div>
            <div className="col slider-right-label text-right">{rightLabel}</div>
          </div>
        ) : null}

        <SliderComponent
          name={name}
          className={name}
          maxLength={maxLength}
          placeholder={placeholder}
          valid={!hasErrors && showValid}
          invalid={hasErrors}
          onChange={this.onChange}
          value={_value as number & number[]}
          disabled={disabled}
          readOnly={readOnly}
          autoFocus={autoFocus}
          min={min}
          max={max}
          step={step}
          range
          {...sliderProps}
        />

        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
        <HelpText help={help} />
      </FormGroup>
    )
  }
}

export const Slider = styled(SliderBase)`
  margin-bottom: 2.5rem;
  .rc-slider-rail {
    height: 8px;
  }
  .rc-slider-track {
    height: 8px;
    background-color: #007aff;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  }
  .rc-slider-handle {
    margin-left: -16px;
    margin-top: -13px;
    width: 32px;
    height: 32px;
    background-image: linear-gradient(#ffffff, rgba(0, 0, 0, 0.05));
    border: solid 1px #ccc;
    background-color: #fff;
    z-index: 1000;
  }
  .rc-slider-handle:focus {
    border-color: #007aff;
    box-shadow: 0 0 5px 0 #007aff;
  }
  .rc-slider-handle-click-focused:focus {
    border-color: #ccc;
  }
  .rc-slider-handle:hover {
    border-color: #007aff;
  }
  .rc-slider-handle:active {
    border-color: #007aff;
    box-shadow: 0 0 5px 0 #007aff;
  }
  .slider-labels {
    margin-bottom: 0.5em;
  }
`

export default withForm(Slider)
