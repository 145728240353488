import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'voca/capitalize'
import toPath from 'lodash/toPath'
import get from 'lodash/get'
import { FormText, Label } from 'reactstrap'

export const getFormGroupClasses = (errors: string[] = [], showValid = false): string => {
  const classes = []
  if (errors.length > 0) {
    classes.push('is-invalid')
  } else {
    if (showValid) {
      classes.push('is-valid')
    }
  }
  return classes.join(' ')
}

export const getLabel = (name: string, label: string, formSchema: Record<string, any>): string => {
  if (label) {
    return label
  }

  // Support nested properties
  let pathName = name
  if (pathName && pathName.includes('.')) {
    pathName = name.replace('.', '.properties.')
  }

  if (formSchema && formSchema.properties) {
    const schema = get(formSchema.properties, toPath(pathName))
    if (schema && schema.title) {
      return schema.title
    }
  }

  return capitalize(toPath(name).pop())
}

export const FormErrors = ({ errors }: { errors: string[] }): React.ReactElement => {
  if (!errors || errors.length === 0) {
    return null
  }
  return (
    <>
      {errors.map((error, index) => {
        return (
          <div key={index} className="invalid-feedback">
            * {error}
          </div>
        )
      })}
    </>
  )
}

export const ValidFeedback = ({
  showValid,
  validFeedback,
}: {
  showValid: boolean
  validFeedback: string
}): React.ReactElement => {
  if (!showValid) {
    return null
  }
  return <div className="valid-feedback">{validFeedback}</div>
}

export const HelpText = ({ help }: { help: string | React.ReactNode }): React.ReactElement => {
  if (!help) {
    return null
  }
  return <FormText color="muted">{help}</FormText>
}

export const FormLabel = ({
  showLabel,
  id,
  name,
  label,
  formSchema,
}: {
  showLabel: boolean
  id: string
  name: string
  label: string
  formSchema?: Record<string, any>
}): React.ReactElement => {
  if (!showLabel) {
    return null
  }

  return <Label for={id}>{getLabel(name, label, formSchema)}</Label>
}
