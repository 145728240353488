import getHeightStyles from './settings/Height/getHeightStyles'
import getWidthStyles from './settings/Width/getWidthStyles'
import getAlignmentStyles from './settings/Alignment/getAlignmentStyles'
import getBackgroundStyles from './settings/Background/getBackgroundStyles'
import getMarginStyles from './settings/Margin/getMarginStyles'
import getPaddingStyles from './settings/Padding/getPaddingStyles'
import getTextStyles from './settings/Text/getTextStyles'
import { BlockResponsiveAppearance, BlockAppearanceConfig, BlockStyleContext } from './types'
import mediaQueries from '../backend/client/css/_media'

const joinStyles = (styles: BlockResponsiveAppearance, newStyles: BlockResponsiveAppearance) => {
  if (newStyles.desktop) {
    styles.desktop += newStyles.desktop
  }
  if (newStyles.tablet) {
    styles.tablet += newStyles.tablet
  }
  if (newStyles.mobile) {
    styles.mobile += newStyles.mobile
  }
  if (newStyles.all) {
    styles.all += newStyles.all
  }
}

export const getBlockStyles = (
  data?: BlockAppearanceConfig,
  context?: BlockStyleContext
): string => {
  const styles = {
    desktop: '',
    tablet: '',
    mobile: '',
    all: '',
  }

  joinStyles(styles, getMarginStyles(data))
  joinStyles(styles, getPaddingStyles(data))
  joinStyles(styles, getWidthStyles(data))
  joinStyles(styles, getHeightStyles(data))
  joinStyles(styles, getAlignmentStyles(data))
  joinStyles(styles, getBackgroundStyles(data, context))
  joinStyles(styles, getTextStyles(data))

  let style = ''
  if (styles.all) {
    style += styles.all
  }
  if (styles.mobile) {
    style += styles.mobile
  }
  if (styles.tablet) {
    style += `@media (min-width: ${mediaQueries.md}){${styles.tablet}}`
  }
  if (styles.desktop) {
    style += `@media (min-width: ${mediaQueries.lg}){${styles.desktop}}`
  }

  return style
}
