import React, { ReactElement, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { FormGroup } from 'reactstrap'
import withForm from '../withForm'
import styled from 'styled-components'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { FormContextProps } from '../Form'
import mediaQueries from '../../../backend/client/css/_media'

export const StyledCardboxItem = styled.div`
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-muted);
  transition: all 0.15s ease-out;
  flex: 0 0 auto;
  cursor: pointer;
  position: relative;
  background: #fff;

  pre {
    color: inherit;
  }

  font-size: 0.9375rem;

  p {
    margin: 0rem 0 0 0 !important;
    font-size: 0.875rem;
    line-height: 1.3;
  }
  .title {
    margin: 0 0 0.1rem 0;
    color: #304963;
    font-weight: 500;
    transition: color 0.15s ease-out;
  }

  .fa-circle-check {
    position: absolute;
    color: var(--primary);
    right: 0.35em;
    top: 0.35em;
    font-size: 1.25em;
    opacity: 0;
    transition: opacity 0.15s ease-out;
  }

  &:hover,
  &:focus {
    border-color: var(--primary);
    color: var(--text-muted-blue);
    .title {
      color: var(--primary);
    }
  }

  &.active,
  &:active {
    border-color: var(--primary) !important;
    box-shadow: 0 0 6px 0 rgba(15, 109, 199, 0.56);
    &,
    .title {
      color: var(--primary) !important;
    }
    .fa-circle-check {
      opacity: 100;
    }
  }

  .icon {
    text-align: center;

    @media (max-width: ${mediaQueries.md}) {
      margin-bottom: 1rem;
    }
  }

  &.disabled {
    cursor: not-allowed;
    .title {
      color: var(--text-muted);
    }
    color: #aaa;
  }
`

export interface CardboxItemProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  active?: boolean
  title: string
  description?: string
  disabled?: boolean
  image?: string
  value:
    | string
    | number
    | boolean
    | Record<string, any>
    | string[]
    | number[]
    | boolean[]
    | Record<string, any>[]
}

export const CardboxItem = ({
  onClick,
  active,
  title,
  description,
  disabled,
  image,
  value,
}: CardboxItemProps): React.ReactElement => {
  return (
    <StyledCardboxItem
      onClick={onClick}
      className={(active ? 'active ' : '') + (disabled ? 'disabled ' : '') + 'CardboxItem'}
    >
      <FontAwesomeIcon icon={faCheckCircle} />
      <div className="row align-items-center">
        {image ? (
          <div className="col-md-auto icon">
            <img src={image} />
          </div>
        ) : null}
        <div className="col-md">
          <div className="title">{title}</div>
          {description && <p>{description}</p>}
        </div>
      </div>
    </StyledCardboxItem>
  )
}

export const StyledCardbox = styled.div<{ vertical: boolean; thin: boolean }>`
  .items {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  }
  ${StyledCardboxItem} {
    margin: 0 1em 1em 0;
    width: 100%;
    display: flex;

    padding: ${(props) => (props.thin ? '.75em' : '1.5em')};
    .icon {
      padding-right: ${(props) => (props.thin ? '0' : '15px')};
    }
    .icon img {
      max-width: ${(props) => (props.thin ? '64px' : '90px')};
      max-height: ${(props) => (props.thin ? '64px' : '90px')};
    }

    @media (min-width: ${mediaQueries.md}) {
      width: ${(props) => (props.vertical ? '100%' : '40%')};
      min-width: ${(props) => (props.vertical ? '0' : '18rem')};
      max-width: ${(props) => (props.vertical ? 'none' : '450px')};
    }

    &:last-child {
      ${(props) => (props.vertical ? 'margin-bottom:0;' : '')};
    }
  }
  .form-text {
    margin: -0.5rem 0 1rem 0;
  }
`

export type CardboxValue =
  | string
  | number
  | boolean
  | Record<string, any>
  | string[]
  | number[]
  | boolean[]
  | Record<string, any>[]
export interface CardboxProps extends Partial<FormContextProps> {
  label?: string
  showLabel?: boolean
  id?: string
  help?: string | ReactNode
  errors?: string[]
  showValid?: boolean
  validFeedback?: string
  name: string
  value?: CardboxValue
  // children?: React.ReactChildren | JSX.Element[]
  vertical?: boolean
  thin?: boolean
  multiple?: boolean
  selectionRequired?: boolean
  defaultValue?: CardboxValue
  unsetOnUnmount?: boolean
  onChange?: (value: CardboxValue) => void
}

class Cardbox extends React.PureComponent<CardboxProps> {
  static defaultProps = {
    errors: [],
    showValid: false,
    showLabel: true,
    options: [],
    value: [],
  }

  componentDidMount() {
    const { defaultValue, multiple, value, setValue } = this.props
    if (defaultValue && typeof value === 'undefined') {
      if (multiple && !(defaultValue instanceof Array)) {
        throw new Error('defaultValue must be an array when multiple is set')
      }
      setValue(this.props, defaultValue)
    }
  }

  componentWillUnmount() {
    if (this.props.unsetOnUnmount !== false) {
      this.props.setValue(this.props, undefined)
    }
  }

  clickItem = (newValue: CardboxValue) => {
    const { value, selectionRequired, setValue, multiple } = this.props

    if (multiple) {
      const currentValue: any[] = (value as any[]) || []
      if (currentValue.find((v) => v === newValue)) {
        newValue = currentValue.filter((v) => v !== newValue)
      } else {
        newValue = [...currentValue, newValue]
      }

      if (newValue.length === 0) {
        newValue = undefined
      }

      if (selectionRequired && newValue === undefined) {
        return
      }

      setValue(this.props, newValue)
    } else {
      const currentValue: any = value as any
      if (newValue === currentValue) {
        if (selectionRequired) {
          return
        }
        // Deselect the value
        setValue(this.props, undefined)
      } else {
        setValue(this.props, newValue)
      }
    }
  }

  render() {
    const {
      name,
      id,
      showLabel,
      label,
      formSchema,
      errors,
      value,
      showValid,
      vertical,
      thin,
      multiple,
      validFeedback,
      help,
    } = this.props

    const children = React.Children.map(this.props.children, (item: ReactElement) => {
      let active: boolean

      if (!item) return

      if (multiple) {
        const valueArr: any[] = value as any[]
        active = value && !!valueArr.find((v) => v === item.props.value)
      } else {
        active = value === item.props.value
      }

      return React.cloneElement(item, {
        onClick: () => {
          if (!item.props.disabled) {
            this.clickItem(item.props.value)
          }
        },
        active,
      })
    })

    return (
      <StyledCardbox vertical={vertical} thin={thin}>
        <FormGroup tag="fieldset" className={getFormGroupClasses(errors, showValid)}>
          <FormLabel
            showLabel={showLabel}
            id={id}
            name={name}
            label={label}
            formSchema={formSchema}
          />
          <HelpText help={help} />
          <div className="items">{children}</div>
          <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
          <FormErrors errors={errors} />
        </FormGroup>
      </StyledCardbox>
    )
  }
}

export default withForm(Cardbox)
