import React, { useContext } from 'react'
import styled from 'styled-components'
import BlockManager from '../BlockManager'
import { getBlockStyles } from '../../getBlockStyles'
import VividOverlay from './VividOverlay'
import { SectionOverlayConfig, SectionOverlayTypes, SectionProps } from './SectionTypes'
import { PageRendererComponentContext } from '../../PageRenderer'
import { RenderingComponents, RGBAColor } from '../../types'
import RowBlock from '../row/Row'
import Block, { BlockData } from '../Block'
import classnames from 'classnames'

export const SectionUnstyled = function ({
  block,
  blockHTMLAttributes,
  className,
  index,
}: SectionProps): React.ReactElement {
  const { data } = block

  // Determine overlay element
  const overlay = block.data?.config?.overlay
  let Overlay: React.ElementType
  if (overlay) {
    switch (overlay.type) {
      case SectionOverlayTypes.VIVID:
        Overlay = VividOverlay
        break
    }
  }

  const context = useContext<RenderingComponents>(PageRendererComponentContext)
  if (!context) {
    return null
  }
  const { Region, Row } = context

  return (
    <div
      {...blockHTMLAttributes}
      className={classnames(className, 'block block-section', data?.advanced?.cssClass)}
      id={data?.advanced?.cssId}
    >
      {Overlay && <Overlay {...overlay} />}
      <Region key={block.id} id={`${block.id}:${index}`} type="row">
        {block.content?.map((rowBlock, rowIndex) => (
          <Row
            key={rowBlock.id}
            block={rowBlock}
            section={block}
            sectionIndex={index}
            index={rowIndex}
          />
        ))}
      </Region>
    </div>
  )
}

// todo: Clean up the 'context' param in getBlockStyles
export const Section = styled(SectionUnstyled)<SectionProps>`
  position: relative;
  ${(props) =>
    getBlockStyles(props.block.data?.appearance, {
      location: props.context?.location as Location,
      block: props.block as Block,
    })}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'Section'

export interface SectionBlockConfig {
  overlay?: SectionOverlayConfig
}
export interface SectionBlockData extends BlockData {
  config: SectionBlockConfig
}
export default class SectionBlock extends Block {
  static type? = key
  declare content: RowBlock[]
  declare data?: SectionBlockData
  static template = {
    type: key,
    content: [],
    data: {
      appearance: {
        padding: {
          tablet: {
            top: '2',
            topUnit: 'rem',
            left: '2',
            leftUnit: 'rem',
            bottom: '2',
            bottomUnit: 'rem',
            right: '2',
            rightUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<SectionBlock>) {
    super(params)
    Object.assign(this, SectionBlock.template, params)
  }

  getComponent(): React.ElementType {
    return Section
  }

  toJSON(): Record<string, any> {
    const json = super.toJSON()
    json.content = this.content
    return json
  }
}
// SectionBlock.key = key

BlockManager.registerBlockClass(SectionBlock)
