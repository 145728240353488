import { BlockResponsiveAppearance, BlockAppearanceConfig } from '../../types'

export interface WidthConfig {
  type: string
  custom?: { value?: number; unit?: string }
}

export default function getWidthStyles(data: BlockAppearanceConfig): BlockResponsiveAppearance {
  let styles = ''

  const widthSettings = data?.width
  switch (widthSettings?.type) {
    case 'full':
      styles += 'width: 100%;'
      break
    case 'container':
      styles += 'max-width: 1200px;'
      break
    case 'custom':
      if (widthSettings?.custom?.value) {
        const { value, unit } = widthSettings.custom
        const custom = value
        const width = custom + (unit || 'px')
        styles += `max-width: ${width};`
        styles += `flex-grow: 0;`
        styles += `flex-basis: auto;`
      }

      break
    default:
      break
  }

  return { all: styles }
}
