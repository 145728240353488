import React from 'react'
import PropTypes from 'prop-types'
import { Editor } from 'slate-react'
import { Value } from 'slate'
import SlateToolbar from './SlateToolbar'
import SoftBreak from 'slate-soft-break'
import html from './serializer'
import { BoldPlugin, BoldMark } from './plugins/bold'
import { ItalicPlugin, ItalicMark } from './plugins/italic'
import { UnderlinePlugin, UnderlineMark } from './plugins/underline'
import { StrikethroughPlugin, StrikethroughMark } from './plugins/strikethrough'
import { OrderedListPlugin, OrderedListNode } from './plugins/orderedList'
import { UnorderedListPlugin, UnorderedListNode } from './plugins/unorderedList'
import { AlignmentPlugin, AlignNode } from './plugins/alignment'
import { FontSizePlugin, FontSizeMark } from './plugins/fontSize'
import { FontFamilyPlugin, FontFamilyMark } from './plugins/fontFamily'
import { ColorPlugin, ColorMark } from './plugins/color'
import { LinkPlugin, LinkNode } from './plugins/link'
import { LineHeightPlugin, LineHeightMark } from './plugins/lineHeight'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import RichTextBlock from './RichText'
import { faFont } from '@fortawesome/pro-solid-svg-icons/faFont'
import { HeadingNode } from './plugins/headings'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'

const plugins = [
  BoldPlugin(),
  ItalicPlugin(),
  UnderlinePlugin(),
  StrikethroughPlugin(),
  OrderedListPlugin(),
  UnorderedListPlugin(),
  AlignmentPlugin(),
  FontSizePlugin(),
  FontFamilyPlugin(),
  ColorPlugin(),
  LinkPlugin(),
  SoftBreak({ shift: true }),
  LineHeightPlugin(),
]

const CodeNode = (props) => (
  <pre {...props.attributes}>
    <code>{props.children}</code>
  </pre>
)

// Add a `renderNode` method to render a `CodeNode` for code blocks.
export const renderNode = (props, editor, next) => {
  switch (props.node.type) {
    case 'code':
      return <CodeNode {...props} />
    case 'ordered-list':
      return <OrderedListNode {...props} />
    case 'unordered-list':
      return <UnorderedListNode {...props} />
    case 'list-item':
      return <li {...props.attributes}>{props.children}</li>
    case 'align':
      return <AlignNode {...props} />
    case 'link':
      return <LinkNode {...props} />
    case 'paragraph':
      return <p {...props.attributes}>{props.children}</p>
    case 'heading':
      return <HeadingNode {...props} />
    default:
      return next()
  }
}

// Add a `renderMark` method to render marks.
export const renderMark = (props, editor, next) => {
  switch (props.mark.type) {
    case 'bold':
      return <BoldMark {...props} />
    case 'italic':
      return <ItalicMark {...props} />
    case 'underline':
      return <UnderlineMark {...props} />
    case 'strikethrough':
      return <StrikethroughMark {...props} />
    case 'font-size':
      return <FontSizeMark {...props} />
    case 'font-family':
      return <FontFamilyMark {...props} />
    case 'color':
      return <ColorMark {...props} />
    case 'line-height':
      return <LineHeightMark {...props} />
    default:
      return next()
  }
}

interface RichTextProps {
  block: RichTextBlock
  section: SectionBlock
  row: RowBlock
  updateBlock: (locationQuery: Record<string, any>, data: any, content: any) => void
  locationQuery: Record<string, any>
  blockProps: Record<string, any>
  className?: string
}

interface RichTextState {
  value: any
}

class RichText extends React.PureComponent<RichTextProps, RichTextState> {
  // Set the initial value when the app is first constructed.
  constructor(props) {
    super(props)

    this.state = {
      value: Value.fromJSON(
        props.block.content || {
          document: {
            nodes: [
              {
                object: 'block',
                type: 'paragraph',
                nodes: [
                  {
                    object: 'text',
                    leaves: [
                      {
                        text: 'Click here to edit rich text.',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        }
      ),
    }
  }

  // On change, update the app's React state with the new editor value.
  onChange = ({ value }) => {
    const { locationQuery, updateBlock } = this.props
    if (value.document !== this.state.value.document) {
      updateBlock(locationQuery, null, value.toJSON())
    }
    this.setState({ value })
  }

  // Render the editor.
  render() {
    const { block, blockProps, className } = this.props
    const isVisible = block.isVisible()

    return (
      <div
        {...blockProps}
        className={className + ` block block-content ${isVisible ? '' : 'block-hide'}`}
      >
        <Editor
          plugins={plugins}
          value={this.state.value}
          onChange={this.onChange}
          renderNode={renderNode}
          renderMark={renderMark}
          renderEditor={this.renderEditor}
        />
      </div>
    )
  }

  /**
   * Render the editor.
   *
   * @param {Object} props
   * @param {Function} next
   * @return {Element}
   */

  renderEditor = (props, editor, next) => {
    const children = next()
    return (
      <React.Fragment>
        {children}
        <SlateToolbar editor={editor} />
      </React.Fragment>
    )
  }
}

const StyledRichText = styled(RichText)`
  cursor: auto;
  p:last-child {
    margin-bottom: 0;
  }
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};

  &.block.block-content {
    opacity: 1;
  }

  &.block-hide {
    div[data-slate-editor] {
      opacity: 0.5 !important;
    }
  }
`

class RichTextBlockEditor extends RichTextBlock {
  static editor = {
    component: StyledRichText,
    label: 'Rich Text',
    icon: faFont,
    category: 'content',
  }
}

BlockManager.registerBlockClass(RichTextBlockEditor)

export const serialize = (content) => {
  content.forEach((section) => {
    section.content?.forEach((row) => {
      row.content?.forEach((region) => {
        region?.forEach((block) => {
          if (block.type === 'RichText') {
            block.content = html.serialize(Value.fromJSON(block.content))
          }
        })
      })
    })
  })
  return content
}

export const deserialize = (content) => {
  content.forEach((section) => {
    section.content?.forEach((row) => {
      row.content?.forEach((region) => {
        region?.forEach((block) => {
          if (block.type === 'RichText') {
            block.content = html.deserialize(block.content).toJSON()
          }
        })
      })
    })
  })
  return content
}
