import { BlockResponsiveAppearance, BlockAppearanceConfig } from '../../types'

export default function getAlignmentStyles(data: BlockAppearanceConfig): BlockResponsiveAppearance {
  let styles = ''
  const align = data?.align
  switch (align) {
    case 'left':
      styles += `margin-right: auto;`
      break
    case 'right':
      styles += `margin-left: auto;`
      break
    case 'center':
    default:
      styles += `margin-left: auto;`
      styles += `margin-right: auto;`
  }

  return { all: styles }
}
