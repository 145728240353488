import React from 'react'
import { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar'
import CalendlyField from './Calendly'
import BlockManager from '../../BlockManager'
import Number from '../../../../_shared/components/Form/Number'
import Input from '../../../../_shared/components/Form/Input'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'

export const Form = (props) => (
  <div className="section-content">
    <Input name="config.url" />
    <Number name="config.height" />
    <Cardbox showLabel={false} name="config.submitWhenBooked" thin vertical defaultValue={true}>
      <CardboxItem
        value={true}
        title="Submit Step Automatically"
        description="Submit this step when an event is booked in Calendly"
      />
    </Cardbox>
  </div>
)

class CalendlyFieldEditor extends CalendlyField {
  static editor = {
    label: 'Calendly',
    category: 'content',
    icon: faCalendar,
    settingsForm: Form,
    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        height: {
          title: 'Embed Height',
          type: 'number',
        },
        url: {
          title: 'Calendly URL',
          type: 'string',
        },
        submitWhenBooked: {
          title: 'Submit Step When Booked',
          type: 'boolean',
        },
      })
    },
  }
}

BlockManager.registerBlockClass(CalendlyFieldEditor)