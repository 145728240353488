import Field, { FieldConfig, FieldData } from '../../Field'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Input from '../../../../_shared/components/Form/Input'
import classnames from 'classnames'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { ConfirmValueConfig } from '../../ConfirmValue'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import { SetConfirmValue } from '../../../settings/ConfirmValue'

export interface LastNameInputProps {
  block: LastNameField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class LastNameInput extends React.PureComponent<LastNameInputProps> {
  componentDidMount() {
    SetConfirmValue(this.props)
  }

  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)

    return (
      <Input
        name={block.id}
        className={classnames('block', className)}
        label={label}
        showLabel={!block.data.config.hideLabel}
        placeholder={block.data.config?.placeholder}
        autocomplete="lastname"
      />
    )
  }
}

export const StyledLastNameInput = styled(LastNameInput)`
  input {
    font-size: 1.066666666666667rem;
  }
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

export const key = 'LastName'

export interface LastNameFieldConfig extends ConfirmValueConfig {
  placeholder?: string
}

export interface LastNameFieldData extends FieldData {
  config?: LastNameFieldConfig
}

export default class LastNameField extends Field {
  static type? = key
  static enableConditionalLogic = true
  declare data: LastNameFieldData
  static template: Partial<LastNameField> = {
    data: {
      config: {
        label: 'Last Name',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
      advanced: {
        profile: {
          group: 'contact',
          key: 'lastname',
          label: 'Last Name',
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<LastNameField>) {
    super(params)
    Object.assign(this, LastNameField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledLastNameInput
  }
}

BlockManager.registerBlockClass(LastNameField)
