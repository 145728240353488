import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import styled from 'styled-components'
import { FieldResponse } from '../../../../_shared/models/response/response.types'

export interface MatchValueProps {
  field: FieldResponse
  className: string
}

const MatchValue: React.FC<MatchValueProps> = ({ field, className }: MatchValueProps) => {
  return (
    <div className={className}>
      <table className="table table-striped erMatch">
        <thead>
          <tr>
            <th>Preference</th>
            <th className="text-center">Match</th>
          </tr>
        </thead>
        <tbody>
          {field &&
            field.data?.map((answer, index) => (
              <tr key={index}>
                <td
                  className="erMatch-label"
                  dangerouslySetInnerHTML={{
                    __html: answer.feedback || answer.label, // eslint-disable-line
                  }}
                />
                <td className="erMatch-value">
                  {!answer.value ? (
                    <FontAwesomeIcon icon={faTimesCircle} className={`erMatch-false`} />
                  ) : (
                    <FontAwesomeIcon icon={faCheckCircle} className={`erMatch-true`} />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default styled(MatchValue)`
  .erMatch {
    page-break-inside: avoid;

    td {
      vertical-align: middle !important;
    }
  }

  tbody tr:nth-of-type(odd) {
    background-color: #fff;
  }

  tbody tr:nth-of-type(even) {
    background-color: #f9fafd;
  }

  .erMatch-value {
    text-align: center;
    width: 65px;
    font-size: 2em;
  }

  .erMatch-true {
    color: #4caf50;
  }

  .erMatch-false {
    color: #a1a1a1;
  }
`
