import Block, { BlockData } from './Block'
import ClientResponse from '../../_shared/models/response/response.client'
import { FieldResponse } from '../../_shared/models/response/response.types'
import { FrontendContext } from '../../_shared/StepSubmission'
import { substituteVariables } from './blockUtilities'
import { BlockDetailedOutputProps, BlockSimpleOutputProps } from './BlockManager'
import ConditionalLogic, {
  ConditionalLogicConfig,
  ConditionalLogicCondition,
} from './ConditionalLogic'
export interface FieldConfig {
  label: string
  required?: {
    type: 'conditional' | boolean
    conditional?: ConditionalLogicConfig
  }
  hideLabel?: boolean
  scoring?: {
    maxScore?: number
  }
  confirmFieldValue?: string
}
export interface FieldData extends BlockData {
  config?: FieldConfig
}

export default class Field extends Block {
  declare data?: FieldData
  declare static detailedOutput?: React.ComponentType<BlockDetailedOutputProps>
  declare static simpleOutput?: React.ComponentType<BlockSimpleOutputProps>
  declare static conditionalLogicCompare?: (
    field: FieldResponse,
    condition: ConditionalLogicCondition
  ) => boolean
  static enableStatistics?: boolean = false
  static enableConditionalLogic?: boolean = false

  static getConditionalLogicValue(response: FieldResponse): FieldResponse['value'] {
    return response.value
  }

  constructor(params: NonFunctionProperties<Block>) {
    super(params)
  }

  getFakeValue(context: FrontendContext): FieldResponse {
    return null
  }

  sanitizeValue(value?: any) {
    if (typeof value === 'string') {
      value = value.replace(/^\s+/, '').replace(/\s+$/, '')
    }
    return value
  }

  canRecap(): boolean {
    return true
  }

  getLabel(context?: FrontendContext): string {
    const label = this.data?.config?.label
    if (label) {
      return substituteVariables(label, context)
    }
  }

  /**
   * Returns a string representation of the field's value
   */
  getValue(answer: unknown): string {
    return String(answer)
  }

  /**
   * Converts the form value (usually a primitive) to a FieldResponse
   */
  formToDoc(value: unknown, context?: FrontendContext): FieldResponse {
    const answer: FieldResponse = {
      _id: this.id,
      name: this.data?.advanced?.name,
      label: this.getLabel(context),
      value: this.getValue(value),
      type: (this.constructor as any).type,
    }
    if (typeof value === 'object') {
      answer.data = value
    }
    return answer
  }

  docToForm(doc: { data?: unknown; value?: unknown }): unknown {
    return doc.data || doc.value
  }

  transformPostValue(value: unknown, context?: FrontendContext): unknown {
    if (value === '') {
      value = undefined
    }
    return value
  }

  isConditional(): boolean {
    const required = this.data.config.required
    if (required?.type === 'conditional') {
      return true
    }

    return super.isConditional()
  }

  isRequired(response?: ClientResponse): boolean {
    const required = this.data?.config?.required
    if (required) {
      const { type, conditional } = required
      switch (type) {
        case false:
          return false
        case true:
          return true
        case 'conditional':
          if (!response || !conditional) {
            return false
          }
          return ConditionalLogic.evaluate(conditional, response)
      }
    }
  }

  minScore(response?: ClientResponse): number {
    return 0
  }
  maxScore(response?: ClientResponse): number {
    return 0
  }
  calculateScore(answer: FieldResponse): number {
    return 0
  }

  getFormSchema(context?: FrontendContext): Record<string, any> {
    return {
      schema: {
        title: this.getLabel(context),
        type: 'string',
      },
    }
  }
}
