import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Provider } from 'react-redux'
import store from './editorRedux'
import Log, { LogLevel } from '../../../_shared/log'
import history from '../history'
import { Route, RouteProps, Router, Switch } from 'react-router-dom'
import StepEditorContainer from '../containers/StepEditorContainer'
import ReportEditorContainer from '../containers/ReportEditorContainer'

// Sentry.init({
//   enabled: !!process.env.CI && !process.env.AWS_SAM_LOCAL && process.env.NODE_ENV !== 'development',
//   dsn: 'https://cc28af38ce8b4badb987daee5d5a815f@o315296.ingest.sentry.io/1795485',
//   environment: VA.getEnvironment(),
//   integrations: [new Integrations.BrowserTracing()],
//   release: process.env.COMMIT,

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// })

const logLevel: LogLevel =
  LogLevel[new URLSearchParams(window.location.search).get('_logLevel')?.toUpperCase()] ||
  (window.location.href.includes('localhost:') ? LogLevel.INFO : LogLevel.WARN)
Log.setLogLevel(logLevel)
// Log.useSentry(Sentry)

const isEditor = window.location.href.includes('Editor') ? true : false

async function main() {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Route
          path="/pathways/:pathwayId/stepEditor/:stepId"
          exact
          component={StepEditorContainer}
        />
        <Route
          path="/pathways/:pathwayId/reportEditor/:reportId"
          exact
          component={ReportEditorContainer}
        />
      </Router>
    </Provider>,
    document.getElementById('root')
  )
}

if (isEditor) {
  main()
}

if (module.hot) {
  module.hot.accept()
}
