import Field, { FieldConfig, FieldData } from '../../Field'
import React from 'react'
import styled from 'styled-components'
import Input from '../../../../_shared/components/Form/Input'
import classnames from 'classnames'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { ConfirmValueConfig } from '../../ConfirmValue'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import { SetConfirmValue } from '../../../settings/ConfirmValue'

export interface FirstNameInputProps {
  block: FirstNameField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class FirstNameInput extends React.PureComponent<FirstNameInputProps> {
  // todo: is this actually necessary like in textinput?
  componentDidMount() {
    SetConfirmValue(this.props)
  }

  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)

    return (
      <Input
        name={block.id}
        className={classnames('block', className)}
        label={label}
        showLabel={!block.data?.config?.hideLabel}
        placeholder={block.data?.config?.placeholder}
        autocomplete="firstname"
      />
    )
  }
}

export const StyledFirstNameInput = styled(FirstNameInput)`
  input {
    font-size: 1.066666666666667rem;
  }
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

export const key = 'FirstName'

export interface FirstNameFieldConfig extends ConfirmValueConfig {
  placeholder?: string
}

export interface FieldNameFieldData extends FieldData {
  config?: FirstNameFieldConfig
}

export default class FirstNameField extends Field {
  static type? = key
  static enableConditionalLogic = true
  declare data?: FieldNameFieldData

  static template: Partial<FirstNameField> = {
    data: {
      config: {
        label: 'First Name',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
      advanced: {
        profile: {
          group: 'contact',
          key: 'firstname',
          label: 'First Name',
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<FirstNameField>) {
    super(params)
    Object.assign(this, FirstNameField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledFirstNameInput
  }
}

BlockManager.registerBlockClass(FirstNameField)
