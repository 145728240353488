import React from 'react'
import { FormContext } from './Form'
import hoistNonReactStatic from 'hoist-non-react-statics'
import toPath from 'lodash/toPath'
import get from 'lodash/get'

export interface FormComponentProps {
  value: unknown
  props: Record<string, any>
  formContext: any
  updateOnAllChanges?: boolean
  errors?: any
  Component: React.ComponentType // eslint-disable-line
}

export class FormComponent extends React.Component<FormComponentProps> {
  componentDidMount(): void {
    const { value, props, formContext } = this.props
    const { name, defaultValue } = props
    if (name && typeof value === 'undefined' && typeof defaultValue !== 'undefined') {
      formContext.setFieldValue(props.name, defaultValue)
    }
  }
  shouldComponentUpdate(nextProps: FormComponentProps): boolean {
    if (nextProps.value !== this.props.value) {
      return true
    }
    if (nextProps.props.children !== this.props.props.children) {
      return true
    }
    if (nextProps.errors !== this.props.errors) {
      return true
    }
    if (this.props.updateOnAllChanges) {
      if (nextProps.formContext.formData !== this.props.formContext.formData) {
        return true
      }
    }
    if (nextProps.props !== this.props.props) {
      return true
    }

    return false
  }
  render(): React.ReactElement {
    const { Component, props, formContext, errors, value } = this.props

    return <Component {...props} {...formContext} errors={errors} value={value} />
  }
}

interface WithFormProps {
  confirmValue?: string
}

function withForm<T>(
  Component: React.ComponentType<T>, // eslint-disable-line
  updateOnAllChanges = false
): React.ComponentType<T & WithFormProps> {
  const C = (props: any) => {
    return (
      <FormContext.Consumer>
        {(formContext) => {
          let value: any
          let errors: any[]
          if (props.name) {
            value = get(formContext.formData, toPath(props.name))
            if (props.errors) {
              errors = props.errors
            } else if (formContext.errors[props.name]) {
              errors = formContext.errors[props.name]
            }
          }

          // If the value is not in the answers, then we need to clear it
          if (value?.value && props.answers) {
            if (!props.answers.some((answer: any) => answer.value === value.value)) {
              value = undefined
              formContext.setFieldValue(props.name, undefined)
            }
          }

          return (
            <FormComponent
              Component={Component}
              props={props}
              updateOnAllChanges={updateOnAllChanges}
              formContext={formContext}
              errors={errors}
              value={value}
            />
          )
        }}
      </FormContext.Consumer>
    )
  }

  C.displayName = `withForm(${Component.displayName || Component.name})`

  return hoistNonReactStatic(C, Component)
}

export default withForm
