export enum LogLevel {
  DEBUG,
  INFO,
  WARN,
  ERROR,
}

export type Transaction = {
  name: string
  done: () => void
}

const Log = {
  logLevel: LogLevel.INFO,
  printConsole: true,
  integrations: {
    sentry: null,
    rollbar: null,
    cloudWatch: null,
  },

  setLogLevel(logLevel: LogLevel): void {
    this.logLevel = logLevel
  },

  useSentry(sentry: any): void {
    this.integrations.sentry = sentry
  },

  // useRollbar(rollbar: Rollbar): void {
  //   this.integrations.rollbar = rollbar;
  // },

  useCloudWatch(cloudwatch: any): void {
    this.integrations.cloudWatch = cloudwatch
  },

  info(message: string, context?: Record<string, any>, force?: boolean): void {
    if (force || this.logLevel <= LogLevel.INFO) {
      if (this.printConsole) {
        if (context) {
          console.log(message, context)
        } else {
          console.log(message)
        }
      }
    }
    // if (this.logLevel <= LogLevel.INFO) {
    //   if (process.env.CI !== 'true') {
    //     if (this.integrations.rollbar) {
    //       this.integrations.rollbar.info(message, context)
    //     }
    //     if (this.integrations.sentry) {
    //       if (context) {
    //         this.integrations.sentry.setExtras(context)
    //       }
    //       this.integrations.sentry.captureMessage(message)
    //     }
    //   }
    // }
  },

  warn(message: string, context?: Record<string, any>, force?: boolean): void {
    if (force || this.logLevel <= LogLevel.WARN) {
      if (this.printConsole) {
        if (context) {
          console.warn(message, context)
        } else {
          console.warn(message)
        }
      }
    }
    if (this.logLevel <= LogLevel.WARN) {
      if (process.env.CI !== 'true') {
        if (this.integrations.rollbar) {
          this.integrations.rollbar.warn(message, context)
        }
        if (this.integrations.sentry) {
          if (context) {
            this.integrations.sentry.setExtras(context)
          }
          this.integrations.sentry.captureMessage(message)
        }
      }
    }
  },

  debug(message: string, context?: Record<string, any>, force?: boolean): void {
    if (force || this.logLevel <= LogLevel.DEBUG) {
      if (this.printConsole) {
        if (context) {
          console.log(message, context)
        } else {
          console.log(message)
        }
      }
    }
    if (this.logLevel <= LogLevel.DEBUG) {
      if (process.env.CI !== 'true') {
        if (this.integrations.rollbar) {
          this.integrations.rollbar.debug(message, context)
        }
        if (this.integrations.sentry) {
          if (context) {
            this.integrations.sentry.setExtras(context)
          }
          this.integrations.sentry.captureMessage(message)
        }
      }
    }
  },

  error(error: Error, context?: Record<string, any>, force?: boolean): void {
    if (force || this.logLevel <= LogLevel.ERROR) {
      if (this.printConsole) {
        if (context) {
          console.error(error, context)
        } else {
          console.error(error)
        }
      }
    }
    if (this.logLevel <= LogLevel.ERROR) {
      if (process.env.CI !== 'true') {
        if (this.integrations.rollbar) {
          this.integrations.rollbar.error(error, context)
        }
        if (this.integrations.sentry) {
          if (context) {
            this.integrations.sentry.setExtras(context)
          }
          this.integrations.sentry.captureException(error)
        }
      }
    }
  },
}

export default Log
