import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PathwayContext from '../../PathwayContext'
import styled from 'styled-components'
import Input from '../../../_shared/components/Form/Input'
import Dropdown from '../../../_shared/components/Form/Dropdown'
import Cardbox, { CardboxItem } from '../../../_shared/components/Form/Cardbox'
import withForm from '../../../_shared/components/withForm'
import { ReactFrontendContext } from '../../../frontend/client/containers/StepViewContainer'

const AnsweredComparators = ['is answered', 'is not answered']

const StringComparators = ['equals', 'does not equal', 'contains', 'does not contain', 'regex']

const MultipleChoiceComparators = ['is', 'is not']

const NumericComparators = [
  'equals',
  'does not equal',
  'is greater than',
  'is greater than or equal to',
  'is less than',
  'is less than or equal to',
]

const BooleanComparators = ['is']

const Condition = (props) => {
  const { path, condition, index, pathway, company, programGroup, removeCondition } = props

  // Calculate second column options
  let targetOptions = []
  let targetCompare
  let ConditionValue
  let mixinAnsweredComparators
  const valueName = `${path}[${index}].value`

  switch (condition.type) {
    case 'answer':
      mixinAnsweredComparators = true
      pathway.getSteps().forEach((step) => {
        step.forEachBlock((block) => {
          if (block.constructor.enableConditionalLogic) {
            targetOptions.push({
              label: block.data.config.label,
              value: block.id,
            })
          }
        })
      })

      // Calculate third column options
      if (condition.target) {
        // Get the schema of the target block
        const target = pathway.getBlock(condition.target)

        if (target?.getOptions) {
          targetCompare = MultipleChoiceComparators

          // populate conditionvalue
          ConditionValue = (
            <Dropdown name={valueName} showLabel={false} size="sm" placeholder="(Select One)">
              {target.getOptions({ pathway, company, programGroup }).map((a) => (
                <option key={a.value} value={a.value}>
                  {a.label}
                </option>
              ))}
            </Dropdown>
          )
        } else if (target?.getFormSchema) {
          const schemaType = target.getFormSchema().schema.type
          switch (schemaType) {
            case 'string':
              targetCompare = StringComparators
              break
            case 'number':
              targetCompare = NumericComparators
              break
            case 'boolean':
              targetCompare = BooleanComparators
              break
            case 'array':
              mixinAnsweredComparators = false
              targetCompare = AnsweredComparators
          }
        }
      }
      break

    case 'meta':
      targetOptions = [
        { label: 'Score', value: 'score' },
        { label: 'Progress', value: 'progress' },
      ]

      switch (condition.target) {
        case 'score':
        case 'progress':
          targetCompare = NumericComparators
          break
      }
      break

    case 'tracking':
      targetOptions = [
        { label: 'Referrer', value: 'referrer' },
        { label: 'lead_src', value: 'lead_src' },
      ]

      switch (condition.target) {
        case 'referrer':
        case 'lead_src':
          targetCompare = StringComparators
          break
      }
      break
  }

  if (!ConditionValue) {
    switch (targetCompare) {
      case StringComparators:
        ConditionValue = <Input type="text" name={valueName} showLabel={false} size="sm" />
        break
      case NumericComparators:
        ConditionValue = <Input type="number" name={valueName} showLabel={false} size="sm" />
        break
      case BooleanComparators:
        ConditionValue = (
          <Dropdown name={valueName} showLabel={false} size="sm" placeholder="(Select One)">
            <option value={true}>true</option>
            <option value={false}>false</option>
          </Dropdown>
        )
        break
    }
  }

  if (mixinAnsweredComparators && targetCompare) {
    targetCompare = [...targetCompare, ...AnsweredComparators]
  }

  return (
    <div className="condition">
      <div className="row no-gutters align-items-center">
        <div className="col">
          <div className="row no-gutters">
            <div className="col-4">
              <Dropdown name={`${path}[${index}].type`} showLabel={false} size="sm">
                <option value="answer">Answer to</option>
                <option value="tracking">Tracking</option>
                <option value="meta">Meta</option>
              </Dropdown>
            </div>
            <div className="col">
              <Dropdown
                name={`${path}[${index}].target`}
                showLabel={false}
                size="sm"
                placeholder="(Select One)"
              >
                {targetOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Dropdown>
            </div>
          </div>
          {targetCompare && (
            <div className="row bottom-row no-gutters">
              <div className="col-auto">
                <Dropdown name={`${path}[${index}].comparison`} showLabel={false} size="sm">
                  {targetCompare.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Dropdown>
              </div>
              {!AnsweredComparators.includes(condition.comparison) && (
                <div className="col">{ConditionValue}</div>
              )}
            </div>
          )}
        </div>
        <div className="col-auto remove" onClick={removeCondition}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  )
}
Condition.propTypes = {
  path: PropTypes.string,
  condition: PropTypes.object,
  pathway: PropTypes.object,
  index: PropTypes.number,
  removeCondition: PropTypes.func,
}

class ConditionalOptions extends React.PureComponent {
  static propTypes = {
    value: PropTypes.object,
    name: PropTypes.string,
    className: PropTypes.string,
  }

  componentWillUnmount() {
    this.props.setValue(this.props, undefined)
  }

  addCondition = () => {
    let { value } = this.props
    if (!value || !value.conditions) {
      value = {
        conditions: [{}],
        matchType: value?.matchType || 'all',
      }
    } else {
      value = Object.assign({}, value)
      value.conditions.push({})
    }
    this.props.setValue(this.props, value)
  }

  removeCondition = (condition) => {
    let { value } = this.props
    value = Object.assign({}, value)
    value.conditions = value.conditions.filter((c) => c !== condition)
    this.props.setValue(this.props, value)
  }

  render() {
    const { name, value, className } = this.props

    return (
      <ReactFrontendContext.Consumer>
        {({ pathway, company, programGroup }) => (
          <div className={className}>
            <div className="match">
              <h6>Condition Requirements</h6>
              <Cardbox
                name={`${name}.matchType`}
                thin
                vertical
                showLabel={false}
                defaultValue="all"
              >
                <CardboxItem value="all" title="All conditions must be met" />
                <CardboxItem value="any" title="Any condition must be met" />
              </Cardbox>
            </div>

            <h6>Conditions</h6>

            {value?.conditions?.map((c, index) => (
              <Condition
                key={index}
                index={index}
                condition={c}
                path={`${name}.conditions`}
                pathway={pathway}
                company={company}
                programGroup={programGroup}
                removeCondition={() => {
                  this.removeCondition(c)
                }}
              />
            ))}
            <Button color="primary" onClick={this.addCondition} block>
              <FontAwesomeIcon icon={faPlus} /> Add Condition
            </Button>
          </div>
        )}
      </ReactFrontendContext.Consumer>
    )
  }
}

const StyledConditionalOptions = styled(ConditionalOptions)`
  background: #f2f4f7;
  margin: 0 -1rem -1rem -1rem !important;
  padding: 1rem;
  font-size: 0.875em;

  h6 {
    text-align: center;
    font-weight: 500;
  }
  .condition {
    border-bottom: 1px solid #e4e9ec;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    .row > div:first-child .form-group {
      margin-right: 0.35rem;
    }
    .bottom-row {
      margin-top: 0.35rem;
    }
    .form-group {
      margin: 0;
    }
    .remove {
      margin-left: 0.05rem;
      padding: 0rem 0.2rem;
      cursor: pointer;
      &:hover {
        color: var(--danger);
      }
    }
  }
`

export default withForm(StyledConditionalOptions)
