import React from 'react'
import { BoldButton } from './plugins/bold'
import { ItalicButton } from './plugins/italic'
import { UnderlineButton } from './plugins/underline'
import { OrderedListButton } from './plugins/orderedList'
import { UnorderedListButton } from './plugins/unorderedList'
import { AlignmentButtons } from './plugins/alignment'
import { FontSizeControl } from './plugins/fontSize'
import { FontFamilyControl } from './plugins/fontFamily'
import { LineHeightControl } from './plugins/lineHeight'
import { ColorControl } from './plugins/color'
import { LinkButton } from './plugins/link'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFont } from '@fortawesome/pro-solid-svg-icons/faFont'
import { faListUl } from '@fortawesome/pro-solid-svg-icons/faListUl'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import { faAlignLeft } from '@fortawesome/pro-solid-svg-icons/faAlignLeft'
import { findDOMNode } from 'slate-react'
import { RemoveFormattingButton } from './plugins/removeFormatting'
import { HeadingControl } from './plugins/headings'

const StyledDropdown = styled(Dropdown)`
  .dropdown-menu {
    margin: 0;
    background: #11365d;
    color: #fff;
    padding: 0;
    border: 0;
    min-width: 0;
    overflow: hidden;
  }
`

const StyledDropdownMenu = styled((props) => (
  <DropdownMenu className={props.className}>{props.children}</DropdownMenu>
))`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')} !important;
  flex-wrap: nowrap;
  align-items: center;
`
class AlignmentDropdown extends React.Component {
  state = {
    dropdownOpen: false,
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  render() {
    return (
      <StyledDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle tag="button" type="button" caret>
          <FontAwesomeIcon icon={faAlignLeft} fixedWidth />
        </DropdownToggle>
        <StyledDropdownMenu isOpen={this.state.dropdownOpen}>
          <AlignmentButtons editor={this.props.editor} />
        </StyledDropdownMenu>
      </StyledDropdown>
    )
  }
}

class ListDropdown extends React.Component {
  state = {
    dropdownOpen: false,
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  render() {
    const { editor } = this.props
    return (
      <StyledDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle tag="button" type="button" caret>
          <FontAwesomeIcon icon={faListUl} fixedWidth />
        </DropdownToggle>
        <StyledDropdownMenu isOpen={this.state.dropdownOpen}>
          <UnorderedListButton editor={editor} />
          <OrderedListButton editor={editor} />
        </StyledDropdownMenu>
      </StyledDropdown>
    )
  }
}

class FontDropdown extends React.Component {
  state = {
    dropdownOpen: false,
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  render() {
    const { editor } = this.props
    return (
      <StyledDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle tag="button" type="button" caret>
          <FontAwesomeIcon icon={faFont} fixedWidth />
        </DropdownToggle>
        <DropdownMenu style={{ width: '300px', padding: '0.5' }}>
          <HeadingControl editor={editor} />
          {/* <div className="row"> */}
          {/* <div className="col-8"> */}
          <FontSizeControl editor={editor} />
          {/* </div> */}
          {/* <div className="col-4">
              <LineHeightControl editor={editor} />
            </div> */}
          {/* </div> */}
          <FontFamilyControl editor={editor} />
        </DropdownMenu>
      </StyledDropdown>
    )
  }
}

const toolbarHeight = 35
const toolbarOffset = 20

function getToolbarPositionAbove(offsetTop, rect) {
  let pos = 0

  // block starts y
  // 32
  pos = offsetTop - toolbarHeight - toolbarOffset

  return { top: pos }
}

function getToolbarPositionBelow(offsetTop, rect) {
  let pos = 0

  // block starts y
  // 32
  pos = offsetTop + rect.height + toolbarOffset

  return { top: pos }
}

function getToolbarPosition(editor) {
  const { value } = editor

  let node
  try {
    node = findDOMNode(value.anchorBlock) // eslint-disable-line
  } catch (e) {}

  if (!node || !node.offsetParent) {
    return
  }

  const rect = node.getBoundingClientRect()
  let pos = getToolbarPositionAbove(node.offsetTop, rect)

  if (pos.top + node.offsetParent.getBoundingClientRect().y <= 0) {
    pos = getToolbarPositionBelow(node.offsetTop, rect)
  }

  const style = {
    ...pos,
  }
  if (value.selection.isFocused) {
    style.opacity = 1
    style.pointerEvents = 'all'
    style.zIndex = 2147483638
  }
  return style
}

const SlateToolbar = (props) => (
  <div className={props.className} style={getToolbarPosition(props.editor)}>
    <BoldButton editor={props.editor} />
    <ItalicButton editor={props.editor} />
    <UnderlineButton editor={props.editor} />
    <ColorControl editor={props.editor} />
    <LinkButton editor={props.editor} />
    <FontDropdown editor={props.editor} />
    <AlignmentDropdown editor={props.editor} />
    <ListDropdown editor={props.editor} />
    <RemoveFormattingButton editor={props.editor} />
  </div>
)

export default styled(SlateToolbar)`
  position: absolute;
  border-radius: 3px;
  color: #fff;
  left: 50%;
  transform: translateX(calc(-50% + 0.5px));
  transition: all 0.3s ease-out;
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  background: #11365d;
  opacity: 0;
  z-index: -1;
  pointer-events: none;

  &:focus-within {
    opacity: 1 !important;
    z-index: 2147483638 !important;
    pointer-events: all;
  }

  .dropdown-menu {
    transform: none !important;
    top: 34px !important;
  }

  .dropdown {
    &.show {
      .dropdown-toggle {
        background: rgba(0, 0, 0, 0.4);
      }
    }
    button,
    .dropdown-toggle {
      white-space: nowrap;
      outline: none;
    }
  }

  button {
    color: #fff;
    border: 0;
    background: transparent;
    padding: 0.3rem 0.5rem;
    outline: none !important;
    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }
    &.active {
      background: var(--primary);
    }

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    &[disabled] {
      opacity: 0.5;
    }
  }

  ${(props) => {
    if (props.anchored === true) {
      return `
        
      `
    }
  }}
`
