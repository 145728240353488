import React, { ChangeEvent } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Label, Input as StrapInput } from 'reactstrap'
import withForm from '../withForm'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import { FormContextProps } from '../Form'

export interface RadioOption {
  label: string
  value: string | number
}

export interface RadioProps extends Partial<FormContextProps> {
  name: string
  label?: string
  showLabel?: boolean
  id?: string
  help?: string
  errors?: string[]
  showValid?: boolean
  validFeedback?: string
  onChange?: (newValue: string) => void
  value?: string
  options?: RadioOption[]
  className?: string
}

class Radio extends React.PureComponent<RadioProps> {
  static defaultProps = {
    errors: [],
    showLabel: true,
    showValid: false,
    options: [],
  }

  componentWillUnmount() {
    this.props.setValue(this.props, undefined)
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.setValue(this.props, event.target.value)
  }

  render() {
    const {
      showLabel,
      name,
      id,
      label,
      formSchema,
      errors,
      validFeedback,
      showValid,
      help,
      value,
      className,
    } = this.props

    const hasErrors = errors.length > 0
    return (
      <FormGroup
        tag="fieldset"
        className={getFormGroupClasses(errors, showValid) + ' ' + className}
      >
        <FormLabel
          showLabel={showLabel}
          id={id}
          name={name}
          label={label}
          formSchema={formSchema}
        />
        <HelpText help={help} />
        <div className="radio-group">
          {this.props.options.map((option, index) => {
            return (
              <FormGroup key={index} check className={value == option.value ? 'active' : ''}>
                <div className="form-check-input-wrap">
                  <StrapInput
                    type="radio"
                    name={name}
                    id={name + '_' + index}
                    value={option.value}
                    valid={!hasErrors && showValid}
                    invalid={hasErrors}
                    onChange={this.onChange}
                    checked={value == option.value}
                  />
                </div>
                <Label check for={name + '_' + index}>
                  {option.label}
                </Label>
              </FormGroup>
            )
          })}
        </div>
        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
      </FormGroup>
    )
  }
}

export default withForm(Radio)
