import React from 'react'
import styled from 'styled-components'
import Block from '../../Block'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import BlockManager from '../../BlockManager'
import { getBlockStyles } from '../../../getBlockStyles'
import { substituteVariables } from '../../blockUtilities'
import { FrontendContext } from '../../../../_shared/StepSubmission'

export interface RichTextProps {
  block: RichTextBlock
  section?: SectionBlock
  row?: RowBlock
  className?: string
  context?: FrontendContext
}

class RichText extends React.PureComponent<RichTextProps> {
  // Render the editor.
  render() {
    const { block, className, context } = this.props

    const content = substituteVariables(block.content, context)

    return (
      <div
        className={className + ' block block-content'}
        dangerouslySetInnerHTML={{ __html: content }} // eslint-disable-line
      />
    )
  }
}

const StyledRichText = styled(RichText)`
  p:last-child {
    margin-bottom: 0;
  }
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'RichText'

export default class RichTextBlock extends Block {
  static type? = key
  declare content?: string

  static template = {
    type: key,
    data: {
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
    content: {
      document: {
        nodes: [
          {
            object: 'block',
            type: 'paragraph',
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: 'Click here to edit rich text.',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  }

  constructor(params: NonFunctionProperties<RichTextBlock>) {
    super(params)
    Object.assign(this, RichTextBlock.template, params)
  }

  getComponent(): React.ElementType {
    return StyledRichText
  }
}

BlockManager.registerBlockClass(RichTextBlock)
