import { faAt } from '@fortawesome/pro-solid-svg-icons/faAt'
import EmailField, { key } from './Email'
import { PlaceholderSchema } from '../../commonSchemas'
import React from 'react'
import ConfirmValue, { ConfirmValueSchema } from '../../../settings/ConfirmValue'
import Input from '../../../../_shared/components/Form/Input'
import BlockManager from '../../BlockManager'

export const Form = (props) => (
  <React.Fragment>
    <ConfirmValue fieldKey={key} />
    <div className="section-content">
      <Input name="config.placeholder" label="Placeholder" />
    </div>
  </React.Fragment>
)

class EmailFieldEditor extends EmailField {
  static editor = {
    label: 'Email',
    category: 'profile',
    icon: faAt,
    settingsForm: Form,
    canConfirmValue: true,
    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        ...PlaceholderSchema,
        ...ConfirmValueSchema,
      })
    },
  }
}

BlockManager.registerBlockClass(EmailFieldEditor)
