import styled from 'styled-components'
import React from 'react'
import { RGBAColor } from '../../types'

type VividPropTypes = {
  className: string
  colorOne: RGBAColor
  colorTwo: RGBAColor
}

const VividUnstyled = ({ className, colorOne, colorTwo }: VividPropTypes) => (
  <div className={className}>
    <div id="svg-fill" />
    <div id="svg-top">
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="xMinYMax slice"
        viewBox="0 0 480 130"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient x1="0%" y1="43.0347711%" x2="100%" y2="43.0347711%" id="linearGradient-1">
            <stop
              stopColor={`rgba(${colorOne?.r}, ${colorOne?.g}, ${colorOne?.b}, ${colorOne?.a})`}
              offset="5%"
            />
            <stop
              stopColor={`rgba(${colorTwo?.r}, ${colorTwo?.g}, ${colorTwo?.b}, ${colorTwo?.a})`}
              offset="80%"
            />
          </linearGradient>
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon
            id="Rectangle"
            fill="url(#linearGradient-1)"
            points="0 0 480 0 480 47.9215266 0 130"
          />
        </g>
      </svg>
    </div>
    <div id="svg-top-chunk">
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="xMinYMax slice"
        viewBox="0 0 480 130"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs />
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon
            id="Rectangle"
            fill={`rgba(${colorTwo?.r}, ${colorTwo?.g}, ${colorTwo?.b}, ${colorTwo?.a})`}
            points="0 0 480 0 480 47.9215266 0 130"
          />
        </g>
      </svg>
    </div>
    <div id="svg-middle-chunk">
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="xMaxYMin slice"
        viewBox="0 0 480 130"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs />
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon
            id="Rectangle"
            fill={`rgba(${colorTwo?.r}, ${colorTwo?.g}, ${colorTwo?.b}, ${colorTwo?.a})`}
            points="0 82.0761719 480 0 480 130 0 130"
          />
        </g>
      </svg>
    </div>
    <div id="svg-bottom">
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="xMaxYMin slice"
        viewBox="0 0 480 130"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient x1="0%" y1="43.0347711%" x2="100%" y2="43.0347711%" id="linearGradient-2">
            <stop
              stopColor={`rgba(${colorTwo?.r}, ${colorTwo?.g}, ${colorTwo?.b}, ${colorTwo?.a})`}
              offset="35%"
            />
            <stop
              stopColor={`rgba(${colorOne?.r}, ${colorOne?.g}, ${colorOne?.b}, ${colorOne?.a})`}
              offset="100%"
            />
          </linearGradient>
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon
            id="Rectangle"
            fill="url(#linearGradient-2)"
            points="0 82.0761719 480 0 480 130 0 130"
          />
        </g>
      </svg>
    </div>
  </div>
)
const VividOverlay = styled(VividUnstyled)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  #svg-fill {
    position: absolute;
    /* z-index: 1; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(90deg, #0089ce 38%, #a6ffcb 83%);
    opacity: 0.4;
  }
  #svg-top {
    position: absolute;
    /* z-index: 2; */
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.6;
    height: 180px;
    svg {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
    }
  }
  #svg-top-chunk {
    opacity: 0.1;
    position: absolute;
    /* z-index: 3; */
    top: 0;
    left: 0;
    right: 0;
    height: 135px;
    svg {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  #svg-middle-chunk {
    opacity: 0.15;
    position: absolute;
    top: 380px;
    left: 0;
    /* z-index: 2; */
    right: 0;
    bottom: 0;
    svg {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
    }
  }
  #svg-bottom {
    opacity: 0.4;
    position: absolute;
    top: 500px;
    left: 0;
    /* z-index: 3; */
    right: 0;
    bottom: 0;
    svg {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
    }
  }

  ${({ colorOne, colorTwo }) => `
    #svg-fill {
      background-image: linear-gradient(
        90deg,
        rgba(${colorOne?.r}, ${colorOne?.g}, ${colorOne?.b}, ${colorOne?.a}) 38%,
        rgba(${colorTwo?.r}, ${colorTwo?.g}, ${colorTwo?.b}, ${colorTwo?.a}) 83%
      );
    }
  `}
`

export default VividOverlay
