export interface FieldResponse {
  _id: string
  label?: string
  name?: string // does name really belong?
  value: string | string[] | number | number[] | boolean
  type: string
  data?: Record<string, any> | Array<any>
}

export interface Device {
  type: 'Mobile' | 'Desktop'
  source: string
  name?: string
  browser?: string
  version?: string
}

export interface StepResponse {
  _id: string
  name: string
  fields?: FieldResponse[]
}

// Metadata to be gleamed from query parameters
export const ResponseMetadata = [
  'referrer',
  'lead_src',
  'gclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'matchtype',
  'fbclid',
  'mscklid',
  'keyword',
  'crtv',
  'ntwk',
  'dvc',
  'sn',
  'dn',
  'webad',
  'adg',
  'cpgn',
  'kw',
  'site',
  'isTest',
  'embedPage',
  'fbc',
  'fbp',
  'metaPixels',
]

export enum ResponseStatus {
  PENDING = 'pending',
  DELIVERED = 'delivered',
  FAILED = 'failed',
  SKIPPED = 'skipped',
  ABORTED = 'aborted',
}

export const ResponseStatusSuccessLike = [ResponseStatus.DELIVERED, ResponseStatus.SKIPPED]

export type StepReference = { _id: string; name: string }
