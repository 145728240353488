import { Campus, Program } from './programGroup.types'

export default class ClientProgramGroup {
  constructor(props?: NonFunctionProperties<ClientProgramGroup>) {
    Object.assign(this, props)
  }

  _id?: string
  name: string
  companyId: string
  company?: Record<string, any>
  declare campuses?: Campus[]
  declare programs?: Program[]
  programGroup?: Record<string, any>
}
