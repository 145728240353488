import React from 'react'
import HTMLBlock from './HTML'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-html'
import { faCode } from '@fortawesome/pro-solid-svg-icons/faCode'
import Code from '../../../../_shared/components/Form/Code'
import BlockManager from '../../BlockManager'

import ace from 'ace-builds'

ace.config.setModuleUrl(
  'ace/mode/html_worker',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.12/src-noconflict/worker-html.js'
)

const forceWebpackToLoad = [AceEditor]

const Form = (props) => (
  <div className="section-content">
    <Code
      name="config.content"
      editorProps={{
        $blockScrolling: Infinity,
      }}
      mode="html"
    />
  </div>
)

class HTMLBlockEditor extends HTMLBlock {
  static editor = {
    label: 'HTML',
    icon: faCode,
    category: 'content',
    settingsForm: Form,
    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        content: {
          title: 'Content',
          type: 'string',
        },
      })
      schema.required.push('content')
    },
  }
}

BlockManager.registerBlockClass(HTMLBlockEditor)
