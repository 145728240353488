import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Block, { BlockData } from '../../Block'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import {
  FacebookShareButton,
  FacebookShareCount,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import ClientPathway from '../../../../_shared/models/pathway/pathway.client'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { FrontendContext } from '../../../../_shared/StepSubmission'

const ButtonContainer = styled.div`
  display: flex;
`

const ShareButton = (props) => {
  const { children, className } = props
  return <div className={className}>{children}</div>
}

ShareButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

const StyledShareButton = styled(ShareButton)`
  padding: 4px 8px;
  border-radius: 3px;
  color: #fff;
  background-color: ${(props) => props.color || ''};

  .SocialMediaShareCount {
    display: inline-block;
    border-radius: 2px;
    margin: -1px -2px -1px 3px;
    padding: 0 4px;
    background-color: ${(props) => props.countColor || ''};
  }
`

export interface SocialProps {
  block: SocialBlock
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class Social extends React.PureComponent<SocialProps> {
  render() {
    const { className, block, context } = this.props
    const buttons = block.data.config.buttons || ['facebook', 'twitter']
    const pathwayUrl = `https://${context?.pathway?.getPrimaryURL()}`
    return (
      <ButtonContainer>
        <div className={'block ' + className}>
          {buttons.length === 0 && <p>Please add some social buttons</p>}
          {buttons.indexOf('facebook') > -1 && (
            <FacebookShareButton url={pathwayUrl}>
              <StyledShareButton color="#3b5998" countColor="#2c4272">
                <FontAwesomeIcon icon={faFacebook} color="white" /> Share
              </StyledShareButton>
            </FacebookShareButton>
          )}
          {buttons.indexOf('twitter') > -1 && (
            <TwitterShareButton url={pathwayUrl}>
              <StyledShareButton color="#38A1F3">
                <FontAwesomeIcon icon={faTwitter} color="white" /> Tweet
              </StyledShareButton>
            </TwitterShareButton>
          )}
          {buttons.indexOf('linkedin') > -1 && (
            <LinkedinShareButton url={pathwayUrl}>
              <StyledShareButton color="#0077B5" countColor="#005f91">
                <FontAwesomeIcon icon={faLinkedin} color="white" /> Share
              </StyledShareButton>
            </LinkedinShareButton>
          )}
        </div>
      </ButtonContainer>
    )
  }
}

const StyledSocial = styled(Social)`
  align-items: center;
  ${(props) => {
    const height = props.block.data?.appearance?.height
    if (height?.type === 'custom') {
      return 'width:auto;'
    }
  }}
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
  .react-share__ShareButton {
    display: inline-block;
    margin-right: 1rem;
  }

  @media print {
    display: none;
  }
`

export interface SocialBlockConfig {
  buttons: string[]
}

export interface SocialBlockData extends BlockData {
  config?: SocialBlockConfig
}

const key = 'Social'
export default class SocialBlock extends Block {
  static type? = key
  declare data?: SocialBlockData

  static template: Partial<SocialBlock> = {
    data: {
      config: {
        buttons: [],
      },
      appearance: {
        align: 'center',
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<SocialBlock>) {
    super(params)
    Object.assign(this, SocialBlock.template, params)
  }

  getComponent(): React.ElementType {
    return StyledSocial
  }
}

BlockManager.registerBlockClass(SocialBlock)
