import { connect } from 'react-redux'
import ClientPathway from '../../../_shared/models/pathway/pathway.client'
import ClientResponse from '../../../_shared/models/response/response.client'
import React from 'react'
import Step from '../../../_shared/models/step/step.class'
import StepView from '../components/StepView'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Action } from 'redux'
import { submitStep, updateResponse } from '../actions'
import { AppState } from '../types'
import { ThunkDispatch } from 'redux-thunk'
import { FormSubmissionEvent, FormSubmitContext } from '../../../_shared/components/Form'
import ClientProgramGroup from '../../../_shared/models/programGroup/programGroup.client'
import { FrontendContext } from '../../../_shared/StepSubmission'
import { embedUpdateHeight } from '../embedHelpers'
import Log from '../../../_shared/log'
import { ResponseMetadata } from '../../../_shared/models/response/response.types'
import GoogleAnalytics from '../components/GoogleAnalytics'
import { IGoogleReCaptchaConsumerProps, withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Cookies from 'js-cookie'
import GoogleAnalytics4 from '../components/GoogleAnalytics4'

type StepViewContainerProps = RouteComponentProps & {
  pathway: ClientPathway
  response: ClientResponse
  programGroup?: ClientProgramGroup
  step: Step
  dispatch: ThunkDispatch<AppState, unknown, Action<string>>
  isSubmittingStep?: boolean
  errors?: Record<string, any>
  reduxAction?: any
  googleReCaptchaProps?: IGoogleReCaptchaConsumerProps
}

export const ReactFrontendContext = React.createContext<FrontendContext>(null)
class StepViewContainer extends React.Component<StepViewContainerProps> {
  componentDidMount() {
    embedUpdateHeight()
    this.execTrackingCodes()
  }

  componentDidUpdate(prevProps) {
    embedUpdateHeight()
    const { step } = this.props
    if (step._id !== prevProps.step._id) {
      this.execTrackingCodes()
    }
  }

  execTrackingCodes() {
    const { pathway, step } = this.props
    if (!pathway.trackingCodes) {
      return
    }

    pathway.trackingCodes
      .filter((c) => c.type === 'script')
      .forEach((code) => {
        if (code.stepTarget !== 'all' && !code.steps?.includes(step._id)) {
          return
        }

        try {
          eval(code.content) // eslint-disable-line
        } catch (e) {
          console.error(`Error in tracking code "${code.name}"`)
          Log.error(e, {
            _id: code._id,
            name: code.name,
          })
        }
      })
  }

  isEmbedded() {
    const params = new URLSearchParams(this.props.location.search)
    return !!params.get('_embed')
  }

  onSubmit = ({ formData }: FormSubmissionEvent, context: FormSubmitContext) => {
    const {
      isSubmittingStep,
      pathway,
      response,
      programGroup,
      history,
      location,
      step,
      dispatch,
      googleReCaptchaProps,
    } = this.props

    if (isSubmittingStep) {
      return
    }

    dispatch(
      submitStep({
        history,
        pathway,
        step,
        programGroup,
        doc: formData,
        response,
        location,
        context,
        googleReCaptchaProps,
      })
    )
  }

  onFormChange = (params) => {
    const { pathway, response, programGroup, step, dispatch, reduxAction } = this.props

    const newResponse = new ClientResponse(response)
    const fields = pathway.formToResponse(params.formData, {
      pathway,
      response,
      programGroup,
    })
    const responseStep = newResponse.steps?.find((s) => s._id === step._id)
    if (responseStep) {
      responseStep.fields = fields
    } else {
      newResponse.steps.push({ _id: step._id, name: step.name, fields })
    }
    dispatch(reduxAction(newResponse))
  }

  getFormData() {
    const { pathway, response, step, location } = this.props
    const doc = pathway.responseToForm(response, step._id)

    const params = new URLSearchParams(location.search)
    ResponseMetadata.forEach((field) => {
      if (params.has(field)) {
        doc[field] = params.get(field)
      }
    })

    // cookie check for facebook tracking params
    try {
      const _fbc = Cookies.get('_fbc') // eslint-disable-line
      const _fbp = Cookies.get('_fbp') // eslint-disable-line
      if (_fbc) {
        doc.fbc = _fbc
      }
      if (_fbp) {
        doc.fbp = _fbp
      }

      // try to capture all the present Meta pixel tracking codes
      if (global.window && global.window.fbq) {
        const pixels = window.fbq.getState().pixels.map((p) => p.id)
        doc.metaPixels = pixels.join(',')
      }
    } catch (e) {
      Log.error(e)
    }

    return doc
  }

  getTrimFields(step: Step) {
    const fields = []

    step.forEachBlock((block) => {
      if (block.data?.advanced?.trimAnswers) {
        fields.push(block.id)
      }
    })

    return fields
  }

  render() {
    const { pathway, response, programGroup, step, location, isSubmittingStep, errors } = this.props

    return (
      <ReactFrontendContext.Provider
        value={{
          pathway,
          response,
          programGroup,
          location,
        }}
      >
        <GoogleAnalytics pathway={pathway} />
        <GoogleAnalytics4 pathway={pathway} />
        <StepView
          pathway={pathway}
          response={response}
          programGroup={programGroup}
          step={step}
          errors={errors}
          isEmbedded={this.isEmbedded()}
          onFormChange={this.onFormChange}
          formData={this.getFormData()}
          isSubmitting={isSubmittingStep}
          onSubmit={this.onSubmit}
          trimFields={this.getTrimFields(step)}
          scrollToField={!!new URLSearchParams(location.search).get('_scroll')}
        />
      </ReactFrontendContext.Provider>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  pathway: state.pathway,
  programGroup: state.programGroup,
  isSubmittingStep: state.isSubmittingStep,
  response: state.response,
  errors: state.errors,
})

export default withGoogleReCaptcha(withRouter(connect(mapStateToProps)(StepViewContainer)))
