import Block from '../../../pagebuilder/blocks/Block'
import Field from '../../../pagebuilder/blocks/Field'
import { FrontendContext } from '../../StepSubmission'
import ClientResponse from '../response/response.client'
import { FieldResponse, ResponseMetadata } from '../response/response.types'
import Step from '../step/step.class'

export type TrackingCode = {
  _id: string
  name: string
  type: 'html' | 'script'
  location?: 'head' | 'body'
  content: string
  stepTarget?: 'all' | 'specific'
  steps?: string[]
}

export enum PathwayURLType {
  MAIN = 'main',
  CUSTOM = 'custom',
}

export class PathwayURL {
  type: PathwayURLType
  value: string
}

export default class ClientPathway {
  declare _id?: string
  declare companyId: string
  declare name: string
  declare steps?: Record<string, Step>
  declare scoringAlgorithm: 'linear' | 'fractionalPower'
  declare trackingCodes: TrackingCode[]
  declare urls?: PathwayURL[]
  declare type?: string
  declare gaMeasurementId?: string
  declare googleAnalyticsUA?: string
  declare cookieConsentBanner?: boolean
  declare inactivityTimeoutSeconds?: number

  constructor(props: NonFunctionProperties<ClientPathway>) {
    Object.assign(this, props)
    this.initialize()
  }

  initialize(): void {
    if (this.steps) {
      Object.values(this.steps).forEach((step) => {
        this.steps[step._id] = new Step(step, this)
      })
    }
  }

  getHTMLTrackingCodes(): TrackingCode[] {
    return this.trackingCodes?.filter((tc) => {
      return tc.type === 'html'
    })
  }

  getJSTrackingCodes(stepId: string): TrackingCode[] {
    return this.trackingCodes?.filter((tc) => {
      return tc.type === 'script' && tc.steps.includes(stepId)
    })
  }

  getStep(stepId: string): Step {
    return this.steps[stepId]
  }

  getSteps(): Step[] {
    const steps = Object.values(this.steps) || []
    steps.sort((s1, s2) => s1.order - s2.order)
    return steps
  }

  getNextStep(currentStep: Step): Step {
    const steps = this.getSteps()
    if (steps.length > 0) {
      const currentStepIndex = steps.findIndex((s) => s._id === currentStep._id)
      return steps[currentStepIndex + 1] || null
    }
  }

  getBlock(idOrName: string): Block {
    const steps = this.getSteps()
    let block: Block
    steps.find((step) => {
      const b = step.getBlock(idOrName)
      if (b) {
        block = b
        return true
      }
    })

    return block
  }

  getBlockAndStep(idOrName: string) {
    const steps = this.getSteps()
    let block: Block
    let step: Step
    steps.find((s) => {
      const b = s.getBlock(idOrName)
      if (b) {
        step = s
        block = b
        return true
      }
    })

    return { block, step }
  }

  findBlock(func: (block: Block) => boolean): Block {
    const steps = this.getSteps()
    let block

    steps.find((step) => {
      const b = step.findBlock(func)
      if (b) {
        block = b
        return true
      }
    })

    return block
  }

  findParentBlock(func: (block: Block) => boolean): Block {
    const steps = this.getSteps()
    let parentBlock

    steps.find((step) => {
      const b = step.findParentBlock(func)
      if (b) {
        parentBlock = b
        return true
      }
    })

    return parentBlock
  }

  getPrimaryURL(): string {
    return this.urls.find((u) => u.type === 'main').value
  }

  calculateScore(response: ClientResponse): number {
    let score = 0
    let maxScore = 0

    Object.values(this.steps).forEach((step) => {
      score += step.calculateScore(response)
      maxScore += step.maxScore(response)
    })

    // Calculate the score based on the selected algorithm
    if (this.scoringAlgorithm === 'linear') {
      // Linear equation
      score = (score / maxScore) * 100
    } else {
      // Fractional power equation
      // Adjust the scores on a curve that's fairly steep for lower values,
      // and gradual for higher values to ensure most users get good looking scores
      // formula: Readiness = (Actual/Total) ^ 0.3
      score = Math.pow(score / maxScore, 0.25) * 100
    }

    if (isNaN(score)) {
      score = 0
    }

    score = parseInt(score.toFixed(0), 10)

    return score
  }

  calcMaxScore(): number {
    let score = 0
    Object.values(this.steps).forEach((step) => {
      score += step.maxScore(null, true)
    })
    return score
  }

  formToResponse(formDoc: Record<string, any>, dataContext?: FrontendContext): FieldResponse[] {
    const fields = []
    if (!formDoc) {
      return fields
    }
    Object.entries(formDoc).forEach(([blockId, value]) => {
      const block = this.getBlock(blockId)
      if (value && block && block instanceof Field) {
        const doc = block.formToDoc(value, dataContext)
        if (doc) {
          fields.push(doc)
        }
      }
    })
    return fields
  }

  responseToForm(response: ClientResponse, stepId: string): Record<string, any> {
    const formDoc = {}
    const step = response.steps?.find((step) => step._id === stepId)
    if (step) {
      step.fields?.forEach((field) => {
        const block = this.getBlock(field._id)
        if (block && block instanceof Field) {
          formDoc[block.id] = block.docToForm(field)
        }
      })
    }

    // Check the query params for built-in extra properties
    ResponseMetadata.forEach((prop) => {
      const propInQuery = response[prop]
      if (propInQuery) {
        formDoc[prop] = propInQuery
      }
    })

    return formDoc
  }

  maxScoreFrom(stepId: string, response: ClientResponse): number {
    let maxScore = 0

    const steps = this.getSteps()

    const currentStepIndex = steps.findIndex((s) => s._id === stepId)

    steps.splice(0, currentStepIndex)

    Object.values(steps).forEach((step) => {
      maxScore += step.maxScore(response)
    })

    return maxScore
  }
}
