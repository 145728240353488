import { BlockResponsiveAppearance, BlockAppearanceConfig, RGBAColor } from '../../types'
export interface BlockAppearanceText {
  color: RGBAColor
}

export default function getTextStyles(data: BlockAppearanceConfig): BlockResponsiveAppearance {
  let styles = ''

  const text = data?.text
  if (text) {
    const { color } = text

    if (color) {
      styles += `color: rgba(${color.r}, ${color.g}, ${color.b}, ${color.a});`
    }
  }

  return {
    all: styles,
  }
}
