import React from 'react'
import styled from 'styled-components'

interface Props {
  className?: string
}

const CookieConsentBanner: React.FC<Props> = (props) => {
  return (
    <div className={props.className}>
      We use cookies to improve your browsing experience and for analytics about our visitors. You
      accept the use of cookies by submitting the form on this page.{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://virtualadviser.com/privacypolicy.html#cookies"
      >
        <strong>Learn more.</strong>
      </a>
    </div>
  )
}

export default styled(CookieConsentBanner)`
  padding: 0.5rem;
  font-size: 0.8rem;
  background: #000;
  position: relative;
  z-index: 90000;

  &,
  a {
    color: #fff;
  }
`
