import React from 'react'
import styled from 'styled-components'
import Input from '../../../../_shared/components/Form/Input'
import InputGroup from '../../../../_shared/components/Form/InputGroup'
import classnames from 'classnames'
import Field, { FieldConfig, FieldData } from '../../Field'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import { FieldResponse } from '../../../../_shared/models/response/response.types'

export interface NumberProps {
  block: NumberField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class Number extends React.PureComponent<NumberProps> {
  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)

    const prefix = block.data?.config.prefix
    const suffix = block.data?.config.suffix

    const Component = prefix || suffix ? InputGroup : Input

    return (
      <Component
        name={block.id}
        type="number"
        className={classnames('block', className)}
        label={label}
        showLabel={!block.data.config.hideLabel}
        placeholder={block.data?.config.placeholder}
        prefix={prefix}
        postfix={suffix}
      />
    )
  }
}

export const StyledNumber = styled(Number)`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'Number'

export interface NumberFieldConfig extends FieldConfig {
  placeholder?: string
  prefix?: string
  suffix?: string
}

export interface NumberFieldData extends FieldData {
  config?: NumberFieldConfig
}

export default class NumberField extends Field {
  static type? = key
  static enableConditionalLogic = true
  declare data?: NumberFieldData
  static template: Partial<NumberField> = {
    data: {
      config: {
        label: 'Number',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<NumberField>) {
    super(params)
    Object.assign(this, NumberField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledNumber
  }

  getValue(response: string): string {
    return response
  }

  formToDoc(value: string | number, context?: FrontendContext): FieldResponse {
    const answer: FieldResponse = {
      _id: this.id,
      name: this.data?.advanced?.name,
      label: this.getLabel(),
      value: parseInt(this.getValue(String(value)), 10),
      type: (this.constructor as any).type,
    }
    return answer
  }

  getFormSchema(context?: FrontendContext): Record<string, any> {
    return {
      schema: {
        title: this.getLabel(context),
        type: 'number',
      },
      required: this.data?.config?.required,
    }
  }
}

BlockManager.registerBlockClass(NumberField)
