import React, { SyntheticEvent } from 'react'
import Ajv, { ErrorObject } from 'ajv'
import addFormats from 'ajv-formats'
import capitalize from 'voca/capitalize'
import toPath from 'lodash/toPath'
import set from 'lodash/set'
import unset from 'lodash/unset'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { DebouncedFunc } from 'lodash'
import get from 'lodash/get'
import Log from '../log'
import { embedScrollTop, embedUpdateHeight } from '../../frontend/client/embedHelpers'
import smoothscroll from 'smoothscroll-polyfill'
import ClientProgramGroup from '../models/programGroup/programGroup.client'

// Smooth scrolling polyfill for scrolling to nearest field below
if (typeof window !== 'undefined') {
  // Force polyfill on in embedded situations
  if (window.parent) {
    const anyWindow = window as any
    anyWindow.__forceSmoothScrollPolyfill__ = true
  }
  smoothscroll.polyfill()
}

export interface FormContextProps {
  formData: Record<string, any>
  formSchema: Record<string, any>
  setFieldValue: (name: string, value: unknown) => void
  setValue: (props: SetValueProps, newValue: any) => void
  errors?: Record<string, any>
  onSubmit: (event: SyntheticEvent, context?: { buttonId?: string }) => void
  setDefaultFormValue: (props: SetDefaultFormValueProps) => boolean
  programGroup?: ClientProgramGroup
}

export const FormContext = React.createContext<FormContextProps>(null)

const ajv = new Ajv({
  allErrors: true,
  useDefaults: true,
  removeAdditional: true,
  coerceTypes: true,
  verbose: true,
  // jsPropertySyntax: true,
})
addFormats(ajv)

function getLabel(schema: Record<string, any>, property: string) {
  return schema[property]?.title || capitalize(property)
}

export const transformErrors = function (errors: ErrorObject[]): Record<string, string[]> {
  const newErrors = {}
  errors.forEach((err) => {
    let property: string = err.instancePath.replace(/^\//, '').replace(/\//g, '.')
    let message: string
    // Required fields
    if (err.keyword === 'required') {
      const { missingProperty } = err.params
      property += '.' + missingProperty
      const label = getLabel(err.parentSchema.properties, missingProperty)
      message = `${label} is required`
    } else if (err.keyword === 'pattern' || err.keyword === 'format') {
      const label = err.parentSchema?.title || property
      message = `Please enter a valid ${label}`
    } /* if (err.keyword === 'type') */ else {
      const label = err.parentSchema?.title || property
      message = `${label} ${err.message}`
    }

    property = property.replace(/^\./, '').replace('["', '').replace('"]', '')

    if (!newErrors[property]) {
      newErrors[property] = []
    }
    newErrors[property].push(message)
  })

  return newErrors
}

export type FormSubmissionEvent = {
  formData: Record<string, any>
  errors: Record<string, any>
  changed: boolean
  submitted: boolean
  fieldName?: string
}

export interface FormSubmitContext {
  buttonId?: string
}

export interface SetValueProps {
  allowedRegex?: RegExp
  name: string
  value?: any
  onChange?: (value?: any) => void
}

export interface SetDefaultFormValueProps {
  name: string
  value?: any
  children?: JSX.Element | JSX.Element[] | undefined
  placeholder?: string
}

type FormProps = RouteComponentProps & {
  id?: string
  formData?: Record<string, any>
  errors?: Record<string, string[]>
  onValidSubmit?: (state: FormState, context?: FormSubmitContext) => void
  onInvalidSubmit?: (event: FormSubmissionEvent) => void
  onValidChange?: (event: FormSubmissionEvent) => void
  onInvalidChange?: (event: FormSubmissionEvent) => void
  onChange?: (event: FormSubmissionEvent) => void
  schema: Record<string, any>
  children?: React.ReactNode
  className?: string
  name?: string
  method?: string
  target?: string
  action?: string
  autocomplete?: string
  enctype?: string
  acceptcharset?: string
  noHtml5Validate?: boolean
  leaveWarning?: string
  warnOnUnsavedLeave?: boolean
  validateOnChange?: boolean
  validateOnChangeAfterSubmit?: boolean
  scrollToField?: boolean
  trimFields?: string[]
  programGroup?: ClientProgramGroup
}
export type FormState = {
  formData: Record<string, any>
  errors: Record<string, string[]>
  changed: boolean
  submitted: boolean
  scrollTarget?: HTMLElement
}

class Form extends React.Component<FormProps, FormState> {
  debouncedChangeValidator: DebouncedFunc<any>
  formRef: React.RefObject<HTMLFormElement>
  unblockHistory: () => void
  _lastChanged: HTMLElement

  static defaultProps = {
    formData: {},
    errors: {},
    noHtml5Validate: true,
    warnOnUnsavedLeave: false, // todo: this feature is buggy and needs to be worked on
    validateOnChange: false,
    leaveWarning: 'Are you sure you want to leave the page? Your changes have not been saved.',
  }

  constructor(props: FormProps) {
    super(props)

    this.debouncedChangeValidator = debounce(this.changeValidator, 10)

    this.state = {
      formData: props.formData || {},
      errors: props.errors || {},
      changed: false,
      submitted: false,
    }

    this.formRef = React.createRef()
  }

  validate = () => {
    ajv.validate(this.props.schema, this.state.formData)
    if (ajv.errors) {
      console.log(ajv.errors)
      console.warn(this.state.formData, ajv.errors)
      return transformErrors(ajv.errors)
    }
    return {}
  }

  onUnload = (event: BeforeUnloadEvent) => {
    if (this.state.changed) {
      event.returnValue = this.props.leaveWarning
    }
  }

  componentDidMount = () => {
    const { history, warnOnUnsavedLeave } = this.props
    if (warnOnUnsavedLeave) {
      window && window.addEventListener('beforeunload', this.onUnload)
      this.unblockHistory = history.block(() => {
        if (this.state.changed) return this.props.leaveWarning
      })
    }
  }

  componentWillUnmount = () => {
    if (this.unblockHistory) {
      window && window.removeEventListener('beforeunload', this.onUnload)
      this.unblockHistory()
    }
  }

  onSubmit = (event?: SyntheticEvent, context?: FormSubmitContext) => {
    event?.preventDefault()
    let errors = {}
    const { schema, onInvalidSubmit, onValidSubmit } = this.props

    // Validate if we have a schema
    if (schema) {
      errors = this.validate()
    }

    if (Object.keys(errors).length === 0) {
      this.setState(
        {
          errors: {},
          changed: false,
          submitted: true,
        },
        () => {
          if (onValidSubmit) {
            onValidSubmit(
              {
                ...this.state,
              },
              context
            )
          }
        }
      )
    } else {
      Log.warn('Form errors:', errors)
      this.setState(
        {
          submitted: true,
          errors,
        },
        () => {
          if (!window.parent || window.parent === window) {
            // Scroll to the first error
            const element = this.formRef.current?.querySelector('.is-invalid')
            if (element) {
              if (element.scrollIntoView) {
                element.scrollIntoView()
              } else {
                // todo: need fallback for older browsers maybe
              }
            }
          } else {
            embedUpdateHeight()
            // todo: scroll to element's position
            // embedScrollTop(element.)
          }
          if (onInvalidSubmit) {
            onInvalidSubmit({
              ...this.state,
            })
          }
        }
      )
    }
  }

  changeValidator = (formData: Record<string, any>) => {
    const { schema, onValidChange, onInvalidChange } = this.props

    let errors = {}
    // Validate if we have a schema
    if (schema) {
      errors = this.validate()
    }

    if (Object.keys(errors).length === 0) {
      this.setState(
        {
          errors: {},
        },
        () => {
          if (onValidChange) {
            onValidChange({
              ...this.state,
              formData,
            })
          }
        }
      )
    } else {
      this.setState(
        {
          errors,
        },
        () => {
          if (onInvalidChange) {
            onInvalidChange({
              ...this.state,
              formData,
            })
          }
        }
      )
    }
  }

  onChange(fieldName?: string) {
    const { onChange, validateOnChange, validateOnChangeAfterSubmit, scrollToField, trimFields } =
      this.props
    const { formData, submitted } = this.state

    if (onChange) {
      onChange({ ...this.state, formData, fieldName })
    }

    if (validateOnChange) {
      this.debouncedChangeValidator(formData)
    }

    if (validateOnChangeAfterSubmit && submitted) {
      this.debouncedChangeValidator(formData)
    }

    if (trimFields?.includes(fieldName)) {
      this.removeSubsequentAnswers(fieldName)
    }

    if (scrollToField) {
      try {
        if (this._lastChanged) {
          this.scrollTo(this._lastChanged)
        }
      } catch (e) {
        Log.error(e)
      }
    }
  }

  scrollTo(field: any) {
    if (field.closest('.no-scroll')) {
      return
    }

    if (field.nodeName === 'TEXTAREA' || field.nodeName == 'INPUT') {
      switch (field.type) {
        case 'text':
        case 'tel':
        case 'email':
          return
      }
    }

    setTimeout(() => {
      const scrollPos = Math.max(0, (field.closest('.block') as HTMLElement).offsetTop - 20)
      window.scroll({ top: scrollPos, left: 0, behavior: 'smooth' })
    }, 50)
  }

  setFieldValue = (name: string, value: unknown) => {
    this.setState(
      (state) => {
        let formData = JSON.parse(JSON.stringify(state.formData))
        if (value === null || value === undefined) {
          unset(formData, toPath(name))
        } else {
          formData = set(formData, toPath(name), value)
        }

        return { formData, changed: true }
      },
      () => {
        this.onChange(name)
      }
    )
  }

  onReset = (event: SyntheticEvent) => {
    event.preventDefault()
    this.setState({ formData: this.props.formData, errors: this.props.errors })
  }

  setValue = ({ allowedRegex, name, value, onChange }: SetValueProps, newValue: any): void => {
    if (newValue && allowedRegex) {
      // make sure newvalue matches allowed input
      if (!allowedRegex.test(newValue)) {
        newValue = value
      }
    }

    // Set the value on the form
    if (name) {
      this.setFieldValue(name, newValue)
    }

    // Also call the optional onChange callback
    onChange && onChange(newValue)
  }

  removeSubsequentAnswers(fieldName: string) {
    const { schema } = this.props
    const { formData } = this.state

    let fieldFound = false
    const newFormData = {}
    Object.keys(schema.properties).forEach((key) => {
      if (key === fieldName) {
        newFormData[key] = formData[key]
        fieldFound = true
      } else if (!fieldFound) {
        const ans = formData[key]
        if (ans) {
          newFormData[key] = ans
        }
      }
    })

    this.setState({
      formData: newFormData,
    })
  }

  setDefaultFormValue = ({ value, name, children, placeholder }: SetDefaultFormValueProps) => {
    if (value || placeholder || !children) {
      return false
    }

    if (Array.isArray(children)) {
      if (children.length === 0) {
        return false
      }
    }

    const defaultValue = get(children[0], 'props.value')

    if (defaultValue) {
      this.setFieldValue(name, defaultValue)
    }

    return true
  }

  nativeOnChange = (event) => {
    this._lastChanged = event.target
  }

  render() {
    const {
      children,
      id,
      className,
      name,
      method,
      target,
      action,
      autocomplete,
      enctype,
      acceptcharset,
      noHtml5Validate,
      schema,
      programGroup,
    } = this.props

    const { formData, errors } = this.state

    const context: FormContextProps = {
      formData,
      formSchema: schema,
      setFieldValue: this.setFieldValue,
      setDefaultFormValue: this.setDefaultFormValue,
      errors,
      onSubmit: this.onSubmit,
      setValue: this.setValue,
      programGroup,
    }

    return (
      <form
        className={className}
        id={id}
        name={name}
        method={method}
        target={target}
        action={action}
        autoComplete={autocomplete}
        encType={enctype}
        acceptCharset={acceptcharset}
        noValidate={noHtml5Validate}
        onSubmit={this.onSubmit}
        onReset={this.onReset}
        ref={this.formRef}
        onChange={this.nativeOnChange}
      >
        <FormContext.Provider value={context}>{children}</FormContext.Provider>
      </form>
    )
  }
}

export default withRouter(Form)
