import { faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons/faCanadianMapleLeaf'
import BlockManager from '../../BlockManager'
import ProvinceField from './Province'

class ProvinceFieldEditor extends ProvinceField {
  static editor = {
    label: 'Canadian Province',
    category: 'form',
    icon: faCanadianMapleLeaf,
  }
}

BlockManager.registerBlockClass(ProvinceFieldEditor)
