import React, { ChangeEvent } from 'react'
import { FormContextProps } from '../Form'
import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import { FormGroup } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import styled from 'styled-components'
import { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar'
import classnames from 'classnames'
import withForm from '../withForm'
import Log from '../../log'

function parseDate(str: string, format: string, locale: any) {
  //todo: parse has a required third param baseDate, it was blank, put 0, will it mess it up
  const parsed = dateFnsParse(str, format, 0, { locale })
  if (DateUtils.isDate(parsed)) {
    return parsed
  }
  return undefined
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale })
}

export interface DateProps extends Partial<FormContextProps> {
  showLabel?: boolean
  label?: string
  type?: string
  id?: string
  placeholder?: string
  help?: string
  errors?: string[]
  showValid?: boolean
  validFeedback?: string
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  name: string
  value?: string
  format?: string
  className?: string
}

class Date extends React.PureComponent<DateProps> {
  static defaultProps = {
    showLabel: true,
    errors: [],
    showValid: false,
    format: 'yyyy/MM/dd',
  }

  componentWillUnmount() {
    const { setValue } = this.props
    setValue(this.props, undefined)
  }

  onChange = (value) => {
    try {
      const date = dateFnsFormat(value, this.props.format),
        { setValue } = this.props
      setValue(this.props, date)
    } catch (e) {
      Log.info(e.message, e.stack)
    }
  }

  render() {
    const {
      showLabel,
      name,
      label,
      formSchema,
      validFeedback,
      id,
      className,
      errors,
      showValid,
      help,
      value,
      format,
    } = this.props

    const hasErrors = errors.length > 0

    return (
      <FormGroup className={className + ' ' + getFormGroupClasses(errors, showValid)}>
        <FormLabel
          showLabel={showLabel}
          id={id}
          name={name}
          label={label}
          formSchema={formSchema}
        />
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faCalendar} />
            </span>
          </div>
          <DayPickerInput
            inputProps={{
              className: classnames('form-control', {
                'is-invalid': hasErrors,
              }),
              name,
            }}
            //todo: add id={id}
            formatDate={formatDate}
            format={format}
            parseDate={parseDate}
            placeholder={''}
            value={value == null ? '' : value}
            onDayChange={this.onChange}
          />
        </div>

        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
        <HelpText help={help} />
      </FormGroup>
    )
  }
}

const DateWithForm = withForm<DateProps>(Date)

export default styled(DateWithForm)`
  input {
    font-size: 1.066666666666667rem;
  }
  .DayPickerInput {
    display: block;
    flex: 1 1 auto;
    width: 1%;
  }
`
