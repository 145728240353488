import React from 'react'
import BlockRenderer from './BlockRenderer'
import './blocks'
import SectionBlock from './blocks/section/Section'
import { RenderingComponents } from './types'

// pagerenderer allows you to swap out the components used to render Sections, Rows, Regions, and Content blocks
// canvas is the actual base of the visual rendering process

// should they be merged?
// the override components could be specified directly to the base

export type RegionProps = {
  id?: string
  children: React.ReactNode
}

export function Region({ children }: RegionProps): React.ReactElement {
  return <React.Fragment>{children}</React.Fragment>
}

const viewComponents = {
  Region, // eslint-disable-line
  Section: BlockRenderer, // eslint-disable-line
  Row: BlockRenderer, // eslint-disable-line
  Content: BlockRenderer, // eslint-disable-line
}

export type PageRendererProps = {
  content: SectionBlock[]
  components?: RenderingComponents
}

export const PageRendererComponentContext = React.createContext(null)

export default function PageRenderer({
  content,
  components,
}: PageRendererProps): React.ReactElement {
  const Section = components?.Section || viewComponents.Section

  return (
    <div className="content">
      <PageRendererComponentContext.Provider value={components || viewComponents}>
        {content &&
          content.map((section, index) => (
            <Section key={section.id} block={section} index={index} />
          ))}
      </PageRendererComponentContext.Provider>
    </div>
  )
}
