import React, { ChangeEvent, ReactNode } from 'react'
import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup as StrapInputGroup,
} from 'reactstrap'
import withForm from '../withForm'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import classnames from 'classnames'
import { FormContextProps } from '../Form'
import { InputType } from 'reactstrap/lib/Input'

export interface InputGroupProps extends Partial<FormContextProps> {
  name: string
  showLabel?: boolean
  label?: string
  type?: InputType
  id?: string
  placeholder?: string
  help?: string | ReactNode
  errors?: string[]
  showValid?: boolean
  validFeedback?: string
  onChange?: (newValue: string) => void
  value?: string
  prefix?: any
  postfix?: any
  maxLength?: number
  tag?: React.ComponentType
  size?: 'lg' | 'sm'
  className?: string
}

class InputGroup extends React.PureComponent<InputGroupProps> {
  static defaultProps = {
    errors: [],
    showValid: false,
    showLabel: true,
  }

  componentWillUnmount() {
    this.props.setValue(this.props, undefined)
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value
    if (value === '' || !value) {
      value = undefined
    }
    this.props.setValue(this.props, value)
  }

  render() {
    const {
      showLabel,
      type,
      name,
      label,
      formSchema,
      id,
      tag,
      prefix,
      postfix,
      placeholder,
      errors,
      showValid,
      help,
      validFeedback,
      value,
      maxLength,
      size,
      className,
    } = this.props

    const InputTag = tag || Input

    return (
      <FormGroup className={classnames(className, getFormGroupClasses(errors, showValid))}>
        <FormLabel
          showLabel={showLabel}
          id={id}
          name={name}
          label={label}
          formSchema={formSchema}
        />
        <div className="input-group-outer">
          <div className="input-group-inner">
            <StrapInputGroup size={size}>
              {prefix ? (
                <InputGroupAddon addonType="prepend" size={size}>
                  <InputGroupText>{prefix}</InputGroupText>
                </InputGroupAddon>
              ) : (
                ''
              )}

              <InputTag
                type={type}
                name={name}
                id={id}
                maxLength={maxLength}
                placeholder={placeholder}
                onChange={this.onChange}
                // bsSize={size}
                value={value == null ? '' : value}
              />

              {postfix ? (
                <InputGroupAddon addonType="append" size={size}>
                  <InputGroupText>{postfix}</InputGroupText>
                </InputGroupAddon>
              ) : (
                ''
              )}
            </StrapInputGroup>
          </div>
        </div>
        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
        <HelpText help={help} />
      </FormGroup>
    )
  }
}

export default withForm(InputGroup)
