import React from 'react'
import styled from 'styled-components'
import RadioInput from '../../../../_shared/components/Form/Radio'
import classnames from 'classnames'
import { AnswersBlock } from '../../AnswersBlock'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { FrontendContext } from '../../../../_shared/StepSubmission'

export interface RadioProps {
  block: RadioField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class Radio extends React.PureComponent<RadioProps> {
  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)
    return (
      <RadioInput
        className={classnames('block', className)}
        name={block.id}
        id={block.id}
        label={label}
        showLabel={!block.data.config.hideLabel}
        options={block.data.config.answers}
      />
    )
  }
}

const StyledRadio = styled(Radio)`
  position: relative;
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'Radio'

export default class RadioField extends AnswersBlock {
  static type? = key
  static template: Partial<RadioField> = {
    data: {
      config: {
        label: 'Radio',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<RadioField>) {
    super(params)
    Object.assign(this, RadioField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledRadio
  }
}

BlockManager.registerBlockClass(RadioField)
