import { BlockResponsiveAppearance, BlockAppearanceConfig } from '../../types'

export interface HeightConfig {
  type: string
  custom?: number
  customUnits?: string
}

export default function getHeightStyles(data: BlockAppearanceConfig): BlockResponsiveAppearance {
  let styles = ''

  const heightSettings = data?.height

  let custom = 0
  switch (heightSettings?.type) {
    case 'custom':
      if (!isNaN(heightSettings?.custom)) {
        custom = heightSettings.custom
      }
      styles += `height: ${custom + (heightSettings?.customUnits || 'px')};`
      break
    case 'default':
    default:
  }

  return { all: styles }
}
