import { BlockResponsiveAppearance, BlockAppearanceConfig } from '../../types'

export interface Padding {
  left?: number
  leftUnit?: string
  right?: number
  rightUnit?: string
  top?: number
  topUnit?: string
  bottom?: number
  bottomUnit?: string
}

export interface ResponsivePadding {
  desktop?: Padding
  tablet?: Padding
  mobile?: Padding
}

const getStyles = (padding: Padding) => {
  let styles = ''
  if (padding.left) {
    styles += `padding-left: ${padding.left + (padding.leftUnit || 'px')};`
  }
  if (padding.right) {
    styles += `padding-right: ${padding.right + (padding.rightUnit || 'px')};`
  }
  if (padding.top) {
    styles += `padding-top: ${padding.top + (padding.topUnit || 'px')};`
  }
  if (padding.bottom) {
    styles += `padding-bottom: ${padding.bottom + (padding.bottomUnit || 'px')};`
  }
  return styles
}

export default function getPaddingStyles(data: BlockAppearanceConfig): BlockResponsiveAppearance {
  const styles: {
    desktop?: string
    tablet?: string
    mobile?: string
  } = {}
  const padding = data?.padding

  if (padding) {
    if (padding.desktop) {
      styles.desktop = getStyles(padding.desktop)
    }
    if (padding.tablet) {
      styles.tablet = getStyles(padding.tablet)
    }
    if (padding.mobile) {
      styles.mobile = getStyles(padding.mobile)
    }
  }

  return styles
}
