import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBold } from '@fortawesome/pro-solid-svg-icons/faBold'
import { isMod } from './utils'

export const hasBold = value => value.marks.some(mark => mark.type === 'bold')

export const toggleBold = change => change.toggleMark('bold').focus()

export const BoldButton = ({ editor, className }) => (
  <button
    type="button"
    onClick={e => toggleBold(editor)}
    className={classnames(
      'slate-bold-plugin--button',
      { active: hasBold(editor.value) },
      className
    )}
  >
    <FontAwesomeIcon icon={faBold} fixedWidth />
  </button>
)

export const BoldPlugin = options => ({
  onKeyDown(event, editor, next) {
    if (isMod(event) && event.key === 'b') {
      event.preventDefault()
      return toggleBold(editor)
    }
    next()
  },
})

export const BoldMark = ({ children }) => <strong>{children}</strong>
