import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltV } from '@fortawesome/pro-solid-svg-icons/faArrowsAltV'
import styled from 'styled-components'

const defaultLineHeight = 1.5

export const hasLineHeight = value =>
  value.marks.some(mark => mark.type === 'line-height')

export const getLineHeight = value =>
  value.marks.filter(mark => mark.type === 'line-height').first()

export const setLineHeight = (inHeight, editor) => {
  const { value } = editor
  const { selection } = value

  const height = parseFloat(inHeight)
  if (isNaN(height)) {
    return
  }
  const mark = {
    type: 'line-height',
    data: {
      height,
    },
  }

  let originalSelection
  if (selection.isCollapsed) {
    originalSelection = selection

    editor.moveStartToStartOfText()
    editor.moveEndToEndOfText()
  }

  const existingMarks = value.marks.filter(mark => mark.type === 'line-height')

  if (existingMarks.size > 0) {
    existingMarks.forEach(mark => {
      editor.removeMark(mark)
    })
  }

  if (height !== defaultLineHeight) {
    editor.addMark(mark)
  }

  if (originalSelection) {
    editor.select(originalSelection)
  }
}

const FontRangeDiv = styled.div``
export const LineHeightControl = ({ editor, className }) => {
  let value = 1.5
  const fs = getLineHeight(editor.value)
  if (fs) {
    value = fs.data.get('height')
  }

  return (
    <FontRangeDiv className="d-flex flex-nowrap align-items-center">
      <FontAwesomeIcon icon={faArrowsAltV} fixedWidth />
      <div className="slider-value">
        <input
          type="number"
          min={1}
          max={100}
          value={value}
          onChange={event => setLineHeight(event.target.value, editor)}
          className={classnames(
            'slate-line-height-plugin--button',
            {},
            className
          )}
        />
      </div>
    </FontRangeDiv>
  )
}

export const LineHeightPlugin = options => ({
  // onKeyDown(event, editor, next) {
  //   if (isMod(event) && event.key === 'b') {
  //     event.preventDefault()
  //     return toggleLineHeight(editor)
  //   }
  //   next()
  // },
})

export const LineHeightMark = ({ children, mark }) => (
  <span style={{ lineHeight: mark.data.get('height') }}>{children}</span>
)
