import React from 'react'
import { faBalanceScaleLeft } from '@fortawesome/pro-solid-svg-icons/faBalanceScaleLeft'
import MatchField from './Match'
import SettingsSection from '../../../settings/SettingsSection'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import ScoringOptions, { ScoringOptionsSchema } from '../../../settings/Scoring/ScoringOptions'
import AnswerOptions, { AnswersSchema } from '../../../settings/Answers/Answers'
import BlockManager from '../../BlockManager'

export const Form = (props) => (
  <React.Fragment>
    <ScoringOptions path="config.scoring" />
    <AnswerOptions name="config.answers" includeNotApplicable={true} />
    <SettingsSection title="Question Type">
      <Cardbox name="config.type" thin vertical showLabel={false} defaultValue="boolean">
        <CardboxItem value="boolean" title="True / False" />
        <CardboxItem value="yesno" title="Yes / No" />
      </Cardbox>
    </SettingsSection>
  </React.Fragment>
)

class MatchFieldEditor extends MatchField {
  static editor = {
    label: 'Match',
    category: 'form',
    icon: faBalanceScaleLeft,
    settingsForm: Form,

    getSettingsSchema(schema) {
      Object.assign(
        schema.properties,
        {
          type: {
            type: 'string',
          },
        },
        AnswersSchema
      )
      Object.assign(schema.properties, ScoringOptionsSchema)
      schema.required.push('type')
    },
  }
}

BlockManager.registerBlockClass(MatchFieldEditor)
