import ClientResponse from '../../_shared/models/response/response.client'
import BlockManager from './BlockManager'
import Field from './Field'

export interface ConditionalLogicCondition {
  type: 'answer' | 'tracking' | 'meta'
  target: string
  comparison:
    | 'is'
    | 'is not'
    | 'equals'
    | 'is'
    | 'does not equal'
    | 'contains'
    | 'does not contain'
    | 'is greater than'
    | 'is greater than or equal to'
    | 'is less than'
    | 'is less than or equal to'
    | 'regex'
    | 'is answered'
    | 'is not answered'
  value?: string | number | boolean
}

export interface ConditionalLogicConfig {
  matchType: 'any' | 'all'
  conditions?: ConditionalLogicCondition[]
}

class ConditionalLogic {
  /**
   * Returns true or false depending on if the specified conditions are met
   * in the response
   * @param {Object} config
   * @param {Response} response
   */
  evaluate(config: ConditionalLogicConfig, response: ClientResponse = null): boolean {
    const { matchType, conditions } = config

    if (!conditions || conditions.length === 0) {
      return true
    }

    if (matchType === 'any') {
      // Stop on first success
      return !!conditions.find((condition) => {
        let defaultIfNoAnswer = false
        switch (condition.comparison) {
          case 'is not answered':
          case 'is not':
          case 'does not equal':
          case 'does not contain':
            defaultIfNoAnswer = true
            break
          case 'is answered':
            defaultIfNoAnswer = false
        }

        return this.evaluateCondition(condition, response, defaultIfNoAnswer)
      })
    }

    if (matchType === 'all') {
      // Require all conditions to pass
      let passed = true
      conditions.find((condition) => {
        let defaultIfNoAnswer = false
        switch (condition.comparison) {
          case 'is not answered':
          case 'is not':
          case 'does not equal':
          case 'does not contain':
            defaultIfNoAnswer = true
            break
          case 'is answered':
            defaultIfNoAnswer = false
        }

        passed = this.evaluateCondition(condition, response, defaultIfNoAnswer)
        // Stop on first failure
        if (!passed) {
          return true
        }
      })

      return passed
    }
  }

  /**
   * Evaluates a single condition against the response
   * @param {Object} condition
   * @param {Response} response
   */
  evaluateCondition(
    condition: ConditionalLogicCondition,
    response: ClientResponse,
    defaultIfNoAnswer: boolean
  ) {
    let property
    switch (condition.type) {
      case 'answer':
        return this._evaluateAnswer(condition, response, defaultIfNoAnswer)

      // todo: don't know if these all actually work, should allow
      // conditional logic based on ExtraProperties as well, scoring, progress, etc
      case 'tracking':
      case 'meta':
        property = response[condition.target]
        return this.compareValue(property, condition.comparison, condition.value)
    }
  }

  /**
   * Evaluate a multiple-choice answer condition
   *
   * todo: field.data has too many abstract uses, it can be:
   * - an object
   * - an array of objects
   *
   * in this code, we use data as the comparison object first,
   * falling back to value if it doesn't exist
   *
   * this is undocumented and doesn't feel standardized
   *
   * @param {Object} condition
   * @param {Response} response
   */
  _evaluateAnswer(
    condition: ConditionalLogicCondition,
    response: ClientResponse,
    defaultIfNoAnswer
  ) {
    const field = response.getField(condition.target)

    if (condition.comparison === 'is answered') {
      return !!field?.value
    }
    if (condition.comparison === 'is not answered') {
      return !field?.value
    }

    let value
    if (!field) {
      // allows (select one) to be selected for multiple choice, a precursor to the new
      // is answered condition
      if (!condition.value) {
        return true
      }
      return defaultIfNoAnswer
    }

    if (!condition.value) {
      return defaultIfNoAnswer
    }

    const blockClass = BlockManager.getBlockClass(field.type) as typeof Field
    if (blockClass) {
      value = blockClass.getConditionalLogicValue(field)
    }

    if (blockClass.conditionalLogicCompare) {
      return blockClass.conditionalLogicCompare(field, condition)
    }

    if (Array.isArray(value)) {
      let result = true
      switch (condition.comparison) {
        case 'is':
          return !!value.find((a) => this.compareValue(a, condition.comparison, condition.value))
        case 'is not':
          value.find((a) => {
            result = this.compareValue(a, condition.comparison, condition.value)
            if (!result) {
              return true
            }
          })
          return result
      }
    } else {
      return this.compareValue(value, condition.comparison, condition.value)
    }
  }

  /**
   * Returns the result of comparing actualValue with compareValue using the
   * specified comparator
   * @param {*} actualValue
   * @param {String} comparator
   * @param {*} compareValue
   */
  compareValue(actualValue: any, comparator: string, compareValue: any) {
    switch (comparator) {
      case 'equals':
      case 'is':
        return actualValue == compareValue // eslint-disable-line
      case 'does not equal':
      case 'is not':
        return actualValue != compareValue // eslint-disable-line
      case 'contains':
        return actualValue && actualValue.indexOf(compareValue) !== -1
      case 'does not contain':
        if (!actualValue) {
          return true
        }
        return actualValue.indexOf(compareValue) === -1
      case 'regex':
        return actualValue && new RegExp(compareValue, 'i').test(actualValue)
      case 'is greater than':
        return parseFloat(actualValue || 0) > parseFloat(compareValue)
      case 'is greater than or equal to':
        return parseFloat(actualValue || 0) >= parseFloat(compareValue)
      case 'is less than':
        return parseFloat(actualValue || 0) < parseFloat(compareValue)
      case 'is less than or equal to':
        return parseFloat(actualValue || 0) <= parseFloat(compareValue)
    }
  }
}

export default new ConditionalLogic()
