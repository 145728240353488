import React, { useContext } from 'react'
import styled from 'styled-components'
import Block, { BlockData } from '../../Block'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/pro-solid-svg-icons/faArrowCircleRight'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { RGBAColor } from '../../../types'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { FormContext } from '../../../../_shared/components/Form'

export interface SubmitProps {
  block: SubmitButtonBlock
  section: SectionBlock
  row: RowBlock
  className?: string
}

const Submit: React.FC<SubmitProps> = ({ block, className }: SubmitProps) => {
  const hideIcon = block.data?.config?.hideIcon
  const { onSubmit } = useContext(FormContext)
  return (
    <div className={`block ${className}`}>
      <button
        type="submit"
        name="_submitButtonId"
        value={block.id}
        className="btn btn-lg btn-block btn-primary"
        onClick={(event) => {
          event.preventDefault()
          onSubmit(event, {
            buttonId: block.id,
          })
        }}
      >
        {block.data?.config?.label}
        {!hideIcon && <FontAwesomeIcon icon={faArrowCircleRight} />}
      </button>
    </div>
  )
}

const StyledSubmit = styled(Submit)`
  text-align: center;

  @media print {
    display: none;
  }

  .svg-inline--fa {
    margin-left: 0.4em;
  }

  &:active {
    opacity: 0.8;
  }

  ${(props) => {
    let output = '> button {'
    const color = props.block.data.config?.color
    const bgColor = props.block.data.config?.backgroundColor
    if (color) {
      output += `
      &,&:not(:disabled):not(.disabled):active,&:hover,&:focus,&:active{
        color: rgba(${color.r},${color.g},${color.b},${color.a});
      }
      `
    }
    if (bgColor) {
      output += `
      &,&:not(:disabled):not(.disabled):active,&:hover,&:focus,&:active{
        background-color: rgba(${bgColor.r},${bgColor.g},${bgColor.b},${bgColor.a});
        border-color: rgba(${bgColor.r},${bgColor.g},${bgColor.b},${bgColor.a});
        }
      `
    }

    output += '}'

    return output
  }}

  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};

  ${(props) =>
    props.block.data?.appearance?.width?.type !== 'default'
      ? ''
      : `.btn {
    display: inline-block;
    width: auto;
  }`}
`

export interface SubmitButtonConfig {
  color?: RGBAColor
  backgroundColor?: RGBAColor
  label?: string
  hideIcon?: boolean
  target?: 'next' | 'step' | 'pathway' | 'custom'
  targetUrl?: string
  targetStep?: string
  targetPathway?: string
  redirectParent?: boolean
}

export interface SubmitButtonData extends BlockData {
  config?: SubmitButtonConfig
}

const key = 'Submit'

export default class SubmitButtonBlock extends Block {
  static type? = key
  declare data?: SubmitButtonData

  static template: Partial<SubmitButtonBlock> = {
    data: {
      config: {
        label: 'Continue',
        target: 'next',
        color: {
          r: 255,
          g: 255,
          b: 255,
          a: 1,
        },
        backgroundColor: {
          r: 0,
          g: 123,
          b: 255,
          a: 1,
        },
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
            top: 2,
            topUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<SubmitButtonBlock>) {
    super(params)
    Object.assign(this, SubmitButtonBlock.template, params)
  }

  getComponent(): React.ElementType {
    return StyledSubmit
  }
}

BlockManager.registerBlockClass(SubmitButtonBlock)
