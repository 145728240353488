import React from 'react'
import styled from 'styled-components'
import { NavLink as BootstrapNavLink } from 'reactstrap'
import Link from '../components/Link'
import media from '../css/_media'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

export const StyledNav = styled.nav`
  /* overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; */
  display: flex;
  flex-wrap: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  > a,
  > .link,
  .btn {
    flex: 0 0 auto;
    font-size: 0.9375rem;
    cursor: pointer;
    padding: 0.8rem 1.3rem;
    margin: 0 0.3rem 0 0;
    color: #477198;
    transition: box-shadow 0.2s ease-out;
    font-weight: 600;

    &:hover {
      color: var(--primary);
      text-decoration: none;
    }
  }

  .active {
    color: var(--primary);
    box-shadow: inset 0 -3px 0 0 var(--primary);
  }

  @media (min-width: ${media.lg}) {
    flex-wrap: wrap;
  }

  .dropdown-toggle {
    border: 0;
    border-radius: 0;
    &::after {
      vertical-align: 0.1em;
    }
  }
  .dropdown-menu {
    font-size: 0.875rem;
    .dropdown-item {
      border-radius: 0;
    }
  }

  .badge {
    position: relative;
    top: -1px;
    margin-left: 0.3em;
    text-transform: uppercase;
  }
`

export interface TabLink {
  isActive?: boolean
  label: string
  onClick?: () => void
}

interface TabsProps {
  items: [TabLink]
  className?: string
}
export const Tabs: React.FC<TabsProps> = ({ items, className }) => {
  return (
    <StyledNav className={className + ' nav TabNav'}>
      {items &&
        items.map((link) => (
          <div
            className={`link ${link.isActive ? 'active' : ''}`}
            key={link.label}
            onClick={link.onClick}
          >
            {link.label}
          </div>
        ))}
    </StyledNav>
  )
}

export interface TabNavDropdownItem {
  route: string
  active?: boolean
  label: string
}

interface TabNavDropdownProps {
  label: string
  active?: boolean
  subItems?: TabNavDropdownItem[]
}

interface TabNavDropdownState {
  dropdownOpen: boolean
}

class TabNavDropdown extends React.Component<TabNavDropdownProps, TabNavDropdownState> {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      dropdownOpen: false,
    }
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  render() {
    const { label, active, subItems } = this.props
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret color="alt" className={active ? 'active' : ''}>
          {label}
        </DropdownToggle>
        <DropdownMenu>
          {subItems?.map((item, index) => (
            <DropdownItem
              tag={Link}
              to={item.route}
              key={index}
              className={item.active ? 'active' : ''}
            >
              {item.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    )
  }
}

export interface TabNavItem {
  subItems?: TabNavDropdownItem[]
  active?: boolean
  exact?: boolean
  route: string
  label: string
  isNew?: boolean
}

interface TabNavProps {
  className?: string
  items?: TabNavItem[]
}

const TabNav: React.FC<TabNavProps> = (props) => {
  return (
    <StyledNav className={props.className + ' nav TabNav'}>
      {props.items &&
        props.items.map((link, index) => {
          if (link.subItems) {
            return <TabNavDropdown key={index} {...link} />
          }

          return (
            <BootstrapNavLink
              key={index}
              tag={Link}
              active={link.active}
              activeClassName="active"
              exact={typeof link.exact !== 'undefined' ? link.exact : true}
              navLink
              to={link.route}
            >
              {link.label} {link.isNew && <span className="badge badge-success">New</span>}
            </BootstrapNavLink>
          )
        })}
    </StyledNav>
  )
}

export default TabNav
