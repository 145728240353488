import React from 'react'
import Field from './Field'
import SettingsSection from '../settings/SettingsSection'
import Required, { RequiredSchema } from '../settings/Required/Required'
import Input from '../../_shared/components/Form/Input'
import Cardbox, { CardboxItem } from '../../_shared/components/Form/Cardbox'

const Form = (props) => (
  <React.Fragment>
    <SettingsSection title="Label" startOpen={true}>
      <Input name="config.label" label="Label" showLabel={false} multipleLines minRows={1} />
      <Cardbox name="config.hideLabel" thin vertical showLabel={false}>
        <CardboxItem value={true} title="Hide label" />
      </Cardbox>
    </SettingsSection>
    <Required name="config.required" />
  </React.Fragment>
)

Object.assign(Field.prototype, {
  editor: {
    settingsForm: Form,
  },

  getSettingsSchema(schema) {
    Object.assign(schema.properties, {
      label: {
        type: 'string',
      },
      hideLabel: {
        type: 'boolean',
      },
      ...RequiredSchema,
    })
    schema.required = ['label']
  },
})
