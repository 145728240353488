import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import Radio from '../../../../_shared/components/Form/Radio'
import MatchValue from './MatchValue'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import withForm from '../../../../_shared/components/withForm'
import { FormErrors } from '../../../../_shared/components/Form/helpers'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import Field, { FieldConfig, FieldData } from '../../Field'
import { FieldResponse } from '../../../../_shared/models/response/response.types'
import ClientResponse from '../../../../_shared/models/response/response.client'
import ClientPathway from '../../../../_shared/models/pathway/pathway.client'
import { FormContextProps } from '../../../../_shared/components/Form'
import Ajv from 'ajv'
import addFormats from 'ajv-formats'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import Log from '../../../../_shared/log'
import { Answer } from '../../AnswersBlock'

const SimpleOutput = ({
  answer,
  className,
}: {
  answer: MatchFieldResponse
  className?: string
}) => (
  <ul className={className}>
    {answer.data?.map((a) => (
      <li key={a._id}>
        {a.value ? (
          <FontAwesomeIcon icon={faCheckCircle} className={`erMatch-true`} />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} className={`erMatch-false`} />
        )}{' '}
        {a.label}
      </li>
    ))}
  </ul>
)

const StyledSimpleOutput = styled(SimpleOutput)`
  & {
    margin: 0;
    list-style: none;
    padding: 0;
  }

  li {
  }

  .erMatch-true {
    color: var(--success);
  }
  .erMatch-false {
    color: var(--danger);
  }
`

export interface MatchControlProps extends Partial<FormContextProps> {
  name: string
  value?: Record<number, string>
  answers?: Answer[]
  block: MatchField
  errors?: string[]
  context?: FrontendContext
}

class MatchControl extends React.PureComponent<MatchControlProps> {
  render() {
    const { name, errors, answers, block, context, value } = this.props
    const type = block.data?.config?.type

    const radioOptions: { positiveLabel: string; negativeLabel: string } = {
      positiveLabel: 'True',
      negativeLabel: 'False',
    }

    if (type === 'yesno') {
      radioOptions.positiveLabel = 'Yes'
      radioOptions.negativeLabel = 'No'
    }

    return (
      <div
        className={classnames('form-group', {
          'is-invalid': errors?.length > 0,
        })}
      >
        {!block.data.config.hideLabel && <label>{block.getLabel(context)}</label>}
        {answers &&
          answers.map((answer) => {
            const options = [
              { label: radioOptions.positiveLabel, value: 1 },
              { label: radioOptions.negativeLabel, value: 0 },
            ]

            if (answer.includeNotApplicable) {
              options.push({ label: 'Not Applicable', value: -1 })
            }

            return (
              <div key={answer.value}>
                <Radio label={answer.label} name={`${name}.${answer.value}`} options={options} />
              </div>
            )
          })}
        <FormErrors errors={errors} />
      </div>
    )
  }
}
const MatchControlWithForm = withForm(MatchControl)

export interface MatchBlockProps {
  block: MatchField
  className?: string
}

const MatchBlock: React.FC<MatchBlockProps> = ({ block, className }: MatchBlockProps) => {
  const answers = block.getOptions()

  return (
    <div className={'block ' + className}>
      <MatchControlWithForm answers={answers} name={block.id} block={block} />
    </div>
  )
}

export const StyledMatch = styled(MatchBlock)`
  .form-group {
    margin-bottom: 1.5rem;
  }
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'Match'

export interface MatchAnswer {
  _id: string
  label: string
  value: number
  points?: number
  includeNotApplicable?: boolean
}

export interface MatchFieldConfig extends FieldConfig {
  answers?: Answer[]
  type: 'truefalse' | 'yesno'
}

export interface MatchFieldData extends FieldData {
  config?: MatchFieldConfig
}

export interface MatchFieldResponse extends FieldResponse {
  data: MatchAnswer[]
  value: string[]
}

export default class MatchField extends Field {
  static type? = key
  includeNotApplicable? = true
  declare data?: MatchFieldData

  static simpleOutput = StyledSimpleOutput
  static detailedOutput = MatchValue
  static enableStatistics = true
  static enableConditionalLogic = true

  static getConditionalLogicValue(value: MatchFieldResponse): string[] {
    return value ? value.data.filter((ans) => ans.value > 0).map((ans) => ans._id) : []
  }

  static template: Partial<MatchField> = {
    data: {
      config: {
        label: 'Match',
        type: 'truefalse',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<MatchField>) {
    super(params)
    Object.assign(this, MatchField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledMatch
  }

  // todo: conditional logic typing
  static conditionalLogicCompare(
    field: MatchFieldResponse,
    condition: Record<string, any>
  ): boolean {
    // get values as object
    const valueObj = {}
    if (field?.data) {
      field.data.forEach((v) => {
        valueObj[v._id] = typeof v.value === 'number' ? !!v.value : v.value
      })
    }

    switch (condition.comparison) {
      case 'is':
        return valueObj[condition.value]
      case 'is not':
        return valueObj[condition.value] === false
    }
  }

  /**
   * Converts the form value (usually a primitive) to a FieldResponse
   */
  formToDoc(answers: Record<string, string>): MatchFieldResponse {
    const defAnswers = this.data?.config?.answers
    const answer: MatchFieldResponse = {
      _id: this.id,
      name: this.data?.advanced?.name,
      label: this.getLabel(),
      value: [],
      type: (this.constructor as any).type,
      data: defAnswers.map((answer) => {
        const answeredTrue = parseInt(answers[answer.value], 10) === 1
        return Object.assign({}, answer, {
          _id: answer.value,
          value: answeredTrue ? 1 : 0,
        })
      }),
    }

    defAnswers.forEach((a) => {
      if (parseInt(answers[a.value], 10) === 1) {
        answer.value.push(a.label)
      }
    })

    return answer
  }

  docToForm(doc: MatchFieldResponse): Record<string, string> {
    const value = {}
    const defAnswers = this.data?.config?.answers
    doc.data?.forEach((answer) => {
      const answerDef = defAnswers.find((a) => answer.label === a.label)
      if (answerDef) {
        value[answerDef.value] = answer.value
      }
    })

    return value
  }

  getOptions(): Answer[] {
    return this.data?.config?.answers
  }

  transformPostValue(value: unknown): Record<string, string> {
    const postValueSchema = {
      type: 'object',
      properties: {},
      additionalProperties: false,
      required: [],
    }

    this.getOptions().forEach((option) => {
      postValueSchema.properties[option.value] = {
        type: 'string',
      }
    })

    const ajv = new Ajv({
      allErrors: true,
      useDefaults: true,
      removeAdditional: true,
      coerceTypes: true,
      verbose: true,
    })
    addFormats(ajv)

    ajv.validate(postValueSchema, value)
    if (ajv.errors) {
      Log.warn('TransformPostValue failed validation', {
        block: this,
        value,
        errors: ajv.errors,
      })
      return
    }

    return value as Record<string, string>
  }

  getFormSchema({ response, pathway }: FrontendContext): Record<string, any> {
    const schema = super.getFormSchema({ response, pathway })

    const properties = {}
    const required = []
    const answers = this.data?.config?.answers

    answers?.forEach((answer) => {
      properties[answer.value] = {
        type: 'number',
        title: answer.label,
      }
      required.push(String(answer.value))
    })

    Object.assign(schema.schema, {
      type: 'object',
      properties,
      required,
      additionalProperties: false,
    })

    return schema
  }
}

BlockManager.registerBlockClass(MatchField)
