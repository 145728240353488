import React from 'react'
import { faPeopleCarry } from '@fortawesome/pro-solid-svg-icons/faPeopleCarry'
import SupportersField from './Supporters'
import BlockManager from '../../BlockManager'

export const Form = (props) => <div className="section-content" />

class SupportersFieldEditor extends SupportersField {
  static editor = {
    label: 'Supporters',
    category: 'form',
    icon: faPeopleCarry,
    settingsForm: Form,

    getSettingsSchema(schema) {
      return {}
    },
  }
}
BlockManager.registerBlockClass(SupportersFieldEditor)
