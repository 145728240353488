import React from 'react'
import styled from 'styled-components'
import DropdownInput from '../../../../_shared/components/Form/Dropdown'
import classnames from 'classnames'
import Field from '../../Field'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { FrontendContext } from '../../../../_shared/StepSubmission'

export interface ProvinceProps {
  block: ProvinceField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

const options = [
  {
    _id: 'Alberta',
    label: 'Alberta',
    value: 'Alberta',
  },
  {
    _id: 'British Columbia',
    label: 'British Columbia',
    value: 'British Columbia',
  },
  {
    _id: 'Manitoba',
    label: 'Manitoba',
    value: 'Manitoba',
  },
  {
    _id: 'New Brunswick',
    label: 'New Brunswick',
    value: 'New Brunswick',
  },
  {
    _id: 'Newfoundland and Labrador',
    label: 'Newfoundland and Labrador',
    value: 'Newfoundland and Labrador',
  },
  {
    _id: 'Northwest Territories',
    label: 'Northwest Territories',
    value: 'Northwest Territories',
  },
  {
    _id: 'Nova Scotia',
    label: 'Nova Scotia',
    value: 'Nova Scotia',
  },
  {
    _id: 'Nunavut',
    label: 'Nunavut',
    value: 'Nunavut',
  },
  {
    _id: 'Ontario',
    label: 'Ontario',
    value: 'Ontario',
  },
  {
    _id: 'Prince Edward Island',
    label: 'Prince Edward Island',
    value: 'Prince Edward Island',
  },
  {
    _id: 'Quebec',
    label: 'Quebec',
    value: 'Quebec',
  },
  {
    _id: 'Saskatchewan',
    label: 'Saskatchewan',
    value: 'Saskatchewan',
  },
  {
    _id: 'Yukon',
    label: 'Yukon',
    value: 'Yukon',
  },
]

class Province extends React.PureComponent<ProvinceProps> {
  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)

    return (
      <DropdownInput
        className={classnames('block', className)}
        name={block.id}
        id={block.id}
        label={label}
        showLabel={!block.data.config.hideLabel}
        placeholder="(Select One)"
      >
        {options.map((answer) => (
          <option key={answer.value} value={answer.value}>
            {answer.label}
          </option>
        ))}
      </DropdownInput>
    )
  }
}

const StyledProvince = styled(Province)`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'Province'
export default class ProvinceField extends Field {
  static type? = key
  static enableStatistics = true
  static enableConditionalLogic = true

  static template: Partial<ProvinceField> = {
    data: {
      config: {
        label: 'Province',
        required: {
          type: true,
        },
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<ProvinceField>) {
    super(params)
    Object.assign(this, ProvinceField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledProvince
  }
}

BlockManager.registerBlockClass(ProvinceField)
