import React from 'react'
import styled from 'styled-components'

const defaultFontFamily = 'sans-serif'

export const hasFontFamily = value =>
  value.marks.some(mark => mark.type === 'font-family')

export const getFontFamily = value =>
  value.marks.filter(mark => mark.type === 'font-family').first()

export const setFontFamily = (family, editor) => {
  const { value } = editor
  const { selection } = value

  const mark = {
    type: 'font-family',
    data: {
      family,
    },
  }

  let originalSelection
  if (selection.isCollapsed) {
    originalSelection = selection

    editor.moveStartToStartOfText()
    editor.moveEndToEndOfText()
  }

  const existingMarks = value.marks.filter(mark => mark.type === 'font-family')

  if (existingMarks.size > 0) {
    existingMarks.forEach(mark => {
      editor.removeMark(mark)
    })
  }

  if (family !== defaultFontFamily) {
    console.log('skip adding mark for default font')
    editor.addMark(mark)
  }

  if (originalSelection) {
    editor.select(originalSelection)
  }
  editor.focus()
}

const StyledDiv = styled.div`
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #091f39;
  font-size: 0.9375rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0.5rem 1rem;

    li {
      color: #bbb;
      cursor: pointer;
      &:hover,
      &:focus {
        color: #fff;
      }
      &.active {
        color: #40ef2f;
      }
    }
  }
`

export const FontFamilyControl = ({ editor, className }) => {
  let value = 'sans-serif'
  const fs = getFontFamily(editor.value)
  if (fs) {
    value = fs.data.get('family')
  }

  return (
    <StyledDiv>
      <ul>
        <li
          className={value === 'sans-serif' ? 'active' : ''}
          onClick={event => setFontFamily('sans-serif', editor)}
        >
          Sans-serif
        </li>
        <li
          className={value === 'serif' ? 'active' : ''}
          onClick={event => setFontFamily('serif', editor)}
        >
          Serif
        </li>
      </ul>
    </StyledDiv>
  )
}

export const FontFamilyPlugin = options => ({
  // onKeyDown(event, editor, next) {
  //   if (isMod(event) && event.key === 'b') {
  //     event.preventDefault()
  //     return toggleFontFamily(editor)
  //   }
  //   next()
  // },
})

export const FontFamilyMark = ({ children, mark }) => (
  <span style={{ fontFamily: mark.data.get('family') }}>{children}</span>
)
