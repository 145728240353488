import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFont } from '@fortawesome/pro-solid-svg-icons/faFont'
import styled from 'styled-components'
import { Button } from 'reactstrap'

export const hasHeading = value =>
  value.blocks.some(block => block.type === 'heading')

export const getHeading = value =>
  value.blocks.filter(block => block.type === 'heading').first()

export const setHeading = (size, editor) => {
  const { value } = editor
  const { selection } = value

  let originalSelection
  if (selection.isCollapsed) {
    originalSelection = selection

    editor.moveStartToStartOfText()
    editor.moveEndToEndOfText()
  }

  editor.unwrapBlock('heading')

  if (size) {
    editor.wrapBlock({ type: 'heading', data: { size } })
  }

  if (originalSelection) {
    editor.select(originalSelection)
  }
}

export const HeadingControl = ({ editor, className }) => {
  let currentSize
  const heading = getHeading(editor.value)
  if (heading) {
    currentSize = heading.data.get('size')
  }

  return (
    <div>
      <Button
        type="button"
        onClick={() => setHeading(null, editor)}
        className={classnames('slate-headings--button', className)}
        active={currentSize < 1}
        color="primary"
      >
        P
      </Button>
      <Button
        type="button"
        onClick={() => setHeading(1, editor)}
        active={currentSize === 1}
        color="primary"
      >
        H1
      </Button>
      <Button
        type="button"
        onClick={() => setHeading(2, editor)}
        active={currentSize === 2}
        color="primary"
      >
        H2
      </Button>
      <Button
        type="button"
        onClick={() => setHeading(3, editor)}
        active={currentSize === 3}
        color="primary"
      >
        H3
      </Button>
      <Button
        type="button"
        onClick={() => setHeading(4, editor)}
        active={currentSize === 4}
        color="primary"
      >
        H4
      </Button>
    </div>
  )
}

export const HeadingNode = ({ attributes, children, node }) => {
  const size = parseInt(node.data.get('size'), 10)

  let Tag

  switch (size) {
    case 2:
      Tag = 'h2'
      break
    case 3:
      Tag = 'h3'
      break
    case 4:
      Tag = 'h4'
      break
    default:
    case 1:
      Tag = 'h1'
  }

  return <Tag {...attributes}>{children}</Tag>
}
