import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListUl } from '@fortawesome/pro-solid-svg-icons/faListUl'
import {
  isList,
  applyList,
  isOrderedList,
  isUnorderedList,
  switchToUnorderedList,
  deepRemoveList,
} from './utils'

export const toggleUnorderedList = editor => {
  const { value } = editor
  // If it is not a list yet, transform it!
  if (!isList(value)) return applyList(editor, 'unordered-list')

  // If it is already a list, handle it!
  if (isUnorderedList(value)) return deepRemoveList(editor)
  else if (isOrderedList(value)) return switchToUnorderedList(editor)
  else console.info('[SlateJS][ListPlugin] It is a different type of list.')
  return editor
}

export const UnorderedListButton = ({ editor, className }) => (
  <button
    type="button"
    onClick={e => toggleUnorderedList(editor)}
    className={classnames(
      'slate-orderedList-plugin--button',
      { active: isUnorderedList(editor.value) },
      className
    )}
  >
    <FontAwesomeIcon icon={faListUl} fixedWidth />
  </button>
)

export const UnorderedListPlugin = options => ({
  onKeyDown(event, editor, next) {
    const { value } = editor
    if (
      event.key === 'Backspace' &&
      value.selection.isCollapsed &&
      value.focusBlock.type === 'list-item' &&
      value.focusBlock.text === ''
    ) {
      event.preventDefault()
      deepRemoveList(editor)
      return
    }

    next()
  },
})

export const UnorderedListNode = ({ attributes, children }) => (
  <ul {...attributes}>{children}</ul>
)
