import React from 'react'
import { faFont } from '@fortawesome/pro-solid-svg-icons/faFont'
import NumberField from './Number'
import Input from '../../../../_shared/components/Form/Input'
import BlockManager from '../../BlockManager'

export const Form = (props) => (
  <div className="section-content">
    <Input name="config.prefix" />
    <Input name="config.suffix" />
  </div>
)

class NumberFieldEditor extends NumberField {
  static editor = {
    label: 'Number',
    category: 'form',
    icon: faFont,
    settingsForm: Form,

    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        prefix: {
          type: 'string',
        },
        suffix: {
          type: 'string',
        },
      })
    },
  }
}

BlockManager.registerBlockClass(NumberFieldEditor)
