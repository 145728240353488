import getHeightStyles from '../settings/Height/getHeightStyles'
import getWidthStyles from '../settings/Width/getWidthStyles'
import getAlignmentStyles from '../settings/Alignment/getAlignmentStyles'
import getBackgroundStyles from '../settings/Background/getBackgroundStyles'
import getMarginStyles from '../settings/Margin/getMarginStyles'
import getPaddingStyles from '../settings/Padding/getPaddingStyles'
import getTextStyles from '../settings/Text/getTextStyles'
import { BlockStyleContext, BlockResponsiveAppearance, BlockAppearanceConfig } from '../types'
import escapeRegExp from 'lodash/escapeRegExp'
import mediaQueries from '../../backend/client/css/_media'
import { FrontendContext } from '../../_shared/StepSubmission'
import { FieldResponse } from '../../_shared/models/response/response.types'

export const substituteVariables = (input: string, context?: FrontendContext): string => {
  if (!context) {
    return input
  }

  if (!context.response) {
    return input
  }

  // Parse returnURLs first
  if (context.pathway) {
    const regexp = /#{pathway\.url}/gim
    let match = regexp.exec(input) // eslint-disable-line
    if (match) {
      input = input.replace('#{pathway.url}', context.pathway.getPrimaryURL())
    }
  }

  // Parse the rest
  const matches = []
  let match = null
  const regexp = /#{([^}|]+)\|?([^}]*)}/gim
  while ((match = regexp.exec(input))) {
    // eslint-disable-line
    matches.push({
      name: match[1], // id or name of the field
      default: match[2], // default value if the field doesn't have an answer
      match: escapeRegExp(match[0]),
    })
  }

  let field: Partial<FieldResponse>
  let urlencode: boolean
  matches.forEach((match) => {
    urlencode = false
    if (match.name.indexOf('urlencode') === 0) {
      match.name = match.name.replace('urlencode ', '')
      urlencode = true
    }

    let params
    if (context.location) {
      params = new URLSearchParams(context.location.search)
    }

    // Find the desired variable in the response object
    field = context.response.getField(match.name)
    if (!field && params) {
      // If we can't find it in the response, look in the query params for the rest
      const val: string = params.get(match.name)
      if (val) {
        field = {
          value: val,
        }
      }
    }

    if (!field && match.default) {
      field = {
        value: match.default,
      }
    }

    if (!field) {
      input = input.replace(new RegExp(`${match.match}`, 'gi'), '')
      return
    }

    if (field?.value != null && typeof field?.value !== 'undefined') {
      let value: string
      if (field.value instanceof Array) {
        value = field.value.join(', ')
      } else {
        value = String(field.value)
      }
      if (urlencode) {
        value = encodeURIComponent(value)
      }
      input = input.replace(new RegExp(`${match.match}`, 'gi'), value)
    }
  })
  return input
}

const joinStyles = (styles: BlockResponsiveAppearance, newStyles: BlockResponsiveAppearance) => {
  if (newStyles.desktop) {
    styles.desktop += newStyles.desktop
  }
  if (newStyles.tablet) {
    styles.tablet += newStyles.tablet
  }
  if (newStyles.mobile) {
    styles.mobile += newStyles.mobile
  }
  if (newStyles.all) {
    styles.all += newStyles.all
  }
}

export const getBlockStyles = (
  data: BlockAppearanceConfig,
  context?: BlockStyleContext
): string => {
  const styles = {
    desktop: '',
    tablet: '',
    mobile: '',
    all: '',
  }

  joinStyles(styles, getMarginStyles(data))
  joinStyles(styles, getPaddingStyles(data))
  joinStyles(styles, getWidthStyles(data))
  joinStyles(styles, getHeightStyles(data))
  joinStyles(styles, getAlignmentStyles(data))
  joinStyles(styles, getBackgroundStyles(data, context))
  joinStyles(styles, getTextStyles(data))

  let style = ''
  if (styles.all) {
    style += styles.all
  }
  if (styles.mobile) {
    style += styles.mobile
  }
  if (styles.tablet) {
    style += `@media (min-width: ${mediaQueries.md}){${styles.tablet}}`
  }
  if (styles.desktop) {
    style += `@media (min-width: ${mediaQueries.lg}){${styles.desktop}}`
  }

  return style
}
