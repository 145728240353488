import PageBuilder from '../../../pagebuilder/PageBuilder'
import React from 'react'
import { deserialize } from '../../../pagebuilder/blocks/content/RichText/RichText.editor'
import { ReactFrontendContext } from '../../../frontend/client/containers/StepViewContainer'
import { connect } from 'react-redux'
import { editorUpdateResponse } from '../editorFrame/editorRedux'
import Step from '../../../_shared/models/step/step.class'
import Pathway from '../../../_shared/models/pathway/pathway.backend.client'
import ClientProgramGroup from '../../../_shared/models/programGroup/programGroup.client'
import Response from '../../../_shared/models/response/response.backend.client'
import ReportView from '../views/report/ReportView'

interface Props {
  report: Step
  pathway: Pathway
  programGroup?: ClientProgramGroup
}

export default class ReportEditor extends React.Component<Props> {
  shouldComponentUpdate() {
    return false
  }

  getPreview = (content) => {
    const { report, pathway } = this.props
    const preview = {
      ...report,
      content,
    }

    return <ReportView report={preview} pathway={pathway} />
  }

  render() {
    const { report, pathway, programGroup } = this.props
    let content
    if (report.content) {
      content = deserialize(report.content)
    }

    return (
      <ReactFrontendContext.Provider
        value={{
          pathway,
          programGroup,
        }}
      >
        <PageBuilder content={content} getPreview={this.getPreview} />
      </ReactFrontendContext.Provider>
    )
  }
}
