import { faGraduationCap } from '@fortawesome/pro-solid-svg-icons/faGraduationCap'
import ProgramField from './Program'
import React from 'react'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import BlockManager from '../../BlockManager'

export const Form = (props) => (
  <React.Fragment>
    <div className="section-content">
      <Cardbox name="config.includeUndecided" thin vertical showLabel={false}>
        <CardboxItem value={true} title="Include Undecided" />
      </Cardbox>
      <Cardbox name="config.useOptGroups" thin vertical showLabel={false}>
        <CardboxItem
          value={true}
          title="Use Option Groups"
          description="Programs will be sorted into groups based on Career Fields"
        />
      </Cardbox>
    </div>
  </React.Fragment>
)

class ProgramFieldEditor extends ProgramField {
  static editor = {
    label: 'Program',
    category: 'profile',
    icon: faGraduationCap,
    settingsForm: Form,
    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        includeUndecided: {
          title: 'Include Undecided',
          type: 'boolean',
        },
        useOptGroups: {
          title: 'Use Opt Groups',
          type: 'boolean',
        },
      })
    },
  }
}

BlockManager.registerBlockClass(ProgramFieldEditor)
