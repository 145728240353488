import React from 'react'
import NotFound from '../views/NotFound'
import StepEditor from '../components/StepEditor'
import { RouteComponentProps } from 'react-router'
import RESTDataLoader from './RESTDataLoader'
import Pathway from '../../../_shared/models/pathway/pathway.backend.client'
import ClientProgramGroup from '../../../_shared/models/programGroup/programGroup.client'
import {
  EditorState,
  editorUpdatePathway,
  editorUpdateProgramGroup,
} from '../editorFrame/editorRedux'
import { connect } from 'react-redux'

interface MatchParams {
  stepId: string
  pathwayId: string
}

interface Props extends RouteComponentProps<MatchParams> {}

const StepEditorContainer: React.FC<Props> = ({ match }) => (
  <RESTDataLoader
    resource="pathways"
    action={match.params.pathwayId}
    params={{}}
    showLoader={true}
    reduxAction={editorUpdatePathway}
    transform={(p) => new Pathway(p)}
  >
    {(pathway) => {
      if (!pathway) {
        return <NotFound />
      }

      if (pathway.programGroupId) {
        return (
          <RESTDataLoader
            resource="programGroups"
            action={pathway.programGroupId}
            params={{}}
            showLoader={true}
            reduxAction={editorUpdateProgramGroup}
            transform={(p) => new ClientProgramGroup(p)}
          >
            {(programGroup) => {
              if (!programGroup) {
                return <NotFound />
              }

              const step = pathway.getStep(match.params.stepId)
              if (!step) {
                return <NotFound />
              }

              return (
                <div key={step._id}>
                  <StepEditor step={step} pathway={pathway} programGroup={programGroup} />
                </div>
              )
            }}
          </RESTDataLoader>
        )
      } else {
        const step = pathway.getStep(match.params.stepId)
        if (!step) {
          return <NotFound />
        }

        return (
          <div key={step._id}>
            <StepEditor step={step} pathway={pathway} />
          </div>
        )
      }
    }}
  </RESTDataLoader>
)

export default StepEditorContainer
