import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import { Answer } from '../../AnswersBlock'
import { FormGroup, Input as StrapInput } from 'reactstrap'
import {
  getFormGroupClasses,
  FormErrors,
  FormLabel,
} from '../../../../_shared/components/Form/helpers'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import ClientResponse from '../../../../_shared/models/response/response.client'
import ClientPathway from '../../../../_shared/models/pathway/pathway.client'
import BlockManager from '../../BlockManager'
import { FormContextProps } from '../../../../_shared/components/Form'
import withForm from '../../../../_shared/components/withForm'
import { getBlockStyles } from '../../../getBlockStyles'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import { FieldResponse } from '../../../../_shared/models/response/response.types'
import Field, { FieldConfig, FieldData } from '../../Field'
import ClientProgramGroup from '../../../../_shared/models/programGroup/programGroup.client'
import ProgramField, { ProgramFieldResponse } from '../Program/Program'
import Block from '../../Block'
import Log from '../../../../_shared/log'

export interface CampusInputProps extends Partial<FormContextProps> {
  answers?: Answer[]
  name: string
  errors?: string[]
  value?: Answer
  label?: string
  showLabel?: boolean
  waitingForProgramSelection?: boolean
}

class CampusInput extends React.PureComponent<CampusInputProps> {
  componentDidMount() {
    setImmediate(() => {
      document.dispatchEvent(
        new CustomEvent('updated', {
          detail: this.props.name,
        })
      )
    })
  }
  componentDidUpdate() {
    if (!this.props.waitingForProgramSelection) {
      document.dispatchEvent(
        new CustomEvent('updated', {
          detail: this.props.name,
        })
      )
    }
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { answers, setValue } = this.props
    const value = event.target.value

    if (value === '') {
      setValue(this.props, undefined)
    }

    if (Array.isArray(answers)) {
      const answer = answers.find((a) => a.value === value)
      if (answer) {
        setValue(this.props, answer)
      }
    }
  }

  render() {
    const { answers, name, errors, value, label, showLabel, waitingForProgramSelection } =
      this.props
    const hasErrors = errors?.length > 0

    let _answers = answers // eslint-disable-line
    if (waitingForProgramSelection) {
      _answers = []
    }

    return (
      <FormGroup className={getFormGroupClasses(errors)}>
        <FormLabel showLabel={showLabel} id={name} name={name} label={label} />
        <StrapInput
          type="select"
          name={name}
          id={name}
          invalid={hasErrors}
          onChange={this.onChange}
          value={value == null ? '' : value.value}
        >
          <option value="">
            {waitingForProgramSelection ? '(Select a program first)' : '(Select One)'}
          </option>
          {_answers &&
            _answers
              .sort((a, b) => {
                if (a.label < b.label) {
                  return -1
                }
                if (a.label > b.label) {
                  return 1
                }
                return 0
              })
              .map((answer) => (
                <option key={answer.value} value={answer.value}>
                  {answer.label}
                </option>
              ))}
        </StrapInput>
        <FormErrors errors={errors} />
      </FormGroup>
    )
  }
}

export interface CampusProps {
  block: CampusField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

const CampusInputWithForm = withForm(CampusInput)

const Campus: React.FC<CampusProps> = ({ block, className, context }: CampusProps) => {
  const label = block.getLabel(context)
  const answers = block.getOptions(context)

  const hideLabel = block.data?.config?.hideLabel

  return (
    <div className={'block ' + className}>
      <CampusInputWithForm
        name={block.id}
        showLabel={!hideLabel}
        answers={answers}
        label={label}
        waitingForProgramSelection={block.waitingForProgramSelection(context)}
      />
    </div>
  )
}

const StyledCampus = styled(Campus)`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

export interface CampusFieldConfig extends FieldConfig {
  answers?: Answer[]
}

export interface CampusFieldData extends FieldData {
  config?: CampusFieldConfig
}

export interface CampusFieldResponse extends FieldResponse {
  data: Answer
}

const key = 'Campus'
export default class CampusField extends Field {
  static type? = key
  static enableStatistics = true
  static enableConditionalLogic = true
  declare data?: CampusFieldData

  static template: Partial<CampusField> = {
    data: {
      config: {
        label: 'Which campus is closest to you?',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
      advanced: {
        profile: {
          group: 'prospects',
          key: 'campus',
          label: 'Campus',
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<CampusField>) {
    super(params)
    Object.assign(this, CampusField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledCampus
  }

  static getConditionalLogicValue(value: CampusFieldResponse): string {
    return value?.data.value
  }

  getFakeValue({ pathway, programGroup }: FrontendContext): CampusFieldResponse {
    const options = this.getOptions({ pathway, programGroup })
    if (!options || options.length === 0) {
      return
    }
    return {
      _id: 'fakevalue',
      label: this.data?.config?.label,
      type: (this.constructor as any).type,
      value: options[0].label,
      data: options[0],
    }
  }

  /**
   * Transforms the provided POST'ed value to the real value. Allows
   * users to POST the option's label instead of it's value.
   */
  transformPostValue(value: unknown, context?: FrontendContext): Answer {
    if (typeof value !== 'string') {
      Log.warn('TransformPostValue failed validation', {
        block: this,
        value: String(value),
      })
      return
    }

    let selectedCampus: Answer
    const answers = this.data?.config.answers
    if (answers) {
      selectedCampus = answers?.find((a) => a.label === value || a.value === value)
    } else {
      const campus = context?.programGroup?.campuses?.find(
        (a) => a.name === value || a._id === value
      )
      if (campus) {
        selectedCampus = {
          label: campus.name,
          value: campus._id,
        }
      }
    }

    if (!selectedCampus) {
      return null
    }

    return selectedCampus
  }

  getFormSchema(context?: FrontendContext): Record<string, any> {
    return {
      schema: {
        title: this.getLabel(context),
        type: 'object',
        properties: {
          label: {
            type: 'string',
          },
          value: {
            type: 'string',
          },
        },
        required: ['label', 'value'],
      },
      required: this.data?.config?.required,
    }
  }

  formToDoc(value: Answer, context?: FrontendContext): CampusFieldResponse {
    let selectedCampus: Answer
    const answers = this.data.config?.answers
    if (answers) {
      selectedCampus = answers?.find((a) => a.value === value.value)
    } else {
      const campus = context?.programGroup?.campuses?.find((a) => a._id === value.value)
      if (campus) {
        selectedCampus = {
          label: campus.name,
          value: campus._id,
        }
      }
    }

    if (!selectedCampus) {
      return null
    }

    return {
      _id: this.id,
      label: this.getLabel(context),
      name: this.data.advanced?.name,
      value: this.getValue(value),
      type: (this.constructor as any).type,
      data: selectedCampus,
    }
  }

  docToForm(doc: CampusFieldResponse): Answer {
    return doc.data
  }

  waitingForProgramSelection({ pathway, response }: FrontendContext): boolean {
    // Find either a program field or this field, whichever comes first
    let programField: Block
    const searchField = pathway.findBlock(
      (block) => block instanceof ProgramField || block.id === this.id
    )
    if (searchField && searchField instanceof ProgramField) {
      programField = searchField
    }

    if (programField && programField.isVisible(response, pathway)) {
      if (response) {
        return !response.getField(programField.id)
      } else {
        return true
      }
    }

    return false
  }

  getOptions({ pathway, response, programGroup }: FrontendContext): Answer[] {
    // Use answers as an override if set on this field
    const answers = this.data?.config?.answers
    if (answers) {
      return answers
    }

    if (!programGroup) {
      console.warn('No program group provided to campus field, no options shown')
      return []
    }

    // For SSR, render all options for users with no JS
    // if (Meteor.isServer) {
    //   return programGroup.campuses?.map(p => ({
    //     label: p.name,
    //     value: p._id,
    //   }))
    // }

    // Find either a program field or this field, whichever comes first
    let programField
    const searchField = pathway.findBlock(
      (block) => block instanceof ProgramField || block.id === this.id
    )
    if (searchField && searchField instanceof ProgramField) {
      programField = searchField
    }

    // If a Program field appears before this field, we need to wait for a selection
    // to be made before we can show options
    if (response && programField) {
      const selectedProgram = response.getField(programField.id) as ProgramFieldResponse
      if (selectedProgram) {
        const selectedProgramValue = selectedProgram.data.value
        // Show only the campuses available for the selected program
        const programDef = programGroup.programs?.find((p) => p._id === selectedProgramValue)
        if (!programDef) {
          console.warn('Program definition not found for', selectedProgram, programGroup)
          return []
        }

        if (programDef?.campuses) {
          return (
            programGroup.campuses &&
            programGroup.campuses
              .filter((campus) => programDef.campuses.includes(campus._id))
              .map((campus) => ({
                label: campus.name,
                value: campus._id,
              }))
          )
        }
      }
    }

    // Otherwise, return the campuses defined on the company
    if (!programGroup.campuses) {
      return []
    }

    return programGroup.campuses.map((campus) => ({
      label: campus.name,
      value: campus._id,
    }))
  }

  getValue(answer: Answer): string {
    return answer.label
  }
}

BlockManager.registerBlockClass(CampusField)
