import { WidthOptionsSchema } from '../settings/Width/WidthOptions'
import { RequiredSchema } from '../settings/Required/Required'
import { ShowLabelSchema } from '../settings/ShowLabel/ShowLabel'

export const PlaceholderSchema = {
  placeholder: {
    type: 'string',
  },
}

export const FormInputSchema = () => {
  return {
    label: {
      type: 'string',
    },
    ...RequiredSchema,
    ...ShowLabelSchema,
  }
}

export const BlockStyleSchema = {
  appearance: {
    type: 'object',
    // todo: add other options schemas and turn off additionalproperties
    properties: {
      // ...WidthOptionsSchema,
    },
    additionalProperties: true,
  },
}

export const BlockAdvancedSchema = {
  advanced: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        title: 'Field Name',
        pattern: '^[\\D][a-z_\\-0-9]+$',
      },
      cssId: {
        type: 'string',
        title: 'CSS ID',
      },
      cssClass: {
        type: 'string',
        title: 'CSS Class',
      },
      customCss: {
        type: 'string',
        title: 'Custom CSS',
      },
      profile: {
        type: 'object',
        properties: {
          group: {
            type: 'string',
          },
          label: {
            type: 'string',
          },
          key: {
            type: 'string',
            pattern: '^[\\D][a-z_\\-0-9]+$',
          },
        },
        additionalProperties: false,
      },
      trimAnswers: {
        type: 'boolean',
      },
    },
  },
}

const CommonBlockSchema = {
  ...BlockStyleSchema,
  ...BlockAdvancedSchema,
}

export default CommonBlockSchema
