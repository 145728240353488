import React from 'react'
import styled from 'styled-components'
import BlockManager from '../../BlockManager'
import DropdownInput from '../../../../_shared/components/Form/Dropdown'
import classnames from 'classnames'
import Field from '../../Field'
import RowBlock from '../../row/Row'
import SectionBlock from '../../section/Section'
import { getBlockStyles } from '../../../getBlockStyles'
import { FrontendContext } from '../../../../_shared/StepSubmission'

export interface CountryProps {
  block: CountryField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

const countryOptions = [
  { _id: 'Afghanistan', value: 'Afghanistan', label: 'Afghanistan' },
  { _id: 'Aland Islands', value: 'Aland Islands', label: 'Aland Islands' },
  { _id: 'Albania', value: 'Albania', label: 'Albania' },
  { _id: 'Algeria', value: 'Algeria', label: 'Algeria' },
  {
    _id: 'American Samoa',
    value: 'American Samoa',
    label: 'American Samoa',
  },
  { _id: 'Andorra', value: 'Andorra', label: 'Andorra' },
  { _id: 'Angola', value: 'Angola', label: 'Angola' },
  { _id: 'Anguilla', value: 'Anguilla', label: 'Anguilla' },
  { _id: 'Antarctica', value: 'Antarctica', label: 'Antarctica' },
  {
    _id: 'Antigua And Barbuda',
    value: 'Antigua And Barbuda',
    label: 'Antigua And Barbuda',
  },
  { _id: 'Argentina', value: 'Argentina', label: 'Argentina' },
  { _id: 'Armenia', value: 'Armenia', label: 'Armenia' },
  { _id: 'Aruba', value: 'Aruba', label: 'Aruba' },
  { _id: 'Australia', value: 'Australia', label: 'Australia' },
  { _id: 'Austria', value: 'Austria', label: 'Austria' },
  { _id: 'Azerbaijan', value: 'Azerbaijan', label: 'Azerbaijan' },
  { _id: 'Bahamas', value: 'Bahamas', label: 'Bahamas' },
  { _id: 'Bahrain', value: 'Bahrain', label: 'Bahrain' },
  { _id: 'Bangladesh', value: 'Bangladesh', label: 'Bangladesh' },
  { _id: 'Barbados', value: 'Barbados', label: 'Barbados' },
  { _id: 'Belarus', value: 'Belarus', label: 'Belarus' },
  { _id: 'Belgium', value: 'Belgium', label: 'Belgium' },
  { _id: 'Belize', value: 'Belize', label: 'Belize' },
  { _id: 'Benin', value: 'Benin', label: 'Benin' },
  { _id: 'Bermuda', value: 'Bermuda', label: 'Bermuda' },
  { _id: 'Bhutan', value: 'Bhutan', label: 'Bhutan' },
  { _id: 'Bolivia', value: 'Bolivia', label: 'Bolivia' },
  {
    _id: 'Bosnia And Herzegovina',
    value: 'Bosnia And Herzegovina',
    label: 'Bosnia And Herzegovina',
  },
  { _id: 'Botswana', value: 'Botswana', label: 'Botswana' },
  { _id: 'Bouvet Island', value: 'Bouvet Island', label: 'Bouvet Island' },
  { _id: 'Brazil', value: 'Brazil', label: 'Brazil' },
  {
    _id: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory',
    label: 'British Indian Ocean Territory',
  },
  {
    _id: 'Brunei Darussalam',
    value: 'Brunei Darussalam',
    label: 'Brunei Darussalam',
  },
  { _id: 'Bulgaria', value: 'Bulgaria', label: 'Bulgaria' },
  { _id: 'Burkina Faso', value: 'Burkina Faso', label: 'Burkina Faso' },
  { _id: 'Burundi', value: 'Burundi', label: 'Burundi' },
  { _id: 'Cambodia', value: 'Cambodia', label: 'Cambodia' },
  { _id: 'Cameroon', value: 'Cameroon', label: 'Cameroon' },
  { _id: 'Canada', value: 'Canada', label: 'Canada' },
  { _id: 'Cape Verde', value: 'Cape Verde', label: 'Cape Verde' },
  {
    _id: 'Cayman Islands',
    value: 'Cayman Islands',
    label: 'Cayman Islands',
  },
  {
    _id: 'Central African Republic',
    value: 'Central African Republic',
    label: 'Central African Republic',
  },
  { _id: 'Chad', value: 'Chad', label: 'Chad' },
  { _id: 'Chile', value: 'Chile', label: 'Chile' },
  { _id: 'China', value: 'China', label: 'China' },
  {
    _id: 'Christmas Island',
    value: 'Christmas Island',
    label: 'Christmas Island',
  },
  {
    _id: 'Cocos (Keeling) Islands',
    value: 'Cocos (Keeling) Islands',
    label: 'Cocos (Keeling) Islands',
  },
  { _id: 'Colombia', value: 'Colombia', label: 'Colombia' },
  { _id: 'Comoros', value: 'Comoros', label: 'Comoros' },
  { _id: 'Congo', value: 'Congo', label: 'Congo' },
  {
    _id: 'Congo, Democratic Republic',
    value: 'Congo, Democratic Republic',
    label: 'Congo, Democratic Republic',
  },
  { _id: 'Cook Islands', value: 'Cook Islands', label: 'Cook Islands' },
  { _id: 'Costa Rica', value: 'Costa Rica', label: 'Costa Rica' },
  { _id: "Cote D'Ivoire", value: "Cote D'Ivoire", label: "Cote D'Ivoire" },
  { _id: 'Croatia', value: 'Croatia', label: 'Croatia' },
  { _id: 'Cuba', value: 'Cuba', label: 'Cuba' },
  { _id: 'Cyprus', value: 'Cyprus', label: 'Cyprus' },
  {
    _id: 'Czech Republic',
    value: 'Czech Republic',
    label: 'Czech Republic',
  },
  { _id: 'Denmark', value: 'Denmark', label: 'Denmark' },
  { _id: 'Djibouti', value: 'Djibouti', label: 'Djibouti' },
  { _id: 'Dominica', value: 'Dominica', label: 'Dominica' },
  {
    _id: 'Dominican Republic',
    value: 'Dominican Republic',
    label: 'Dominican Republic',
  },
  { _id: 'Ecuador', value: 'Ecuador', label: 'Ecuador' },
  { _id: 'Egypt', value: 'Egypt', label: 'Egypt' },
  { _id: 'El Salvador', value: 'El Salvador', label: 'El Salvador' },
  {
    _id: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
    label: 'Equatorial Guinea',
  },
  { _id: 'Eritrea', value: 'Eritrea', label: 'Eritrea' },
  { _id: 'Estonia', value: 'Estonia', label: 'Estonia' },
  { _id: 'Ethiopia', value: 'Ethiopia', label: 'Ethiopia' },
  {
    _id: 'Falkland Islands (Malvinas)',
    value: 'Falkland Islands (Malvinas)',
    label: 'Falkland Islands (Malvinas)',
  },
  { _id: 'Faroe Islands', value: 'Faroe Islands', label: 'Faroe Islands' },
  { _id: 'Fiji', value: 'Fiji', label: 'Fiji' },
  { _id: 'Finland', value: 'Finland', label: 'Finland' },
  { _id: 'France', value: 'France', label: 'France' },
  { _id: 'French Guiana', value: 'French Guiana', label: 'French Guiana' },
  {
    _id: 'French Polynesia',
    value: 'French Polynesia',
    label: 'French Polynesia',
  },
  {
    _id: 'French Southern Territories',
    value: 'French Southern Territories',
    label: 'French Southern Territories',
  },
  { _id: 'Gabon', value: 'Gabon', label: 'Gabon' },
  { _id: 'Gambia', value: 'Gambia', label: 'Gambia' },
  { _id: 'Georgia', value: 'Georgia', label: 'Georgia' },
  { _id: 'Germany', value: 'Germany', label: 'Germany' },
  { _id: 'Ghana', value: 'Ghana', label: 'Ghana' },
  { _id: 'Gibraltar', value: 'Gibraltar', label: 'Gibraltar' },
  { _id: 'Greece', value: 'Greece', label: 'Greece' },
  { _id: 'Greenland', value: 'Greenland', label: 'Greenland' },
  { _id: 'Grenada', value: 'Grenada', label: 'Grenada' },
  { _id: 'Guadeloupe', value: 'Guadeloupe', label: 'Guadeloupe' },
  { _id: 'Guam', value: 'Guam', label: 'Guam' },
  { _id: 'Guatemala', value: 'Guatemala', label: 'Guatemala' },
  { _id: 'Guernsey', value: 'Guernsey', label: 'Guernsey' },
  { _id: 'Guinea', value: 'Guinea', label: 'Guinea' },
  { _id: 'Guinea-Bissau', value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
  { _id: 'Guyana', value: 'Guyana', label: 'Guyana' },
  { _id: 'Haiti', value: 'Haiti', label: 'Haiti' },
  {
    _id: 'Heard Island & Mcdonald Islands',
    value: 'Heard Island & Mcdonald Islands',
    label: 'Heard Island & Mcdonald Islands',
  },
  {
    _id: 'Holy See (Vatican City State)',
    value: 'Holy See (Vatican City State)',
    label: 'Holy See (Vatican City State)',
  },
  { _id: 'Honduras', value: 'Honduras', label: 'Honduras' },
  { _id: 'Hong Kong', value: 'Hong Kong', label: 'Hong Kong' },
  { _id: 'Hungary', value: 'Hungary', label: 'Hungary' },
  { _id: 'Iceland', value: 'Iceland', label: 'Iceland' },
  { _id: 'India', value: 'India', label: 'India' },
  { _id: 'Indonesia', value: 'Indonesia', label: 'Indonesia' },
  {
    _id: 'Iran, Islamic Republic Of',
    value: 'Iran, Islamic Republic Of',
    label: 'Iran, Islamic Republic Of',
  },
  { _id: 'Iraq', value: 'Iraq', label: 'Iraq' },
  { _id: 'Ireland', value: 'Ireland', label: 'Ireland' },
  { _id: 'Isle Of Man', value: 'Isle Of Man', label: 'Isle Of Man' },
  { _id: 'Israel', value: 'Israel', label: 'Israel' },
  { _id: 'Italy', value: 'Italy', label: 'Italy' },
  { _id: 'Jamaica', value: 'Jamaica', label: 'Jamaica' },
  { _id: 'Japan', value: 'Japan', label: 'Japan' },
  { _id: 'Jersey', value: 'Jersey', label: 'Jersey' },
  { _id: 'Jordan', value: 'Jordan', label: 'Jordan' },
  { _id: 'Kazakhstan', value: 'Kazakhstan', label: 'Kazakhstan' },
  { _id: 'Kenya', value: 'Kenya', label: 'Kenya' },
  { _id: 'Kiribati', value: 'Kiribati', label: 'Kiribati' },
  { _id: 'Korea', value: 'Korea', label: 'Korea' },
  { _id: 'Kuwait', value: 'Kuwait', label: 'Kuwait' },
  { _id: 'Kyrgyzstan', value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  {
    _id: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic",
    label: "Lao People's Democratic Republic",
  },
  { _id: 'Latvia', value: 'Latvia', label: 'Latvia' },
  { _id: 'Lebanon', value: 'Lebanon', label: 'Lebanon' },
  { _id: 'Lesotho', value: 'Lesotho', label: 'Lesotho' },
  { _id: 'Liberia', value: 'Liberia', label: 'Liberia' },
  {
    _id: 'Libyan Arab Jamahiriya',
    value: 'Libyan Arab Jamahiriya',
    label: 'Libyan Arab Jamahiriya',
  },
  { _id: 'Liechtenstein', value: 'Liechtenstein', label: 'Liechtenstein' },
  { _id: 'Lithuania', value: 'Lithuania', label: 'Lithuania' },
  { _id: 'Luxembourg', value: 'Luxembourg', label: 'Luxembourg' },
  { _id: 'Macao', value: 'Macao', label: 'Macao' },
  { _id: 'Macedonia', value: 'Macedonia', label: 'Macedonia' },
  { _id: 'Madagascar', value: 'Madagascar', label: 'Madagascar' },
  { _id: 'Malawi', value: 'Malawi', label: 'Malawi' },
  { _id: 'Malaysia', value: 'Malaysia', label: 'Malaysia' },
  { _id: 'Maldives', value: 'Maldives', label: 'Maldives' },
  { _id: 'Mali', value: 'Mali', label: 'Mali' },
  { _id: 'Malta', value: 'Malta', label: 'Malta' },
  {
    _id: 'Marshall Islands',
    value: 'Marshall Islands',
    label: 'Marshall Islands',
  },
  { _id: 'Martinique', value: 'Martinique', label: 'Martinique' },
  { _id: 'Mauritania', value: 'Mauritania', label: 'Mauritania' },
  { _id: 'Mauritius', value: 'Mauritius', label: 'Mauritius' },
  { _id: 'Mayotte', value: 'Mayotte', label: 'Mayotte' },
  { _id: 'Mexico', value: 'Mexico', label: 'Mexico' },
  {
    _id: 'Micronesia, Federated States Of',
    value: 'Micronesia, Federated States Of',
    label: 'Micronesia, Federated States Of',
  },
  { _id: 'Moldova', value: 'Moldova', label: 'Moldova' },
  { _id: 'Monaco', value: 'Monaco', label: 'Monaco' },
  { _id: 'Mongolia', value: 'Mongolia', label: 'Mongolia' },
  { _id: 'Montenegro', value: 'Montenegro', label: 'Montenegro' },
  { _id: 'Montserrat', value: 'Montserrat', label: 'Montserrat' },
  { _id: 'Morocco', value: 'Morocco', label: 'Morocco' },
  { _id: 'Mozambique', value: 'Mozambique', label: 'Mozambique' },
  { _id: 'Myanmar', value: 'Myanmar', label: 'Myanmar' },
  { _id: 'Namibia', value: 'Namibia', label: 'Namibia' },
  { _id: 'Nauru', value: 'Nauru', label: 'Nauru' },
  { _id: 'Nepal', value: 'Nepal', label: 'Nepal' },
  { _id: 'Netherlands', value: 'Netherlands', label: 'Netherlands' },
  {
    _id: 'Netherlands Antilles',
    value: 'Netherlands Antilles',
    label: 'Netherlands Antilles',
  },
  { _id: 'New Caledonia', value: 'New Caledonia', label: 'New Caledonia' },
  { _id: 'New Zealand', value: 'New Zealand', label: 'New Zealand' },
  { _id: 'Nicaragua', value: 'Nicaragua', label: 'Nicaragua' },
  { _id: 'Niger', value: 'Niger', label: 'Niger' },
  { _id: 'Nigeria', value: 'Nigeria', label: 'Nigeria' },
  { _id: 'Niue', value: 'Niue', label: 'Niue' },
  {
    _id: 'Norfolk Island',
    value: 'Norfolk Island',
    label: 'Norfolk Island',
  },
  {
    _id: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands',
  },
  { _id: 'Norway', value: 'Norway', label: 'Norway' },
  { _id: 'Oman', value: 'Oman', label: 'Oman' },
  { _id: 'Pakistan', value: 'Pakistan', label: 'Pakistan' },
  { _id: 'Palau', value: 'Palau', label: 'Palau' },
  {
    _id: 'Palestinian Territory, Occupied',
    value: 'Palestinian Territory, Occupied',
    label: 'Palestinian Territory, Occupied',
  },
  { _id: 'Panama', value: 'Panama', label: 'Panama' },
  {
    _id: 'Papua New Guinea',
    value: 'Papua New Guinea',
    label: 'Papua New Guinea',
  },
  { _id: 'Paraguay', value: 'Paraguay', label: 'Paraguay' },
  { _id: 'Peru', value: 'Peru', label: 'Peru' },
  { _id: 'Philippines', value: 'Philippines', label: 'Philippines' },
  { _id: 'Pitcairn', value: 'Pitcairn', label: 'Pitcairn' },
  { _id: 'Poland', value: 'Poland', label: 'Poland' },
  { _id: 'Portugal', value: 'Portugal', label: 'Portugal' },
  { _id: 'Puerto Rico', value: 'Puerto Rico', label: 'Puerto Rico' },
  { _id: 'Qatar', value: 'Qatar', label: 'Qatar' },
  { _id: 'Reunion', value: 'Reunion', label: 'Reunion' },
  { _id: 'Romania', value: 'Romania', label: 'Romania' },
  {
    _id: 'Russian Federation',
    value: 'Russian Federation',
    label: 'Russian Federation',
  },
  { _id: 'Rwanda', value: 'Rwanda', label: 'Rwanda' },
  {
    _id: 'Saint Barthelemy',
    value: 'Saint Barthelemy',
    label: 'Saint Barthelemy',
  },
  { _id: 'Saint Helena', value: 'Saint Helena', label: 'Saint Helena' },
  {
    _id: 'Saint Kitts And Nevis',
    value: 'Saint Kitts And Nevis',
    label: 'Saint Kitts And Nevis',
  },
  { _id: 'Saint Lucia', value: 'Saint Lucia', label: 'Saint Lucia' },
  { _id: 'Saint Martin', value: 'Saint Martin', label: 'Saint Martin' },
  {
    _id: 'Saint Pierre And Miquelon',
    value: 'Saint Pierre And Miquelon',
    label: 'Saint Pierre And Miquelon',
  },
  {
    _id: 'Saint Vincent And Grenadines',
    value: 'Saint Vincent And Grenadines',
    label: 'Saint Vincent And Grenadines',
  },
  { _id: 'Samoa', value: 'Samoa', label: 'Samoa' },
  { _id: 'San Marino', value: 'San Marino', label: 'San Marino' },
  {
    _id: 'Sao Tome And Principe',
    value: 'Sao Tome And Principe',
    label: 'Sao Tome And Principe',
  },
  { _id: 'Saudi Arabia', value: 'Saudi Arabia', label: 'Saudi Arabia' },
  { _id: 'Senegal', value: 'Senegal', label: 'Senegal' },
  { _id: 'Serbia', value: 'Serbia', label: 'Serbia' },
  { _id: 'Seychelles', value: 'Seychelles', label: 'Seychelles' },
  { _id: 'Sierra Leone', value: 'Sierra Leone', label: 'Sierra Leone' },
  { _id: 'Singapore', value: 'Singapore', label: 'Singapore' },
  { _id: 'Slovakia', value: 'Slovakia', label: 'Slovakia' },
  { _id: 'Slovenia', value: 'Slovenia', label: 'Slovenia' },
  {
    _id: 'Solomon Islands',
    value: 'Solomon Islands',
    label: 'Solomon Islands',
  },
  { _id: 'Somalia', value: 'Somalia', label: 'Somalia' },
  { _id: 'South Africa', value: 'South Africa', label: 'South Africa' },
  {
    _id: 'South Georgia And Sandwich Isl.',
    value: 'South Georgia And Sandwich Isl.',
    label: 'South Georgia And Sandwich Isl.',
  },
  { _id: 'Spain', value: 'Spain', label: 'Spain' },
  { _id: 'Sri Lanka', value: 'Sri Lanka', label: 'Sri Lanka' },
  { _id: 'Sudan', value: 'Sudan', label: 'Sudan' },
  { _id: 'Suriname', value: 'Suriname', label: 'Suriname' },
  {
    _id: 'Svalbard And Jan Mayen',
    value: 'Svalbard And Jan Mayen',
    label: 'Svalbard And Jan Mayen',
  },
  { _id: 'Swaziland', value: 'Swaziland', label: 'Swaziland' },
  { _id: 'Sweden', value: 'Sweden', label: 'Sweden' },
  { _id: 'Switzerland', value: 'Switzerland', label: 'Switzerland' },
  {
    _id: 'Syrian Arab Republic',
    value: 'Syrian Arab Republic',
    label: 'Syrian Arab Republic',
  },
  { _id: 'Taiwan', value: 'Taiwan', label: 'Taiwan' },
  { _id: 'Tajikistan', value: 'Tajikistan', label: 'Tajikistan' },
  { _id: 'Tanzania', value: 'Tanzania', label: 'Tanzania' },
  { _id: 'Thailand', value: 'Thailand', label: 'Thailand' },
  { _id: 'Timor-Leste', value: 'Timor-Leste', label: 'Timor-Leste' },
  { _id: 'Togo', value: 'Togo', label: 'Togo' },
  { _id: 'Tokelau', value: 'Tokelau', label: 'Tokelau' },
  { _id: 'Tonga', value: 'Tonga', label: 'Tonga' },
  {
    _id: 'Trinidad And Tobago',
    value: 'Trinidad And Tobago',
    label: 'Trinidad And Tobago',
  },
  { _id: 'Tunisia', value: 'Tunisia', label: 'Tunisia' },
  { _id: 'Tur_id', value: 'Tur_id', label: 'Tur_id' },
  { _id: 'Turkmenistan', value: 'Turkmenistan', label: 'Turkmenistan' },
  {
    _id: 'Turks And Caicos Islands',
    value: 'Turks And Caicos Islands',
    label: 'Turks And Caicos Islands',
  },
  { _id: 'Tuvalu', value: 'Tuvalu', label: 'Tuvalu' },
  { _id: 'Uganda', value: 'Uganda', label: 'Uganda' },
  { _id: 'Ukraine', value: 'Ukraine', label: 'Ukraine' },
  {
    _id: 'United Arab Emirates',
    value: 'United Arab Emirates',
    label: 'United Arab Emirates',
  },
  {
    _id: 'United Kingdom',
    value: 'United Kingdom',
    label: 'United Kingdom',
  },
  { _id: 'United States', value: 'United States', label: 'United States' },
  {
    _id: 'United States Outlying Islands',
    value: 'United States Outlying Islands',
    label: 'United States Outlying Islands',
  },
  { _id: 'Uruguay', value: 'Uruguay', label: 'Uruguay' },
  { _id: 'Uzbekistan', value: 'Uzbekistan', label: 'Uzbekistan' },
  { _id: 'Vanuatu', value: 'Vanuatu', label: 'Vanuatu' },
  { _id: 'Venezuela', value: 'Venezuela', label: 'Venezuela' },
  { _id: 'Viet Nam', value: 'Viet Nam', label: 'Viet Nam' },
  {
    _id: 'Virgin Islands, British',
    value: 'Virgin Islands, British',
    label: 'Virgin Islands, British',
  },
  {
    _id: 'Virgin Islands, U.S.',
    value: 'Virgin Islands, U.S.',
    label: 'Virgin Islands, U.S.',
  },
  {
    _id: 'Wallis And Futuna',
    value: 'Wallis And Futuna',
    label: 'Wallis And Futuna',
  },
  {
    _id: 'Western Sahara',
    value: 'Western Sahara',
    label: 'Western Sahara',
  },
  { _id: 'Yemen', value: 'Yemen', label: 'Yemen' },
  { _id: 'Zambia', value: 'Zambia', label: 'Zambia' },
  { _id: 'Zimbabwe', value: 'Zimbabwe', label: 'Zimbabwe' },
]

class Country extends React.PureComponent<CountryProps> {
  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)

    return (
      <DropdownInput
        className={classnames('block', className)}
        name={block.id}
        id={block.id}
        label={label}
        showLabel={!block.data.config.hideLabel}
        placeholder="(Select One)"
      >
        {countryOptions.map((answer) => (
          <option key={answer.value} value={answer.value}>
            {answer.label}
          </option>
        ))}
      </DropdownInput>
    )
  }
}

const StyledCountry = styled(Country)`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'Country'
export default class CountryField extends Field {
  static type? = key
  static enableStatistics = true
  static enableConditionalLogic = true
  static template: Partial<CountryField> = {
    data: {
      config: {
        label: 'Country',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<CountryField>) {
    super(params)
    Object.assign(this, CountryField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledCountry
  }
}

BlockManager.registerBlockClass(CountryField)
