import React from 'react'
import PropTypes from 'prop-types'
import Input from '../../../../_shared/components/Form/Input'
import DropdownInput from '../../../../_shared/components/Form/Dropdown'
import Field from '../../Field'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import styled from 'styled-components'
import { FormErrors, FormLabel } from '../../../../_shared/components/Form/helpers'
import classnames from 'classnames'
import BlockManager, { BlockDetailedOutputProps } from '../../BlockManager'
import { FieldResponse } from '../../../../_shared/models/response/response.types'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import { getBlockStyles } from '../../../getBlockStyles'
import withForm from '../../../../_shared/components/withForm'
import { FormContext, FormContextProps } from '../../../../_shared/components/Form'

export interface SupporterInputControlProps {
  row: SupportersAnswer
  removeSupporterRow?: (index: number) => void
  index: number
  path: string
  errors?: Record<string, any>
}

class SupporterInputControl extends React.PureComponent<SupporterInputControlProps> {
  static propTypes = {
    row: PropTypes.object.isRequired,
    removeSupporterRow: PropTypes.func,
    errors: PropTypes.object,
  }

  render() {
    const { row, removeSupporterRow, errors, index, path } = this.props
    return (
      <div className="row">
        <div className="col-md">
          <Input name={`${path}.name`} label="Name" errors={errors?.name} />
        </div>
        <div className="col-md">
          <DropdownInput
            name={`${path}.relationship`}
            errors={errors?.relationship}
            label="Relationship"
          >
            <option value="">(Select One)</option>
            <option value="Parent">Parent</option>
            <option value="Partner">Partner</option>
            <option value="Family">Family</option>
            <option value="Friend">Friend</option>
            <option value="Other">Other</option>
          </DropdownInput>
        </div>
        <div className="col-md">
          <DropdownInput name={`${path}.type`} errors={errors?.type} label="Type of Support">
            <option value="">(Select One)</option>
            <option value="Moral Support">Moral Support</option>
            <option value="Financial Support">Financial Support</option>
            <option value="Lifestyle Support">Lifestyle Support</option>
            <option value="Other">Other</option>
          </DropdownInput>
        </div>
        <div className="col-md-auto remove-supporter-col">
          <FontAwesomeIcon
            icon={faTimesCircle}
            className={'remove-supporter ' + (removeSupporterRow ? '' : 'invisible')}
            onClick={() => {
              removeSupporterRow && removeSupporterRow(index)
            }}
          />
        </div>
      </div>
    )
  }
}

export interface SupportersControlProps extends Partial<FormContextProps> {
  className?: string
  errors?: string[]
  value?: any[]
  label?: string
  name: string
  showLabel?: boolean
}

class SupportersControl extends React.PureComponent<SupportersControlProps> {
  static contextType?: React.Context<any> = FormContext

  addSupporterRow = (): void => {
    const { setFieldValue, name, value } = this.props
    if (value?.length === 10) {
      return
    }
    setFieldValue(name, [...(value || [{}]), {}])
  }

  removeSupporterRow = (index: number): void => {
    const { setFieldValue, name, value } = this.props
    let newValue = Array.from(value)
    newValue.splice(index, 1)
    if (newValue.length === 0) {
      newValue = undefined
    }
    setFieldValue(name, newValue)
  }

  render() {
    const { name, className, value, showLabel, label } = this.props
    const formErrors = Object.keys(this.context.errors).filter((error) => error.startsWith(name))
    const errors = {}

    if (formErrors) {
      formErrors.forEach((error) => {
        const [id, key, field] = error.split('.')
        if (!errors[key]) {
          errors[key] = {}
        }
        errors[key][field] = this.context.errors[error]
      })
    }

    return (
      <div
        className={classnames('block', className, {
          'is-invalid': Object.keys(errors).length > 0 ? true : false,
        })}
      >
        <FormLabel showLabel={showLabel} id={name} name={name} label={label} />
        <SupporterInputControl
          key={0}
          index={0}
          row={value ? value[0] : {}}
          path={`${name}[${0}]`}
          errors={errors[0]}
        />
        {value?.map((row, index) => {
          if (index === 0) {
            return null
          }
          return (
            <SupporterInputControl
              key={index}
              index={index}
              row={row}
              path={`${name}[${index}]`}
              removeSupporterRow={this.removeSupporterRow}
              errors={errors[index]}
            />
          )
        })}
        <Button className="add-supporter" color="primary" onClick={this.addSupporterRow}>
          <FontAwesomeIcon icon={faPlus} /> Add Supporter
        </Button>
      </div>
    )
  }
}

const SupportersControlWithForm = withForm(SupportersControl, true)

export interface SupportersFieldProps {
  block: SupportersField
  className?: string
}

const SupportersBlock: React.FC<SupportersFieldProps> = ({
  block,
  className,
}: SupportersFieldProps) => {
  return (
    <div className={'block ' + className}>
      <SupportersControlWithForm name={block.id} />
    </div>
  )
}

const SupportersBlockStyled = styled(SupportersBlock)`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};

  .remove-supporter-col {
    /* line-height: 6.3rem; */
    color: #757575;
    font-size: 1.3rem;
    padding-left: 0;
    > svg {
      cursor: pointer;
    }
  }

  .row {
    position: relative;
  }

  @media (max-width: 575.98px) {
    .row {
      border: 1px solid #d4d4d4;
      border-radius: 3px;
      padding-top: 0.5rem;
      margin: 0 0 1rem 0;
    }
    .remove-supporter-col {
      position: absolute;
      top: 0;
      right: 0.5rem;
      width: auto;
      padding: 0;
    }
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .remove-supporter:hover {
    color: #e00000;
  }
`

export interface SupportersAnswer {
  name: string
  relationship: string
  type: string
}

export interface SupportersFieldResponse extends FieldResponse {
  data?: SupportersAnswer[]
}

export interface SupportersSimpleOutputProps {
  answer: SupportersFieldResponse
}

const simpleOutput: React.FC<SupportersSimpleOutputProps> = ({
  answer,
}: SupportersSimpleOutputProps) => {
  return (
    <ul>
      {answer?.data?.map((row, index) => (
        <li key={index}>
          <strong>{row.name}</strong>: {row.relationship}, {row.type}
        </li>
      ))}
    </ul>
  )
}

// todo: need to standardize the props going into simpleoutput and detailed output
const detailedOutput: React.FC<BlockDetailedOutputProps> = ({
  label,
  field,
  hideLabels,
}: BlockDetailedOutputProps) => {
  return (
    <React.Fragment>
      {!hideLabels && <h4>{label || field.label}</h4>}
      <table className="table" style={{ margin: '1rem 0' }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Relationship</th>
            <th>Support Type</th>
          </tr>
        </thead>
        <tbody>
          {field?.data?.map((row, index) => (
            <tr key={index}>
              <td width="33%">{row.name}</td>
              <td width="33%">{row.relationship}</td>
              <td width="33%">{row.type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  )
}

const key = 'Supporters'

export default class SupportersField extends Field {
  static type? = key

  static simpleOutput = simpleOutput
  static detailedOutput = detailedOutput
  static enableConditionalLogic = true

  static template: Partial<SupportersField> = {
    data: {
      config: {
        label: 'Supporters',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  getComponent(): React.ElementType {
    return SupportersBlockStyled
  }

  getValue(response: SupportersAnswer[]): string {
    let values = ''

    response?.map((row, i) => {
      values += `${row.name} - ${row.relationship} - ${row.type}`
      if (response.length > 1 && i < response.length - 1) values += ', '
    })

    return values
  }

  getFormSchema(context?: FrontendContext): Record<string, any> {
    return {
      schema: {
        title: this.getLabel(context),
        type: 'array',
        items: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
            },
            relationship: {
              type: 'string',
            },
            type: {
              type: 'string',
            },
          },
          required: ['name', 'relationship', 'type'],
        },
      },
      required: this.data?.config?.required,
    }
  }
}

BlockManager.registerBlockClass(SupportersField)
