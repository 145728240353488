import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEraser } from '@fortawesome/pro-solid-svg-icons/faEraser'

export const removeFormatting = editor => {
  if (editor.value.selection.isCollapsed) {
    // Select everything
    editor.moveToRangeOfDocument()
  }

  editor.value.marks.forEach(mark => {
    editor.removeMark(mark)
  })
  editor.unwrapBlock()
  editor.unwrapInline()
}

export const RemoveFormattingButton = ({ editor, className }) => (
  <button
    type="button"
    onClick={e => removeFormatting(editor)}
    className={classnames(
      'slate-remove-formatting-plugin--button',
      // { active: editor.value },
      className
    )}
  >
    <FontAwesomeIcon icon={faEraser} fixedWidth />
  </button>
)
