import { faPrint } from '@fortawesome/pro-solid-svg-icons/faPrint'
import BlockManager from '../../BlockManager'
import PrintButtonBlock from './Print'

class PrintButtonBlockEditor extends PrintButtonBlock {
  static editor = {
    label: 'Print Step',
    icon: faPrint,
    category: 'content',
  }
}

BlockManager.registerBlockClass(PrintButtonBlockEditor)
