import React from 'react'
import NotFound from '../views/NotFound'
import StepEditor from '../components/StepEditor'
import { RouteComponentProps } from 'react-router'
import RESTDataLoader from './RESTDataLoader'
import Pathway from '../../../_shared/models/pathway/pathway.backend.client'
import ClientProgramGroup from '../../../_shared/models/programGroup/programGroup.client'
import {
  EditorState,
  editorUpdatePathway,
  editorUpdateProgramGroup,
} from '../editorFrame/editorRedux'
import { connect } from 'react-redux'
import ReportEditor from '../components/ReportEditor'

interface MatchParams {
  reportId: string
}

interface Props extends RouteComponentProps<MatchParams> {}

const ReportEditorContainer: React.FC<Props> = ({ match }) => (
  <RESTDataLoader
    resource="reports"
    action={match.params.reportId}
    // params={{
    //   reportId: match.params.reportId,
    // }}
    showLoader={true}
    // reduxAction={editorUpdateReport}
    // transform={(p) => new Pathway(p)}
  >
    {(report) => {
      if (!report) {
        return <NotFound />
      }

      return (
        <RESTDataLoader
          resource="pathways"
          action={report.pathwayId}
          // params={{
          //   pathwayId: report.pathwayId,
          // }}
          showLoader={true}
          reduxAction={editorUpdatePathway}
          transform={(p) => new Pathway(p)}
        >
          {(pathway) => {
            if (!pathway) {
              return <NotFound />
            }

            if (pathway.programGroupId) {
              return (
                <RESTDataLoader
                  resource="programGroups"
                  action={pathway.programGroupId}
                  // params={{
                  //   programGroupId: pathway.programGroupId,
                  // }}
                  showLoader={true}
                  reduxAction={editorUpdateProgramGroup}
                  transform={(p) => new ClientProgramGroup(p)}
                >
                  {(programGroup) => {
                    if (pathway.programGroupId && !programGroup) {
                      return <NotFound />
                    }

                    return (
                      <div key={report._id}>
                        <ReportEditor
                          report={report}
                          pathway={pathway}
                          programGroup={programGroup}
                        />
                      </div>
                    )
                  }}
                </RESTDataLoader>
              )
            } else {
              return (
                <div key={report._id}>
                  <ReportEditor report={report} pathway={pathway} />
                </div>
              )
            }
          }}
        </RESTDataLoader>
      )
    }}
  </RESTDataLoader>
)

export default ReportEditorContainer
