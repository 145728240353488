import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import Block, { BlockData } from '../../Block'
import { picture } from './filestack-adaptive'
import LazyLoad from 'react-lazyload'
import RowBlock from '../../row/Row'
import SectionBlock from '../../section/Section'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { FrontendContext } from '../../../../_shared/StepSubmission'

// Need to spread children parameter to prevent React key warnings
const createElement = (component: React.ComponentType, props, children) => {
  return React.createElement(component, props, ...(children || []))
}

export interface ImageProps {
  block: ImageBlock
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class Image extends React.Component<ImageProps> {
  render() {
    const { block, className } = this.props

    // console.log(this.props)

    const handle = block.data.config?.image?.handle || 'I8oq6P8oQtePgRcLGhNa'
    const width = block.data.config?.image?.width || 480
    const alt = block.data.config?.alt || ''

    let lazyLoad = false
    if (block.data.config?.lazyload) lazyLoad = block.data.config?.lazyload
    if (this.props.context?.location?.search?.includes('_pdf')) lazyLoad = false

    // Can't lazyload conditionally visible images
    const visibility = block.data?.visibility
    if (visibility?.show === 'conditional-show') {
      lazyLoad = false
    }

    // console.log('lazyloaaad', lazyLoad)

    let Wrapper: React.ElementType = React.Fragment
    if (lazyLoad) {
      Wrapper = LazyLoad
    }

    return (
      <Wrapper>
        <div className={classnames(className, 'block block-content')}>
          {picture(
            handle,
            {
              alt,
              formats: ['webp', 'png', 'jpg'],
              sizes: { fallback: width + 'px' },
            },
            createElement
          )}
        </div>
      </Wrapper>
    )
  }
}

const StyledImage = styled(Image)`
  img {
    display: block;
    max-width: 100%;
    ${(props) => {
      const height = props.block.data?.appearance?.height
      if (height?.type === 'custom') {
        return 'width:auto;'
      }
    }}
    ${(props) => getBlockStyles(props.block.data?.appearance)}
    ${(props) => props.block.data?.advanced?.customCss};
  }
`

export interface ImageBlockConfig {
  alt?: string
  image?: {
    url: string
    handle: string
    width: number
    height: number
  }
  lazyload?: boolean
}

export interface ImageBlockData extends BlockData {
  config?: ImageBlockConfig
}

const key = 'Image'
export default class ImageBlock extends Block {
  static type? = key
  declare data?: ImageBlockData
  static template: Partial<ImageBlock> = {
    data: {
      config: {},
      appearance: {
        align: 'center',

        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<ImageBlock>) {
    super(params)
    Object.assign(this, ImageBlock.template, params)
  }

  getComponent(): React.ElementType {
    return StyledImage
  }
}

BlockManager.registerBlockClass(ImageBlock)
