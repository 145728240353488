import React from 'react'
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH'
import SliderField from './Slider'
import Input from '../../../../_shared/components/Form/Input'
import BlockManager from '../../BlockManager'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'

export const Form = (props) => (
  <div className="section-content">
    <Input type="number" name="config.min" label="Minimum" />
    <Input type="number" name="config.max" label="Maximum" />
    <Input name="config.leftLabel" label="Left Label" />
    <Input name="config.rightLabel" label="Right Label" />
    <Cardbox name="config.tooltip" thin vertical showLabel={false} defaultValue={false}>
      <CardboxItem
        value={true}
        title="Display Tooltip"
        description="Show a tooltip on the slider handle"
      />
    </Cardbox>
  </div>
)

class SliderFieldBlockEditor extends SliderField {
  static editor = {
    label: 'Slider',
    category: 'form',
    icon: faSlidersH,
    settingsForm: Form,
    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        min: {
          type: 'number',
        },
        max: {
          type: 'number',
        },
        leftLabel: {
          type: 'string',
        },
        rightLabel: {
          type: 'string',
        },
        tooltip: {
          type: 'boolean',
        },
      })
    },
  }
}
BlockManager.registerBlockClass(SliderFieldBlockEditor)
