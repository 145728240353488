import React from 'react'
import { AnswersBlock, AnswersMultipleBlock } from './AnswersBlock'
import AnswerOptions, { AnswersSchema } from '../settings/Answers/Answers'
import ScoringOptions, { ScoringOptionsSchema } from '../settings/Scoring/ScoringOptions'

const Form = (props) => (
  <React.Fragment>
    <AnswerOptions name="config.answers" />
  </React.Fragment>
)
const MultipleForm = (props) => (
  <React.Fragment>
    <ScoringOptions path="config.scoring" />
    <AnswerOptions name="config.answers" includeNotApplicable={props.block.includeNotApplicable} />
  </React.Fragment>
)

Object.assign(AnswersBlock.prototype, {
  editor: {
    settingsForm: Form,
  },
  getSettingsSchema(schema) {
    Object.assign(schema.properties, AnswersSchema)
  },
})

Object.assign(AnswersMultipleBlock.prototype, {
  editor: {
    settingsForm: MultipleForm,
  },
  getSettingsSchema(schema) {
    Object.assign(schema.properties, AnswersSchema)
    Object.assign(schema.properties, ScoringOptionsSchema)
  },
})
