import React, { ChangeEvent } from 'react'
import { FormContextProps } from '../Form'
import { FormGroup, Label, Input as StrapInput } from 'reactstrap'
import withForm from '../withForm'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import styled from 'styled-components'

export interface CheckboxProps extends Partial<FormContextProps> {
  label?: string
  showLabel?: boolean
  id?: string
  help?: string
  errors?: string[]
  showValid?: boolean
  validFeedback?: string
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  name: string
  value?: string[]
  options?: Record<string, any>
  className?: string
}

class Checkbox extends React.PureComponent<CheckboxProps> {
  static defaultProps = {
    errors: [],
    showValid: false,
    showLabel: true,
    options: [],
    value: [],
  }

  componentWillUnmount() {
    const { setValue } = this.props
    setValue(this.props, undefined)
  }

  onChange = (event) => {
    const { setValue } = this.props
    let value = this.props.value ? Array.from(this.props.value) : []
    const newValue = event.target.value

    if (value.find((v) => v === newValue)) {
      value = value.filter((v) => v !== newValue)
    } else {
      value.push(newValue)
    }

    if (value.length === 0) {
      value = undefined
    }

    setValue(this.props, value)
  }

  render() {
    const {
      name,
      id,
      showLabel,
      label,
      formSchema,
      errors,
      showValid,
      validFeedback,
      help,
      value,
      className,
    } = this.props

    const hasErrors = errors.length > 0

    return (
      <FormGroup
        tag="fieldset"
        className={getFormGroupClasses(errors, showValid) + ' ' + className}
      >
        <FormLabel
          showLabel={showLabel}
          id={id}
          name={name}
          label={label}
          formSchema={formSchema}
        />
        <HelpText help={help} />
        <div className="checkbox-group">
          {this.props.options.map((option, index) => (
            <FormGroup key={index} check className={value?.includes(option.value) ? 'active' : ''}>
              <div className="form-check-input-wrap">
                <StrapInput
                  type="checkbox"
                  name={name}
                  id={`${name}_${index}`}
                  value={option.value}
                  valid={!hasErrors && showValid}
                  invalid={hasErrors}
                  onChange={this.onChange}
                  checked={value?.includes(option.value)}
                />
              </div>
              <Label check for={`${name}_${index}`}>
                {option.label}
              </Label>
            </FormGroup>
          ))}
        </div>
        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
      </FormGroup>
    )
  }
}

const StyledCheckbox = styled(Checkbox)`
  .valid-feedback,
  .invalid-feedback {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
`

export default withForm<CheckboxProps>(StyledCheckbox)
