import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle'
import BlockManager from '../../BlockManager'
import SocialStylesField from './SocialStyles'

class SocialStylesFieldEditor extends SocialStylesField {
  static editor = {
    label: 'Social Styles',
    category: 'profile',
    icon: faUserCircle,
  }
}

BlockManager.registerBlockClass(SocialStylesFieldEditor)
