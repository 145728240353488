import { BlockResponsiveAppearance, BlockAppearanceConfig } from '../../types'

export interface Margin {
  left?: number
  leftUnit?: string
  right?: number
  rightUnit?: string
  top?: number
  topUnit?: string
  bottom?: number
  bottomUnit?: string
}

export interface ResponsiveMargin {
  desktop?: Margin
  tablet?: Margin
  mobile?: Margin
}

const getStyles = (margin: Margin) => {
  let styles = ''
  if (margin.left) {
    styles += `margin-left: ${margin.left + (margin.leftUnit || 'px')};`
  }
  if (margin.right) {
    styles += `margin-right: ${margin.right + (margin.rightUnit || 'px')};`
  }
  if (margin.top) {
    styles += `margin-top: ${margin.top + (margin.topUnit || 'px')};`
  }
  if (margin.bottom) {
    styles += `margin-bottom: ${margin.bottom + (margin.bottomUnit || 'px')};`
  }
  return styles
}

export default function getMarginStyles(data: BlockAppearanceConfig): BlockResponsiveAppearance {
  const styles: {
    desktop?: string
    tablet?: string
    mobile?: string
  } = {}

  const margin = data?.margin

  if (margin) {
    if (margin.desktop) {
      styles.desktop = getStyles(margin.desktop)
    }
    if (margin.tablet) {
      styles.tablet = getStyles(margin.tablet)
    }
    if (margin.mobile) {
      styles.mobile = getStyles(margin.mobile)
    }
  }

  return styles
}
