import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import Block, { BlockData } from '../../Block'
import { Table } from 'reactstrap'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import Field from '../../Field'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import FieldOutput from '../../FieldOutput'
import { FrontendContext } from '../../../../_shared/StepSubmission'

const StyledTable = styled(Table)`
  font-size: 0.875rem;

  tbody > tr:first-child > td {
    border-top: 0;
  }

  td {
    vertical-align: middle;
    word-break: break-word;
  }

  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent;
  }
  &.table-striped tbody tr:nth-of-type(even) {
    background-color: #f9fafd;
  }

  ul {
    margin: 0;
  }

  th,
  td {
    border-top: 0;
  }

  .label-cell {
    font-weight: 600;
    width: 50%;
    text-align: right;
  }

  @media (min-width: 1300px) {
    .label-cell {
      width: 300px;
    }
  }

  th,
  td {
    border-top: 0;
    padding: 0.75em 0.85em;
    font-size: 0.8125rem;
    color: #212529;
    word-break: break-word;

    @media print {
      font-size: 12px;
    }
  }
`

export interface RecapProps {
  block: RecapBlock
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

const SimpleRecap: React.FC<RecapProps> = ({ block, context }: RecapProps) => {
  const blocksToShow = block.data.config.blocks

  return (
    <StyledTable className="table table-striped simple-summary-table">
      <tbody>
        {blocksToShow &&
          blocksToShow.map((blockId, index) => {
            const blockToShow = context?.pathway?.getBlock(blockId)
            if (blockToShow instanceof Field) {
              let field = context?.response?.getField(blockId)

              if (context?.response && !field) {
                return
              } else if (!context?.response) {
                // Create a fake value for the editor
                const fakeValue = blockToShow?.getFakeValue && blockToShow.getFakeValue(context)
                if (fakeValue) {
                  field = fakeValue
                }
              }

              return (
                <tr key={index}>
                  <td className="label-cell">
                    {(context.response && field?.label) || blockToShow?.getLabel(context)}
                  </td>
                  <td>{field && <FieldOutput field={field} />}</td>
                </tr>
              )
            }
          })}
      </tbody>
    </StyledTable>
  )
}

const DetailedRecap: React.FC<RecapProps> = ({ block, context }: RecapProps) => {
  const blocksToShow = block.data.config?.blocks
  const hideLabels = block.data.config?.hideLabels

  return (
    <React.Fragment>
      {blocksToShow?.map((blockId, index) => {
        // raw answer primitive
        const blockToShow = context?.pathway.getBlock(blockId)
        if (!blockToShow) {
          console.warn(
            `Block ID "${blockId}" is no longer present in the pathway, but is being rendered by summary field "${block.id}"`
          )
          return null
        }

        if (blockToShow instanceof Field) {
          let field
          if (!context || !context.response || context.response._id === 'editorPreview') {
            // Create a fake value for the editor
            const fakeValue =
              blockToShow.getFakeValue &&
              blockToShow.getFakeValue({
                pathway: context.pathway,
                programGroup: context.programGroup,
              })
            if (fakeValue) {
              field = fakeValue
            }
          } else {
            field = context.response.getField(blockId)
          }

          if (!field) {
            return null
          }

          const BlockClass = BlockManager.getBlockClass(
            (blockToShow.constructor as any).type
          ) as typeof Field
          if (!BlockClass) {
            return null
          }

          // If there is no response, can we pick a random answer to show?
          // todo: this is where generateFakeValue would come back into use
          if (BlockClass.detailedOutput) {
            const Component = BlockClass.detailedOutput
            return (
              <Component
                label={!context?.response && blockToShow.getLabel(context)}
                key={blockId}
                hideLabels={hideLabels}
                field={field}
              />
            )
          } else {
            const Component = BlockClass.simpleOutput
            return (
              <div className="detailed-summary" key={blockId} style={{ marginBottom: '1rem' }}>
                <strong>
                  {(context?.response && field?.label) || blockToShow.getLabel(context)}:
                </strong>{' '}
                {field && <FieldOutput simpleOutput={Component} field={field} />}
              </div>
            )
          }
        }
      })}
    </React.Fragment>
  )
}

class Recap extends React.PureComponent<RecapProps> {
  render() {
    const { block, className, context } = this.props
    const format = block.data?.config?.format
    const blocksToShow = block.data?.config?.blocks

    if (!blocksToShow || blocksToShow.length === 0) {
      return (
        <div className={classnames('block', className, 'alert alert-warning text-center')}>
          Empty summary block
        </div>
      )
    }

    return (
      <div className={classnames('block', className)}>
        {format === 'detailed' ? (
          <DetailedRecap {...this.props} />
        ) : (
          <SimpleRecap {...this.props} />
        )}
      </div>
    )
  }
}

const StyledRecap = styled(Recap)`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

export interface RecapBlockConfig {
  hideLabels?: boolean
  format?: string
  blocks?: string[]
}

export interface RecapBlockData extends BlockData {
  config?: RecapBlockConfig
}

const key = 'Recap'

export default class RecapBlock extends Block {
  static type? = key

  declare data?: RecapBlockData

  static template: Partial<RecapBlock> = {
    data: {
      config: {},
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<RecapBlock>) {
    super(params)
    Object.assign(this, RecapBlock.template, params)
  }

  getComponent(): React.ElementType {
    return StyledRecap
  }
}

BlockManager.registerBlockClass(RecapBlock)
