import React from 'react'
import View from '../components/View'
import styled from 'styled-components'

const StyledDiv = styled.div`
  text-align: center;
  padding: 2rem 1rem;
  h2 {
    margin: 1rem 0 1rem 0;
  }
  p {
    color: var(--text-muted);
  }
`

const NotFound: React.FC = (props) => {
  return (
    <View title="Not Found">
      <StyledDiv className="content">
        <img src="/images/danger.png" width="128" alt="404 Not Found" />
        <h2>Sorry, that page does not exist</h2>
        <p>The resource you are trying to access may have been deleted.</p>
      </StyledDiv>
    </View>
  )
}

export default NotFound
