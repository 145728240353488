import React from 'react'
import PageRenderer from '../../../../pagebuilder/PageRenderer'
import styled from 'styled-components'
import { instantiateBlocks } from '../../../../pagebuilder/components/utilities'
import { ReactFrontendContext } from '../../../../frontend/client/containers/StepViewContainer'
import Pathway from '../../../../_shared/models/pathway/pathway.backend.client'
import Response from '../../../../_shared/models/response/response.backend.client'

const StyledDiv = styled.div`
  color: #454545;
  .content {
    padding: 0 !important;
    margin: -0.75rem -0.5rem;
    overflow: hidden;
  }
`

interface Props {
  report: any
  pathway: Pathway
  response?: Response
  programGroup?: any
  location?: any
}

const ReportView: React.FC<Props> = (props) => (
  <ReactFrontendContext.Provider
    value={{
      pathway: props.pathway,
      response: props.response,
      programGroup: props.programGroup,
      location: props.location,
    }}
  >
    <StyledDiv>
      <PageRenderer content={instantiateBlocks(props.report.content)} />
    </StyledDiv>
  </ReactFrontendContext.Provider>
)

export default ReportView
