import React from 'react'
import Gauge from './Gauge'
import styled from 'styled-components'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RGBAColor } from '../../../pagebuilder/types'

const dynamicColor = function (value) {
  if (value < 50) {
    return '#dc3545'
  } else {
    return '#2ca30b'
  }
}

const labelWithPercent = (value) => {
  return parseInt(value, 10) + '%'
}

function getAngle(percentage, gaugeSpanAngle) {
  return (percentage * gaugeSpanAngle) / 100
}

function getCartesian(cx, cy, radius, angle) {
  const rad = (angle * Math.PI) / 180
  return {
    x: Math.round((cx + radius * Math.cos(rad)) * 1000) / 1000,
    y: Math.round((cy + radius * Math.sin(rad)) * 1000) / 1000,
  }
}

function getDialCoords(radius, startAngle, endAngle) {
  const cx = 50
  const cy = 50
  return {
    end: getCartesian(cx, cy, radius, endAngle),
    start: getCartesian(cx, cy, radius, startAngle),
  }
}

function pathString(radius, startAngle, endAngle, largeArc) {
  const coords = getDialCoords(radius, startAngle, endAngle)
  const start = coords.start
  const end = coords.end
  const largeArcFlag = typeof largeArc === 'undefined' ? 1 : largeArc
  return ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 1, end.x, end.y].join(' ')
}

interface Props extends RouteComponentProps {
  value?: number
  className?: string
  showPercent?: boolean
  animateValue?: boolean
  color?: RGBAColor
  size?: 'lg' | 'xl'
}

const ScoreGauge: React.FC<Props> = (props) => {
  let valueD
  if (props.location.search?.includes('_pdf')) {
    const angle = getAngle(props.value, 360 - Math.abs(165 - 15))
    const flag = angle <= 180 ? 0 : 1
    valueD = pathString(40, 165, angle + 165, flag)
  }

  return (
    <div className={props.className}>
      {valueD && (
        <div className="Gauge-sc-2p4ccw-0 gBUfoI">
          <svg viewBox="0 0 100 70" className="gauge">
            <path
              className="dial"
              fill="none"
              stroke="#eee"
              strokeWidth="2"
              d="M 11.363 60.353 A 40 40 0 1 1 88.637 60.353"
            />
            <text
              x="50"
              y="43"
              fill="#999"
              className="value-text"
              fontSize="100%"
              fontFamily="sans-serif"
              fontWeight="normal"
              textAnchor="middle"
              alignmentBaseline="middle"
              dominantBaseline="central"
            >
              {props.value}%
            </text>
            <path className="value" fill="none" stroke="#666" strokeWidth="2.5" d={valueD} />
          </svg>
        </div>
      )}

      {!valueD && (
        <Gauge
          value={props.value}
          dialStartAngle={165}
          dialEndAngle={15}
          max={100}
          label={props.showPercent ? labelWithPercent : (value) => parseInt(value, 10)}
          viewBox="0 0 100 70"
          animateValue={props.animateValue}
          textY={43}
        />
      )}
    </div>
  )
}

export default styled(withRouter(ScoreGauge))`
  .gauge > .dial {
    stroke: #eee;
    stroke-width: 7;
    fill: rgba(0, 0, 0, 0);
  }
  .gauge > .value {
    stroke: ${(props) =>
      props.color
        ? `rgba(${props.color.r},${props.color.g},${props.color.b},${props.color.a})`
        : dynamicColor(props.value)};
    stroke-width: 7;
    fill: rgba(0, 0, 0, 0);
    /* stroke-dasharray: 35 2; */
  }
  .gauge > .value-text,
  .gauge > .text-container text {
    fill: ${(props) =>
      props.color
        ? `rgba(${props.color.r},${props.color.g},${props.color.b},${props.color.a})`
        : dynamicColor(props.value)};
    font-family: sans-serif;
    font-weight: 600;
    font-size: 26px;
  }

  ${(props) => {
    switch (props.size) {
      case 'lg':
        return `
          width: 100px;
          height: 70px;
          .gauge > .dial,
          .gauge > .value{
            stroke-width: 5;
          }
          .gauge > .value-text, .gauge > .text-container text {
            font-size: 24px;
          }
        `
      case 'xl':
        return `
          // width: 60%;
          // height: auto;
          .gauge > .dial,
          .gauge > .value{
            stroke-width: 5;
          }
          .gauge > .value-text, .gauge > .text-container text {
            font-size: 24px;
          }
        `
      default:
        return `
          width: 60px;
          height: 40px;
        `
    }
  }}
`
