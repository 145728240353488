import { faFlagUsa } from '@fortawesome/pro-solid-svg-icons/faFlagUsa'
import BlockManager from '../../BlockManager'
import StateField from './State'

class StateFieldEditor extends StateField {
  static editor = {
    label: 'US State',
    category: 'form',
    icon: faFlagUsa,
  }
}

BlockManager.registerBlockClass(StateFieldEditor)
