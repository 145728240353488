import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import StatisticsConsentField from './StatisticsConsent'
import React from 'react'
import Input from '../../../../_shared/components/Form/Input'
import BlockManager from '../../BlockManager'

const Form = (props) => (
  <div className="section-content">
    <Input name="config.label" label="Label" showLabel={false} multipleLines minRows={1} />
  </div>
)

class StatisticsConsentFieldEditor extends StatisticsConsentField {
  static editor = {
    label: 'Statistics Consent',
    category: 'form',
    icon: faCheck,
    allowFieldNameEdit: false,
    settingsForm: Form,

    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        label: {
          type: 'string',
        },
      })
    },
  }
}

BlockManager.registerBlockClass(StatisticsConsentFieldEditor)
