import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'

const AddButton = props => (
  <div
    className={props.className}
    onClick={event => {
      event.nativeEvent.stopImmediatePropagation()
      props.onClick()
    }}
  >
    <FontAwesomeIcon icon={faPlus} />
  </div>
)

const AddBlockButton = styled(AddButton)`
  width: 70px;
  height: 70px;
  background: var(--primary);
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
  position: fixed;
  bottom: 1rem;
  line-height: 70px;
  left: 50%;
  text-align: center;
  font-size: 2.2rem;
  transition: all 0.15s ease-out;
  transform: translateX(-50%) scale(0.9, 0.9);
  &:hover {
    background-color: #1b89ff;
    transform: translateX(-50%) scale(1, 1);
  }
  &:active {
    transform: translateX(-50%) scale(0.95, 0.95);
  }
  z-index: 2147484000;
`
export default AddBlockButton
