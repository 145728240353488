import React, { ChangeEvent, ReactNode } from 'react'
import { FormGroup } from 'reactstrap'
import { FormContextProps } from '../Form'
import withForm from '../withForm'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import { SketchPicker } from 'react-color'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { RGBAColor } from '../../../pagebuilder/types'

export interface ColorpickerProps extends Partial<FormContextProps> {
  showLabel?: boolean
  label?: string
  id?: string
  help?: string | ReactNode
  errors?: string[]
  showValid?: boolean
  validFeedback?: string
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  name: string
  value?: RGBAColor
  className?: string
  innerRef?: any
}

class Colorpicker extends React.PureComponent<ColorpickerProps> {
  //todo: replace with redux?
  state = {
    showPicker: false,
  }

  togglePicker = () => {
    this.setState({
      showPicker: !this.state.showPicker,
    })
  }

  hidePicker = () => {
    this.setState({
      showPicker: false,
    })
  }

  static defaultProps = {
    errors: [],
    showValid: false,
    showLabel: true,
  }

  componentWillUnmount() {
    const { setValue } = this.props
    setValue(this.props, undefined)
  }

  onChange = (value) => {
    const { setValue } = this.props
    setValue(this.props, value.rgb)
  }

  unset = () => {
    const { setValue } = this.props
    setValue(this.props, undefined)
  }

  render() {
    const {
      showLabel,
      name,
      label,
      formSchema,
      id,
      errors,
      showValid,
      help,
      validFeedback,
      value,
      className,
      innerRef,
    } = this.props

    return (
      <FormGroup className={getFormGroupClasses(errors, showValid) + ' ' + className}>
        <FormLabel
          showLabel={showLabel}
          id={id}
          name={name}
          label={label}
          formSchema={formSchema}
        />

        <div className="row align-items-center no-gutters">
          <div className="col">
            <div
              className="form-control"
              style={{
                background: value ? `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})` : null,
              }}
              onClick={this.togglePicker}
              ref={innerRef}
            />
          </div>
          {value && (
            <div className="col-auto btn-remove" onClick={this.unset}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </div>
        {this.state.showPicker ? (
          <div className="picker">
            <div className="cover" onClick={this.hidePicker} />
            <SketchPicker color={value || '#ffffff'} onChange={this.onChange} />
          </div>
        ) : null}
        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
        <HelpText help={help} />
      </FormGroup>
    )
  }
}

export default styled(withForm<ColorpickerProps>(Colorpicker))`
  position: relative;
  .sketch-picker {
    transform: translate3d(0, 0, 0);
    position: absolute;
    z-index: 90000;
    input {
      width: 100% !important;
    }
  }
  .cover {
    position: fixed;
    z-index: 89999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .form-control {
    background: linear-gradient(45deg, #808080 25%, transparent 25%),
      linear-gradient(-45deg, #808080 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #808080 75%),
      linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  }
  .btn-remove {
    font-size: 1.4em;
    padding-left: 0.5em;
    cursor: pointer;
  }
`
