import React from 'react'
import ClientPathway from '../../../_shared/models/pathway/pathway.client'
import ClientResponse from '../../../_shared/models/response/response.client'
import Step from '../../../_shared/models/step/step.class'
import Helmet from 'react-helmet'
import FrontendFooter from './FrontendFooter'
import Form, { FormSubmissionEvent, FormSubmitContext } from '../../../_shared/components/Form'
import PageRenderer from '../../../pagebuilder/PageRenderer'
import ClientProgramGroup from '../../../_shared/models/programGroup/programGroup.client'
import CookieConsentBanner from './CookieConsentBanner'

interface StepViewProps {
  step: Step
  pathway: ClientPathway
  isEmbedded?: boolean
  isSubmitting?: boolean
  response?: ClientResponse
  programGroup?: ClientProgramGroup
  errors?: Record<string, string[]>
  formData?: Record<string, any>
  trimFields?: string[]
  isEditor?: boolean
  onSubmit?: (event: FormSubmissionEvent, context: FormSubmitContext) => void
  onFormChange?: (event: FormSubmissionEvent) => void
  scrollToField?: boolean
}

// step view container
// - loads data
// - builds form doc
// - grabs query params
// - has submit function logic

// step view component
// - dumb
// - presentational only

const StepView: React.FC<StepViewProps> = ({
  step,
  response,
  pathway,
  isEmbedded,
  isSubmitting,
  errors,
  formData,
  trimFields,
  onSubmit,
  isEditor,
  onFormChange,
  programGroup,
  scrollToField,
}: StepViewProps) => {
  return (
    <div className={isEmbedded ? 'embedded step-view' : 'step-view'}>
      <Helmet>
        <title>{step.pageTitle || step.name}</title>
      </Helmet>
      {pathway.cookieConsentBanner && <CookieConsentBanner />}
      <Form
        method="POST"
        errors={errors}
        key={step._id}
        formData={formData}
        schema={step.getSchema({ response, pathway, programGroup })}
        onValidSubmit={isEditor ? null : onSubmit}
        onChange={onFormChange}
        validateOnChangeAfterSubmit
        leaveWarning={'Are you sure you want to leave? Your answers have not been saved yet.'}
        trimFields={trimFields}
        scrollToField={scrollToField}
        programGroup={programGroup}
      >
        <PageRenderer content={step.content} />
      </Form>
      {!isEmbedded && <FrontendFooter />}
    </div>
  )
}
StepView.displayName = 'StepView'
export default StepView
