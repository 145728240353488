import React from 'react'
import styled from 'styled-components'
import Input from '../../../../_shared/components/Form/Input'
import Field, { FieldConfig, FieldData } from '../../Field'
import classnames from 'classnames'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import ClientResponse from '../../../../_shared/models/response/response.client'
import ClientPathway from '../../../../_shared/models/pathway/pathway.client'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import { ConfirmValueConfig } from '../../ConfirmValue'
import { SetConfirmValue } from '../../../settings/ConfirmValue'

const REGEX_US_STANDARD = '\\(\\d{3}\\)\\s\\d{3}-\\d{4}'
const REGEX_US_STANDARD_COUNTRY = '\\d-\\d{3}-\\d{3}-\\d{4}'
const REGEX_INTERNATIONAL = '\\+\\d{11}'
const REGEX_US_VALIDATE_AREA_CODES =
  '^(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:\\(\\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*\\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*(?:[.-]\\s*)?)(?:[2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\\s*(?:[.-]\\s*)?(?:[0-9]{4})$'
const REGEX_US_PERMISSIVE = '^[0-9]?-?\\(?([0-9]{3})\\)? ?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$'

export interface PhoneProps {
  block: PhoneField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class Phone extends React.PureComponent<PhoneProps> {
  componentDidMount() {
    SetConfirmValue(this.props)
  }

  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)

    let inputMask
    let placeholder
    switch (block.data.config?.format) {
      case 'usStandard':
        placeholder = '(999) 999-9999'
        inputMask = [
          '(',
          /[1-9]/,
          /\d/,
          /\d/,
          ')',
          ' ',
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]
        break
      case 'usStandardCountry':
        placeholder = '9-999-999-9999'
        inputMask = ['1', '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        break
      case 'international':
        placeholder = '+99999999999'
        // Disabling the input mask and validation because of the wide variety of international formats and lengths
        // inputMask = [
        //   '+',
        //   /\d/,
        //   /\d/,
        //   /\d/,
        //   /\d/,
        //   /\d/,
        //   /\d/,
        //   /\d/,
        //   /\d/,
        //   /\d/,
        //   /\d/,
        //   /\d/,
        // ]
        break
      case 'usPermissive':
      case 'usValidOnly':
        placeholder = ''
        break
    }

    return (
      <Input
        name={block.id}
        className={classnames('block', className)}
        label={label}
        mask={inputMask}
        type="tel"
        keepCharacterPositions
        showLabel={!block.data.config.hideLabel}
        guide={true}
        autocomplete="tel"
        maskOptions={{
          guide: true,
          placeholderChar: '\u2000',
        }}
        placeholder={block.data.config.showPlaceholder ? placeholder : null}
      />
    )
  }
}
export const StyledPhone = styled(Phone)`
  input {
    font-size: 1.066666666666667rem;
  }
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

function normalize(phone) {
  const stripped = phone.replace(/[^\d]/g, '')

  if (stripped.length === 10) {
    return stripped.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  } else if (stripped.length === 11) {
    return stripped.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '1-$2-$3-$4')
  } else if (stripped.length === 7) {
    return stripped.replace(/(\d{3})(\d{4})/, '$1-$2')
  }

  return stripped
}

export const key = 'Phone'

export interface PhoneFieldConfig extends ConfirmValueConfig {
  placeholder?: string
  showPlaceholder?: boolean
  format: 'usStandard' | 'usStandardCountry' | 'international' | 'usPermissive' | 'usValidOnly'
}

export interface PhoneFieldData extends FieldData {
  config?: PhoneFieldConfig
}

export default class PhoneField extends Field {
  static type? = key
  static enableConditionalLogic = true
  declare data: PhoneFieldData

  static template: Partial<PhoneField> = {
    data: {
      config: {
        format: 'usStandard',
        label: 'Phone',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
      advanced: {
        profile: {
          group: 'contact',
          key: 'phone',
          label: 'Phone',
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<PhoneField>) {
    super(params)
    Object.assign(this, PhoneField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledPhone
  }

  getValue(answer: string): string {
    let output
    switch (this.data.config.format) {
      case 'usValidOnly':
        output = normalize(answer)
        break
      default:
        output = answer
    }
    return String(output)
  }

  getFormSchema(context: FrontendContext): Record<string, any> {
    const schema = super.getFormSchema(context)

    let formatRegex

    switch (this.data.config.format) {
      case 'usStandard':
        formatRegex = REGEX_US_STANDARD
        break
      case 'usStandardCountry':
        formatRegex = REGEX_US_STANDARD_COUNTRY
        break
      case 'usPermissive':
        formatRegex = REGEX_US_PERMISSIVE
        break
      case 'usValidOnly':
        formatRegex = REGEX_US_VALIDATE_AREA_CODES
        break

      // case 'international':
      //   formatRegex = REGEX_INTERNATIONAL
      //   break
    }

    if (formatRegex /* && Meteor.isClient */) {
      schema.schema.pattern = formatRegex
    }

    return schema
  }
}

BlockManager.registerBlockClass(PhoneField)
