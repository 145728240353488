import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import BlockManager from '../../BlockManager'
import CheckboxField from './Checkbox'

class CheckboxFieldEditor extends CheckboxField {
  static editor = {
    label: 'Checkbox',
    category: 'form',
    icon: faCheck,
  }
}

BlockManager.registerBlockClass(CheckboxFieldEditor)
