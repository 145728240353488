import ClientPathway from '../pathway/pathway.client'
import { FieldResponse, StepReference, StepResponse, ResponseMetadata } from './response.types'
import Field from '../../../pagebuilder/blocks/Field'
import qs from 'qs'
import { FrontendContext } from '../../StepSubmission'

export default class ClientResponse {
  constructor(props: NonFunctionProperties<ClientResponse>) {
    Object.assign(this, props)
  }

  declare _id?: string
  declare companyId: string
  declare campus?: string
  declare program?: string
  declare progress?: number
  declare lastStep?: StepReference
  declare nextStep?: StepReference
  declare score?: number
  steps?: StepResponse[] = []

  // metadata
  declare referrer?: string
  declare lead_src?: string // eslint-disable-line
  declare gclid?: string
  declare utm_source?: string // eslint-disable-line
  declare utm_medium?: string // eslint-disable-line
  declare utm_campaign?: string // eslint-disable-line
  declare utm_term?: string // eslint-disable-line
  declare utm_content?: string // eslint-disable-line
  declare fbclid?: string
  declare mscklid?: string
  declare keyword?: string
  declare crtv?: string
  declare ntwk?: string
  declare dvc?: string
  declare sn?: string
  declare dn?: string
  declare webad?: string
  declare adg?: string
  declare cpgn?: string
  declare kw?: string
  declare site?: string
  declare embedPage?: string // the page that an embedded form was submitted on

  queryToParams(context?: FrontendContext, queryParams?: Record<string, any>, referrer?: any) {
    if (referrer) {
      this.referrer = referrer
    }

    // Loop through all of the provided query params, adding it's value to the document
    // if it has a matching block anywhere in the pathway
    Object.entries(queryParams).forEach(([key, value]) => {
      if (ResponseMetadata.includes(key)) {
        this[key] = value
      }

      const { block, step } = context.pathway.getBlockAndStep(key)
      if (block instanceof Field) {
        // Some blocks have a transformPostValue method to convert delimited strings to arrays, etc.
        let preparedValue
        if (block.transformPostValue) {
          preparedValue = block.transformPostValue(value, context)
        } else {
          preparedValue = String(value)
        }

        if (!preparedValue) {
          return
        }

        const fieldResponse = block.formToDoc(preparedValue, context)
        let responseStep = this.steps.find((s) => s._id === step._id)

        if (!responseStep) {
          responseStep = {
            _id: step._id,
            name: step.name,
            fields: [],
          }
          this.steps.push(responseStep)
        }

        if (!responseStep.fields) {
          const fields = [fieldResponse]
          responseStep.fields = fields
        } else {
          // ensure this block isnt already present
          const exists = responseStep.fields.find((f) => f._id === fieldResponse._id)
          if (!exists) {
            responseStep.fields.push(fieldResponse)
          }
        }
      }
    })
  }

  getUrl() {
    return `${process.env.ADMINURL}/response/${this._id}?a=${this.companyId}`
  }

  flatten() {
    const { steps, integrations, ...response } = this as any

    this.steps.forEach((step) => {
      step.fields.forEach((field) => {
        response[field.name || field._id] = field.value
      })
    })

    response.url = this.getUrl()
    return response
  }

  getField(idOrName: string): FieldResponse {
    let field: FieldResponse

    this.steps?.find((step) => {
      return !!step.fields?.find((f) => {
        if (f._id === idOrName || f.name === idOrName) {
          field = f
          return true
        }
      })
    })

    return field
  }
}
