import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import Field, { FieldConfig, FieldData } from '../../Field'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import Input from '../../../../_shared/components/Form/Input'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import { SetConfirmValue } from '../../../settings/ConfirmValue'

interface TextInputProps {
  block: TextInputField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class TextInput extends React.PureComponent<TextInputProps> {
  componentDidMount() {
    SetConfirmValue(this.props)
  }

  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)

    return (
      <Input
        name={block.id}
        className={classnames('block', className)}
        label={label}
        showLabel={!block.data?.config?.hideLabel}
        placeholder={block.data?.config?.placeholder}
        multipleLines={block.data?.config?.multipleLines}
      />
    )
  }
}

export const StyledTextInput = styled(TextInput)`
  input,
  textarea {
    font-size: 1.066666666666667rem;
  }
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

export const key = 'TextInput'

export interface TextInputConfig extends FieldConfig {
  placeholder?: string
  multipleLines?: boolean
}

export interface TextInputData extends FieldData {
  config?: TextInputConfig
}

export default class TextInputField extends Field {
  static type? = key
  static enableConditionalLogic = true
  declare data?: TextInputData

  static template: Partial<TextInputField> = {
    data: {
      config: {
        label: 'Text Input',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<TextInputField>) {
    super(params)
    Object.assign(this, TextInputField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledTextInput
  }
}

BlockManager.registerBlockClass(TextInputField)
