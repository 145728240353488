import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import BlockToolbar from './BlockToolbar'
import { PageRendererComponentContext } from '../PageRenderer'
import { ReactFrontendContext } from '../../frontend/client/containers/StepViewContainer'

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // styles we need to apply on draggables
  ...draggableStyle,
})

class EditorContentBlock extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    block: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.block !== nextProps.block) {
      return true
    }
    if (this.props.index !== nextProps.index) {
      return true
    }
    if (this.props.sectionIndex !== nextProps.sectionIndex) {
      return true
    }
    if (this.props.rowIndex !== nextProps.rowIndex) {
      return true
    }

    return false
  }

  render() {
    const { className, block, index, section, row, sectionIndex, rowIndex, contentId } = this.props

    const Component = block.constructor.editor?.component || block.getComponent()

    const locationQuery = {
      section: sectionIndex,
      row: rowIndex,
      content: index,
      contentId,
    }

    return (
      <PageRendererComponentContext.Consumer>
        {({ removeBlock, openBlockSettings, cloneBlock, updateBlock }) => (
          <Draggable draggableId={block.id} type="content" index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              >
                <div className={className}>
                  <BlockToolbar
                    block={block}
                    onHover={this.hoverToolbar}
                    dragHandleProps={provided.dragHandleProps}
                    onHoverOff={this.hoverOffToolbar}
                    section={section}
                    cloneBlock={() => cloneBlock(locationQuery)}
                    row={row}
                    hide={this.hoverOffNow}
                    openBlockSettings={() => {
                      openBlockSettings(block, locationQuery)
                    }}
                    removeBlock={() => {
                      removeBlock(locationQuery)
                    }}
                  />
                  <ReactFrontendContext.Consumer>
                    {(frontendContext) => (
                      <Component
                        className={block.data.advanced?.cssClass}
                        block={block}
                        index={index}
                        section={section}
                        row={row}
                        context={frontendContext}
                        sectionIndex={sectionIndex}
                        rowIndex={rowIndex}
                        updateBlock={updateBlock}
                        locationQuery={locationQuery}
                      />
                    )}
                  </ReactFrontendContext.Consumer>
                </div>
              </div>
            )}
          </Draggable>
        )}
      </PageRendererComponentContext.Consumer>
    )
  }
}

export default styled(EditorContentBlock)`
  position: relative;
  pointer-events: auto;

  > .block {
    outline: 2px solid transparent;
    outline-offset: -1px;
    transition: outline-color 0.3s ease-out;
    min-height: 0.5rem;

    ${(props) => {
      if (!props.block.isVisible()) {
        return 'opacity:0.5;'
      }
    }}
  }

  &:focus-within {
    > ${BlockToolbar} {
      display: none !important;
    }
  }

  > ${BlockToolbar}:hover + .block {
    outline-color: rgba(220, 53, 69, 0.8);
  }

  &:hover > ${BlockToolbar} {
    animation-name: fadeIn;
  }

  > ${BlockToolbar} {
    background: var(--danger);
    left: 50%;
    top: 50%;
  }
`
