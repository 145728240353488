import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CheckboxInput from '../../../../_shared/components/Form/Checkbox'
import classnames from 'classnames'
import Block from '../../Block'
import Field from '../../Field'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import ClientPathway from '../../../../_shared/models/pathway/pathway.client'
import { getBlockStyles } from '../../../getBlockStyles'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import { FieldResponse } from '../../../../_shared/models/response/response.types'
import BlockManager from '../../BlockManager'

export interface StatisticsConsentProps {
  block: StatisticsConsentField
  section: SectionBlock
  row: RowBlock
  className?: string
}

class StatisticsConsent extends React.PureComponent<StatisticsConsentProps> {
  render() {
    const { block, className } = this.props

    const answers = [{ label: block.data?.config?.label, value: 'true' }]

    return (
      <CheckboxInput
        className={classnames('block', className)}
        name={block.id}
        options={answers}
        showLabel={false}
      />
    )
  }
}

const StyledStatisticsConsent = styled(StatisticsConsent)`
  position: relative;
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

// todo: proper typing
const SimpleOutput = ({ answer }: { answer: FieldResponse }): React.ReactElement => {
  return <div>{answer.value === true && 'Consent given'}</div>
}

const key = 'StatisticsConsent'

export default class StatisticsConsentField extends Field {
  static type? = key
  static usePrimarySettingsFormOnly = true
  static simpleOutput = SimpleOutput

  static template: Partial<StatisticsConsentField> = {
    data: {
      advanced: {
        name: '_va_statistics_consent',
      },
      config: {
        label:
          'By checking this box, you consent to your data being used for analytics purposes to improve this service.',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<StatisticsConsentField>) {
    super(params)
    Object.assign(this, StatisticsConsentField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledStatisticsConsent
  }

  transformPostValue(value: unknown): string[] {
    if (value == 'true' || value == '1') {
      return ['true']
    } else {
      return undefined
    }
  }

  getFormSchema(context: FrontendContext): Record<string, any> {
    const schema = super.getFormSchema(context)
    Object.assign(schema.schema, {
      type: 'array',
    })
    return schema
  }

  /**
   * Converts the form value (usually a primitive) to a FieldResponse
   */
  formToDoc(value: string[], context?: FrontendContext): FieldResponse {
    let val = false
    if (value && value[0]) {
      val = value[0] === 'true'
    }

    const answer: FieldResponse = {
      _id: this.id,
      name: this.data?.advanced?.name,
      label: this.getLabel(context),
      value: val,
      type: (this.constructor as any).type,
    }

    return answer
  }

  docToForm(doc: FieldResponse): string[] {
    return [String(doc.value)]
  }
}

BlockManager.registerBlockClass(StatisticsConsentField)
