import React, { ChangeEvent } from 'react'
import { FormGroup, Input as StrapInput } from 'reactstrap'
import withForm from '../withForm'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import { FormContextProps } from '../Form'
import { InputType } from 'reactstrap/lib/Input'

export interface NumberProps extends Partial<FormContextProps> {
  name: string
  showLabel?: boolean
  label?: string
  type?: InputType
  id?: string
  placeholder?: string
  help?: string
  errors?: string[]
  showValid?: boolean
  validFeedback?: string
  onChange?: (newValue: number) => void
  value?: number
}

class Number extends React.PureComponent<NumberProps> {
  static propTypes = {}

  static defaultProps = {
    showLabel: true,
    errors: [],
    showValid: false,
  }

  componentWillUnmount() {
    this.props.setValue(this.props, undefined)
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value, 10)
    if (isNaN(value)) {
      value = undefined
    }
    this.props.setValue(this.props, value)
  }

  render() {
    const {
      showLabel,
      type,
      name,
      label,
      formSchema,
      validFeedback,
      id,
      placeholder,
      errors,
      showValid,
      help,
      value,
    } = this.props

    const hasErrors = errors.length > 0

    return (
      <FormGroup className={getFormGroupClasses(errors, showValid)}>
        <FormLabel
          showLabel={showLabel}
          id={id}
          name={name}
          label={label}
          formSchema={formSchema}
        />
        <StrapInput
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          valid={!hasErrors && showValid}
          invalid={hasErrors}
          onChange={this.onChange}
          value={value == null ? '' : value}
        />
        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
        <HelpText help={help} />
      </FormGroup>
    )
  }
}

export default withForm(Number)
