import React from 'react'
import PropTypes from 'prop-types'
import SettingsSection from '../../settings/SettingsSection'
import Cardbox, { CardboxItem } from '../../../_shared/components/Form/Cardbox'
import withForm from '../../../_shared/components/withForm'

export const ShowLabelSchema = {
  showLabel: {
    type: 'boolean',
  },
}

const ShowLabel = (props) => (
  <SettingsSection title="Label Visibility" startOpen={props.startOpen}>
    <Cardbox name={props.name} thin vertical showLabel={false}>
      <CardboxItem value={false} title="Hide label" description="Hide this field's label" />
      <CardboxItem value={true} title="Show label" description="Show this field's label" />
    </Cardbox>
  </SettingsSection>
)

ShowLabel.propTypes = {
  formData: PropTypes.object,
  options: PropTypes.array,
  path: PropTypes.string,
  name: PropTypes.string,
  startOpen: PropTypes.bool,
}

export default withForm(ShowLabel)
