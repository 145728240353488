import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import PhoneField, { key } from './Phone'
import React from 'react'
import ConfirmValue, { ConfirmValueSchema } from '../../../settings/ConfirmValue'
import Dropdown from '../../../../_shared/components/Form/Dropdown'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import BlockManager from '../../BlockManager'

export const Form = (props) => (
  <React.Fragment>
    <ConfirmValue fieldKey={key} />
    <div className="section-content">
      <Dropdown label="Format" name="config.format" placeholder="Unrestricted">
        <option value="usStandard">US Standard: (999) 999-9999</option>
        <option value="usStandardCountry">US Standard with Country Code: 9-999-999-9999</option>

        <option value="international">International Standard (E.164): +99999999999</option>

        <option value="usPermissive">
          US Permissive (1-999-999-9999, 999-999-9999, or (999) 999-9999)
        </option>

        <option value="usValidOnly">
          US Valid Area Codes Only (any formatting, optional country code)
        </option>
      </Dropdown>
      <Cardbox name="config.showPlaceholder" thin vertical defaultValue={false}>
        <CardboxItem value={false} title="Hide placeholder" />
        <CardboxItem value={true} title="Show placeholder" />
      </Cardbox>
    </div>
  </React.Fragment>
)
class PhoneFieldEditor extends PhoneField {
  static editor = {
    label: 'Phone',
    category: 'profile',
    icon: faPhone,
    settingsForm: Form,
    canConfirmValue: true,

    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        showPlaceholder: {
          title: 'Placeholder',
          type: 'boolean',
        },
        format: {
          type: 'string',
        },
        ...ConfirmValueSchema,
      })
    },
  }
}

BlockManager.registerBlockClass(PhoneFieldEditor)
