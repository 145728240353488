import React from 'react'
import Gauge from 'svg-gauge/src/gauge.js'
import styled from 'styled-components'

const defaultOptions = {
  animDuration: 1,
  showValue: true,
  max: 100,
}

interface Props {
  value?: number
  className?: string
  animateValue?: boolean
  dialStartAngle?: number
  dialEndAngle?: number
  max?: number
  label?: any
  viewBox?: string
  textY?: number
}

class GaugeComponent extends React.Component<Props> {
  gaugeEl: React.RefObject<HTMLDivElement> = React.createRef()
  gauge: any = null

  componentDidMount() {
    this.renderGauge()
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.renderGauge()
    }
  }

  render() {
    return <div className={this.props.className} ref={this.gaugeEl} />
  }

  renderGauge() {
    const gaugeOptions = Object.assign({}, defaultOptions, this.props) as any

    if (!this.gauge) {
      if (gaugeOptions.animateValue) {
        gaugeOptions.value = 0
      }
      this.gauge = Gauge(this.gaugeEl.current, gaugeOptions)
    }

    if (gaugeOptions.animateValue) {
      this.gauge.setValueAnimated(this.props.value, 1)
    } else {
      this.gauge.setValue(this.props.value)
    }
  }
}

export default styled(GaugeComponent)`
  .gauge > .dial {
    stroke: #eee;
    stroke-width: 7;
    fill: rgba(0, 0, 0, 0);
  }
  .gauge > .value {
    stroke: #2ca30b;
    stroke-width: 7;
    fill: rgba(0, 0, 0, 0);
  }
  .gauge > .value-text {
    fill: #2ca30b;
    font-family: var(--font-family-sans-serif);
    font-weight: bold;
    font-size: 1rem;
  }
`
