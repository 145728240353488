import React from 'react'
import styled from 'styled-components'
import DropdownInput from '../../../../_shared/components/Form/Dropdown'
import classnames from 'classnames'
import Field from '../../Field'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { FrontendContext } from '../../../../_shared/StepSubmission'

export interface StateProps {
  block: StateField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

const options = [
  {
    _id: 'Alabama',
    label: 'Alabama',
    value: 'Alabama',
  },
  {
    _id: 'Alaska',
    label: 'Alaska',
    value: 'Alaska',
  },
  {
    _id: 'American Samoa',
    label: 'American Samoa',
    value: 'American Samoa',
  },
  {
    _id: 'Arizona',
    label: 'Arizona',
    value: 'Arizona',
  },
  {
    _id: 'Arkansas',
    label: 'Arkansas',
    value: 'Arkansas',
  },
  {
    _id: 'California',
    label: 'California',
    value: 'California',
  },
  {
    _id: 'Colorado',
    label: 'Colorado',
    value: 'Colorado',
  },
  {
    _id: 'Connecticut',
    label: 'Connecticut',
    value: 'Connecticut',
  },
  {
    _id: 'Delaware',
    label: 'Delaware',
    value: 'Delaware',
  },
  {
    _id: 'District Of Columbia',
    label: 'District Of Columbia',
    value: 'District Of Columbia',
  },
  {
    _id: 'Federated States Of Micronesia',
    label: 'Federated States Of Micronesia',
    value: 'Federated States Of Micronesia',
  },
  {
    _id: 'Florida',
    label: 'Florida',
    value: 'Florida',
  },
  {
    _id: 'Georgia',
    label: 'Georgia',
    value: 'Georgia',
  },
  {
    _id: 'Guam',
    label: 'Guam',
    value: 'Guam',
  },
  {
    _id: 'Hawaii',
    label: 'Hawaii',
    value: 'Hawaii',
  },
  {
    _id: 'Idaho',
    label: 'Idaho',
    value: 'Idaho',
  },
  {
    _id: 'Illinois',
    label: 'Illinois',
    value: 'Illinois',
  },
  {
    _id: 'Indiana',
    label: 'Indiana',
    value: 'Indiana',
  },
  {
    _id: 'Iowa',
    label: 'Iowa',
    value: 'Iowa',
  },
  {
    _id: 'Kansas',
    label: 'Kansas',
    value: 'Kansas',
  },
  {
    _id: 'Kentucky',
    label: 'Kentucky',
    value: 'Kentucky',
  },
  {
    _id: 'Louisiana',
    label: 'Louisiana',
    value: 'Louisiana',
  },
  {
    _id: 'Maine',
    label: 'Maine',
    value: 'Maine',
  },
  {
    _id: 'Marshall Islands',
    label: 'Marshall Islands',
    value: 'Marshall Islands',
  },
  {
    _id: 'Maryland',
    label: 'Maryland',
    value: 'Maryland',
  },
  {
    _id: 'Massachusetts',
    label: 'Massachusetts',
    value: 'Massachusetts',
  },
  {
    _id: 'Michigan',
    label: 'Michigan',
    value: 'Michigan',
  },
  {
    _id: 'Minnesota',
    label: 'Minnesota',
    value: 'Minnesota',
  },
  {
    _id: 'Mississippi',
    label: 'Mississippi',
    value: 'Mississippi',
  },
  {
    _id: 'Missouri',
    label: 'Missouri',
    value: 'Missouri',
  },
  {
    _id: 'Montana',
    label: 'Montana',
    value: 'Montana',
  },
  {
    _id: 'Nebraska',
    label: 'Nebraska',
    value: 'Nebraska',
  },
  {
    _id: 'Nevada',
    label: 'Nevada',
    value: 'Nevada',
  },
  {
    _id: 'New Hampshire',
    label: 'New Hampshire',
    value: 'New Hampshire',
  },
  {
    _id: 'New Jersey',
    label: 'New Jersey',
    value: 'New Jersey',
  },
  {
    _id: 'New Mexico',
    label: 'New Mexico',
    value: 'New Mexico',
  },
  {
    _id: 'New York',
    label: 'New York',
    value: 'New York',
  },
  {
    _id: 'North Carolina',
    label: 'North Carolina',
    value: 'North Carolina',
  },
  {
    _id: 'North Dakota',
    label: 'North Dakota',
    value: 'North Dakota',
  },
  {
    _id: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
  },
  {
    _id: 'Ohio',
    label: 'Ohio',
    value: 'Ohio',
  },
  {
    _id: 'Oklahoma',
    label: 'Oklahoma',
    value: 'Oklahoma',
  },
  {
    _id: 'Oregon',
    label: 'Oregon',
    value: 'Oregon',
  },
  {
    _id: 'Palau',
    label: 'Palau',
    value: 'Palau',
  },
  {
    _id: 'Pennsylvania',
    label: 'Pennsylvania',
    value: 'Pennsylvania',
  },
  {
    _id: 'Puerto Rico',
    label: 'Puerto Rico',
    value: 'Puerto Rico',
  },
  {
    _id: 'Rhode Island',
    label: 'Rhode Island',
    value: 'Rhode Island',
  },
  {
    _id: 'South Carolina',
    label: 'South Carolina',
    value: 'South Carolina',
  },
  {
    _id: 'South Dakota',
    label: 'South Dakota',
    value: 'South Dakota',
  },
  {
    _id: 'Tennessee',
    label: 'Tennessee',
    value: 'Tennessee',
  },
  {
    _id: 'Texas',
    label: 'Texas',
    value: 'Texas',
  },
  {
    _id: 'Utah',
    label: 'Utah',
    value: 'Utah',
  },
  {
    _id: 'Vermont',
    label: 'Vermont',
    value: 'Vermont',
  },
  {
    _id: 'Virgin Islands',
    label: 'Virgin Islands',
    value: 'Virgin Islands',
  },
  {
    _id: 'Virginia',
    label: 'Virginia',
    value: 'Virginia',
  },
  {
    _id: 'Washington',
    label: 'Washington',
    value: 'Washington',
  },
  {
    _id: 'West Virginia',
    label: 'West Virginia',
    value: 'West Virginia',
  },
  {
    _id: 'Wisconsin',
    label: 'Wisconsin',
    value: 'Wisconsin',
  },
  {
    _id: 'Wyoming',
    label: 'Wyoming',
    value: 'Wyoming',
  },
]

class State extends React.PureComponent<StateProps> {
  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)

    return (
      <DropdownInput
        className={classnames('block', className)}
        name={block.id}
        id={block.id}
        label={label}
        showLabel={!block.data.config.hideLabel}
        placeholder="(Select One)"
      >
        {options.map((answer) => (
          <option key={answer.value} value={answer.value}>
            {answer.label}
          </option>
        ))}
      </DropdownInput>
    )
  }
}

const StyledState = styled(State)`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'State'
export default class StateField extends Field {
  static type? = key
  static enableStatistics = true
  static enableConditionalLogic = true

  static template: Partial<StateField> = {
    data: {
      config: {
        label: 'State',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<StateField>) {
    super(params)
    Object.assign(this, StateField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledState
  }
}

BlockManager.registerBlockClass(StateField)
