import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import { FormGroup, Input as StrapInput } from 'reactstrap'
import {
  getFormGroupClasses,
  FormErrors,
  FormLabel,
} from '../../../../_shared/components/Form/helpers'
import Field, { FieldConfig, FieldData } from '../../Field'
import { FormContextProps } from '../../../../_shared/components/Form'
import withForm from '../../../../_shared/components/withForm'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import ClientResponse from '../../../../_shared/models/response/response.client'
import ClientPathway from '../../../../_shared/models/pathway/pathway.client'
import ClientProgramGroup from '../../../../_shared/models/programGroup/programGroup.client'
import { getBlockStyles } from '../../../getBlockStyles'
import { FieldResponse } from '../../../../_shared/models/response/response.types'
import { Answer } from '../../AnswersBlock'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import CampusField, { CampusFieldResponse } from '../Campus/Campus'
import BlockManager from '../../BlockManager'
import { Career } from '../../../../_shared/models/programGroup/programGroup.types'
import Block from '../../Block'
import Log from '../../../../_shared/log'
import CareerFieldMap from '../../../../_shared/models/programGroup/careerFields'

export interface ProgramInputProps extends Partial<FormContextProps> {
  answers?: ProgramAnswer[]
  name: string
  errors?: string[]
  value?: ProgramAnswer
  label?: string
  showLabel?: boolean
  waitingForCampusSelection?: boolean
  useOptGroups?: boolean
}

const ProgramOptions = (props) => {
  if (props.useOptGroups) {
    return Array.from(props.careerFields.keys() as string[]).map((careerField) => (
      <optgroup key={careerField} label={careerField}>
        {props.careerFields.get(careerField).map((answer) => (
          <option key={answer.value} value={answer.value}>
            {answer.label}
          </option>
        ))}
      </optgroup>
    ))
  } else {
    if (props._answers) {
      return props._answers.map((answer) => (
        <option key={answer.value} value={answer.value}>
          {answer.label}
        </option>
      ))
    }
  }
}

class ProgramInput extends React.PureComponent<ProgramInputProps> {
  componentDidUpdate() {
    document.dispatchEvent(
      new CustomEvent('updated', {
        detail: this.props.name,
      })
    )
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { answers, setValue } = this.props
    const value = event.target.value

    if (value === '') {
      setValue(this.props, undefined)
    }

    if (Array.isArray(answers)) {
      const answer = answers.find((a) => a.value === value)
      if (answer) {
        setValue(this.props, answer)
      }
    }
  }

  render() {
    const {
      answers,
      name,
      errors,
      value,
      label,
      showLabel,
      waitingForCampusSelection,
      useOptGroups,
      programGroup,
    } = this.props
    const hasErrors = errors?.length > 0
    let careerFields = new Map<string, ProgramAnswer[]>()

    let _answers = answers // eslint-disable-line
    if (waitingForCampusSelection) {
      _answers = []
    }

    // Set up OptGroups if needed
    if (useOptGroups) {
      const completeCareerFieldMap = new Map<string, string>()
      Object.keys(CareerFieldMap).map((careerFieldId) => {
        completeCareerFieldMap.set(careerFieldId, CareerFieldMap[careerFieldId])
      })
      if (programGroup?.company?.careerFields) {
        programGroup.company.careerFields.forEach((careerField) => {
          completeCareerFieldMap.set(careerField._id, careerField.name)
        })
      }

      _answers.forEach((answer) => {
        if (completeCareerFieldMap.has(answer.careerField)) {
          if (careerFields.has(completeCareerFieldMap.get(answer.careerField))) {
            careerFields.get(completeCareerFieldMap.get(answer.careerField)).push(answer)
          } else {
            careerFields.set(completeCareerFieldMap.get(answer.careerField), [answer])
          }
        } else {
          if (careerFields.has('Other')) {
            careerFields.get('Other').push(answer)
          } else {
            careerFields.set('Other', [answer])
          }
        }
      })

      // Sort the career fields
      careerFields = new Map(
        [...careerFields.entries()].sort((a, b) => {
          // Move "Other" to the end
          if (a[0] === 'Other') return b[0] === 'Other' ? 0 : 1
          if (b[0] === 'Other') return -1
          return a[0].localeCompare(b[0])
        })
      )
    }

    return (
      <FormGroup className={getFormGroupClasses(errors)}>
        <FormLabel showLabel={showLabel} id={name} name={name} label={label} />
        <StrapInput
          type="select"
          name={name}
          id={name}
          invalid={hasErrors}
          onChange={this.onChange}
          value={value == null ? '' : value.value}
        >
          <option value="">
            {waitingForCampusSelection ? '(Select a campus first)' : '(Select One)'}
          </option>
          <ProgramOptions
            _answers={_answers}
            useOptGroups={useOptGroups}
            careerFields={careerFields}
          />
        </StrapInput>
        <FormErrors errors={errors} />
      </FormGroup>
    )
  }
}

const ProgramInputWithForm = withForm(ProgramInput)

export interface DetailedOutputProps {
  field: ProgramFieldResponse
  label?: string
  className?: string
  hideLabels?: boolean
}
const DetailedOutput = ({ label, field, className, hideLabels }: DetailedOutputProps) => {
  let Careers = null
  if (field?.data?.careers?.length > 0) {
    if (field.data.careers.length === 1) {
      Careers = (
        <div className="detailed-summary-careers">
          The career associated with this program is:{' '}
          <a href={field.data.careers[0].disclosureUrl} target="_blank" rel="noopener noreferrer">
            {field.data.careers[0].name}
          </a>
          .
        </div>
      )
    } else {
      Careers = (
        <div className="detailed-summary-careers">
          The careers associated with this program are:
          <ul>
            {field.data.careers.map((career, idx) => (
              <li key={idx}>
                {career.disclosureUrl ? (
                  <a href={career.disclosureUrl} target="_blank" rel="noopener noreferrer">
                    {career.name}
                  </a>
                ) : (
                  <span>{career.name}</span>
                )}
              </li>
            ))}
          </ul>
        </div>
      )
    }
  }

  return (
    <div className={className}>
      <div>
        {!hideLabels && <span className="detailed-summary-label">{label || field?.label}: </span>}
        <span className="detailed-summary-answer"> {field?.value}</span>
        {field?.data?.feedback && (
          <div
            className="detailed-summary-answer-feedback"
            dangerouslySetInnerHTML={{
              __html: field.data.feedback, // eslint-disable-line
            }}
          />
        )}
      </div>
      <div
        className="detailed-summary-program-description"
        dangerouslySetInnerHTML={{
          __html: field.data.description, // eslint-disable-line
        }}
      />
      {Careers}
    </div>
  )
}

const StyledDetailedOutput = styled(DetailedOutput)`
  min-height: 1rem;
  .detailed-summary-label {
    font-weight: bold;
  }
  .detailed-summary-answer-feedback {
    margin: 1rem 0;
  }
  .detailed-summary-answer {
    margin: 0 0 1rem 0;
  }
  .detailed-summary-program-description,
  .detailed-summary-careers {
    margin: 1rem 0 0 0;
  }
`

export interface ProgramProps {
  block: ProgramField
  section: SectionBlock
  row: RowBlock
  className?: string
  response?: ClientResponse
  context?: FrontendContext
}

class Program extends React.PureComponent<ProgramProps> {
  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)
    const answers = block.getOptions(context)

    const hideLabel = block.data.config?.hideLabel

    return (
      <div className={'block ' + className}>
        <ProgramInputWithForm
          name={block.id}
          showLabel={!hideLabel}
          answers={answers}
          label={label}
          waitingForCampusSelection={block.waitingForCampusSelection(context)}
          useOptGroups={block.data.config?.useOptGroups}
        />
      </div>
    )
  }
}

const StyledProgram = styled(Program)`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

export interface ProgramAnswer extends Answer {
  careerField?: any
  description?: string
  careers?: Career[]
}

export interface ProgramFieldConfig extends FieldConfig {
  answers?: Answer[]
  includeUndecided?: boolean
  useOptGroups?: boolean
}

export interface ProgramFieldData extends FieldData {
  config?: ProgramFieldConfig
}

export interface ProgramFieldResponse extends FieldResponse {
  data: ProgramAnswer
}

const key = 'Program'
export default class ProgramField extends Field {
  static detailedOutput = StyledDetailedOutput
  declare data?: ProgramFieldData

  static type? = key
  static enableStatistics = true
  static enableConditionalLogic = true
  static template: Partial<ProgramField> = {
    data: {
      config: {
        label: 'Which program are you interested in?',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
      advanced: {
        profile: {
          group: 'prospects',
          key: 'program',
          label: 'Program',
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<ProgramField>) {
    super(params)
    Object.assign(this, ProgramField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledProgram
  }

  static getConditionalLogicValue(value: ProgramFieldResponse): string {
    return value?.data.value
  }

  getFakeValue(context?: FrontendContext): ProgramFieldResponse {
    const options = this.getOptions(context)
    if (!options || options.length === 0) {
      return
    }
    return {
      _id: 'fakevalue',
      label: this.getLabel(context),
      type: (this.constructor as any).type,
      value: options[0].label,
      data: options[0],
    }
  }

  transformPostValue(value: string, context: FrontendContext): ProgramAnswer {
    if (typeof value !== 'string') {
      Log.warn('TransformPostValue failed validation', {
        block: this,
        value: String(value),
      })
      return
    }

    let selectedProgram: ProgramAnswer
    const answers = this.data.config?.answers
    if (answers) {
      selectedProgram = answers?.find((a) => a.label === value || a.value === value)
    } else {
      const program = context?.programGroup?.programs?.find(
        (a) => a.name === value || a._id === value
      )
      if (program) {
        selectedProgram = {
          label: program.name,
          value: program._id,
        }
      }
    }

    if (!selectedProgram) {
      return
    }

    return selectedProgram
  }

  getFormSchema(context?: FrontendContext): Record<string, any> {
    return {
      schema: {
        title: this.getLabel(context),
        type: 'object',
        properties: {
          label: {
            type: 'string',
          },
          value: {
            type: 'string',
          },
        },
        required: ['label', 'value'],
      },
      required: this.data.config?.required,
    }
  }

  formToDoc(value: ProgramAnswer, context?: FrontendContext): ProgramFieldResponse {
    let selectedProgram: ProgramAnswer
    const answers = this.data.config?.answers

    if (value.value === 'undecided') {
      selectedProgram = value
    } else if (answers) {
      selectedProgram = answers?.find((a) => a.value === value.value)
    } else {
      const program = context?.programGroup?.programs?.find((a) => a._id === value.value)
      if (program) {
        selectedProgram = {
          label: program.name,
          value: program._id,
          description: program.description,
          careers: program.careers,
        }
      }
    }

    if (!selectedProgram) {
      return
    }

    return {
      _id: this.id,
      name: this.data.advanced?.name,
      label: this.getLabel(context),
      value: this.getValue(value),
      type: (this.constructor as any).type,
      data: selectedProgram,
    }
  }

  docToForm(doc: ProgramFieldResponse): ProgramAnswer {
    return doc.data
  }

  waitingForCampusSelection({ pathway, response }: FrontendContext): boolean {
    // Find either a program field or this field, whichever comes first
    let campusField: Block
    const searchField = pathway.findBlock(
      (block) => block instanceof CampusField || block.id === this.id
    )
    if (searchField && searchField instanceof CampusField) {
      campusField = searchField
    }

    if (campusField && campusField.isVisible(response, pathway)) {
      if (response) {
        return !response.getField(campusField.id)
      } else {
        return true
      }
    }

    return false
  }

  getOptions({ pathway, response, programGroup }: FrontendContext): ProgramAnswer[] {
    let answers = []

    // Use answers as an override if set on this field
    const answersOverride = this.data.config.answers
    if (answersOverride) {
      answers = answersOverride
    } else {
      if (!programGroup) {
        console.warn('No programGroup provided to program field, no options shown')
        return []
      }

      // For SSR, render all options for users with no JS
      // if (Meteor.isServer) {
      //   answers = programGroup.programs?.map((p) => ({
      //     label: p.name,
      //     value: p._id,
      //     description: p.description,
      //     careers: p.careers,
      //   }))
      //   console.log('ssr use all programs')
      // } else {
      // Find either a campus field or this field, whichever comes first
      let campusField
      const searchField = pathway.findBlock((block) => {
        return block instanceof CampusField || block.id === this.id
      })
      if (searchField && searchField instanceof CampusField) {
        campusField = searchField
      }

      // If a Campus field appears before this field, we need to wait for a selection
      // to be made before we can show options
      if (response && campusField) {
        const selectedCampus = response.getField(campusField.id) as CampusFieldResponse
        if (selectedCampus) {
          const selectedProgramValue = selectedCampus.data.value

          answers = (programGroup.programs || [])
            .filter((program) =>
              program.campuses ? program.campuses?.includes(selectedProgramValue) : true
            )
            .map((p) => ({
              label: p.name,
              value: p._id,
              description: p.description,
              careers: p.careers,
              careerField: p.careerField,
            }))
        }
      } else {
        // Otherwise, return all programs defined on the company
        if (programGroup.programs) {
          answers = programGroup.programs.map((program) => ({
            label: program.name,
            value: program._id,
            description: program.description,
            careers: program.careers,
            careerField: program.careerField,
          }))
        }
      }
    }
    // }

    if (!answers) {
      answers = []
    }

    answers = answers.sort((a, b) => {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    })

    if (this.data.config?.includeUndecided) {
      answers.push({ label: 'Undecided', value: 'undecided' })
    }

    return answers
  }

  getValue(answer: ProgramAnswer): string {
    return answer?.label
  }
}

BlockManager.registerBlockClass(ProgramField)
