import React from 'react'
import Dropdown from '../../../_shared/components/Form/Dropdown'
import BlockManager from '../BlockManager'
import RowBlock from './Row'

const Form = (props) => (
  <div className="section-content">
    <Dropdown name="config.layout">
      <option value="one">One Column</option>
      <option value="two">Two Equal Columns</option>
      <option value="three">Three Equal Columns</option>
      <option value="four">Four Equal Columns</option>
      <option value="six">Six Equal Columns</option>
    </Dropdown>
  </div>
)

class RowBlockEditor extends RowBlock {
  static editor = {
    label: 'Row',
    settingsForm: Form,
    onEditorUpdate(currentBlock, newData, newContent) {
      // If the layout is changing
      if (newData.config?.layout !== currentBlock.data.config.layout) {
        // Remove orphaned regions
        newContent = Array.from(currentBlock.content)

        let orphaned
        let targetRegion
        switch (newData.config?.layout) {
          case 'one':
            orphaned = newContent.splice(1, newContent.length - 1)
            targetRegion = 0
            break
          case 'two':
            orphaned = newContent.splice(2, newContent.length - 2)
            targetRegion = 1
            break
          case 'three':
            orphaned = newContent.splice(3, newContent.length - 3)
            targetRegion = 2
            break
          case 'four':
            orphaned = newContent.splice(5, newContent.length - 5)
            targetRegion = 3
            break
        }

        orphaned?.forEach((region) => {
          if (Array.isArray(region)) {
            if (!newContent[targetRegion]) {
              newContent[targetRegion] = []
            }

            newContent[targetRegion] = [...newContent[targetRegion], ...region]
          }
        })
      }

      return { newData, newContent }
    },
    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        layout: {
          type: 'string',
          enum: ['one', 'two', 'three', 'four', 'six'],
        },
        column0: {
          type: 'object',
        },
        column1: {
          type: 'object',
        },
        column2: {
          type: 'object',
        },
        column3: {
          type: 'object',
        },
        column4: {
          type: 'object',
        },
        column5: {
          type: 'object',
        },
      })
      schema.required.push('layout')
    },
  }
}

BlockManager.registerBlockClass(RowBlockEditor)
