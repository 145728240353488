import { FrontendContext } from '../../StepSubmission'
import Block from '../../../pagebuilder/blocks/Block'
import Field from '../../../pagebuilder/blocks/Field'
import ClientResponse from '../response/response.client'
import SectionBlock from '../../../pagebuilder/blocks/section/Section'
import RowBlock from '../../../pagebuilder/blocks/row/Row'
import { FieldResponse, ResponseMetadata } from '../response/response.types'
import { PersonDataCategories } from '../person/person.client'
import { instantiateBlocks } from '../../../pagebuilder/components/utilities'
import ClientPathway from '../pathway/pathway.client'

type ForEachBlockContext = {
  section?: Block
  sectionIndex?: number
  row?: Block
  rowIndex?: number
  region?: Block[]
  regionIndex?: number
  block?: Block
  blockIndex?: number
  parentBlock?: Block
}

export interface StepProfileValue {
  key: string
  label: string
  value: FieldResponse['value']
}

export type StepProfile = {
  [K in keyof typeof PersonDataCategories]?: Record<string, StepProfileValue>
}

export default class Step {
  constructor(props: NonFunctionProperties<Step>, pathway?: ClientPathway) {
    Object.assign(this, props)
    if (this.content) {
      this.content = instantiateBlocks(this.content)
    }
    // todo: stop using "new Step" and convert to pathway.CreateStep() method
    if (pathway) {
      this.getPathway = () => pathway
    }
  }
  _id: string
  name: string
  declare pageTitle?: string
  content: SectionBlock[]
  order: number
  declare getPathway: () => ClientPathway

  forEachBlock(callback: (block: Block, context: ForEachBlockContext) => void): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.content?.forEach((section, sectionIndex) => {
          callback(section, { sectionIndex, parentBlock: section })
          section.content?.forEach((row, rowIndex) => {
            callback(row, { sectionIndex, rowIndex, parentBlock: row })
            row.content?.forEach((region, regionIndex) => {
              if (region) {
                region.forEach((block, blockIndex) => {
                  callback(block, {
                    sectionIndex,
                    rowIndex,
                    regionIndex,
                    blockIndex,
                    parentBlock: row,
                  })
                })
              }
            })
          })
        })
        resolve(null)
      } catch (e) {
        reject(e)
      }
    })
  }

  findBlock(func: (block: Block) => boolean): Block {
    let block

    if (!this.content) {
      return
    }

    this.content.find((section) => {
      if (func(section)) {
        block = section
        return true
      }

      return !!section.content?.find((row) => {
        if (func(row)) {
          block = row
          return true
        }

        return !!row.content?.find((region) => {
          return !!region?.find((b) => {
            if (func(b)) {
              block = b
              return true
            }
          })
        })
      })
    })

    return block
  }

  findParentBlock(func: (block: Block) => boolean): Block {
    let parentBlock

    if (!this.content) {
      return
    }

    this.content.find((section) => {
      if (func(section)) {
        parentBlock = null
        return true
      }

      return !!section.content?.find((row) => {
        if (func(row)) {
          parentBlock = section
          return true
        }

        return !!row.content?.find((region) => {
          return !!region?.find((b) => {
            if (func(b)) {
              parentBlock = row
              return true
            }
          })
        })
      })
    })

    return parentBlock
  }

  getSchema(context: FrontendContext): Record<string, any> {
    const schema = {
      type: 'object',
      properties: {
        _isTest: {
          type: 'string',
        },
        isTest: {
          type: 'string',
        },
        _redirectUrl: {
          type: 'string',
        },
        rcToken: {
          type: 'string',
        },
      },
      additionalProperties: false,
      required: [],
    }

    ResponseMetadata.forEach((prop) => {
      schema.properties[prop] = {
        type: 'string',
      }
    })

    if (!this.content) {
      return schema
    }

    this.forEachBlock((block) => {
      if (block instanceof Field) {
        const blockSchema = block.getFormSchema(context)

        if (
          block.isRequired(context.response) &&
          block.isVisible(context.response, context.pathway)
        ) {
          schema.required.push(block.id)
        }

        schema.properties[block.id] = blockSchema.schema
      }
    })

    return schema
  }

  getBlock<T = Block>(idOrName: string): T {
    let block

    if (!this.content) {
      return
    }

    this.content.find((section: SectionBlock) => {
      if (section.id === idOrName) {
        block = section
        return true
      }

      return !!section.content?.find((row: RowBlock) => {
        if (row.id === idOrName) {
          block = row
          return true
        }

        return !!row.content?.find((region: Block[]) => {
          return !!region?.find((b) => {
            if (b.id === idOrName || b.data?.advanced?.name === idOrName) {
              block = b
              return true
            }
          })
        })
      })
    })

    return block
  }

  minScore(response: ClientResponse): number {
    let score = 0

    this.forEachBlock((block) => {
      if (block instanceof Field) {
        if (block.isRequired(response) && block.isVisible(response, this.getPathway())) {
          score += block.minScore(response)
        }
      }
    })

    return score
  }

  maxScore(response: ClientResponse, allFields = false): number {
    let score = 0

    this.forEachBlock((block) => {
      if (block instanceof Field) {
        if (allFields || block.isVisible(response, this.getPathway())) {
          score += block.maxScore(response)
        }
      }
    })

    return score
  }

  calculateScore(response: ClientResponse): number {
    let score = 0

    this.forEachBlock((block) => {
      if (block instanceof Field) {
        const responseField = response.getField(block.id)
        if (responseField && block.calculateScore && block.isVisible(response, this.getPathway())) {
          score += block.calculateScore(responseField)
        }
      }
    })

    return score
  }

  getProfileFields(fields: FieldResponse[]): StepProfile {
    const profileFields: StepProfile = {}

    this.forEachBlock((block) => {
      const profile = block.data.advanced?.profile

      if (profile?.group) {
        const { group, label, key } = profile

        // Try and find the field
        const field = fields.find((a) => a._id === block.id)
        if (field) {
          if (!profileFields[group]) {
            profileFields[group] = {}
          }
          profileFields[group][key] = {
            key,
            label,
            value: field.value,
          }
        }
      }
    })

    return profileFields
  }

  getBlocks(): Block[] {
    const blocks = []
    this.forEachBlock((block) => {
      blocks.push(block)
    })
    return blocks
  }
}
