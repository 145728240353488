import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import BlockToolbar from './BlockToolbar'
import { StyledAddButton } from './EditorSectionBlock'
import { PageRendererComponentContext } from '../PageRenderer'

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // styles we need to apply on draggables
  ...draggableStyle,
})

class EditorRowBlock extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    block: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.block !== nextProps.block) {
      return true
    }
    if (this.props.index !== nextProps.index) {
      return true
    }
    if (this.props.sectionIndex !== nextProps.sectionIndex) {
      return true
    }
    return false
  }

  render() {
    const { className, block, index, section, sectionIndex } = this.props

    const Component = block.getComponent()

    const locationQuery = {
      section: sectionIndex,
      row: index,
    }

    const blockProps = {
      onMouseOver: this.hoverOn,
      onMouseOut: this.hoverOff,
    }

    return (
      <PageRendererComponentContext.Consumer>
        {({ removeBlock, openBlockSettings, addRow, cloneBlock }) => (
          <Draggable draggableId={block.id} type="row" index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              >
                <div className={className}>
                  <BlockToolbar
                    block={block}
                    onHover={this.hoverToolbar}
                    onHoverOff={this.hoverOffToolbar}
                    section={section}
                    cloneBlock={() => cloneBlock(locationQuery)}
                    dragHandleProps={provided.dragHandleProps}
                    hide={this.hoverOffNow}
                    openBlockSettings={() => {
                      openBlockSettings(block, locationQuery)
                    }}
                    removeBlock={() => {
                      removeBlock(locationQuery)
                    }}
                  />
                  <Component
                    block={block}
                    blockProps={blockProps}
                    index={index}
                    section={section}
                    sectionIndex={sectionIndex}
                  />
                  <StyledAddButton
                    color={'var(--success)'}
                    onClick={() => {
                      addRow(locationQuery)
                    }}
                  />
                </div>
              </div>
            )}
          </Draggable>
        )}
      </PageRendererComponentContext.Consumer>
    )
  }
}

export default styled(EditorRowBlock)`
  position: relative;

  > .block,
  .col {
    transition: outline-color 0.3s ease-out;
    outline: 2px solid transparent;
    outline-offset: -1px;
  }

  > .block {
    ${(props) => {
      if (!props.block.isVisible()) {
        return 'opacity:0.5;'
      }
    }}
  }

  &:focus-within {
    > ${BlockToolbar} {
      display: none !important;
    }
  }

  .col {
    outline-style: dashed;
  }

  > ${BlockToolbar}:hover + .block,
  > ${BlockToolbar}:hover + .block .col {
    outline-color: rgba(40, 167, 69, 0.8);
  }

  &:hover > ${BlockToolbar}, &:hover > ${StyledAddButton} {
    animation-name: fadeIn;
  }

  > ${BlockToolbar} {
    background: var(--success);
  }
`
