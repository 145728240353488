import { createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import Pathway from '../../../_shared/models/pathway/pathway.backend.client'
import ClientProgramGroup from '../../../_shared/models/programGroup/programGroup.client'
import Response from '../../../_shared/models/response/response.backend.client'

export const EDITOR_UPDATE_RESPONSE = 'EDITOR_UPDATE_RESPONSE'
export const EDITOR_UPDATE_PATHWAY = 'EDITOR_UPDATE_PATHWAY'
export const EDITOR_UPDATE_PROGRAM_GROUP = 'EDITOR_UPDATE_PROGRAM_GROUP'

export interface EditorUpdateResponse {
  type: typeof EDITOR_UPDATE_RESPONSE
  payload: Record<string, any>
}

export interface EditorUpdatePathway {
  type: typeof EDITOR_UPDATE_PATHWAY
  payload: Record<string, any>
}

export interface EditorUpdateProgramGroup {
  type: typeof EDITOR_UPDATE_PROGRAM_GROUP
  payload: Record<string, any>
}

export function editorUpdateResponse(payload: Record<string, any>): EditorActionTypes {
  return {
    type: EDITOR_UPDATE_RESPONSE,
    payload,
  }
}

export function editorUpdatePathway(payload: Record<string, any>): EditorActionTypes {
  return {
    type: EDITOR_UPDATE_PATHWAY,
    payload,
  }
}
export function editorUpdateProgramGroup(payload: Record<string, any>): EditorActionTypes {
  return {
    type: EDITOR_UPDATE_PROGRAM_GROUP,
    payload,
  }
}

export type EditorActionTypes =
  | EditorUpdateResponse
  | EditorUpdatePathway
  | EditorUpdateProgramGroup

export interface EditorState {
  response: Response
  pathway?: Pathway
  programGroup?: ClientProgramGroup
}

const initialState: EditorState = {
  response: new Response({
    score: 87,
    createdAt: new Date(),
    _id: 'editorPreview',
    companyId: 'editorPreview',
    pathwayId: 'editorPreview',
    personId: 'editorPreview',
    userId: 'editorPreview',
  }),
}

function editorReducer(state = initialState, action: EditorActionTypes): EditorState {
  switch (action.type) {
    case EDITOR_UPDATE_RESPONSE:
      return {
        ...state,
        response: new Response({
          ...state.response,
          ...action.payload,
        }),
      }
    case EDITOR_UPDATE_PATHWAY:
      return {
        ...state,
        pathway: new Pathway({
          ...state.pathway,
          ...action.payload,
        }),
      }
    case EDITOR_UPDATE_PROGRAM_GROUP:
      return {
        ...state,
        programGroup: new ClientProgramGroup({
          ...state.programGroup,
          ...action.payload,
        }),
      }
    default:
      return state
  }
}

const store = createStore(editorReducer, composeWithDevTools())

export default store
