import React from 'react'
import { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar'
import YearField from './Year'
import Input from '../../../../_shared/components/Form/Input'
import BlockManager from '../../BlockManager'

const Form = (props) => (
  <div className="section-content">
    <Input name="config.startYear" label="Start Year" />
    <Input name="config.endYear" label="End Year" />
  </div>
)
class YearFieldEditor extends YearField {
  static editor = {
    label: 'Year',
    category: 'form',
    icon: faCalendar,
    settingsForm: Form,

    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        startYear: {
          type: 'number',
        },
        endYear: {
          type: 'number',
        },
      })
      schema.required.push('startYear')
      schema.required.push('endYear')
    },
  }
}
BlockManager.registerBlockClass(YearFieldEditor)
