import React, { ChangeEvent, ReactNode, MouseEvent } from 'react'
import { FormGroup } from 'reactstrap'
import classnames from 'classnames'
import RCSlider from 'rc-slider'
import styled from 'styled-components'
import { FormContextProps } from '../../../../_shared/components/Form'
import withForm from '../../../../_shared/components/withForm'
import {
  FormErrors,
  FormLabel,
  getFormGroupClasses,
  HelpText,
  ValidFeedback,
} from '../../../../_shared/components/Form/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngry } from '@fortawesome/pro-regular-svg-icons/faAngry'
import { faFrown } from '@fortawesome/pro-regular-svg-icons/faFrown'
import { faMeh } from '@fortawesome/pro-regular-svg-icons/faMeh'
import { faSmile } from '@fortawesome/pro-regular-svg-icons/faSmile'
import { faLaugh } from '@fortawesome/pro-regular-svg-icons/faLaugh'
import { Input as StrapInput } from 'reactstrap'

export type LikertValue = number

export interface LikertBaseProps extends Partial<FormContextProps> {
  showLabel?: boolean
  label?: string
  id?: string
  placeholder?: string
  help?: string | ReactNode
  showValid?: boolean
  errors?: string[]
  validFeedback?: string
  onChange?: (value: LikertValue) => void
  name: string
  value?: LikertValue
  className?: string
  defaultValue?: number
  children?: undefined
  showLabels: boolean
  label1: string
  label2: string
  label3: string
  label4: string
  label5: string
}

interface LikertBaseStateProps {
  usingDefaultValue: boolean
}

class LikertBase extends React.PureComponent<LikertBaseProps, LikertBaseStateProps> {
  static defaultProps = {
    errors: [],
    showValid: false,
    showLabel: true,
  }

  // constructor(props) {
  //   super(props)
  //   console.log(props)
  //   this.state = {
  //     usingDefaultValue: props.value || true,
  //   }
  // }

  // trySetDefaultValue() {
  //   const { value, defaultValue, setFieldValue, name } = this.props
  //   if (value) {
  //     return
  //   }

  //   if (defaultValue) {
  //     setFieldValue(name, defaultValue)
  //     this.setState({
  //       usingDefaultValue: true,
  //     })
  //   }
  // }

  // componentDidMount() {
  //   this.trySetDefaultValue()
  // }

  // componentDidUpdate(prevProps) {
  //   this.trySetDefaultValue()
  // }

  componentWillUnmount() {
    if (this.props.setValue) {
      this.props.setValue(this.props, undefined)
    }
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.setValue(this.props, event.target.value)
    this.doPulse(event.target)
  }

  doPulse(element: HTMLElement) {
    const parent = element.closest('.form-group')
    const li = element.closest('label').children[0]
    setTimeout(function () {
      parent.querySelectorAll('.pulse').forEach((elem) => {
        elem.classList.remove('pulse')
      })
      li.classList.add('pulse')
    }, 15)
  }

  onClick = (event: any) => {
    if (event.target.value == this.props.value) {
      this.doPulse(event.target)
    }
    this.setState({
      usingDefaultValue: false,
    })
  }

  render() {
    const {
      showLabel,
      className,
      name,
      label,
      formSchema,
      id,
      errors,
      showValid,
      help,
      validFeedback,
      value,
      showLabels,
      label1,
      label2,
      label3,
      label4,
      label5,
    } = this.props

    const hasErrors = errors.length > 0

    return (
      <FormGroup
        className={classnames(getFormGroupClasses(errors, showValid), className, {
          'answer-selected': !!value,
        })}
      >
        <FormLabel
          showLabel={showLabel}
          id={id || name}
          name={name}
          label={label}
          formSchema={formSchema}
        />

        <ul className="likert-scale">
          <li className={classnames('animated', { 'answer-selected': value == 1 })}>
            <label>
              <div className="likert-icon animated">
                <FontAwesomeIcon icon={faAngry} />
              </div>
              <div className="likert-label">
                <StrapInput
                  type="radio"
                  name={name}
                  onChange={this.onChange}
                  onClick={this.onClick}
                  id={name + '_1'}
                  value={1}
                  checked={value == 1}
                />
                {label1}
              </div>
            </label>
          </li>
          <li className={classnames('animated', { 'answer-selected': value == 2 })}>
            <label>
              <div className="likert-icon animated">
                <FontAwesomeIcon icon={faFrown} />
              </div>
              <div className="likert-label">
                <StrapInput
                  type="radio"
                  name={name}
                  onChange={this.onChange}
                  onClick={this.onClick}
                  id={name + '_2'}
                  value={2}
                  checked={value == 2}
                />
                {label2}
              </div>
            </label>
          </li>
          <li className={classnames('animated', { 'answer-selected': value == 3 })}>
            <label>
              <div className="likert-icon animated">
                <FontAwesomeIcon icon={faMeh} />
              </div>
              <div className="likert-label">
                <StrapInput
                  type="radio"
                  name={name}
                  onChange={this.onChange}
                  onClick={this.onClick}
                  id={name + '_3'}
                  value={3}
                  checked={value == 3}
                />
                {label3}
              </div>
            </label>
          </li>
          <li className={classnames('animated', { 'answer-selected': value == 4 })}>
            <label>
              <div className="likert-icon animated">
                <FontAwesomeIcon icon={faSmile} />
              </div>
              <div className="likert-label">
                <StrapInput
                  type="radio"
                  name={name}
                  onChange={this.onChange}
                  onClick={this.onClick}
                  id={name + '_4'}
                  value={4}
                  checked={value == 4}
                />
                {label4}
              </div>
            </label>
          </li>
          <li className={classnames('animated', { 'answer-selected': value == 5 })}>
            <label>
              <div className="likert-icon animated">
                <FontAwesomeIcon icon={faLaugh} />
              </div>
              <div className="likert-label">
                <StrapInput
                  type="radio"
                  name={name}
                  onChange={this.onChange}
                  onClick={this.onClick}
                  id={name + '_5'}
                  value={5}
                  checked={value == 5}
                />
                {label5}
              </div>
            </label>
          </li>
        </ul>

        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
        <HelpText help={help} />
        <noscript>
          <style>
            {`.likert-scale input[type='radio'] {
              display:inline-block;
            }`}
          </style>
        </noscript>
      </FormGroup>
    )
  }
}

export const Likert = styled(LikertBase)`
  @-webkit-keyframes pulse {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes pulse {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  .pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
  }
  .likert-scale {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      margin: 0;
      padding: 0;
      flex: 1 0 auto;
      text-align: center;
      width: 20%;

      &:nth-child(1) {
        color: #f31f1b;
      }
      &:nth-child(2) {
        color: #eb5513;
      }
      &:nth-child(3) {
        color: #ffb80c;
      }
      &:nth-child(4) {
        color: #7bca53;
      }
      &:nth-child(5) {
        color: #7bca53;
      }

      &.answer-selected {
        &:nth-child(1) {
          color: #f31f1b !important;
        }
        &:nth-child(2) {
          color: #eb5513 !important;
        }
        &:nth-child(3) {
          color: #ffb80c !important;
        }
        &:nth-child(4) {
          color: #7bca53 !important;
        }
        &:nth-child(5) {
          color: #7bca53 !important;
        }
      }
    }

    label {
      width: 100%;
      cursor: pointer;
    }

    input[type='radio'] {
      margin-right: 0.5rem;
      display: none;
    }
  }

  &.answer-selected .likert-scale > li {
    &:nth-child(1) {
      color: #ccc;
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      color: #ccc;
    }
  }

  .likert-icon {
    font-size: 3em;
  }

  .likert-label {
    font-size: 0.875em;
  }

  @media (max-width: 480px) {
    font-size: 4vw;

    li {
      &:nth-child(1) {
        .likert-label {
          text-align: left;
        }
      }
      &:nth-child(5) {
        .likert-label {
          text-align: right;
        }
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        .likert-label {
          display: none;
        }
      }
    }
  }

  ${(props) => {
    if (props.showLabels !== true) {
      return `
      .likert-label{
        display:none !important;
      }
      `
    }
  }}
`

export default withForm(Likert)
