import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnderline } from '@fortawesome/pro-solid-svg-icons/faUnderline'
import { isMod } from './utils'

export const hasUnderline = value =>
  value.marks.some(mark => mark.type === 'underline')

export const toggleUnderline = change => change.toggleMark('underline').focus()

export const UnderlineButton = ({ editor, className }) => (
  <button
    type="button"
    onClick={e => toggleUnderline(editor)}
    className={classnames(
      'slate-Underline-plugin--button',
      { active: hasUnderline(editor.value) },
      className
    )}
  >
    <FontAwesomeIcon icon={faUnderline} fixedWidth />
  </button>
)

export const UnderlinePlugin = options => ({
  onKeyDown(event, editor, next) {
    if (isMod(event) && event.key === 'u') {
      event.preventDefault()
      return toggleUnderline(editor)
    }
    next()
  },
})

export const UnderlineMark = ({ children }) => (
  <span style={{ textDecoration: 'underline' }}>{children}</span>
)
