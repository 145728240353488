import toPath from 'lodash/toPath'
import get from 'lodash/get'
import ClientResponse from '../../_shared/models/response/response.client'
import { BlockAppearanceConfig } from '../types'
import ConditionalLogic, { ConditionalLogicConfig } from './ConditionalLogic'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import ClientPathway from '../../_shared/models/pathway/pathway.client'

export interface SavedBlock extends NonFunctionProperties<Block> {
  type: string
}

export interface BlockEditorConfig {
  label: string
  icon?: IconDefinition
  category?: string
  settingsForm?: any
  getSettingsSchema?: (schema: any) => void
  onEditorUpdate?: (
    currentBlock: any,
    newData: any,
    newContent: any
  ) => { newData: any; newContent: any }
}

export interface BlockProps {
  id?: string
  type?: string
  data?: Record<string, any>
  content?: Block[]
  editor?: BlockEditorConfig
}

export interface BlockData {
  config?: Record<string, any>
  content?: Record<string, any>
  appearance?: BlockAppearanceConfig
  visibility?: {
    show?: 'conditional-show' | 'conditional-hide' | 'show' | 'hide'
    conditional?: ConditionalLogicConfig
  }
  advanced?: {
    name?: string
    cssId?: string
    cssClass?: string
    customCss?: string
    profile?: {
      group: string
      label: string
      key: string
    }
    linkedType?: 'config' | 'all'
    linkedKey?: string
    trimAnswers?: boolean
  }
}

// structureblock
export default class Block {
  declare id: string
  declare static type?: string
  declare data?: BlockData
  declare content?: any
  declare static editor?: BlockEditorConfig
  declare static usePrimarySettingsFormOnly?: boolean
  declare enableStatistics?: boolean
  declare static template?: BlockData

  constructor(params?: NonFunctionProperties<Block>) {
    this.id = params.id
    this.data = params.data
  }

  getComponent(): React.ElementType {
    return null
  }

  getData(path: string, defaultValue?: unknown): unknown {
    console.warn('Deprecated call to getData')
    return get(this.data, toPath(path), defaultValue)
  }

  isVisible(response?: ClientResponse, pathway?: ClientPathway): boolean {
    let isVisible

    // todo: where does visibility come from?
    const visibility = this.data?.visibility
    switch (visibility?.show) {
      case 'conditional-show':
        if (!response || !visibility.conditional) {
          return false
        }
        isVisible = ConditionalLogic.evaluate(visibility.conditional, response)
        break
      case 'conditional-hide':
        if (!response || !visibility.conditional) {
          return true
        }
        isVisible = !ConditionalLogic.evaluate(visibility.conditional, response)
        break
      case 'hide':
        isVisible = false
        break
      case 'show':
      default:
        isVisible = true
    }

    // check to see if the block's parent row is visible
    if (pathway) {
      const parent = pathway.findParentBlock((b) => b.id === this.id)
      if (parent) {
        if (!parent.isVisible(response, pathway)) {
          return false
        }
      }
    }

    return isVisible
  }

  isConditional(): boolean {
    const visibility = this.data.visibility?.show
    switch (visibility) {
      case 'conditional-hide':
      case 'conditional-show':
        return true
    }
  }
  isRequired(): boolean {
    return this.data?.config?.required
  }
  isLinked(): boolean {
    return !!this.data.advanced?.linkedKey
  }

  toJSON(): Record<string, any> {
    const { id, data, content } = this

    return {
      id,
      type: (this.constructor as any).type,
      data,
      content,
    }
  }

  getSettingsForms(): any[] {
    const forms = []
    if (this.constructor['usePrimarySettingsFormOnly']) {
      forms.push(this.constructor['editor'].settingsForm)
      return forms
    } else {
      if (this.constructor['editor']?.settingsForm) {
        forms.push(this.constructor['editor'].settingsForm)
      }

      let proto = Object.getPrototypeOf(this)
      while (proto !== null) {
        if (proto.hasOwnProperty('editor') && proto?.editor?.settingsForm) {
          forms.push(proto.editor.settingsForm)
        }
        proto = Object.getPrototypeOf(proto)
      }
      return forms.reverse()
    }
  }

  getCompleteSettingsSchema(): Record<string, any> {
    const schema = {
      type: 'object',
      properties: {},
      required: [],
      additionalProperties: false,
    }

    if (this.constructor['editor']?.getSettingsSchema) {
      this.constructor['editor'].getSettingsSchema(schema)
    }

    let proto = Object.getPrototypeOf(this)
    while (proto !== null) {
      if (proto.getSettingsSchema) {
        proto.getSettingsSchema(schema)
      }
      proto = Object.getPrototypeOf(proto)
    }

    return schema
  }
}
