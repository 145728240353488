import { faLandmark } from '@fortawesome/pro-solid-svg-icons/faLandmark'
import BlockManager from '../../BlockManager'
import CampusField from './Campus'

class CampusFieldEditor extends CampusField {
  static editor = {
    label: 'Campus',
    category: 'profile',
    icon: faLandmark,
  }
}

BlockManager.registerBlockClass(CampusFieldEditor)
