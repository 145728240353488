import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { PageRendererComponentContext } from '../PageRenderer'

export default class Canvas extends React.Component {
  static propTypes = {
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    components: PropTypes.object,
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.content !== this.props.content ||
      nextProps.className !== this.props.className ||
      nextProps.components !== this.props.components
    )
  }
  render() {
    const { content, components, className, functions } = this.props

    const { Region, Section } = components

    return (
      <div className={classnames('content', className)}>
        <PageRendererComponentContext.Provider value={{ ...components, ...functions }}>
          <Region id="canvas" type="section">
            {content &&
              content.map((block, index) => <Section key={block.id} block={block} index={index} />)}
          </Region>
        </PageRendererComponentContext.Provider>
      </div>
    )
  }
}
