import React from 'react'
import styled from 'styled-components'
import Block, { BlockData } from '../../Block'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import ClientResponse from '../../../../_shared/models/response/response.client'
import ClientPathway from '../../../../_shared/models/pathway/pathway.client'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { substituteVariables } from '../../blockUtilities'
import { FrontendContext } from '../../../../_shared/StepSubmission'

export interface HTMLProps extends RouteComponentProps {
  block: HTMLBlock
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
  isVisible?: boolean
}

class HTML extends React.Component<HTMLProps> {
  containerRef: React.RefObject<HTMLDivElement>
  constructor(props: HTMLProps) {
    super(props)
    this.containerRef = React.createRef()
  }

  shouldComponentUpdate(prevProps) {
    // must always update if they have conditional logic and their visibility is changing
    return (
      prevProps.block !== this.props.block ||
      prevProps.isVisible !== this.props.isVisible ||
      prevProps.block.data !== this.props.block.data ||
      prevProps.block.data?.config?.content !== this.props.block.data?.config?.content ||
      prevProps.block.id !== this.props.block.id
    )
  }

  // Render the editor.
  render() {
    const { block, className, context } = this.props

    let content = block.data.config?.content
    content = substituteVariables(content, context)

    // Do not allow script tag output period
    content = content?.replace(/<script[^<]*<\/script>/gi, '')

    return (
      <div
        key={Math.random()}
        ref={this.containerRef}
        className={className + ' block block-content'}
        dangerouslySetInnerHTML={{
          __html: content, // eslint-disable-line
        }}
      />
    )
  }
}

const StyledHTML = styled(withRouter(HTML))`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'HTML'

export interface HTMLBlockConfig {
  content?: string
}
export interface HTMLBlockData extends BlockData {
  config?: HTMLBlockConfig
}
export default class HTMLBlock extends Block {
  static type? = key
  declare data?: HTMLBlockData
  static template: Partial<HTMLBlock> = {
    data: {
      config: {},
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<HTMLBlock>) {
    super(params)
    Object.assign(this, HTMLBlock.template, params)
  }

  getComponent(): React.ElementType {
    return StyledHTML
  }
}
BlockManager.registerBlockClass(HTMLBlock)
