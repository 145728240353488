import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrows } from '@fortawesome/pro-solid-svg-icons/faArrows'
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog'
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { faCloudUpload } from '@fortawesome/pro-solid-svg-icons/faCloudUpload'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt'
import Field from '../blocks/Field'

const zIndexStart = 2147482000
const zIndexMax = 2147482100

class BlockToolbar extends React.PureComponent {
  ref = React.createRef()

  state = {
    style: null,
    confirmDelete: false,
  }

  static propTypes = {
    isVisible: PropTypes.bool,
    className: PropTypes.string,
    removeBlock: PropTypes.func,
    block: PropTypes.object.isRequired,
    section: PropTypes.object,
    row: PropTypes.object,
    onHover: PropTypes.func,
    onHoverOff: PropTypes.func,
    hide: PropTypes.func,
    openBlockSettings: PropTypes.func,
    dragHandleProps: PropTypes.object,
  }

  centerOnBlock() {
    const { block } = this.props
    const toolbar = this.ref.current
    const blockNode = toolbar.nextSibling
    switch (block.type) {
      case 'Row':
        this.setState({
          style: {
            top: blockNode.offsetTop,
            left: blockNode.offsetLeft,
          },
        })
        break
      case 'Section':
        return
      default:
        const toolbarRect = toolbar.getBoundingClientRect()
        const style = {}

        style.marginLeft = (toolbarRect.width / 2) * -1 + 'px'
        style.marginTop = (toolbarRect.height / 2) * -1 + 'px'
        if (this._isMounted) {
          this.setState({
            style,
          })
        }
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.block !== this.props.block) {
      this.centerOnBlock()
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.centerOnBlock()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onHover = () => {
    this.setState({
      style: Object.assign({}, this.state.style, {
        zIndex: zIndexMax,
      }),
    })
  }

  offHover = () => {
    this.setState({
      style: Object.assign({}, this.state.style, {
        zIndex: zIndexStart,
      }),
    })
  }

  confirmRemove = () => {
    this.setState({
      confirmDelete: true,
    })
  }

  render() {
    const {
      removeBlock,
      className,
      dragHandleProps,
      openBlockSettings,
      saveBlockTemplate,
      block,
      cloneBlock,
    } = this.props

    return (
      <div
        ref={this.ref}
        onMouseEnter={this.onHover}
        onMouseLeave={this.offHover}
        className={'block-toolbar ' + className}
        style={this.state.style}
      >
        <div className="label">{block.constructor.editor?.label}</div>
        <div className="buttons d-flex align-items-stretch flex-nowrap">
          <div className="button" style={{ cursor: 'grab' }} {...dragHandleProps}>
            <FontAwesomeIcon icon={faArrows} fixedWidth />
          </div>
          <div className="button" onClick={openBlockSettings}>
            <FontAwesomeIcon icon={faCog} fixedWidth />
          </div>
          {saveBlockTemplate && (
            <div className="button" onClick={saveBlockTemplate}>
              <FontAwesomeIcon icon={faCloudUpload} fixedWidth />
            </div>
          )}
          <div className="button" onClick={cloneBlock}>
            <FontAwesomeIcon icon={faCopy} fixedWidth />
          </div>
          {!this.state.confirmDelete && (
            <div className="button" onClick={this.confirmRemove}>
              <FontAwesomeIcon icon={faTrashAlt} fixedWidth />
            </div>
          )}
          {this.state.confirmDelete && (
            <div className="button" onClick={removeBlock}>
              <FontAwesomeIcon icon={faTrashAlt} fixedWidth /> Delete?
            </div>
          )}
        </div>
        {(block.isConditional() && <div className="label label-conditional">conditional</div>) ||
          (!block.isVisible() && <div className="label label-hidden">hidden</div>) 
          || (block instanceof Field && block.isRequired() && <div className="label label-required">required</div>)
          || (block instanceof Field && !block.isRequired() && <div className="label label-optional">optional</div>)
          }
        {block.isLinked() && <div className="label label-linked">linked</div>}
      </div>
    )
  }
}

export default styled(BlockToolbar)`
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  border-radius: 3px;
  z-index: 2147483000;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fadeOut;
  overflow: hidden;

  .label {
    background: rgba(0, 0, 0, 0.2);
    display: block;
    text-align: center;
    padding: 0.1rem 0;
    white-space: nowrap;
    font-size: 10px;
  }

  .label-conditional {
    background: #FF9133;
  }
  .label-required {
    background: #950000;
  }
  .label-optional {
    background: #208A38;
  }
  .label-hidden {
    background: #5f6369;
  }
  .label-linked {
    background: #0070C5;
  }

  .buttons {
    font-size: 0.875rem;
  }

  .button {
    cursor: pointer;
    padding: 0.4rem 0.5rem;
    transition: background-color 0.2s ease-out;
    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .dropdown-menu {
    font-size: 0.875rem;
    z-index: 900;
  }
`
