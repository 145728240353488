import React from 'react'
import PropTypes from 'prop-types'
import SettingsSection from '../../settings/SettingsSection'
import ConditionalOptions from '../Conditional/ConditionalOptions'
import Cardbox, { CardboxItem } from '../../../_shared/components/Form/Cardbox'
import withForm from '../../../_shared/components/withForm'

export const RequiredSchema = {
  required: {
    type: 'object',
    properties: {
      type: {
        type: ['string', 'boolean'],
      },
      conditional: {
        type: 'object',
      },
    },
    additionalProperties: false,
  },
}

const Required = (props) => (
  <SettingsSection title="Answer Requirement" startOpen={props.startOpen}>
    <Cardbox
      name={`${props.name}.type`}
      thin
      vertical
      showLabel={false}
      defaultValue={false}
      selectionRequired
    >
      <CardboxItem value={false} title="Optional" />
      <CardboxItem value={true} title="Required" />
      <CardboxItem value="conditional" title="Conditionally required" />
    </Cardbox>

    {String(props.value?.type).startsWith('conditional') && (
      <ConditionalOptions name={`${props.name}.conditional`} />
    )}
  </SettingsSection>
)

Required.propTypes = {
  formData: PropTypes.object,
  options: PropTypes.array,
  path: PropTypes.string,
  name: PropTypes.string,
  startOpen: PropTypes.bool,
}

export default withForm(Required, true)
