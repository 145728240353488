import React from 'react'
import { ReactFrontendContext } from '../../frontend/client/containers/StepViewContainer'
import Dropdown from '../../_shared/components/Form/Dropdown'
import SettingsSection from './SettingsSection'

export const ConfirmValueSchema = {
  confirmFieldValue: {
    type: 'string',
  },
}

export const SetConfirmValue = (props) => {
  const { block, context, formContext } = props
  if (!context.response) {
    return
  }
  const confirmValue = block.data.config.confirmFieldValue
  if (confirmValue) {
    const targetValue = context.response.getField(confirmValue)
    if (targetValue !== undefined) {
      formContext.setFieldValue(block.id, targetValue.value)
    }
  }
}

const FieldOptions = ({ pathway, type }) => {
  const steps = []
  pathway.getSteps().forEach((step) => {
    const options = []
    step.forEachBlock((block) => {
      if (block.constructor.editor.canConfirmValue && block.constructor.type === type) {
        options.push({
          label: block.data.config.label,
          value: block.id,
        })
      }
    })
    steps.push({
      _id: step._id,
      name: step.name,
      options,
    })
  })

  return (
    <Dropdown
      name="config.confirmFieldValue"
      label="Field"
      help="Which field do you want to confirm?"
      placeholder="(Select One)"
    >
      {steps.map((step) => (
        <optgroup key={step._id} label={step.name}>
          {step.options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </optgroup>
      ))}
    </Dropdown>
  )
}

const ConfirmValue = ({ fieldKey }) => (
  <SettingsSection title="Confirm Value">
    <ReactFrontendContext.Consumer>
      {({ pathway }) => <FieldOptions type={fieldKey} pathway={pathway} />}
    </ReactFrontendContext.Consumer>
  </SettingsSection>
)

export default ConfirmValue
