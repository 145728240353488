import React, { ReactNode } from 'react'
import { FormGroup } from 'reactstrap'
import withForm from '../withForm'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import styled from 'styled-components'
import * as filestack from 'filestack-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faFileUpload } from '@fortawesome/pro-solid-svg-icons/faFileUpload'
import { FormContextProps } from '../Form'

const client = filestack && filestack.init('AcZBaKY39ShSTlXzBMoawz')

export type ImageUploadValue = { url: string }

export interface ImageUploadProps extends Partial<FormContextProps> {
  name: string
  showLabel?: boolean
  label?: string
  id?: string
  help?: string | ReactNode
  errors?: string[]
  showValid?: boolean
  validFeedback?: string
  onChange?: (newValue: ImageUploadValue) => void
  value?: ImageUploadValue
  formSchema?: Record<string, any>
  className?: string
}

export interface ImageUploadState {
  showPicker: boolean
}

class ImageUpload extends React.PureComponent<ImageUploadProps, ImageUploadState> {
  state = {
    showPicker: false,
  }

  togglePicker = () => {
    this.setState({
      showPicker: !this.state.showPicker,
    })
  }

  hidePicker = () => {
    this.setState({
      showPicker: false,
    })
  }

  static defaultProps = {
    errors: [],
    showValid: false,
    showLabel: true,
  }

  componentWillUnmount() {
    this.props.setValue(this.props, undefined)
  }

  render() {
    const {
      showLabel,
      name,
      label,
      formSchema,
      id,
      errors,
      showValid,
      help,
      validFeedback,
      value,
      className,
      setValue,
    } = this.props

    const imageMax: [number, number] = [1600, 1600]
    const filestackOptions = {
      uploadInBackground: false,
      accept: ['image/jpeg', 'image/png', 'image/gif'],
      fromSources: [
        'local_file_system',
        'url',
        'imagesearch',
        'googledrive',
        'dropbox',
        'instagram',
        'facebook',
      ],
      imageMax,
      transformations: {
        crop: true,
        rotate: false,
        circle: false,
      },
      // todo: proper typing
      onUploadDone: (res) => {
        const file = res.filesUploaded[0]
        client
          .metadata(file.handle, { width: true, height: true })
          .then((res) => {
            setValue(this.props, {
              url: file.url,
              handle: file.handle,
              width: res.width,
              height: res.height,
            })
          })
          .catch((e) => {
            console.error('Failed to retrieve image metadata', e)
          })
      },
    }

    return (
      <FormGroup className={getFormGroupClasses(errors, showValid) + ' ' + className}>
        <FormLabel
          showLabel={showLabel}
          id={id}
          name={name}
          label={label}
          formSchema={formSchema}
        />

        <div
          className="form-control"
          onClick={() => client.picker(filestackOptions).open()}
          style={{
            backgroundImage: `url('${value?.url}/convert?h=100&fit=max&quality=70')`,
          }}
        >
          {!value && (
            <div className="placeholder">
              <div className="icon">
                <FontAwesomeIcon icon={faFileUpload} />
              </div>
              Click to upload
            </div>
          )}

          {value && (
            <div
              className="close"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setValue(this.props, undefined)
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </div>
        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
        <HelpText help={help} />
      </FormGroup>
    )
  }
}

const ImageUploadWithForm = withForm(ImageUpload)

export default styled(ImageUploadWithForm)`
  .form-control {
    height: 100px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 0;
    .close {
      margin: 0.375rem 0.5rem 0 0;
      color: #5b7a9e;
      font-size: 1.3rem;
      &:hover {
        color: var(--primary) !important;
      }
    }
    .placeholder {
      font-size: 0.875rem;
      position: relative;
      top: 50%;
      .icon {
        font-size: 34px;
      }
      cursor: pointer;
      text-align: center;
      color: #7d8ba0;
      transition: all 0.2s ease-out;
      transform: scale(0.9) translateY(-50%);

      &:hover {
        color: var(--primary);
        transform: scale(1) translateY(-50%);
      }
    }
  }
`
