import React, { ChangeEvent, ElementType, ReactNode } from 'react'
import { FormGroup, Input as StrapInput } from 'reactstrap'
import { InputType } from 'reactstrap/lib/Input'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import classnames from 'classnames'
import MaskedInput from 'react-text-mask'
import TextareaAutosize from 'react-textarea-autosize'
import withForm from '../withForm'
import { FormContextProps } from '../Form'
export interface InputProps extends Partial<FormContextProps> {
  showLabel?: boolean
  label?: string
  type?: InputType
  id?: string
  placeholder?: string
  help?: string | ReactNode
  errors?: string[]
  showValid?: boolean
  validFeedback?: string
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  name: string
  value?: string | number
  maxLength?: number
  minRows?: number
  disabled?: boolean
  readOnly?: boolean
  autoFocus?: boolean
  multipleLines?: boolean
  className?: string
  size?: 'lg' | 'sm'
  mask?: any[]
  maskOptions?: Record<string, any>
  autocomplete?: string
  unsetOnUnmount?: boolean
  keepCharacterPositions?: boolean
  guide?: boolean
}

class Input extends React.PureComponent<InputProps> {
  static defaultProps = {
    errors: [],
    showValid: false,
    showLabel: true,
  }

  componentWillUnmount(): void {
    if (this.props.unsetOnUnmount !== false) {
      this.props.setValue(this.props, undefined)
    }
  }

  onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    let value: string | number = event.target.value
    if (value === '' || !value) {
      value = undefined
    }
    if (value && this.props.type === 'number') {
      value = parseFloat(value as string)
    }
    this.props.setValue(this.props, value)
  }

  render(): React.ReactElement {
    const {
      showLabel,
      className,
      type,
      name,
      label,
      id,
      placeholder,
      errors,
      showValid,
      maxLength,
      help,
      autoFocus,
      multipleLines,
      validFeedback,
      value,
      disabled,
      readOnly,
      size,
      formSchema,
      mask,
      maskOptions,
      minRows,
      autocomplete,
    } = this.props

    const hasErrors = errors.length > 0

    let FormComponent: React.ReactElement
    if (mask) {
      FormComponent = (
        <MaskedInput
          mask={mask}
          placeholder={placeholder}
          className={classnames('form-control', {
            'is-invalid': errors?.length > 0,
          })}
          name={name}
          id={id || name}
          onChange={this.onChange}
          disabled={disabled}
          readOnly={readOnly}
          autoFocus={autoFocus}
          value={value == null ? '' : value}
          {...maskOptions}
          autoComplete={autocomplete}
          type={type}
        />
      )
    } else if (multipleLines) {
      FormComponent = (
        <TextareaAutosize
          className={classnames('form-control', {
            'is-invalid': errors?.length > 0,
          })}
          style={{
            // resize: 'none',
            height: 5,
          }}
          name={name}
          id={id || name}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={this.onChange}
          value={value == null ? '' : value}
          disabled={disabled}
          readOnly={readOnly}
          autoFocus={autoFocus}
          // async={true}
          minRows={minRows || 2}
          autoComplete={autocomplete}
        />
      )
    } else {
      FormComponent = (
        <StrapInput
          type={type}
          name={name}
          id={id || name}
          maxLength={maxLength}
          placeholder={placeholder}
          valid={!hasErrors && showValid}
          invalid={hasErrors}
          onChange={this.onChange}
          value={value == null ? '' : value}
          disabled={disabled}
          readOnly={readOnly}
          autoFocus={autoFocus}
          bsSize={size}
          autoComplete={autocomplete}
        />
      )
    }

    return (
      <FormGroup className={classnames(getFormGroupClasses(errors, showValid), className)}>
        <FormLabel
          showLabel={showLabel}
          id={id || name}
          name={name}
          label={label}
          formSchema={formSchema}
        />
        {FormComponent}
        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
        <HelpText help={help} />
      </FormGroup>
    )
  }
}

export default withForm<InputProps>(Input)
