import React from 'react'
import SocialBlock from './Social'
import { faShareAlt } from '@fortawesome/pro-solid-svg-icons/faShareAlt'
import SettingsSection from '../../../settings/SettingsSection'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import BlockManager from '../../BlockManager'

export const Form = (props) => (
  <React.Fragment>
    <Cardbox name="config.buttons" thin vertical showLabel={false} multiple>
      <CardboxItem value="facebook" title="Facebook" />
      <CardboxItem value="twitter" title="Twitter" />
      <CardboxItem value="linkedin" title="LinkedIn" />
    </Cardbox>
    <SettingsSection title="Button Shape">
      <Cardbox name="config.shape" thin vertical showLabel={false}>
        <CardboxItem value={false} title="Square" />
        <CardboxItem value={true} title="Round" />
      </Cardbox>
    </SettingsSection>
  </React.Fragment>
)

class SocialBlockEditor extends SocialBlock {
  static editor = {
    label: 'Social Share Buttons',
    icon: faShareAlt,
    category: 'content',
    settingsForm: Form,
    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        buttons: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      })
    },
  }
}

BlockManager.registerBlockClass(SocialBlockEditor)
