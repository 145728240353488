import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faItalic } from '@fortawesome/pro-solid-svg-icons/faItalic'
import { isMod, applyToText } from './utils'

export const hasItalic = value =>
  value.marks.some(mark => mark.type === 'italic')

export const toggleItalic = editor => editor.toggleMark('italic').focus()

export const ItalicButton = ({ editor, className }) => (
  <button
    type="button"
    onClick={e => toggleItalic(editor)}
    className={classnames(
      'slate-italic-plugin--button',
      { active: hasItalic(editor.value) },
      className
    )}
  >
    <FontAwesomeIcon icon={faItalic} fixedWidth />
  </button>
)

export const ItalicPlugin = options => ({
  onKeyDown(event, editor, next) {
    if (isMod(event) && event.key === 'i') {
      event.preventDefault()
      return toggleItalic(editor)
    }
    next()
  },
})

export const ItalicMark = ({ children }) => <em>{children}</em>
