import React from 'react'
import { deserialize } from '../../../pagebuilder/blocks/content/RichText/RichText.editor'
import PageBuilder from '../../../pagebuilder/PageBuilder'
import Pathway from '../../../_shared/models/pathway/pathway.backend.client'
import Step from '../../../_shared/models/step/step.class'
import ClientProgramGroup from '../../../_shared/models/programGroup/programGroup.client'
import StepViewContainer, {
  ReactFrontendContext,
} from '../../../frontend/client/containers/StepViewContainer'
import { connect } from 'react-redux'
import { EditorState, editorUpdateResponse } from '../editorFrame/editorRedux'
import Response from '../../../_shared/models/response/response.backend.client'

interface Props {
  step: Step
  pathway: Pathway
  response: Response
  programGroup?: ClientProgramGroup
}

class StepEditor extends React.Component<Props> {
  shouldComponentUpdate(): boolean {
    return false
  }

  getPreview = (content: Step['content']): JSX.Element => {
    const { step } = this.props
    const previewStep = new Step({
      ...step,
      content,
    })

    return <StepViewContainer step={previewStep} reduxAction={editorUpdateResponse} />
  }

  render(): React.ReactNode {
    const { pathway, step, programGroup, response } = this.props
    let content
    if (step.content) {
      content = deserialize(step.content)
    }

    return (
      <ReactFrontendContext.Provider
        value={{
          pathway,
          programGroup,
          response,
        }}
      >
        <PageBuilder content={content} getPreview={this.getPreview} />
      </ReactFrontendContext.Provider>
    )
  }
}

const mapStateToProps = (state: EditorState) => ({
  response: state.response,
})

export default connect(mapStateToProps)(StepEditor)
