import { BlockHTMLAttributes, RGBAColor } from '../../types'
import SectionBlock from './Section'

export enum SectionOverlayTypes {
  VIVID = 'vivid',
}

export type SectionOverlayConfig = {
  type: SectionOverlayTypes
  colorOne?: RGBAColor
  colorTwo?: RGBAColor
}

export type SectionProps = {
  block: SectionBlock
  blockHTMLAttributes?: BlockHTMLAttributes // renamed from blockProps
  className?: string
  index?: number
  key?: string
  context?: Record<string, unknown>
}
