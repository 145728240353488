import mediaQueries from '../../../backend/client/css/_media'
import {
  BlockResponsiveAppearance,
  BlockAppearanceConfig,
  BlockStyleContext,
  RGBAColor,
  UploadedImage,
} from '../../types'

export interface BackgroundConfig {
  image?: UploadedImage
  repeat?: string
  align?: string
  color?: RGBAColor
  fillStyle?: string
  attachment?: string
}

function getImageUrl(image: UploadedImage, width: number) {
  return `background-image: url('https://cdn.filestackcontent.com/resize=width:${width}/pjpg=quality:80/${image.handle}');`
}

export default function getBackgroundStyles(
  data: BlockAppearanceConfig,
  context: BlockStyleContext
): BlockResponsiveAppearance {
  let styles = ''

  const background = data?.background
  if (background) {
    const { image, repeat, align, color, fillStyle, attachment } = background

    if (context?.location) {
      const blockName = context.block?.data?.advanced?.name
      if (blockName) {
        const search = new URLSearchParams(context.location.search)
        const value = search.get(blockName + '-bg')
        if (image && value) {
          image.handle = value
        }
      }
    }

    // todo: is there a less verbose way to achieve the same effect? what about retina?
    // possibly image-set css property as seen here: https://css-tricks.com/responsive-images-css/
    if (image) {
      styles += `
      ${getImageUrl(image, 800)}
      @media(min-width:${mediaQueries.sm}) {
        ${getImageUrl(image, 1000)}
      }
      @media(min-width:${mediaQueries.md}) {
        ${getImageUrl(image, 1200)}
      }
      @media(min-width:${mediaQueries.lg}) {
        ${getImageUrl(image, 1000)}
      }
      @media(min-width:${mediaQueries.xl}) {
        ${getImageUrl(image, 1200)}
      }
      @media(min-width:1600px) {
        ${getImageUrl(image, 1600)}
      }
      @media(min-width:2400px) {
        ${getImageUrl(image, 2400)}
      }
      @media(min-width:3600px) {
        ${getImageUrl(image, 3600)}
      }
      `
    }

    if (color) {
      styles += `background-color: rgba(${color.r}, ${color.g}, ${color.b}, ${color.a});`
    }

    if (repeat === 'repeat') {
      styles += `background-repeat: repeat;`
    } else {
      styles += `background-repeat: no-repeat;`
    }

    if (align) {
      styles += `background-position: ${align};`
    }

    if (attachment) {
      styles += `background-attachment: ${attachment};`
    }

    switch (fillStyle) {
      case 'actualSize':
        break
      case 'fit':
        styles += `background-size: contain;`
        break
      case 'fill':
      default:
        styles += `background-size: cover;`
        break
    }
  }
  return {
    all: styles,
  }
}
