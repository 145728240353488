import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStrikethrough } from '@fortawesome/pro-solid-svg-icons/faStrikethrough'

export const hasStrikethrough = value =>
  value.marks.some(mark => mark.type === 'strikethrough')

export const toggleStrikethrough = change =>
  change.toggleMark('strikethrough').focus()

export const StrikethroughButton = ({ editor, className }) => (
  <button
    type="button"
    onClick={e => toggleStrikethrough(editor)}
    className={classnames(
      'slate-strikethrough-plugin--button',
      { active: hasStrikethrough(editor.value) },
      className
    )}
  >
    <FontAwesomeIcon icon={faStrikethrough} fixedWidth />
  </button>
)

export const StrikethroughPlugin = options => ({
  // onKeyDown(event, editor, next) {
  //   if (isMod(event) && event.key === 'u') {
  //     event.preventDefault()
  //     return toggleStrikethrough(editor)
  //   }
  //   next()
  // },
})

export const StrikethroughMark = ({ children }) => (
  <span style={{ textDecoration: 'line-through' }}>{children}</span>
)
