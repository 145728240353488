import { faCaretCircleDown } from '@fortawesome/pro-solid-svg-icons/faCaretCircleDown'
import BlockManager from '../../BlockManager'
import DropdownField from './Dropdown'

class DropdownFieldEditor extends DropdownField {
  static editor = {
    label: 'Dropdown',
    category: 'form',
    icon: faCaretCircleDown,
  }
}

BlockManager.registerBlockClass(DropdownFieldEditor)
