import React from 'react'
import { faFont } from '@fortawesome/pro-solid-svg-icons/faFont'
import TextInputField, { key } from './TextInput'
import { PlaceholderSchema } from '../../commonSchemas'
import ConfirmValue, { ConfirmValueSchema } from '../../../settings/ConfirmValue'
import Input from '../../../../_shared/components/Form/Input'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import BlockManager from '../../BlockManager'

export const Form = (props) => (
  <React.Fragment>
    <ConfirmValue fieldKey={key} />
    <div className="section-content">
      <Input name="config.placeholder" label="Placeholder" />
      <Cardbox name="config.multipleLines" thin vertical showLabel={false} defaultValue={false}>
        <CardboxItem value={false} title="Single line" description="Accepts one line of text" />
        <CardboxItem
          value={true}
          title="Multiple lines"
          description="Grows to accept multiple lines of text"
        />
      </Cardbox>
    </div>
  </React.Fragment>
)

class TextInputFieldEditor extends TextInputField {
  static editor = {
    label: 'Text',
    category: 'form',
    icon: faFont,
    settingsForm: Form,
    canConfirmValue: true,
    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        ...PlaceholderSchema,
        ...ConfirmValueSchema,
        multipleLines: {
          title: 'Number of Lines',
          type: 'boolean',
        },
      })
    },
  }
}

BlockManager.registerBlockClass(TextInputFieldEditor)
