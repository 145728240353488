import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { Collapse } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp'

interface SettingsSectionState {
  isOpen: boolean
}

interface SettingsSectionProps {
  startOpen?: boolean
  className?: string
  children?: any
  title?: string
  indent?: boolean
}

class SettingsSection extends React.PureComponent<SettingsSectionProps, SettingsSectionState> {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: props.startOpen || true,
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { className, children, title } = this.props
    const { isOpen } = this.state

    const icon = isOpen ? faChevronUp : faChevronDown

    return (
      <div className={classnames(className, 'settings-section', { open: isOpen })}>
        <div className="section-title" onClick={this.toggle}>
          {title} <FontAwesomeIcon icon={icon} />
        </div>
        <Collapse isOpen={this.state.isOpen}>
          <div className="collapse-content">{children}</div>
        </Collapse>
      </div>
    )
  }
}

export default styled(SettingsSection)`
  margin: 0 -1rem;

  .section-title {
    padding: 0.8rem 1rem;
    font-weight: 600;
    border-bottom: 1px solid #eef1f3;
    background: #eaf1f7;

    cursor: pointer;
    &:hover {
      color: var(--primary) !important;
    }

    .svg-inline--fa {
      float: right;
      position: relative;
      top: 5px;
    }
  }
  &:first-child {
    /* border-top: 1px solid #eef1f3; */
  }
  &.open > .section-title {
    color: var(--primary) !important;
  }
  &.open {
    border-bottom: 1px solid #eef1f3;
  }

  ${(props) => {
    if (props.indent) {
      return `
  &.open > .section-title {
    color: #0063ce;
  }`
    }
  }}

  .collapse-content {
    padding: 1rem;

    > * :last-child,
    > * :last-child > * :last-child,
    > * :last-child > * :last-child > * :last-child .form-group {
      margin-bottom: 0;
    }

    .settings-section {
      margin: 0;
    }
  }

  > div > .collapse-content {
    ${(props) => {
      if (props.indent) {
        return {
          borderLeft: '6px solid #dae0e8',
          padding: 0,
        }
      }
    }}
  }
`
