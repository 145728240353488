import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFillDrip } from '@fortawesome/pro-solid-svg-icons/faFillDrip'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import { SketchPicker } from 'react-color'

export const hasColor = value => value.marks.some(mark => mark.type === 'color')

export const getColor = value =>
  value.marks.filter(mark => mark.type === 'color').first()

export const setColor = (event, editor) => {
  const color = event.rgb
  const { value } = editor

  const existingMarks = value.marks.filter(mark => mark.type === 'color')

  if (existingMarks.size > 0) {
    existingMarks.forEach(mark => {
      editor.removeMark(mark)
    })
  }

  editor.addMark({
    type: 'color',
    data: {
      color,
    },
  })
}

const StyledDropdown = styled(Dropdown)`
  .dropdown-menu {
    margin: 0;
    padding: 0;
    border: 0;
    width: 220px;

    input {
      width: 100% !important;
    }
  }
`

export class ColorControl extends React.Component {
  state = {
    dropdownOpen: false,
  }

  toggle = () => {
    if (this.state.dropdownOpen === true) {
      this.props.editor.focus()
    }

    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  render() {
    const { editor } = this.props

    let value = '#000000'
    const mark = getColor(editor.value)
    if (mark) {
      value = mark.data.get('color')
    }

    return (
      <StyledDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle
          tag="button"
          type="button"
          disabled={editor.value.selection.isCollapsed}
        >
          <FontAwesomeIcon icon={faFillDrip} fixedWidth />
        </DropdownToggle>
        <DropdownMenu>
          <SketchPicker
            autofocus
            onChange={event => setColor(event, editor)}
            color={value}
          />
        </DropdownMenu>
      </StyledDropdown>
    )
  }
}

export const ColorPlugin = options => ({
  // onKeyDown(event, editor, next) {
  //   if (isMod(event) && event.key === 'b') {
  //     event.preventDefault()
  //     return toggleFontSize(editor)
  //   }
  //   next()
  // },
})

export const ColorMark = ({ children, mark }) => {
  const color = mark.data.get('color')
  return (
    <span
      style={{ color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` }}
    >
      {children}
    </span>
  )
}
