import React from 'react'
import Html from 'slate-html-serializer'

const INLINE_TAGS = {
  a: 'link',
}

const BLOCK_TAGS = {
  blockquote: 'quote',
  p: 'paragraph',
  pre: 'code',
  li: 'list-item',
  ol: 'ordered-list',
  ul: 'unordered-list',
  align: 'align',
  heading: 'heading',
}

const MARK_TAGS = {
  em: 'italic',
  strong: 'bold',
  u: 'underline',
  'font-size': 'font-size',
  'font-family': 'font-family',
  color: 'color',
  'line-height': 'line-height',
}

const rules = [
  // Inline Nodes
  {
    deserialize(el, next) {
      const type = INLINE_TAGS[el.tagName.toLowerCase()]
      if (type) {
        const obj = {
          object: 'inline',
          type,
          nodes: next(el.childNodes),
        }
        switch (type) {
          case 'link':
            obj.data = {
              url: el.getAttribute('href'),
              newWindow: el.getAttribute('target') === '_blank',
            }
            break
        }

        return obj
      }
    },
    serialize(obj, children) {
      if (obj.object === 'inline') {
        switch (obj.type) {
          case 'link':
            const newWindow = obj.data.get('newWindow')
            let attrs = {}
            if (newWindow) {
              attrs = {
                target: '_blank',
                rel: 'noopener noreferrer',
              }
            }
            return (
              <a href={obj.data.get('url')} {...attrs}>
                {children}
              </a>
            )
        }
      }
    },
  },
  // Blocks
  {
    deserialize(el, next) {
      const tagName = el.tagName.toLowerCase()
      const typeAttr = el.getAttribute && el.getAttribute('type')
      const type = BLOCK_TAGS[typeAttr || tagName]
      if (type) {
        const obj = {
          object: 'block',
          type,
          nodes: next(el.childNodes),
        }
        switch (type) {
          case 'align':
            obj.data = {
              align: el.getAttribute('class').replace('text-', '').replace('align ', ''),
            }
            break
          case 'heading':
            obj.data = {
              size: parseInt(el.tagName.replace('H', ''), 10),
            }
            break
        }
        return obj
      }
    },
    serialize(obj, children) {
      if (obj.object === 'block') {
        switch (obj.type) {
          case 'paragraph':
            return (
              <p className={obj.data.get('className')}>
                {obj.text !== '' ? children : String.fromCharCode(160)}
              </p>
            )
          case 'quote':
            return <blockquote>{children}</blockquote>
          case 'ordered-list':
            return <ol>{children}</ol>
          case 'unordered-list':
            return <ul>{children}</ul>
          case 'list-item':
            return <li>{children}</li>
          case 'align':
            return (
              <div type="align" className={`align text-${obj.data.get('align')}`}>
                {children}
              </div>
            )
          case 'heading':
            let Tag
            switch (obj.data.get('size')) {
              case 2:
                Tag = 'h2'
                break
              case 3:
                Tag = 'h3'
                break
              case 4:
                Tag = 'h4'
                break
              default:
              case 1:
                Tag = 'h1'
            }
            return <Tag type="heading">{children}</Tag>
        }
      }
    },
  },
  // Marks
  {
    deserialize(el, next) {
      const tagName = el.tagName.toLowerCase()
      const typeAttr = el.getAttribute && el.getAttribute('type')
      const type = MARK_TAGS[typeAttr || tagName]
      if (type) {
        const obj = {
          object: 'mark',
          type: type,
          nodes: next(el.childNodes),
        }
        switch (type) {
          case 'font-size':
            obj.data = {
              size: parseFloat(el.style.fontSize.replace('em', '')),
            }
            break
          case 'font-family':
            obj.data = { family: el.style.fontFamily }
            break
          case 'color':
            let rgb = el.style.color
            rgb = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(,\s*\d+\.*\d+)?\)$/)
            try {
              obj.data = { color: { r: rgb[1], g: rgb[2], b: rgb[3] } }
              obj.data.color.a = rgb[4] || 1
            } catch (e) {
              obj.data = { color: { r: 0, g: 0, b: 0, a: 1 } }
            }
            break
          case 'line-height':
            obj.data = { height: el.style.lineHeight }
            break
        }
        return obj
      }
    },
    serialize(obj, children) {
      if (obj.object === 'mark') {
        switch (obj.type) {
          case 'bold':
            return <strong>{children}</strong>
          case 'italic':
            return <em>{children}</em>
          case 'underline':
            return <u>{children}</u>
          case 'strikethrough':
            return (
              <span type="strikethrough" style={{ textDecoration: 'strikethrough' }}>
                {children}
              </span>
            )
          case 'font-size':
            return (
              <span type="font-size" style={{ fontSize: obj.data.get('size') + 'em' }}>
                {children}
              </span>
            )
          case 'font-family':
            return (
              <span type="font-family" style={{ fontFamily: obj.data.get('family') }}>
                {children}
              </span>
            )
          case 'color':
            const color = obj.data.get('color')
            return (
              <span
                type="color"
                style={{
                  color: `rgba(${color.r || 0}, ${color.g || 0}, ${color.b || 0}, ${color.a || 1})`,
                }}
              >
                {children}
              </span>
            )
          case 'line-height':
            return (
              <span type="line-height" style={{ lineHeight: obj.data.get('height') }}>
                {children}
              </span>
            )
        }
      }
    },
  },
]

export default new Html({ rules })
