import React from 'react'
import PropTypes from 'prop-types'
import SettingsSection from '../SettingsSection'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faArrows } from '@fortawesome/pro-solid-svg-icons/faArrows'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Input from '../../../_shared/components/Form/Input'
import RichText from '../../../_shared/components/Form/RichText'
import Cardbox, { CardboxItem } from '../../../_shared/components/Form/Cardbox'
import { makeid } from '../../components/utilities'
import withForm from '../../../_shared/components/withForm'

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
})

const AnswerSchema = {
  type: 'object',
  additionalProperties: false,
  required: ['value', 'label'],
  properties: {
    value: {
      type: 'string',
    },
    label: {
      type: 'string',
    },
    points: {
      type: 'number',
    },
    feedback: {
      type: 'string',
    },
    hide: {
      type: 'boolean',
    },
    includeNotApplicable: {
      type: 'boolean',
    },
  },
}

export const AnswersSchema = {
  answers: {
    type: 'array',
    items: AnswerSchema,
  },
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

class AnswerEditor extends React.PureComponent {
  static propTypes = {
    answer: PropTypes.object,
    index: PropTypes.number,
    isVisible: PropTypes.bool,
    includeNotApplicable: PropTypes.bool,
  }
  render() {
    return (
      <div className="answer-editor" style={{ display: this.props.isVisible ? 'block' : 'none' }}>
        <Input
          unsetOnUnmount={false}
          multipleLines
          minRows={1}
          name={`config.answers[${this.props.index}].label`}
          autoFocus
        />
        <Input
          unsetOnUnmount={false}
          name={`config.answers[${this.props.index}].points`}
          type="number"
          help="How many points is this answer worth?"
        />
        <RichText unsetOnUnmount={false} name={`config.answers[${this.props.index}].feedback`} />
        {this.props.includeNotApplicable && (
          <Cardbox
            unsetOnUnmount={false}
            name={`config.answers[${this.props.index}].includeNotApplicable`}
            thin
            showLabel={false}
            label={'Include "Not Applicable" Option'}
            vertical
            defaultValue={false}
          >
            <CardboxItem value={true} title={'Include "Not Applicable" Option'} />
          </Cardbox>
        )}
      </div>
    )
  }
}

class AnswerOptions extends React.PureComponent {
  static propTypes = {
    value: PropTypes.array,
    className: PropTypes.string,
    startOpen: PropTypes.bool,
    includeNotApplicable: PropTypes.bool,
  }

  state = {
    editingAnswer: null,
  }

  editAnswer = (answer) => {
    if (this.state.editingAnswer === answer.value) {
      this.setState({
        editingAnswer: null,
      })
    } else {
      this.setState({
        editingAnswer: answer.value || null,
      })
    }
  }

  addAnswer = () => {
    const { value } = this.props
    const newValue = value ? Array.from(value) : []
    const id = makeid(16)
    newValue.push({
      value: id,
      label: 'New answer',
    })
    this.props.setValue(this.props, newValue)
    this.setState({
      editingAnswer: id,
    })
  }

  delete = (index) => {
    const newValue = Array.from(this.props.value)
    newValue.splice(index, 1)
    this.props.setValue(this.props, newValue)
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newValue = reorder(this.props.value, result.source.index, result.destination.index)

    this.props.setValue(this.props, newValue)
  }

  render() {
    const { value, className, startOpen, includeNotApplicable } = this.props
    return (
      <SettingsSection title="Answers" startOpen={startOpen} className={className}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <ul ref={provided.innerRef}>
                {value?.map((answer, index) => (
                  <Draggable key={answer.value} draggableId={answer.value} index={index}>
                    {(provided, snapshot) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div className="row align-items-center no-gutters">
                          <div className="col-auto">
                            <div className="move" {...provided.dragHandleProps}>
                              <FontAwesomeIcon icon={faArrows} />
                            </div>
                          </div>
                          <div
                            className="col"
                            onClick={() => {
                              this.editAnswer(answer)
                            }}
                          >
                            <div className="label">{answer.label}</div>
                          </div>
                          <div className="col-auto">
                            <div
                              className="delete"
                              onClick={() => {
                                this.delete(index)
                              }}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </div>
                          </div>
                        </div>
                        {this.state.editingAnswer === answer.value && (
                          <AnswerEditor
                            answer={answer}
                            index={index}
                            includeNotApplicable={includeNotApplicable}
                            isVisible={true}
                          />
                        )}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
          <Button block color="primary" onClick={this.addAnswer}>
            <FontAwesomeIcon icon={faPlus} /> Add Answer
          </Button>
        </DragDropContext>
      </SettingsSection>
    )
  }
}

const StyledAnswerOptions = styled(AnswerOptions)`
  ul {
    margin: -1rem 0 1rem 0;
    padding: 0;
    list-style: none;
  }
  li {
    padding: 0.5rem;
    border-bottom: 1px solid #eef1f3;
    margin: 0 -1rem;
    background: #fff;
  }
  .move {
    font-size: 0.875rem;
  }
  .delete,
  .move {
    color: #929292;
    padding: 0.1rem 0.25rem;
  }
  .delete {
    cursor: pointer;
    &:hover,
    &:focus {
      color: var(--danger);
    }
  }
  .label {
    padding: 0 0.35rem;
    font-size: 0.8125rem;

    cursor: pointer;
    &:hover,
    &:focus {
      color: var(--primary);
    }
  }
  .btn-primary .fa-plus {
    margin-right: 0.2rem;
  }
  .answer-editor {
    border-top: 1px solid #eef1f3;
    padding: 1rem 1rem 0.75rem 1rem;
    margin: 0.75rem -0.75rem -0.75rem -0.75rem;
    background: #fbfbfb;
    input,
    textarea,
    .form-control {
      font-size: 0.8125rem;
      padding: 0.35rem 0.5rem;
    }
    .form-group {
      margin-bottom: 1rem;
    }
  }

  .CardboxItem {
    font-size: 0.8125rem;
  }
`

export default withForm(StyledAnswerOptions)
