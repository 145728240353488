import React from 'react'
import ImageBlock from './Image'
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage'
import ImageUpload from '../../../../_shared/components/Form/ImageUpload'
import Input from '../../../../_shared/components/Form/Input'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import BlockManager from '../../BlockManager'

const Form = (props) => (
  <div className="section-content">
    <ImageUpload name="config.image" label="Image" />
    <Input name="config.alt" label="Alt Text" />
    <Cardbox name="config.lazyload" thin vertical defaultValue={true}>
      <CardboxItem value={false} title="Load First" description="Load this image immediately" />
      <CardboxItem
        value={true}
        title="Defer Loading"
        description="Only load this image when it is scrolled into view"
      />
    </Cardbox>
  </div>
)

class ImageBlockEditor extends ImageBlock {
  static editor = {
    label: 'Image',
    icon: faImage,
    category: 'content',
    settingsForm: Form,

    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        image: {
          title: 'Image',
          type: 'object',
        },
        alt: {
          type: 'string',
        },
        lazyload: {
          type: 'boolean',
        },
      })
      schema.required.push('image')
    },
  }
}

BlockManager.registerBlockClass(ImageBlockEditor)
