import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import StepSubmission, { StepSubmissionRedirect } from '../../_shared/StepSubmission'
import ClientPathway from '../../_shared/models/pathway/pathway.client'
import ClientResponse from '../../_shared/models/response/response.client'
import Step from '../../_shared/models/step/step.class'

import {
  ActionTypes,
  AppState,
  UPDATE_PATHWAY,
  UPDATE_PROGRAM_GROUP,
  UPDATE_SESSION,
  UPDATE_RESPONSE,
  UPDATE_STEP,
  SUBMIT_STEP,
} from './types'
import { Action } from 'redux'
import Log from '../../_shared/log'
import { History, Location } from 'history'
import { FormSubmitContext } from '../../_shared/components/Form'
import { initSocket } from './api/socketio'
import ClientProgramGroup from '../../_shared/models/programGroup/programGroup.client'
import { embedNewStep, embedRedirect, embedUpdateHeight } from './embedHelpers'
import { IGoogleReCaptchaConsumerProps } from 'react-google-recaptcha-v3'
declare global {
  interface Window {
    DISABLE_RECAPTCHA?: any
  }
}

export function updateStep(step: Step): ActionTypes {
  return {
    type: UPDATE_STEP,
    payload: step,
  }
}

export function updateSession(hasSession: boolean): ActionTypes {
  return {
    type: UPDATE_SESSION,
    payload: hasSession,
  }
}

export function updatePathway(newPathway: Record<string, any>): ActionTypes {
  return {
    type: UPDATE_PATHWAY,
    payload: newPathway,
  }
}

export function updateProgramGroup(newProgramGroup: Record<string, any>): ActionTypes {
  return {
    type: UPDATE_PROGRAM_GROUP,
    payload: newProgramGroup,
  }
}

export async function logoutSession() {
  return async (dispatch: ThunkDispatch<AppState, unknown, ActionTypes>): Promise<void> => {
    await fetch('/logout')
    dispatch(updateSession(false))
  }
}

export function updateResponse(response: ClientResponse): ActionTypes {
  return {
    type: UPDATE_RESPONSE,
    payload: response,
  }
}

export function beginSubmitStep(): ActionTypes {
  return {
    type: SUBMIT_STEP,
    payload: true,
  }
}

export function endSubmitStep(): ActionTypes {
  return {
    type: SUBMIT_STEP,
    payload: false,
  }
}

function handleRedirects(history: History<unknown>, redirect: StepSubmissionRedirect) {
  if (redirect.stepId) {
    Log.info('Redirecting to specific step', { stepId: redirect.stepId })
    window.scrollTo(0, 0)
    const params = new URLSearchParams(location.search)
    params.delete('_autoSubmit')
    history.push({
      search: params.toString(),
      pathname: `/${redirect.stepId}`,
    })
  } else if (redirect.url) {
    if (redirect.parentFrame && window.parent !== window) {
      Log.info('Redirecting parent frame to custom URL', { url: redirect.url })
      embedRedirect(redirect.url)
      return
    } else {
      Log.info('Redirecting to custom URL', { url: redirect.url })
      window.location.href = redirect.url
      return
    }
  }
}

export function submitStep({
  history,
  pathway,
  step,
  doc,
  response,
  programGroup,
  context,
  googleReCaptchaProps,
}: {
  history: History<unknown>
  location: Location<unknown>
  pathway: ClientPathway
  step: Step
  doc: Record<string, any>
  response?: ClientResponse
  programGroup?: ClientProgramGroup
  context: FormSubmitContext
  googleReCaptchaProps: IGoogleReCaptchaConsumerProps
}): ThunkAction<void, AppState, unknown, Action<string>> {
  return async (dispatch) => {
    Log.info('Submit step', {
      pathwayId: pathway._id,
      stepId: step._id,
      doc,
      context,
    })

    window.VA.requestMgr.isLoading = true

    if (googleReCaptchaProps && step.order === 0 && window.DISABLE_RECAPTCHA === undefined) {
      doc.rcToken = await googleReCaptchaProps.executeRecaptcha()
    }

    const submission = new StepSubmission({
      pathway,
      step,
      response,
      programGroup,
      params: doc,
      buttonId: context.buttonId,
    })

    if (submission.validate()) {
      Log.info('Begin submit step', {
        pathwayId: pathway._id,
        pathwayName: pathway.name,
        stepId: step._id,
        doc,
      })

      dispatch(beginSubmitStep())

      const response = submission.process()

      // Submit it to the server
      window.VA.requestMgr
        .add({
          ...doc,
          _submitButtonId: context.buttonId,
          _noTransform: true,
          _stepId: step._id,
        })
        .then(async (result) => {
          const res = await result.json()
          if (res.redirect) {
            handleRedirects(history, res.redirect)
          }
        })

      dispatch(updateSession(true))
      dispatch(updateResponse(response))
      dispatch(endSubmitStep())

      // check server response for redirects
      const { redirect } = submission

      if (redirect) {
        if (redirect.waitForServerResponse === false) {
          handleRedirects(history, redirect)
        }
      } else if (response.nextStep) {
        const params = new URLSearchParams(location.search)
        params.delete('_autoSubmit')
        history.push({
          search: params.toString(),
          pathname: `/${response.nextStep._id}`,
        })
        window.scrollTo(0, 0)
        embedNewStep()
      }
    }
  }
}
