import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFont } from '@fortawesome/pro-solid-svg-icons/faFont'
import styled from 'styled-components'
import Slider from 'rc-slider/lib/Slider'

const defaultFontSize = 1

export const hasFontSize = value =>
  value.marks.some(mark => mark.type === 'font-size')

export const getFontSize = value =>
  value.marks.filter(mark => mark.type === 'font-size').first()

export const setFontSize = (inSize, editor) => {
  const { value } = editor
  const { selection } = value

  const size = parseFloat(inSize)
  const mark = {
    type: 'font-size',
    data: {
      size,
    },
  }

  let originalSelection
  if (selection.isCollapsed) {
    originalSelection = selection

    editor.moveStartToStartOfText()
    editor.moveEndToEndOfText()
  }

  const existingMarks = value.marks.filter(mark => mark.type === 'font-size')

  if (existingMarks.size > 0) {
    existingMarks.forEach(mark => {
      editor.removeMark(mark)
    })
  }

  if (size !== defaultFontSize) {
    editor.addMark(mark)
  }

  if (originalSelection) {
    editor.select(originalSelection)
  }
}

const FontRangeDiv = styled.div`
  padding: 0.5rem;
  background: #0e355e;

  .svg-inline--fa:first-child {
    font-size: 0.7rem;
  }
  .slider-wrapper {
    flex: 1 1 auto;
    padding: 0 1rem;
  }
  .slider-value {
    padding-left: 1rem;
    input {
      font-size: 0.875rem;
      border: 0;
      /* width: 3rem; */
      border-radius: 3px;
      padding: 0.1rem 0.3rem;
      background: #041527;
      color: #fff;
    }
  }
`

export const FontSizeControl = ({ editor, className }) => {
  let value = 1
  const fs = getFontSize(editor.value)
  if (fs) {
    value = fs.data.get('size')
  }

  return (
    <FontRangeDiv className="d-flex flex-nowrap align-items-center">
      <FontAwesomeIcon icon={faFont} fixedWidth />
      <div className="slider-wrapper">
        <Slider
          defaultValue={value}
          min={1}
          max={5}
          step={0.1}
          onChange={value => setFontSize(value, editor)}
        />
      </div>
      <FontAwesomeIcon icon={faFont} fixedWidth />
      <div className="slider-value">
        <input
          type="number"
          min={1}
          max={4}
          step={0.1}
          value={value}
          onChange={event => setFontSize(event.target.value, editor)}
          className={classnames(
            'slate-font-size-plugin--button',
            {},
            className
          )}
        />
      </div>
    </FontRangeDiv>
  )
}

export const FontSizePlugin = options => ({
  // onKeyDown(event, editor, next) {
  //   if (isMod(event) && event.key === 'b') {
  //     event.preventDefault()
  //     return toggleFontSize(editor)
  //   }
  //   next()
  // },
})

export const FontSizeMark = ({ children, mark }) => (
  <span style={{ fontSize: mark.data.get('size') + 'em' }}>{children}</span>
)
