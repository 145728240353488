import React from 'react'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import ClientPathway from '../../../_shared/models/pathway/pathway.client'

let isSetup = false

declare global {
  interface Window {
    ga: any
    _hsq: any
  }
}

function setup(pathway) {
  window.ga('create', process.env.GOOGLE_ANALYTICS_UA, {
    name: 'va',
    allowLinker: true,
  })

  window.ga('va.set', 'dimension1', `${pathway.companyId}-${pathway.campaignId}`)
  window.ga('va.set', 'dimension2', pathway._id)

  if (pathway.googleAnalyticsUA) {
    window.ga('create', pathway.googleAnalyticsUA, {
      name: 'vaclient',
      allowLinker: true,
    })
  }
}

const track = function (location) {
  const track = {
    hitType: 'pageview',
    page: location.pathname + location.search,
    location:
      (window.location.protocol || 'http:') + '//' + window.location.host + window.location.search,
    title: document.title,
  }

  window.ga('va.send', track)
  window.ga('vaclient.send', track)

  const _hsq = (window._hsq = window._hsq || []) // eslint-disable-line
  _hsq.push(['setPath', location.pathname])
  _hsq.push(['trackPageView'])
}

const googleAnalytics = function (pathway, location) {
  if (!window.ga) {
    console.warn('No Google Analytics function exists')
    return
  }

  if (!isSetup) {
    setup(pathway)
    isSetup = true
  }

  track(location)
}

type GoogleAnalyticsProps = RouteComponentProps & {
  pathway: ClientPathway
}
class GoogleAnalytics extends React.Component<GoogleAnalyticsProps> {
  shouldComponentUpdate(prevProps) {
    return (
      prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.pathway._id !== this.props.pathway._id
    )
  }
  render() {
    const { pathway, location } = this.props
    if (process.env.IS_CLIENT) {
      // Do not enable Google Analytics if the cookie consent banner is enabled
      // and we're on the first step
      if (pathway.cookieConsentBanner && location.pathname === '/') {
        return null
      }
      googleAnalytics(pathway, location)
    }

    return null
  }
}

export default withRouter(GoogleAnalytics)
