import React from 'react'
import Colorpicker from '../../../_shared/components/Form/Colorpicker'
import Dropdown from '../../../_shared/components/Form/Dropdown'
import withForm from '../../../_shared/components/withForm'
import BlockManager from '../BlockManager'
import SectionBlock from './Section'

const Form = ({ formData }) => (
  <div className="section-content">
    <Dropdown name="config.overlay.type" label="Overlay Effect" placeholder="(None)">
      <option value="vivid">Vivid</option>
    </Dropdown>
    {formData.config?.overlay?.type === 'vivid' && <VividForm />}
  </div>
)
const FormWithForm = withForm(Form, true)

const VividForm = (props) => (
  <React.Fragment>
    <Colorpicker name="config.overlay.colorOne" label="Color One" />
    <Colorpicker name="config.overlay.colorTwo" label="Color Two" />
  </React.Fragment>
)

class SectionBlockEditor extends SectionBlock {
  static editor = {
    label: 'Section',
    settingsForm: FormWithForm,
    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        overlay: {
          type: 'object',
          properties: {
            type: {
              type: 'string',
              enum: ['vivid'],
            },
          },
          additionalProperties: true,
        },
      })
    },
  }
}

BlockManager.registerBlockClass(SectionBlockEditor)
