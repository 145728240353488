import BlockManager from '../blocks/BlockManager'
import Block, { SavedBlock } from '../blocks/Block'
import SectionBlock from '../blocks/section/Section'
import RowBlock from '../blocks/row/Row'
import Log from '../../_shared/log'

export function makeid(length = 16) {
  const result = []
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
  }
  return result.join('')
}

export const generateNewBlockIds = (obj: Block | Block[], clearLinks = false): void => {
  if (obj instanceof Block) {
    obj.id = 'b' + makeid(16)
    if (clearLinks) {
      if (obj.data?.advanced?.linkedKey) {
        delete obj.data.advanced.linkedKey
      }
      if (obj.data?.advanced?.linkedType) {
        delete obj.data.advanced.linkedType
      }
    }

    if (obj.content && obj.content instanceof Array) {
      obj.content.forEach((b) => generateNewBlockIds(b, clearLinks))
    }
  } else if (obj instanceof Array) {
    obj.forEach((b) => generateNewBlockIds(b, clearLinks))
  }
}

export const getReturnURL = function getReturnURL(pathway, response) {
  if (!pathway) return
  let url = `https://${pathway.getPrimaryURL()}/`

  // Ensure there is only one trailing space
  url = url.replace(/\/\/$/, '/')

  // Populate answers already provided by the user if possible
  // if (response) {
  //   // Get all of the post mapped fields on the register step
  //   const step = pathway.getSteps()[0]
  //   if (step) {
  //     url += '?'

  //     step.forEachBlock((block) => {
  //       const field = response.getField(block.id)
  //       if (field) {
  //         url += `${encodeURIComponent(block.id)}=${encodeURIComponent(field.value)}&`
  //       }
  //     })
  //   }
  // }

  return url
}

export function instantiateBlock<T extends Block>(block: SavedBlock | Block): T {
  const type = block instanceof Block ? (block.constructor as any).type : block.type
  const BlockClass = BlockManager.getBlockClass(type)
  if (BlockClass) {
    return new BlockClass(block) as T
  } else {
    Log.error(new Error(`Block class "${type}" does not exist`))
    return null
  }
}

export const instantiateBlocks = (content: SectionBlock[]): SectionBlock[] => {
  const newContent: SectionBlock[] = []

  content.forEach((section) => {
    const sectionContent = []
    section = instantiateBlock<SectionBlock>(section)
    if (!section) {
      return
    }

    section.content?.forEach((row) => {
      row = instantiateBlock<RowBlock>(row)
      if (!row || !row.content) {
        return
      }

      row.content.forEach((region, regionIndex) => {
        if (region) {
          const regionContent = []
          region.forEach((block) => {
            const blockInstance = instantiateBlock<Block>(block)
            if (blockInstance) {
              regionContent.push(blockInstance)
            }
          })
          row.content[regionIndex] = regionContent
        } else {
          row.content[regionIndex] = []
        }
      })
      sectionContent.push(row)
    })

    section.content = sectionContent
    newContent.push(section)
  })

  return newContent
}
