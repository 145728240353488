import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup } from 'reactstrap'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import { Editor } from 'slate-react'
import { Value } from 'slate'
import SoftBreak from 'slate-soft-break'
import SlateToolbar from '../../../pagebuilder/blocks/content/RichText/SlateToolbar'
import { BoldPlugin } from '../../../pagebuilder/blocks/content/RichText/plugins/bold'
import { ItalicPlugin } from '../../../pagebuilder/blocks/content/RichText/plugins/italic'
import { UnderlinePlugin } from '../../../pagebuilder/blocks/content/RichText/plugins/underline'
import { StrikethroughPlugin } from '../../../pagebuilder/blocks/content/RichText/plugins/strikethrough'
import { OrderedListPlugin } from '../../../pagebuilder/blocks/content/RichText/plugins/orderedList'
import { UnorderedListPlugin } from '../../../pagebuilder/blocks/content/RichText/plugins/unorderedList'
import { AlignmentPlugin } from '../../../pagebuilder/blocks/content/RichText/plugins/alignment'
import { FontSizePlugin } from '../../../pagebuilder/blocks/content/RichText/plugins/fontSize'
import { FontFamilyPlugin } from '../../../pagebuilder/blocks/content/RichText/plugins/fontFamily'
import { ColorPlugin } from '../../../pagebuilder/blocks/content/RichText/plugins/color'
import { LinkPlugin } from '../../../pagebuilder/blocks/content/RichText/plugins/link'
import { LineHeightPlugin } from '../../../pagebuilder/blocks/content/RichText/plugins/lineHeight'
import html from '../../../pagebuilder/blocks/content/RichText/serializer'
import {
  renderMark,
  renderNode,
} from '../../../pagebuilder/blocks/content/RichText/RichText.editor'
import classnames from 'classnames'
import debounce from 'lodash/debounce'
import withForm from '../withForm'

const plugins = [
  BoldPlugin(),
  ItalicPlugin(),
  UnderlinePlugin(),
  StrikethroughPlugin(),
  OrderedListPlugin(),
  UnorderedListPlugin(),
  AlignmentPlugin(),
  FontSizePlugin(),
  FontFamilyPlugin(),
  ColorPlugin(),
  LinkPlugin(),
  SoftBreak({ shift: true }),
  LineHeightPlugin(),
]

const initialValue = {
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            leaves: [
              {
                text: '',
              },
            ],
          },
        ],
      },
    ],
  },
}

class RichText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: Value.fromJSON(props.value ? html.deserialize(props.value).toJSON() : initialValue),
    }
  }

  static propTypes = {
    showLabel: PropTypes.bool,
    label: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    help: PropTypes.string,
    errors: PropTypes.array,
    showValid: PropTypes.bool,
    validFeedback: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    formSchema: PropTypes.object,
    setValue: PropTypes.func,
  }

  static defaultProps = {
    showLabel: true,
    errors: [],
    showValid: false,
  }

  componentWillUnmount() {
    if (this.props.unsetOnUnmount !== false) {
      this.props.setValue(this.props, undefined)
    }
  }

  updateForm = (value) => {
    let val = html.serialize(value)
    if (val === '<p></p>') {
      val = undefined
    }
    this.props.setValue(this.props, val)
  }

  throttledUpdateForm = debounce(this.updateForm, 100)

  onChange = ({ value }) => {
    this.setState({ value })
    this.throttledUpdateForm(value)
  }

  render() {
    const {
      showLabel,
      type,
      name,
      label,
      formSchema,
      validFeedback,
      id,
      placeholder,
      errors,
      showValid,
      help,
      value,
    } = this.props

    const hasErrors = errors.length > 0

    return (
      <FormGroup className={getFormGroupClasses(errors, showValid)}>
        <FormLabel
          showLabel={showLabel}
          id={id}
          name={name}
          label={label}
          formSchema={formSchema}
        />

        <div
          className={classnames('form-control', {
            'is-invalid': hasErrors,
          })}
          style={{ height: 'auto' }}
        >
          <Editor
            plugins={plugins}
            value={this.state.value}
            onChange={this.onChange}
            renderMark={renderMark}
            renderNode={renderNode}
            renderEditor={this.renderEditor}
          />
        </div>

        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
        <HelpText help={help} />
      </FormGroup>
    )
  }

  /**
   * Render the editor.
   *
   * @param {Object} props
   * @param {Function} next
   * @return {Element}
   */

  renderEditor = (props, editor, next) => {
    const children = next()
    return (
      <div style={{ position: 'relative' }}>
        {children}
        <SlateToolbar editor={editor} />
      </div>
    )
  }
}

export default withForm(RichText)
