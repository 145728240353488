import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink'

export const hasLink = value =>
  value.inlines.some(inline => inline.type === 'link')

export const getLink = value =>
  value.inlines.filter(mark => mark.type === 'link').first()

export const setLink = editor => {
  const { value } = editor

  if (value.selection.isCollapsed) {
    editor.moveStartToStartOfText()
    editor.moveEndToEndOfText()
  }

  let existing = ''

  const existingLink = getLink(value)
  if (existingLink) {
    existing = existingLink.data.get('url')
  }

  const url = window.prompt('Enter URL:', existing)

  if (
    url.indexOf('http') !== 0 &&
    url.indexOf('/') !== 0 &&
    url.indexOf('#') !== 0 &&
    url.indexOf('mailto:') !== 0 &&
    url.indexOf('tel:') !== 0
  ) {
    window.alert(
      'Please enter a valid URL starting with "http", "#", "/", "mailto" or "tel:"'
    )
    return
  }

  let newWindow = false
  if (url) {
    newWindow = window.confirm('Should this link open in a new window?')
  }

  if (!url) {
    editor.unwrapInline({ type: 'link' })
    return
  }

  const node = {
    type: 'link',
    data: {
      url,
      newWindow,
    },
  }

  if (existing) {
    editor.setInlines(node).focus()
  } else {
    editor
      .wrapInline({
        type: 'link',
        data: {
          url,
          newWindow,
        },
      })
      .focus()
  }
}

export const LinkButton = ({ editor, className }) => (
  <button
    type="button"
    onClick={e => setLink(editor)}
    disabled={!hasLink(editor.value) && editor.value.selection.isCollapsed}
    className={classnames(
      'slate-link-plugin--button',
      { active: hasLink(editor.value) },
      className
    )}
  >
    <FontAwesomeIcon icon={faLink} fixedWidth />
  </button>
)

export const LinkPlugin = options => ({
  // onKeyDown(event, editor, next) {
  //   if (isMod(event) && event.key === 'u') {
  //     event.preventDefault()
  //     return toggleStrikethrough(editor)
  //   }
  //   next()
  // },
})

function getAttributes(node) {
  if (node.data.get('newWindow')) {
    return {
      target: '_blank',
      rel: 'noopener noreferrer',
    }
  } else {
    return {}
  }
}

export const LinkNode = ({ attributes, children, node }) => (
  <a {...attributes} href={node.data.get('url')} {...getAttributes(node)}>
    {children}
  </a>
)
