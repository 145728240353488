import { FieldResponse } from '../../_shared/models/response/response.types'
import { makeid } from '../components/utilities'
import Block, { BlockEditorConfig } from './Block'
import Field from './Field'

export interface BlockDetailedOutputProps {
  label?: string
  key: string
  hideLabels?: boolean
  field?: FieldResponse
  answer?: FieldResponse
  className?: string
}

export interface BlockSimpleOutputProps {
  answer?: FieldResponse
}

export type BlockClass = typeof Block &
  Omit<Partial<typeof Field>, 'prototype'> & {
    editor?: BlockEditorConfig
  }

class BlockManager {
  _classes: Record<string, typeof Block> = {}
  _categories: {
    content: typeof Block[]
    profile: typeof Block[]
    form: typeof Block[]
  } = {
    content: [],
    profile: [],
    form: [],
  }

  registerBlockClass(blockClass: typeof Block) {
    this._classes[blockClass.type] = blockClass
  }

  getBlockClass(key: string) {
    return this._classes[key]
  }

  /**
   * Returns all of the blocks in a given category.
   * @param {String} category
   */
  getBlocksInCategory(category: string) {
    return Object.entries(this._classes)
      .filter(([key, c]) => c.editor?.category === category)
      .map(([key, c]) => ({ ...c.editor, type: key }))
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
  }

  /**
   * Returns the block at the specified category and index
   * @param {String} category
   * @param {String} index
   */
  getBlockFromCategory(category: string, index: number) {
    return this._categories[category][index]
  }

  /**
   * Returns a new content instance of the specified block.
   * @param {String} key
   */
  newBlockInstance(type: string) {
    const BlockClass = this.getBlockClass(type)
    const data = Object.assign({}, BlockClass.template, {
      id: 'b' + makeid(),
      type,
    })
    return new BlockClass(data)
  }
}

export default new BlockManager()
