const CareerFieldMap = {
  agriculture: 'Agriculture',
  arts: 'Arts, Culture and Entertainment',
  beauty: 'Beauty',
  business: 'Business, Management and Administration',
  community: 'Community and Social Services',
  education: 'Education',
  health: 'Health and Medicine',
  hospitality: 'Hospitality',
  law: 'Law and Public Policy',
  medical: 'Medical Administration',
  science: 'Science and Technology',
  trade: 'Trades',
  other: 'Other',
}

export default CareerFieldMap
