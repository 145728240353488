export const isMod = event => (event.metaKey && !event.ctrlKey) || event.ctrlKey

export const hasParentOfType = (value, type) =>
  value.blocks.some(
    block =>
      !!value.document.getClosest(block.key, parent => parent.type === type)
  )

export const getNodeOfType = (value, type) =>
  value.blocks.filter(block => block.type === type).first()

export const isList = value =>
  value.blocks.some(block => block.type === 'list-item')
export const isUnorderedList = value => hasParentOfType(value, 'unordered-list')
export const isOrderedList = value => hasParentOfType(value, 'ordered-list')

export const applyList = (change, type) =>
  change
    .setBlocks('list-item')
    .wrapBlock(type)
    .focus()

export const removeUnorderedList = change =>
  change
    .setBlocks('paragraph')
    .unwrapBlock('unordered-list')
    .focus()

export const switchToOrderedList = change =>
  change
    .unwrapBlock('unordered-list')
    .wrapBlock('ordered-list')
    .focus()

export const removeOrderedList = change =>
  change
    .setBlocks('paragraph')
    .unwrapBlock('ordered-list')
    .focus()

export const switchToUnorderedList = change =>
  change
    .wrapBlock('unordered-list')
    .unwrapBlock('ordered-list')
    .focus()

export const deepRemoveList = change => {
  const { value } = change
  const { document } = value
  const node = getNodeOfType(value, 'list-item')
  const depth = document.getDepth(node.key)

  Array(depth)
    .fill('.')
    .forEach(() => {
      const parent = document.getParent(node.key)
      if (parent.type === 'unordered-list') removeUnorderedList(change)
      else removeOrderedList(change)
    })
  return change
}
