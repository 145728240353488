import React from 'react'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import ClientPathway from '../../../_shared/models/pathway/pathway.client'

let isSetup = false

declare global {
  interface Window {
    gtag: any
    dataLayer: any
  }
}

function setup(pathway) {
  const isEditor = window.location.href.includes('Editor') ? true : false

  if (!isEditor) {
    const script = document.createElement('script')
    script.defer = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GA_MEASUREMENT_ID}`
    document.body.appendChild(script)

    window.dataLayer = window.dataLayer || []
    window.gtag = function gtag() {
      window.dataLayer.push(arguments)
    }
    window.gtag('js', new Date())
    window.gtag('config', process.env.GA_MEASUREMENT_ID, {
      cookie_flags: 'SameSite=None;Secure',
      custom_map: {
        dimension1: `${pathway.companyId}-${pathway.campaignId}`,
        dimension2: pathway._id,
      },
    })

    if (pathway.gaMeasurementId) {
      window.gtag('config', pathway.gaMeasurementId, {
        cookie_flags: 'SameSite=None;Secure',
      })
    }

    window.gtag('set', {
      user_properties: { dimension1: `${pathway.companyId}-${pathway.campaignId}` },
      send_to: process.env.GA_MEASUREMENT_ID,
    })
    window.gtag('set', {
      user_properties: { dimension2: pathway._id },
      send_to: process.env.GA_MEASUREMENT_ID,
    })
  }
}

const track = function (location) {
  window.gtag('event', 'page_view', {
    page_title: document.title,
    page_location:
      (window.location.protocol || 'http:') + '//' + window.location.host + window.location.search,
    page_path: location.pathname + location.search,
  })
}

const googleAnalytics4 = function (pathway, location) {
  if (!isSetup) {
    setup(pathway)
    isSetup = true
  } else {
    if (!window.gtag) {
      console.warn('No Google Analytics (gtag) function exists')
      return
    }

    track(location)
  }
}

type GoogleAnalytics4Props = RouteComponentProps & {
  pathway: ClientPathway
}
class GoogleAnalytics4 extends React.Component<GoogleAnalytics4Props> {
  shouldComponentUpdate(prevProps) {
    return (
      prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.pathway._id !== this.props.pathway._id
    )
  }
  render() {
    const { pathway, location } = this.props
    if (process.env.IS_CLIENT) {
      // Do not enable Google Analytics if the cookie consent banner is enabled
      // and we're on the first step
      if (pathway.cookieConsentBanner && location.pathname === '/') {
        return null
      }
      googleAnalytics4(pathway, location)
    }

    return null
  }
}

export default withRouter(GoogleAnalytics4)
