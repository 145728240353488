import React from 'react'
import { connect } from 'react-redux'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from '../../../frontend/client/types'
import { CREDENTIALS } from '../../../_shared/api/apiFetch'
import Loader from '../components/Loader'

interface RESTDataLoaderProps {
  showLoader: boolean
  resource: string
  action?: string
  params?: Record<string, any>
  children: (data: any, isLoading: boolean) => JSX.Element
  transform?: (data: any) => any
  reduxAction?: any
  dispatch?: ThunkDispatch<AppState, unknown, Action<string>>
}

interface RESTDataLoaderState {
  data: unknown
  isLoading: boolean
  error: string
}

export class RESTDataLoader extends React.Component<RESTDataLoaderProps, RESTDataLoaderState> {
  state = {
    data: null,
    isLoading: true,
    error: null,
  }

  // shouldComponentUpdate(nextProps: RESTDataLoaderProps, nextState: RESTDataLoaderState): boolean {
  //   return this.state.isLoading !== nextState.isLoading
  // }

  loadData(): void {
    const { resource, action, params, transform, reduxAction, dispatch } = this.props
    this.setState(
      {
        isLoading: true,
        error: null,
        data: null,
      },
      async () => {
        try {
          const url = new URL(`${process.env.API_URL}/${resource}/${action}`)
          url.search = new URLSearchParams(params).toString()

          const headers: Record<string, any> = {
            'Content-Type': 'application/json', // eslint-disable-line
          }

          if (CREDENTIALS.token) {
            headers.Authorization = 'Bearer ' + CREDENTIALS.token // eslint-disable-line
          }

          // todo: change this out for the new fetch abstraction
          const result = await fetch(url.toString(), {
            method: 'GET',
            headers,
          })
          const json = await result.json()

          let data = json.data
          if (data) {
            if (transform) {
              data = transform(data)
            }
            if (reduxAction) {
              dispatch(reduxAction(data))
            }
            this.setState({
              isLoading: false,
              data,
            })
            return
          }

          if (json.error) {
            this.setState({
              isLoading: false,
              error: json.error,
              data: null,
            })
          }
        } catch (e) {
          this.setState({
            isLoading: false,
            error: e.message,
            data: null,
          })
        }
      }
    )
  }

  componentDidMount(): void {
    this.loadData()
  }

  render(): React.ReactNode {
    const { showLoader, children } = this.props
    const { isLoading, data, error } = this.state

    if (error) {
      return <div className="alert alert-danger">{error}</div>
    }

    if (isLoading && showLoader) {
      return <Loader />
    }

    return children(data, isLoading)
  }
}

export default connect()(RESTDataLoader)
