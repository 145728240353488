import React from 'react'
import { FormContextProps } from '../Form'
import { FormGroup, Input as StrapInput } from 'reactstrap'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import withForm from '../withForm'

export interface DropdownProps extends Partial<FormContextProps> {
  showLabel?: boolean
  label?: string
  id?: string
  placeholder?: string
  help?: string | React.ReactNode
  errors?: string[]
  showValid?: boolean
  validFeedback?: string
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  name: string
  value?: string | number
  children?: JSX.Element | JSX.Element[]
  className?: string
  size?: 'lg' | 'sm'
  disabled?: boolean
}

class Dropdown extends React.PureComponent<DropdownProps> {
  static defaultProps = {
    errors: [],
    showLabel: true,
    showValid: false,
  }

  componentDidMount() {
    const { setDefaultFormValue } = this.props
    setDefaultFormValue(this.props)
  }

  componentDidUpdate(prevProps) {
    const { setDefaultFormValue } = this.props
    setDefaultFormValue(this.props)
  }

  componentWillUnmount() {
    const { setValue } = this.props
    setValue(this.props, undefined)
  }

  onChange = (event) => {
    const { setValue } = this.props
    if (event.target.value === '') {
      setValue(this.props, undefined)
    } else {
      setValue(this.props, event.target.value)
    }
  }

  renderPlaceholder() {
    const { placeholder } = this.props
    if (placeholder !== undefined) {
      return <option value="">{placeholder}</option>
    }
  }

  render() {
    const {
      showLabel,
      name,
      id,
      children,
      label,
      formSchema,
      validFeedback,
      disabled,
      placeholder,
      errors,
      showValid,
      help,
      value,
      className,
      size,
    } = this.props

    const hasErrors = errors.length > 0
    return (
      <FormGroup className={getFormGroupClasses(errors, showValid) + ' ' + className}>
        <FormLabel
          showLabel={showLabel}
          id={id}
          name={name}
          label={label}
          formSchema={formSchema}
        />
        <StrapInput
          type="select"
          name={name}
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          valid={!hasErrors && showValid}
          invalid={hasErrors}
          onChange={this.onChange}
          value={value == null ? '' : value}
          bsSize={size}
        >
          {this.renderPlaceholder()}
          {children}
        </StrapInput>
        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
        <HelpText help={help} />
      </FormGroup>
    )
  }
}

export default withForm<DropdownProps>(Dropdown)
