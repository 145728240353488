import React from 'react'
import styled from 'styled-components'
import Input from '../../../../_shared/components/Form/Input'
import Field, { FieldConfig, FieldData } from '../../Field'
import classnames from 'classnames'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import { ConfirmValueConfig } from '../../ConfirmValue'
import { SetConfirmValue } from '../../../settings/ConfirmValue'

const REGEX_EMAIL =
'^(\x22(.+)\x22|[A-Za-z0-9!#$%&\x27*+\\-/=?^_`{|}~]+)(\\.(\x22(.+)\x22|[A-Za-z0-9!#$%&\x27*+\\-/=?^_`{|}~]+))*@([A-Za-z0-9]+(-[A-Za-z0-9]+)*\\.)+((?!con$)[A-Za-z]{2,99}|[xX][nN]--[A-Za-z0-9]+)$'

export interface EmailProps {
  block: EmailField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class Email extends React.PureComponent<EmailProps> {
  componentDidMount() {
    SetConfirmValue(this.props)
  }

  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)

    return (
      <Input
        name={block.id}
        type="email"
        className={classnames('block', className)}
        label={label}
        placeholder={block.data?.config?.placeholder}
        autocomplete="email"
      />
    )
  }
}

export const StyledEmail = styled(Email)`
  input {
    font-size: 1.066666666666667rem;
  }
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

export const key = 'Email'

export interface EmailFieldConfig extends ConfirmValueConfig {
  placeholder?: string
}

export interface EmailFieldData extends FieldData {
  config?: EmailFieldConfig
}

export default class EmailField extends Field {
  static type? = key
  static enableConditionalLogic = true
  declare data: EmailFieldData
  static template: Partial<EmailField> = {
    data: {
      config: {
        label: 'Email Address',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
      advanced: {
        name: 'email',
        profile: {
          group: 'contact',
          key: 'email',
          label: 'Email',
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<EmailField>) {
    super(params)
    Object.assign(this, EmailField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledEmail
  }

  getFormSchema(context: FrontendContext): Record<string, any> {
    const schema = super.getFormSchema(context)
    // add regex to schema.schema for email validation
    // schema.schema.format = 'email'
    schema.schema.pattern = REGEX_EMAIL
    return schema
  }
}

BlockManager.registerBlockClass(EmailField)
