import React, { ReactNode } from 'react'
import { FormGroup } from 'reactstrap'
import withForm from '../withForm'
import AceEditor from 'react-ace'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import throttle from 'lodash/throttle'
import { DebouncedFunc } from 'lodash'
import { FormContextProps, SetValueProps } from '../Form'

export interface CodeProps extends Partial<FormContextProps> {
  showLabel?: boolean
  label?: string
  type?: string
  id?: string
  placeholder?: string
  help?: string | ReactNode
  errors?: string[]
  showValid?: boolean
  validFeedback?: string
  onChange?: (value: string) => void
  name: string
  value?: string
  tabSize?: number
  height?: string
  mode?: string
  setOptions?: Record<string, any>
  editorProps?: Record<string, any>
  transformValueFunc?: (value: string) => string
  defaultValue?: string
  wrapEnabled?: boolean
}

class Code extends React.PureComponent<CodeProps> {
  static defaultProps = {
    errors: [],
    showValid: false,
    showLabel: true,
  }

  throttledSetValue: DebouncedFunc<(props: SetValueProps, newValue: any) => void>

  componentDidMount() {
    const { setValue, name, setDefaultFormValue, value, placeholder } = this.props
    this.throttledSetValue = throttle(setValue, 100, { trailing: true })
    setDefaultFormValue({
      name,
      value,
      placeholder,
    })
  }

  componentWillUnmount() {
    this.props.setValue(this.props, undefined)
  }

  onChange = (value: string) => {
    if (value === '' || !value) {
      value = undefined
    }
    this.throttledSetValue(this.props, value)
  }

  onLoadAce = (editor) => {
    editor.on('change', (arg, activeEditor) => {
      this.resizeEditor(activeEditor)
    })
    this.resizeEditor(editor)
  }

  resizeEditor(aceEditor) {
    const newHeight =
      aceEditor.getSession().getScreenLength() *
      (aceEditor.renderer.lineHeight + aceEditor.renderer.scrollBar.getWidth())
    aceEditor.container.style.height = `${newHeight}px`
    aceEditor.resize()
  }

  render() {
    const {
      showLabel,
      name,
      id,
      errors,
      showValid,
      help,
      height,
      value,
      mode,
      setOptions,
      tabSize,
      editorProps,
      validFeedback,
      formSchema,
      label,
      transformValueFunc,
    } = this.props

    const hasErrors = errors.length > 0

    let val = value
    if (transformValueFunc) {
      val = transformValueFunc(val)
    }

    return (
      <FormGroup className={getFormGroupClasses(errors, showValid)}>
        <FormLabel
          showLabel={showLabel}
          id={id}
          name={name}
          label={label}
          formSchema={formSchema}
        />
        <HelpText help={help} />
        <AceEditor
          fontSize={14}
          height={height}
          tabSize={tabSize}
          mode={mode}
          theme="textmate"
          width="100%"
          onChange={this.onChange}
          onLoad={this.onLoadAce}
          name={name}
          minLines={10}
          editorProps={editorProps}
          setOptions={setOptions}
          value={val == null ? '' : val}
          highlightActiveLine={false}
          wrapEnabled={true}
        />
        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
      </FormGroup>
    )
  }
}

export default withForm<CodeProps>(Code)
