import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { AnswersBlock } from '../../AnswersBlock'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import DropdownInput from '../../../../_shared/components/Form/Dropdown'

export interface DropdownProps {
  block: DropdownField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class Dropdown extends React.PureComponent<DropdownProps> {
  render() {
    const { block, className, context } = this.props
    const answers = block.data.config.answers
    const label = block.getLabel(context)

    return (
      <DropdownInput
        className={classnames('block', className)}
        name={block.id}
        id={block.id}
        showLabel={!block.data.config.hideLabel}
        label={label}
        placeholder="(Select One)"
      >
        {answers &&
          answers.map((answer) => (
            <option key={answer.value} value={answer.value}>
              {answer.label}
            </option>
          ))}
      </DropdownInput>
    )
  }
}

const StyledDropdown = styled(Dropdown)`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'Dropdown'
export default class DropdownField extends AnswersBlock {
  static type? = key
  static template: Partial<DropdownField> = {
    data: {
      config: {
        label: 'Dropdown',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<DropdownField>) {
    super(params)
    Object.assign(this, DropdownField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledDropdown
  }
}

BlockManager.registerBlockClass(DropdownField)
