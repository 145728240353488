import ClientPathway from '../../_shared/models/pathway/pathway.client'
import ClientProgramGroup from '../../_shared/models/programGroup/programGroup.client'
import ClientResponse from '../../_shared/models/response/response.client'
import Step from '../../_shared/models/step/step.class'

export const UPDATE_PATHWAY = 'UPDATE_PATHWAY'
export const UPDATE_PROGRAM_GROUP = 'UPDATE_PROGRAM_GROUP'
export const UPDATE_SESSION = 'UPDATE_SESSION'
export const LOGOUT_SESSION = 'LOGOUT_SESSION'
export const UPDATE_RESPONSE = 'UPDATE_RESPONSE'
export const UPDATE_STEP = 'UPDATE_STEP'
export const SUBMIT_STEP = 'SUBMIT_STEP'
export const INACTIVITY_TIMER_DEFAULT = 180

export interface AppState {
  pathway: ClientPathway
  hasSession: boolean
  step?: Step
  programGroup?: ClientProgramGroup
  response?: ClientResponse
  isSubmittingStep?: boolean
  errors?: Record<string, any>
}

export interface SubmitStepAction {
  type: typeof SUBMIT_STEP
  payload: boolean
}

export interface UpdatePathwayAction {
  type: typeof UPDATE_PATHWAY
  payload: any
}

export interface UpdateProgramGroupAction {
  type: typeof UPDATE_PROGRAM_GROUP
  payload: any
}

export interface UpdateSessionAction {
  type: typeof UPDATE_SESSION
  payload: any
}

export interface LogoutSessionAction {
  type: typeof LOGOUT_SESSION
}

export interface UpdateResponse {
  type: typeof UPDATE_RESPONSE
  payload: ClientResponse
}

export interface UpdateStep {
  type: typeof UPDATE_STEP
  payload: Step
}

export type ActionTypes =
  | UpdatePathwayAction
  | UpdateProgramGroupAction
  | UpdateSessionAction
  | LogoutSessionAction
  | UpdateResponse
  | UpdateStep
  | SubmitStepAction
