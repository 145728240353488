import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignCenter } from '@fortawesome/pro-solid-svg-icons/faAlignCenter'
import { faAlignLeft } from '@fortawesome/pro-solid-svg-icons/faAlignLeft'
import { faAlignRight } from '@fortawesome/pro-solid-svg-icons/faAlignRight'
import { faAlignJustify } from '@fortawesome/pro-solid-svg-icons/faAlignJustify'

export const isAlignedLeft = ({ value }) =>
  value.blocks.some(block => block.data.align === 'left')
export const isAlignedRight = ({ value }) =>
  value.blocks.some(block => block.data.align === 'right')
export const isAlignedCenter = ({ value }) =>
  value.blocks.some(block => block.data.align === 'center')
export const isAlignedJustify = ({ value }) =>
  value.blocks.some(block => block.data.align === 'left')
export const isAligned = ({ value }) =>
  value.blocks.some(block => block.type === 'align')

const removeAlignment = editor => {
  editor.unwrapBlock('align')
}

export const toggleAlign = (editor, align) => {
  removeAlignment(editor)
  editor.wrapBlock({ type: 'align', data: { align } })
}

export const AlignmentButtons = ({ editor, className }) => (
  <React.Fragment>
    <button
      type="button"
      onClick={e => toggleAlign(editor, 'left')}
      className={classnames(
        'slate-alignment-plugin--button',
        {
          active: isAlignedLeft(editor),
        },
        className
      )}
    >
      <FontAwesomeIcon icon={faAlignLeft} fixedWidth />
    </button>
    <button
      type="button"
      onClick={e => toggleAlign(editor, 'center')}
      className={classnames(
        'slate-alignment-plugin--button',
        {
          active: editor.value.blocks.some(
            block => block.data.align === 'center'
          ),
        },
        className
      )}
    >
      <FontAwesomeIcon icon={faAlignCenter} fixedWidth />
    </button>
    <button
      type="button"
      onClick={e => toggleAlign(editor, 'right')}
      className={classnames(
        'slate-alignment-plugin--button',
        {
          active: editor.value.blocks.some(
            block => block.data.align === 'right'
          ),
        },
        className
      )}
    >
      <FontAwesomeIcon icon={faAlignRight} fixedWidth />
    </button>
    <button
      type="button"
      onClick={e => toggleAlign(editor, 'justify')}
      className={classnames(
        'slate-alignment-plugin--button',
        {
          active: editor.value.blocks.some(
            block => block.data.align === 'justify'
          ),
        },
        className
      )}
    >
      <FontAwesomeIcon icon={faAlignJustify} fixedWidth />
    </button>
  </React.Fragment>
)

export const AlignmentPlugin = options => ({
  // onKeyDown(event, editor, next) {
  //   if (isMod(event) && event.key === 'u') {
  //     event.preventDefault()
  //     return toggleStrikethrough(editor)
  //   }
  //   next()
  // },
})

export const AlignNode = ({ attributes, children, node }) => {
  const align = node.data.get('align')
  return (
    <div className={`text-${align}`} {...attributes}>
      {children}
    </div>
  )
}
