import React from 'react'
import styled from 'styled-components'
import DropdownInput from '../../../../_shared/components/Form/Dropdown'
import classnames from 'classnames'
import Field, { FieldConfig, FieldData } from '../../Field'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { FrontendContext } from '../../../../_shared/StepSubmission'

export interface YearProps {
  block: YearField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class Year extends React.PureComponent<YearProps> {
  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)
    const startYear = block.data.config?.startYear || 1959
    const endYear = block.data.config?.endYear || 2029

    const options = []
    if (endYear > startYear) {
      for (let i = startYear; i <= endYear; i++) {
        options.push({ value: i, label: i })
      }
    } else if (startYear > endYear) {
      for (let i = startYear; i >= endYear; i--) {
        options.push({ value: i, label: i })
      }
    }

    return (
      <DropdownInput
        className={classnames('block', className)}
        name={block.id}
        id={block.id}
        showLabel={!block.data.config.hideLabel}
        label={label}
        placeholder="(Select One)"
      >
        {options.map((answer) => (
          <option key={answer.value} value={answer.value}>
            {answer.label}
          </option>
        ))}
      </DropdownInput>
    )
  }
}

const StyledYear = styled(Year)`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

export interface YearFieldConfig extends FieldConfig {
  startYear: number
  endYear: number
}

export interface YearFieldData extends FieldData {
  config?: YearFieldConfig
}

const key = 'Year'
export default class YearField extends Field {
  static type? = key
  static enableStatistics = true
  static enableConditionalLogic = true
  declare data?: YearFieldData

  static template: Partial<YearField> = {
    data: {
      config: {
        label: 'Year',
        startYear: 1959,
        endYear: 2029,
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<YearField>) {
    super(params)
    Object.assign(this, YearField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledYear
  }

  getFormSchema(context?: FrontendContext): Record<string, any> {
    return {
      schema: {
        title: this.getLabel(context),
        type: 'number',
      },
      required: this.data.config.required,
    }
  }
}

BlockManager.registerBlockClass(YearField)
