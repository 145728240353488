import React from 'react'
import styled from 'styled-components'
import DateInput from '../../../../_shared/components/Form/Date'
import classnames from 'classnames'
import Field, { FieldConfig, FieldData } from '../../Field'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import BlockManager from '../../BlockManager'

export interface DateProps {
  block: DateField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class Date extends React.PureComponent<DateProps> {
  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)

    // todo: remove this after new code migration, must find and replace all
    // date field configurations in DB, and change date editor settings
    let format = block.data.config?.format
    switch (format) {
      case 'DD/MM/YYYY':
        format = 'dd/MM/yyyy'
        break
      case 'MM/DD/YYYY':
        format = 'MM/dd/yyyy'
        break
    }

    return (
      <DateInput
        className={classnames('block', className)}
        name={block.id}
        id={block.id}
        label={label}
        showLabel={!block.data.config?.hideLabel}
        placeholder={block.data.config?.placeholder}
        format={format}
      />
    )
  }
}

export const StyledDate = styled(Date)`
  &:focus-within {
    position: relative;
    z-index: 1;
  }

  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

export interface DateFieldConfig extends FieldConfig {
  format?: string
  placeholder?: string
}

export interface DateFieldData extends FieldData {
  config?: DateFieldConfig
}

const key = 'Date'
export default class DateField extends Field {
  static type? = key
  static enableStatistics = true
  static enableConditionalLogic = true
  declare data?: DateFieldData
  static template: Partial<DateField> = {
    data: {
      config: {
        label: 'Date',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<DateField>) {
    super(params)
    Object.assign(this, DateField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledDate
  }

  getFormSchema(context?: FrontendContext): Record<string, any> {
    const format = this.data.config?.format
    let pattern
    switch (format) {
      case 'MM/dd/yyyy':
      case 'dd/MM/yyyy':
        pattern = '^\\d\\d/\\d\\d/\\d\\d\\d\\d$'
        break
      default:
        pattern = '.*'
    }

    return {
      schema: {
        title: this.getLabel(context),
        type: 'string',
        pattern,
      },
    }
  }
}

BlockManager.registerBlockClass(DateField)
