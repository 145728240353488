import React from 'react'
import Field, { FieldData, FieldConfig } from './Field'
import styled from 'styled-components'
import ClientResponse from '../../_shared/models/response/response.client'
import { FieldResponse } from '../../_shared/models/response/response.types'
import { FrontendContext } from '../../_shared/StepSubmission'
import Ajv from 'ajv'
import addFormats from 'ajv-formats'
import Log from '../../_shared/log'
const minScore = function (response: ClientResponse): number {
  let score = 0
  // If this field is required, the minimum score is the option with the lowest points
  if (this.isRequired(response)) {
    let answers = this.data.config.answers
    if (answers) {
      answers = answers
        // Answers that are not hidden and have points
        .filter((a) => !a.hide && !isNaN(a.points))
        // Sort by lowest points
        .sort((a, b) => a.points < b.points)

      if (answers.length > 0) {
        score += parseInt(answers[0].points, 10)
      }
    }
  }
  return score
}

const SimpleOutput = ({ answer }: { answer: AnswersMultipleBlockFieldResponse }) => {
  return (
    <ul style={{ listStylePosition: 'inside', padding: 0 }}>
      {answer?.value?.map((label, index) => (
        <li key={index}>{label}</li>
      ))}
    </ul>
  )
}

const DetailedOutput: React.FC<{
  hideLabels?: boolean
  label?: string
  field: AnswersBlockFieldResponse
  className: string
}> = (props) => {
  const { label, field, className, hideLabels } = props

  return (
    <div className={`detailed-summary ${className}`}>
      {!hideLabels && <span className="detailed-summary-label">{label || field?.label}: </span>}
      <span className="detailed-summary-answer"> {field?.value}</span>
      {field?.data?.feedback && (
        <div
          className="detailed-summary-answer-feedback"
          dangerouslySetInnerHTML={{
            __html: field.data.feedback,
          }}
        />
      )}
    </div>
  )
}

const DetailedOutputMultiple: React.FC<{
  hideLabels?: boolean
  label?: string
  field: AnswersMultipleBlockFieldResponse
  className: string
}> = (props) => {
  const { label, field, className, hideLabels } = props
  return (
    <div className={`detailed-summary ${className}`}>
      {!hideLabels && <div className="detailed-summary-label">{label || field.label}</div>}
      <div className="detailed-summary-answer">
        <ul>
          {field &&
            field.data &&
            field.data.map((answer) => (
              <li key={answer.value} className="detailed-summary-answer">
                <div className="detailed-summary-answer-label">{answer.label}</div>
                {answer.feedback && (
                  <div
                    className="detailed-summary-answer-feedback"
                    dangerouslySetInnerHTML={{
                      __html: answer.feedback,
                    }}
                  />
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

const StyledDetailedOutput = styled(DetailedOutput)`
  min-height: 1rem;
  .detailed-summary-label {
    font-weight: bold;
  }
  .detailed-summary-answer-feedback {
    margin: 1rem 0;
  }
  .detailed-summary-answer {
    margin: 0 0 1rem 0;
  }
`

const StyledDetailedOutputMultiple = styled(DetailedOutputMultiple)`
  .detailed-summary-label {
    font-weight: bold;
    margin: 0 0 1rem 0;
  }
  .detailed-summary-answer-feedback {
    margin: 1rem 0;
  }
  .detailed-summary-answer {
    /* margin: 0 0 1rem 0; */
  }
`

export interface Answer {
  label: string
  value: string
  points?: number
  hide?: boolean
  feedback?: string
  includeNotApplicable?: boolean
}

export interface AnswersBlockConfig extends FieldConfig {
  answers?: Answer[]
}

export interface AnswersBlockData extends FieldData {
  config?: AnswersBlockConfig
}

export interface AnswersBlockFieldResponse extends FieldResponse {
  data: Answer
}

export class AnswersBlock extends Field {
  static enableStatistics = true
  static enableConditionalLogic = true
  static detailedOutput = StyledDetailedOutput
  declare data?: AnswersBlockData

  static getConditionalLogicValue(value: AnswersBlockFieldResponse): string {
    return value?.data.value
  }

  minScore(response: ClientResponse): number {
    return minScore(response)
  }

  getFakeValue(context?: FrontendContext): AnswersBlockFieldResponse {
    const options = this.getOptions()
    if (!options) {
      return
    }
    return {
      _id: 'fakevalue',
      label: this.data?.config?.label,
      type: (this.constructor as any).type,
      value: this.getOptions()[0].label,
      data: this.getOptions()[0],
    }
  }

  /**
   * Transforms the provided POST'ed value to the real value. Allows
   * users to POST the option's label instead of it's value.
   */
  transformPostValue(value: unknown, context?: FrontendContext): string {
    if (typeof value !== 'string') {
      Log.warn('TransformPostValue failed validation', {
        block: this,
        value: String(value),
      })
      return
    }

    const options = this.getOptions()

    const chosenOption = options.find((o) => o.label === value || o.value === value)

    if (!chosenOption) {
      return undefined
    }

    return chosenOption.value
  }

  getOptions(context?: FrontendContext): Answer[] {
    return this.data.config?.answers
  }

  /**
   * Returns the AJV form schema for this field.
   */
  getFormSchema(context: FrontendContext): Record<string, unknown> {
    const schema = super.getFormSchema(context)
    let enumArray = []
    const options = this.getOptions()
    if (options?.length > 0) {
      enumArray = options.reduce((acc, cur) => {
        acc.push(cur.label)
        acc.push(cur.value)
        return acc
      }, [])

      // Deduplicate answers
      enumArray = [...new Set(enumArray)]
    }

    Object.assign(schema.schema, {
      type: 'string',
      enum: enumArray,
    })
    return schema
  }

  /**
   * Converts the form value (usually a primitive) to a FieldResponse
   */
  formToDoc(value: string, context?: FrontendContext): AnswersBlockFieldResponse {
    const answer: AnswersBlockFieldResponse = {
      _id: this.id,
      name: this.data?.advanced?.name,
      label: this.getLabel(context),
      value: this.getValue(value),
      type: (this.constructor as any).type,
      data: this.getOptions()?.find((a) => a.value === value),
    }

    return answer
  }

  /**
   * Converts a FieldResponse to it's primitive equivalent
   */
  docToForm(doc: AnswersBlockFieldResponse): string {
    return doc.data?.value
  }

  maxScore(): number {
    let maxScore = 0
    const answers = this.data.config?.answers
    if (answers) {
      answers.forEach((answer) => {
        if (!isNaN(answer.points) && !answer.hide) {
          if (answer.points > maxScore) {
            maxScore = answer.points
          }
        }
      })
    }
    return maxScore
  }

  calculateScore(responseField: AnswersBlockFieldResponse): number {
    let score = 0
    const answers = this.data?.config?.answers
    if (answers && responseField) {
      answers.forEach((answer) => {
        if (responseField.data.value === answer.value && !isNaN(answer.points)) {
          score += answer.points
        }
      })
    }
    return score
  }

  /**
   * Returns a string representation of the field's value
   */
  getValue(answer: string): string {
    return this.getOptions()?.find((a) => a.value === answer)?.label
  }
}

export interface AnswersMultipleBlockFieldResponse extends FieldResponse {
  data: Answer[]
  value: string[]
}

export class AnswersMultipleBlock extends Field {
  static simpleOutput = SimpleOutput
  static detailedOutput = StyledDetailedOutputMultiple
  static enableConditionalLogic = true
  static enableStatistics = true
  declare data: AnswersBlockData

  static getConditionalLogicValue(value: AnswersMultipleBlockFieldResponse): string[] {
    return value ? value.data.map((ans) => ans.value) : []
  }

  minScore(response: ClientResponse): number {
    return minScore(response)
  }

  getFakeValue(context?: FrontendContext): AnswersMultipleBlockFieldResponse {
    return {
      _id: 'fakevalue',
      label: this.getLabel(context),
      type: (this.constructor as any).type,
      value: this.getOptions().map((o) => {
        return o.label
      }),
      data: this.getOptions(),
    }
  }

  /**
   * Transforms the provided POST'ed value to the real value. Allows
   * users to POST the option's label instead of it's value.
   */
  transformPostValue(value: unknown): string[] {
    const postValueSchema = {
      oneOf: [
        { type: 'string' },
        {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      ],
    }

    const ajv = new Ajv({
      allErrors: true,
      useDefaults: true,
      removeAdditional: true,
      coerceTypes: true,
      verbose: true,
    })
    addFormats(ajv)

    ajv.validate(postValueSchema, value)
    if (ajv.errors) {
      Log.warn('TransformPostValue failed validation', {
        block: this,
        value,
        errors: ajv.errors,
      })
      return
    }

    const options = this.getOptions()
    if (!options || options.length < 1) {
      return
    }

    // Short circuit if "true" is provided as a value to automatically select the first option
    // todo: this should be deprecated but is used for tcpa=true from traditional LPs
    if (value === 'true') {
      return [options[0].value]
    }

    const preTransformValue = Array.isArray(value) ? value : [value]
    const postTransformedValue = []

    preTransformValue.forEach((val) => {
      const chosenOption = options.find((o) => o.label === val || o.value === val)
      if (!chosenOption) {
        return null
      }
      postTransformedValue.push(chosenOption.value)
    })

    return postTransformedValue as string[]
  }

  getOptions(): Answer[] {
    return this.data?.config?.answers
  }

  /**
   * Converts the form value (usually a primitive) to a FieldResponse
   */
  formToDoc(answers: string[], context?: FrontendContext): AnswersMultipleBlockFieldResponse {
    const answer = {
      _id: this.id,
      name: this.data?.advanced?.name,
      label: this.getLabel(context),
      value: [],
      type: (this.constructor as any).type,
      // Save the raw options for displaying feedback
      data: this.getOptions().filter((o) => answers.includes(o.value)),
    }

    // todo: don't like the duplication of data here, can this be improved before launch?
    // Save the string labels into an array for statistics purposes
    const defAnswers = this.data?.config?.answers
    answers.forEach((answerId) => {
      const answerDef = defAnswers.find((a) => answerId === a.value)
      if (answerDef) {
        answer.value.push(answerDef.label)
      }
    })

    return answer
  }

  docToForm(doc: AnswersMultipleBlockFieldResponse): string[] {
    const value = []
    const defAnswers = this.data?.config?.answers
    doc.value?.forEach((label) => {
      const answerDef = defAnswers.find((a) => label === a.label)
      if (answerDef) {
        value.push(answerDef.value)
      }
    })
    return value
  }

  getFormSchema(context: FrontendContext): Record<string, any> {
    const schema = super.getFormSchema(context)

    const options = this.getOptions()
    let enumArray = []
    if (options) {
      enumArray = options.reduce((acc, cur) => {
        acc.push(cur.label)
        acc.push(cur.value)
        return acc
      }, [])
      // Deduplicate answers
      enumArray = [...new Set(enumArray)]
    }

    Object.assign(schema.schema, {
      type: 'array',
      items: {
        type: 'string',
        enum: enumArray,
      },
    })
    return schema
  }

  maxScore(): number {
    const maxScoreSetting = Number(this.data?.config?.scoring?.maxScore)
    if (maxScoreSetting) {
      return maxScoreSetting
    }
    let maxScore = 0
    const answers = this.data?.config?.answers
    if (answers) {
      answers.forEach((answer) => {
        if (!isNaN(answer.points) && !answer.hide) {
          maxScore += answer.points
        }
      })
    }
    return maxScore
  }

  calculateScore(responseField: AnswersMultipleBlockFieldResponse): number {
    let score = 0
    const answers = this.data?.config?.answers
    if (answers && responseField) {
      answers.forEach((answer) => {
        if (!isNaN(answer.points) && responseField.value.includes(answer.label)) {
          score += answer.points
        }
      })
    }

    // Score cannot exceed the max score setting
    const maxScoreSetting = Number(this.data?.config?.scoring?.maxScore)
    if (maxScoreSetting && score > maxScoreSetting) {
      return maxScoreSetting
    }

    return score
  }

  // this needs to be turned into simpleOutput
  // and getValue should just return a string with no formatting to represent the answer (comma dillementedataeeted?)
  getValue(answers: string[]): string {
    if (answers) {
      let value = ''
      const defAnswers = this.data?.config?.answers
      answers.forEach((answerId) => {
        const answerDef = defAnswers.find((a) => answerId === a.value)
        if (answerDef) {
          value += answerDef.label + ', '
        }
      })
      return value
    }
  }
}
