import { faArrowAltSquareRight } from '@fortawesome/pro-solid-svg-icons/faArrowAltSquareRight'
import SubmitButtonBlock from './Submit'
import React from 'react'
import Input from '../../../../_shared/components/Form/Input'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import Colorpicker from '../../../../_shared/components/Form/Colorpicker'
import Dropdown from '../../../../_shared/components/Form/Dropdown'
import { FormContext } from '../../../../_shared/components/Form'
import { useSelector } from 'react-redux'
import SubscriptionLoader from '../../../../backend/client/containers/SubscriptionLoader'
import { AppState } from '../../../../backend/client/types'
import { Company } from '../../../../_shared/models/company/company.client'
import BlockManager from '../../BlockManager'
import Pathway from '../../../../_shared/models/pathway/pathway.backend.client'
import { RESTDataLoader } from '../../../../backend/client/containers/RESTDataLoader'

const Form = (props) => {
  const selectedCompany = useSelector<AppState, Company>(
    (state) => state.subscriptions.companyContext.data.companies[0]
  )

  return (
    <div className="section-content">
      <Input name="config.label" />
      <Cardbox name="config.hideIcon" thin vertical showLabel={false} defaultValue={false}>
        <CardboxItem value={false} title="Show icon" />
        <CardboxItem value={true} title="Hide icon" />
      </Cardbox>

      <Colorpicker name="config.color" label="Text Color" />
      <Colorpicker name="config.backgroundColor" />

      <Dropdown name="config.target" label="Target">
        <option value="next">Next Step</option>
        <option value="step">Specific Step</option>
        <option value="pathway">Pathway</option>
        <option value="custom">Custom URL</option>
      </Dropdown>

      <FormContext.Consumer>
        {({ formData }) => {
          if (formData.config?.target === 'pathway') {
            return (
              <RESTDataLoader
                resource="pathways"
                action="agency"
                params={{ companyId: selectedCompany._id }}
                showLoader={true}
              >
                {(data) => {
                  const options = []

                  data.forEach((pathway) => {
                    if (pathway._id !== props.pathway._id) {
                      options.push(pathway)
                    }
                  })

                  return (
                    <Dropdown
                      name="config.targetPathway"
                      label="Destination Pathway"
                      placeholder="(Select One)"
                    >
                      {options.map((pathway) => (
                        <option value={pathway._id} key={pathway._id}>
                          {pathway.name}
                        </option>
                      ))}
                    </Dropdown>
                  )
                }}
              </RESTDataLoader>
            )
          } else if (formData.config?.target === 'custom') {
            return <Input label="Target URL" name="config.targetUrl" />
          } else if (formData.config?.target === 'step') {
            return (
              <Dropdown name="config.targetStep" label="Step" placeholder="(Select One)">
                {props.pathway?.getSteps().map((step) => (
                  <option value={step._id} key={step._id}>
                    {step.name}
                  </option>
                ))}
              </Dropdown>
            )
          }
        }}
      </FormContext.Consumer>

      <Cardbox name="config.redirectParent" thin vertical showLabel={false}>
        <CardboxItem value={true} title="Redirect parent window if rendered in an iframe" />
      </Cardbox>
    </div>
  )
}

class SubmitButtonBlockEditor extends SubmitButtonBlock {
  static editor = {
    label: 'Submit Step',
    icon: faArrowAltSquareRight,
    category: 'content',
    settingsForm: Form,

    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        hideIcon: {
          type: 'boolean',
        },
        label: {
          type: 'string',
        },
        target: {
          type: 'string',
        },
        color: {
          type: 'object',
          properties: {
            r: {
              type: 'number',
            },
            b: {
              type: 'number',
            },
            g: {
              type: 'number',
            },
            a: {
              type: 'number',
            },
          },
          additionalProperties: false,
          required: ['r', 'g', 'b', 'a'],
        },
        backgroundColor: {
          type: 'object',
          title: 'Background Color',
          properties: {
            r: {
              type: 'number',
            },
            b: {
              type: 'number',
            },
            g: {
              type: 'number',
            },
            a: {
              type: 'number',
            },
          },
          additionalProperties: false,
          required: ['r', 'g', 'b', 'a'],
        },
        targetPathway: {
          type: 'string',
        },
        targetUrl: {
          type: 'string',
        },
        targetStep: {
          type: 'string',
        },
        redirectParent: {
          type: 'boolean',
        },
      })
      schema.required.push('label')
    },
  }
}
BlockManager.registerBlockClass(SubmitButtonBlockEditor)
