import Field from '../pagebuilder/blocks/Field'
import ClientPathway from './models/pathway/pathway.client'
import ClientResponse from './models/response/response.client'
import {
  FieldResponse,
  ResponseMetadata,
  StepReference,
  StepResponse,
} from './models/response/response.types'
import Step from './models/step/step.class'
import Ajv from 'ajv'
import addFormats from 'ajv-formats'
import Log from './log'
import ClientProgramGroup from './models/programGroup/programGroup.client'
import SubmitButtonBlock from '../pagebuilder/blocks/content/Submit/Submit'
import { RouteComponentProps } from 'react-router-dom'
import { transformErrors } from './components/Form'
import { substituteVariables } from '../pagebuilder/blocks/blockUtilities'

export interface StepSubmissionRedirect {
  url?: string
  stepId?: string
  parentFrame?: boolean
  waitForServerResponse?: boolean
}

export type FrontendContext = {
  pathway?: ClientPathway
  response?: ClientResponse
  company?: unknown
  programGroup?: ClientProgramGroup
  location?: RouteComponentProps['location']
}

export type Redirect = {
  url: string
  redirectParent?: string
}

export default class StepSubmission {
  declare pathway: ClientPathway
  declare response?: ClientResponse
  declare step: Step
  declare params: Record<string, any>
  // context: Record<string, any>
  declare buttonId?: string
  declare ipAddress?: string
  declare userAgent?: string
  declare responseStep: StepResponse
  declare stepSchema: Record<string, any>
  declare validationErrors: Record<string, any>
  declare program: FieldResponse
  declare campus: FieldResponse
  declare nextStep?: StepReference
  declare lastStep?: StepReference
  declare progress: number
  declare score: number
  declare redirect?: StepSubmissionRedirect
  declare stepIndex?: number
  declare confirmValueSet?: Record<string, any>
  declare programGroup?: ClientProgramGroup

  constructor(props: Partial<StepSubmission>) {
    Object.assign(this, props)

    // Create a StepResponse object for this step
    this.responseStep = this._createResponseStep()

    // If we don't have a ClientResponse yet, create one
    if (!this.response) {
      this.response = new ClientResponse({
        companyId: this.pathway.companyId,
        steps: [this.responseStep],
      })
    } else {
      this.stepIndex = this.response.steps.findIndex((s) => s._id === this.responseStep._id)
      // Merge the prepared response step into the response for conditional logic
      this._mergeResponseStep()
    }
  }

  /**
   * Creates an object representing a completed step in a response
   * @returns {Object}
   */
  _createResponseStep(): StepResponse {
    const { _id, name } = this.step // eslint-disable-line
    return {
      _id,
      name,
      fields: this.formToResponse({
        pathway: this.pathway,
        response: this.response,
        programGroup: this.programGroup,
      }),
    }
  }

  formToResponse(context?: FrontendContext): FieldResponse[] {
    const fields = new Array<FieldResponse>()
    Object.entries(this.params).forEach(([blockId, value]) => {
      const block = this.pathway.getBlock(blockId)
      if (block instanceof Field && value) {
        const doc = block.formToDoc(value, context)
        if (doc) {
          fields.push(doc)
        }
      }
    })
    return fields
  }

  /**
   * Merges the prepared response step with an existing response
   */
  _mergeResponseStep(): void {
    if (this.stepIndex !== -1) {
      this.response.steps[this.stepIndex] = this.responseStep
    } else {
      this.response.steps.push(this.responseStep)
    }
  }

  /**
   * Validates the submission
   */
  validate(): boolean {
    this.stepSchema = this.step.getSchema({
      response: this.response,
      pathway: this.pathway,
      programGroup: this.programGroup,
    })

    const ajv = new Ajv({
      allErrors: true,
      useDefaults: true,
      removeAdditional: true,
      coerceTypes: true,
      verbose: true,
    })
    addFormats(ajv)

    ajv.validate(this.stepSchema, this.params)
    if (ajv.errors) {
      this.validationErrors = transformErrors(ajv.errors)
      Log.info('StepSubmission failed validation', {
        pathwayId: this.pathway._id,
        stepId: this.step._id,
        errors: this.validationErrors,
        params: this.params,
      })
    }

    return !this.validationErrors
  }

  _calcRedirect(): StepSubmissionRedirect {
    if (!this.buttonId) {
      return
    }

    const button = this.step.getBlock<SubmitButtonBlock>(this.buttonId)
    if (!button) {
      return
    }

    const target = button.data.config.target
    switch (target) {
      case 'custom':
        return {
          url: substituteVariables(button.data.config.targetUrl, {
            response: this.response,
            pathway: this.pathway,
          }),
          parentFrame: !!button.data.config.redirectParent,
          waitForServerResponse: true,
        }
      case 'step':
        return {
          stepId: button.data.config.targetStep,
          waitForServerResponse: false,
        }
      case 'pathway':
        return {
          waitForServerResponse: true,
        }
    }
  }

  /**
   * Processes the submission and saves it to the database.
   */
  process(): ClientResponse {
    // Trim whitespace from fields
    Object.entries(this.params).forEach(([blockId, value]) => {
      const block = this.pathway.getBlock(blockId)
      if (block instanceof Field && value) {
        this.params[blockId] = block.sanitizeValue(value)
      }
    })

    this.lastStep = {
      _id: this.step._id,
      name: this.step.name,
    }
    this.nextStep = this._calcNextStep()
    this.progress = this._calcProgress()
    this.score = this.pathway.calculateScore(this.response)

    // Determine if we need to redirect
    if (!this.redirect) {
      this.redirect = this._calcRedirect()
    }

    Object.assign(this.response, {
      lastStep: {
        _id: this.step._id,
        name: this.step.name,
      },
      progress: this.progress,
      score: this.score,
      nextStep: this.nextStep,
    })

    // Add metadata fields
    ResponseMetadata.forEach((field) => {
      const fieldValue = String(this.params[field])
      if (
        fieldValue &&
        fieldValue !== undefined &&
        fieldValue !== 'undefined' &&
        fieldValue.length > 0
      ) {
        this.response[field] = fieldValue
      }
    })

    this._processConfirmValues()

    return this.response
  }

  _processConfirmValues(): void {
    const confirmValueSet = {}
    this.step.forEachBlock((block) => {
      if (block instanceof Field && this.params[block.id]) {
        const confirmField = block.data?.config?.confirmFieldValue
        if (confirmField) {
          this.response.steps.find((step, stepIndex) => {
            // Ignore the current step
            if (step._id === this.step._id) {
              return
            }

            const fieldIndex = step.fields.findIndex((field) => {
              return field._id === confirmField
            })
            const field = step.fields[fieldIndex]

            if (field) {
              const blockValue = block.formToDoc(this.params[block.id], {
                pathway: this.pathway,
                response: this.response,
                programGroup: this.programGroup,
              })

              field.value = blockValue.value
              field.data = blockValue.data

              confirmValueSet[`steps.${stepIndex}.fields.${fieldIndex}.value`] = blockValue.value

              if (blockValue.data) {
                confirmValueSet[`steps.${stepIndex}.fields.${fieldIndex}.data`] = blockValue.data
              }
            }
            return !!field
          })
        }
      }
    })

    if (Object.entries(confirmValueSet).length > 0) {
      this.confirmValueSet = confirmValueSet
    }
  }

  /**
   * Calculate the user's progress in the pathway.
   * @returns {number}
   */
  _calcProgress(): number {
    let progress

    const steps = this.pathway.getSteps()

    // Count the total amount of steps in the quiz
    let totalSteps = steps.length - 1
    if (totalSteps < 1) {
      totalSteps = 1
    }

    // Find the index of the current step
    let currentStepIndex = steps.findIndex((step) => step._id === this.step._id)
    if (isNaN(currentStepIndex)) {
      currentStepIndex = 0
    }

    // Calculate progress
    progress = parseInt((((currentStepIndex + 1) / totalSteps) * 100).toFixed(0), 10)
    if (isNaN(progress)) {
      progress = 0
    }

    return progress
  }

  /**
   * Returns the next step in the quiz.
   * @returns {Object}
   */
  _calcNextStep(): StepReference {
    const nextStep = this.pathway.getNextStep(this.step)

    if (!nextStep) {
      return null
    }

    return { _id: nextStep._id, name: nextStep.name }
  }

  /**
   * Returns valiation errors associated with this submission
   */
  getValidationErrors(): Record<string, any> {
    return this.validationErrors
  }
}
