import Block from '../../../pagebuilder/blocks/Block'
import Step from '../step/step.class'
import ClientPathway from './pathway.client'

export interface PathwayGoal {
  _id: string
  name: string
  steps: string[]
  trigger: 'submit' | 'view'
  includeInPersona?: boolean
}

export default class Pathway extends ClientPathway {
  declare campaignId?: string
  declare goals?: PathwayGoal[]
  declare programGroupId?: string
  declare templateId?: string
  declare sessionLengthMinutes: number
  declare excludeBilling?: boolean

  constructor(props: NonFunctionProperties<Pathway>) {
    super(props)
  }

  getPrevStep(currentStep: Step): Step {
    const steps = this.getSteps()
    if (steps.length > 0) {
      const currentStepIndex = steps.findIndex((s) => s._id === currentStep._id)
      return steps[currentStepIndex - 1] || null
    }
  }

  getBlock(id: string): Block {
    const steps = this.getSteps()
    let block
    steps.find((step) => {
      const b = step.getBlock(id)
      if (b) {
        block = b
        return true
      }
    })

    return block
  }
}
