export function embedNewStep(): void {
  if (window && window.parent) {
    window.parent.postMessage(
      {
        va: true,
        type: 'newStep',
      },
      '*'
    )
  }
}

export function embedUpdateHeight(): void {
  if (window && window.parent) {
    setImmediate(function () {
      window.parent.postMessage(
        {
          va: true,
          type: 'update',
          height: document.body.offsetHeight,
        },
        '*'
      )
    })
  }
}

export function embedRedirect(url: string): void {
  if (window && window.parent) {
    window.parent.postMessage(
      {
        va: true,
        type: 'redirect',
        url,
      },
      '*'
    )
  }
}

export function embedScrollTop(position: number): void {
  if (window && window.parent) {
    window.parent.postMessage(
      {
        va: true,
        type: 'scroll',
        position,
      },
      '*'
    )
  }
}

if (typeof window !== 'undefined') {
  window.addEventListener('message', receiveMessage, false)
}

function receiveMessage(event) {
  if (event.data && event.data.va) {
    switch (event.data.type) {
      case 'hide-mobile-touch-helpers':
        document.querySelector('body').classList.remove('mobile-touch-helpers')
        embedUpdateHeight()
        break
      case 'show-mobile-touch-helpers':
        document.querySelector('body').classList.add('mobile-touch-helpers')
        embedUpdateHeight()
        break
      case 'requestHeight':
        embedUpdateHeight()
    }
  }
}
