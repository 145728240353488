import { parseISO } from 'date-fns'
import ClientResponse from './response.client'
import { Device, ResponseStatus } from './response.types'

export default class Response extends ClientResponse {
  declare pathwayId: string
  declare userId: string
  declare personId: string
  declare createdAt: Date
  declare lastModified?: Date
  declare scoringComplete?: boolean
  declare isTest?: boolean
  declare isInvalid?: boolean
  declare invalidReason?: string
  declare isOnline?: boolean
  declare ipAddress?: string
  declare status?: ResponseStatus
  declare integrations?: Record<string, any>[]
  declare goals?: Record<string, number>
  declare statisticsConsent?: boolean
  declare completedAt?: Date
  declare abandonedAt?: Date
  declare device?: Device
  declare url?: string
  declare recaptchaScore?: number

  constructor(props: NonFunctionProperties<Response>) {
    super(props)
    const { createdAt, lastModified, completedAt, abandonedAt } = props
    if (typeof createdAt === 'string') {
      this.createdAt = parseISO(createdAt)
    }
    if (typeof lastModified === 'string') {
      this.lastModified = parseISO(lastModified)
    }
    if (typeof completedAt === 'string') {
      this.completedAt = parseISO(completedAt)
    }
    if (typeof abandonedAt === 'string') {
      this.abandonedAt = parseISO(abandonedAt)
    }
  }
}
