import React from 'react'
import styled from 'styled-components'
import Block from '../../Block'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/pro-solid-svg-icons/faPrint'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'

export interface PrintProps {
  block: PrintButtonBlock
  section: SectionBlock
  row: RowBlock
  className?: string
}

class Print extends React.PureComponent<PrintProps> {
  render() {
    const { block, className } = this.props
    return (
      <div className={classnames('block', className)}>
        <button type="button" className={`btn btn-secondary`} onClick={() => window.print()}>
          <FontAwesomeIcon icon={faPrint} /> {block.data.config?.label}
        </button>
      </div>
    )
  }
}

const StyledPrint = styled(Print)`
  text-align: center;
  @media print {
    display: none;
  }
  .svg-inline--fa {
    font-size: 0.9em;
    margin-right: 0.2em;
  }
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'Print'

export default class PrintButtonBlock extends Block {
  static type? = key

  static template: Partial<PrintButtonBlock> = {
    data: {
      config: {
        label: 'Print',
        appearance: {
          margin: {
            mobile: {
              bottom: 1.5,
              bottomUnit: 'rem',
            },
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<PrintButtonBlock>) {
    super(params)
    Object.assign(this, PrintButtonBlock.template, params)
  }

  getComponent(): React.ElementType {
    return StyledPrint
  }
}

BlockManager.registerBlockClass(PrintButtonBlock)
