import { Store } from 'redux'
import ClientResponse from '../../../_shared/models/response/response.client'
import PubSub from '../../../pubsub/src/server/PubSub/client'
import RequestManager from './RequestManager'
import { AppState } from '../types'
import Cookies from 'js-cookie'
import { Socket } from 'socket.io-client'
declare global {
  interface Window {
    VA: VAGlobal
    fbq?: any
  }
}

interface VAGlobal {
  pubSub: PubSub
  socket: Socket
  store: Store
  requestMgr: RequestManager
  getEnvironment: () => string
  waitForFieldUpdate: (fieldId: string, callback: () => void) => void
  getResponse: () => ClientResponse
  setFieldValue: (selector: string, value: any) => void
  token?: string
}

const VA: VAGlobal = {
  pubSub: null,
  socket: null,
  store: null,
  requestMgr: null,
  token: Cookies.get('authToken'),
  getEnvironment(): string {
    if (window.location.href.indexOf('staging.virtualadviser.com') !== -1) {
      return 'staging'
    } else if (window.location.href.indexOf('.virtualadviser.com') !== -1) {
      return 'production'
    } else {
      return 'development'
    }
  },
  waitForFieldUpdate(fieldId, callback): void {
    const listener = function (e) {
      if (e.detail === fieldId) {
        document.removeEventListener('updated', listener)
        if (callback) {
          callback()
        }
      }
    }

    document.addEventListener('updated', listener)
  },
  getResponse(): ClientResponse {
    const state: AppState = VA.store.getState()
    return state.response
  },
  setFieldValue(selector, value) {
    // todo: this func should have a callback instead of waitingforfieldupdate
    const element: any = document.querySelector(selector)
    if (!element) {
      return
    }

    let event = new Event('change', { bubbles: true })

    switch (element.type) {
      case 'text':
      case 'textarea':
      case 'email':
      case 'tel':
      case 'date':
      case 'number':
        element.value = value
        element._valueTracker.setValue()
        break
      case 'checkbox':
      case 'radio':
        // Get all the radio buttons with this selector
        const radioElements = [...document.querySelectorAll('input[name=' + element.name + ']')] // eslint-disable-line

        // Find the radio element with the label that matches our desired value
        radioElements.forEach((elem: HTMLInputElement) => {
          const container = elem.parentNode.parentNode
          const label = container.querySelector('label')
          if (label.innerText == value) {
            elem.checked = true
          }
        })

        element._valueTracker.setValue()

        event = new Event('click', { bubbles: true })
        break
      case 'select':
      case 'select-one':
        // Find the appropraite option and set 'selected' prop to true
        const selectOptions = [...element.querySelectorAll('option')] // eslint-disable-line
        const selectOption = selectOptions.find((optn) => optn.label == value) // eslint-disable-line
        if (selectOption) {
          selectOption.selected = true
        }
        break
    }

    element.dispatchEvent(event)
  },
}

if (typeof window !== 'undefined') {
  window.VA = VA
}

export default VA
