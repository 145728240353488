import React from 'react'
import Helmet from 'react-helmet'

interface Props {
  title: string
  appTitle?: string
}

const View: React.FC<Props> = (props) => {
  const { title, appTitle, children } = props

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {title} - {appTitle || 'Virtual Adviser'}
        </title>
      </Helmet>
      {children}
    </React.Fragment>
  )
}

export default View
