import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import capitalize from 'voca/capitalize'
import Field from '../../Field'
import { FormGroup } from 'reactstrap'
import { Slider as SliderInput } from '../../../../_shared/components/Form/Slider'
import {
  getFormGroupClasses,
  FormErrors,
  FormLabel,
} from '../../../../_shared/components/Form/helpers'
import classnames from 'classnames'
import BlockManager, { BlockDetailedOutputProps } from '../../BlockManager'
import { FieldResponse } from '../../../../_shared/models/response/response.types'
import { getBlockStyles } from '../../../getBlockStyles'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import { FormContextProps } from '../../../../_shared/components/Form'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import ClientResponse from '../../../../_shared/models/response/response.client'
import ClientPathway from '../../../../_shared/models/pathway/pathway.client'
import { Answer } from '../../AnswersBlock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar'
import withForm from '../../../../_shared/components/withForm'

export interface SocialStylesControlProps extends Partial<FormContextProps> {
  errors?: string[]
  value?: any
  name: string
  label?: string
  className?: string
}

class SocialStylesControl extends React.PureComponent<SocialStylesControlProps> {
  componentDidMount() {
    if (!this.props.value) {
      this.props.setValue(this.props, this.getDefaultValue())
    }
  }

  componentWillUnmount() {
    // todo: I have no idea why this  is necessary but without it, when you change steps, the sliders all set themselves to undefined and then call this function which somehow sets itself in the form!?
    this.onChangeSlider = function () {
      return void 0
    }
    this.props.setValue(this.props, undefined)
  }

  getSliderValue(name) {
    let { value } = this.props
    if (!value) {
      value = this.getDefaultValue()
    }
    return value?.answers[name]
  }

  getDefaultValue() {
    return {
      answers: {
        goesAlong: 50,
        quiet: 50,
        cooperative: 50,
        calm: 50,
        serious: 50,
        reserved: 50,
      },
      x: 50,
      y: 50,
      socialStyle: 'analytical',
    }
  }

  onChangeSlider = (name, axis, val) => {
    let { value } = this.props

    if (!value) {
      value = this.getDefaultValue()
    }

    value.answers[name] = val

    // Calculate the average on each axis
    const xAxis = [value.answers['goesAlong'], value.answers['quiet'], value.answers['cooperative']]
    const yAxis = [value.answers['calm'], value.answers['serious'], value.answers['reserved']]

    const xSum: number = xAxis.reduce((total, num) => {
      return total + num
    })
    const ySum: number = yAxis.reduce((total, num) => {
      return total + num
    })

    value.x = parseInt(String(xSum / xAxis.length), 10)
    value.y = parseInt(String(ySum / yAxis.length), 10)

    const halfway = 50
    if (value.x <= halfway && value.y <= halfway) {
      value.socialStyle = 'analytical'
    } else if (value.x >= halfway && value.y <= halfway) {
      value.socialStyle = 'driver'
    } else if (value.x <= halfway && value.y >= halfway) {
      value.socialStyle = 'amiable'
    } else if (value.x >= halfway && value.y >= halfway) {
      value.socialStyle = 'expressive'
    }

    value = Object.assign({}, value)
    this.props.setValue(this.props, value)
  }

  render() {
    const { name, errors, label, className } = this.props

    const sliderProps = {
      min: 0,
      max: 100,
      step: 1,
    }

    return (
      <FormGroup className={classnames('block', getFormGroupClasses(errors, false), className)}>
        <FormLabel showLabel={true} id={name} name={name} label={label} />
        <SliderInput
          name="socialStyles1"
          leftLabel="Supports others"
          rightLabel="Takes charge"
          onChange={(value) => {
            this.onChangeSlider('goesAlong', 'x', value)
          }}
          value={this.getSliderValue('goesAlong')}
          {...sliderProps}
        />
        <SliderInput
          name="socialStyles2"
          leftLabel="Reflective listener"
          rightLabel="Engaged conversationalist"
          onChange={(value) => {
            this.onChangeSlider('quiet', 'x', value)
          }}
          value={this.getSliderValue('quiet')}
          {...sliderProps}
        />
        <SliderInput
          name="socialStyles3"
          leftLabel="Collaborates well"
          rightLabel="Ambitiously competitive"
          onChange={(value) => {
            this.onChangeSlider('cooperative', 'x', value)
          }}
          value={this.getSliderValue('cooperative')}
          {...sliderProps}
        />
        <SliderInput
          name="socialStyles4"
          leftLabel="Steady and composed"
          rightLabel="Enthusiastic and excitable"
          onChange={(value) => {
            this.onChangeSlider('calm', 'y', value)
          }}
          {...sliderProps}
          value={this.getSliderValue('calm')}
        />
        <SliderInput
          name="socialStyles5"
          leftLabel="Thoughtful and serious"
          rightLabel="Warm and engaging"
          onChange={(value) => {
            this.onChangeSlider('serious', 'y', value)
          }}
          {...sliderProps}
          value={this.getSliderValue('serious')}
        />
        <SliderInput
          name="socialStyles6"
          leftLabel="Values solitude"
          rightLabel="Enjoys socializing"
          onChange={(value) => {
            this.onChangeSlider('reserved', 'y', value)
          }}
          {...sliderProps}
          value={this.getSliderValue('reserved')}
        />
        <FormErrors errors={errors} />
      </FormGroup>
    )
  }
}

const SocialStylesControlWithForm = withForm(SocialStylesControl)

export interface SocialStylesProps {
  block: SocialStylesField
  section: SectionBlock
  row: RowBlock
  className?: string
  response?: ClientResponse
  errors?: string[]
  context?: FrontendContext
}

class SocialStyles extends React.PureComponent<SocialStylesProps> {
  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)

    return <SocialStylesControlWithForm name={block.id} label={label} className={className} />
  }
}

export const StyledSocialStyles = styled(SocialStyles)`
  padding-bottom: 0.1rem;
  .form-group {
    margin-bottom: 1.5rem;
  }
  .form-group label {
    display: none;
  }
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

export interface SocialStylesGraphProps {
  x: number
  y: number
  className?: string
}

const SocialStylesGraph: React.FC<SocialStylesGraphProps> = ({
  x: xPos,
  y: yPos,
  className,
}: SocialStylesGraphProps) => {
  return (
    <div className={className}>
      <div className="erSocialStyles-report clearfix">
        <div className="erSocialStyles-report-cell first">
          <div className="circle-wrapper analytical">
            <div className="circle">
              <div className="circle-label">Analytical</div>
            </div>
          </div>
        </div>
        <div className="erSocialStyles-report-cell second">
          <div className="circle-wrapper driver">
            <div className="circle">
              <div className="circle-label">Driver</div>
            </div>
          </div>
        </div>
        <div className="erSocialStyles-report-cell third">
          <div className="circle-wrapper amiable">
            <div className="circle">
              <div className="circle-label">Amiable</div>
            </div>
          </div>
        </div>
        <div className="erSocialStyles-report-cell fourth">
          <div className="circle-wrapper expressive">
            <div className="circle">
              <div className="circle-label">Expressive</div>
            </div>
          </div>
        </div>
        <div className="legend">
          <div className="erSocialStyles-report-label label-top">
            Controls
            <br />
            Task Oriented
          </div>
          <div className="erSocialStyles-report-label label-right">Tells</div>
          <div className="erSocialStyles-report-label label-bottom">
            People Oriented
            <br />
            Emotes
          </div>
          <div className="erSocialStyles-report-label label-left">Asks</div>
          <div className="erSocialStyles-report-arrow arrow-top" />
          <div className="erSocialStyles-report-arrow arrow-left" />
          <div className="erSocialStyles-report-arrow arrow-right" />
          <div className="erSocialStyles-report-arrow arrow-bottom" />
        </div>
        <div className="value-wrapper">
          <div className="value" style={{ left: `${xPos}%`, top: `${yPos}%` }}>
            <FontAwesomeIcon icon={faStar} />
          </div>
        </div>
      </div>
    </div>
  )
}

export const StyledSocialStylesGraph = styled(SocialStylesGraph)`
  .erSocialStyles-report {
    page-break-inside: avoid;
    position: relative;
    max-width: 22.4em;
    padding: 4.5em 3.7em;
    font-size: 1.2em;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;

    &-cell {
      width: 7.25em;
      padding-bottom: 7.25em;
      float: left;
      border-bottom: 0.5em solid #444;
      border-left: 0.5em solid #444;
      position: relative;
      -moz-box-sizing: content-box;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
    }

    &-cell.first,
    &-cell.third {
      border-left: 0;
    }

    &-cell.third,
    &-cell.fourth {
      border-bottom: 0;
    }

    &-label {
      position: absolute;
      font-size: 0.9em;
      color: #848484;
    }

    .label-top,
    .label-bottom {
      left: 0;
      width: 100%;
      text-align: center;
    }

    .label-top {
      top: 0.5em;
    }

    .label-bottom {
      bottom: 0.5em;
    }

    .label-left,
    .label-right {
      top: 50%;
      -moz-transform: translate3d(0, -50%, 0);
      -webkit-transform: translate3d(0, -50%, 0);
      transform: translate3d(0, -50%, 0);
    }

    .label-left {
      left: 0;
    }

    .label-right {
      right: 0;
    }

    &-arrow {
      width: 1em;
      height: 1em;
      position: absolute;
    }

    .arrow-top {
      left: 50%;
      top: 3.4em;
      -moz-transform: translate3d(-50%, 0, 0);
      -webkit-transform: translate3d(-50%, 0, 0);
      transform: translate3d(-50%, 0, 0);
      border-left: 0.6em solid transparent;
      border-right: 0.6em solid transparent;
      border-bottom: 1.2em solid #444;
    }

    .arrow-bottom {
      left: 50%;
      bottom: 3.4em;
      -moz-transform: translate3d(-50%, 0, 0);
      -webkit-transform: translate3d(-50%, 0, 0);
      transform: translate3d(-50%, 0, 0);
      border-left: 0.6em solid transparent;
      border-right: 0.6em solid transparent;
      border-top: 1.2em solid #444;
    }

    .arrow-left {
      left: 2.6em;
      top: 50%;
      -moz-transform: translate3d(0, -50%, 0);
      -webkit-transform: translate3d(0, -50%, 0);
      transform: translate3d(0, -50%, 0);
      border-top: 0.6em solid transparent;
      border-bottom: 0.6em solid transparent;
      border-right: 1.2em solid #444;
    }
    .arrow-right {
      right: 2.6em;
      top: 50%;
      -moz-transform: translate3d(0, -50%, 0);
      -webkit-transform: translate3d(0, -50%, 0);
      transform: translate3d(0, -50%, 0);
      border-top: 0.6em solid transparent;
      border-bottom: 0.6em solid transparent;
      border-left: 1.2em solid #444;
    }

    .circle-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .circle {
      line-height: 730%;
    }

    .analytical {
      background: #fcf59d !important;
      background-image: -webkit-linear-gradient(90deg, #ffffff 0%, #ffde0a 100%) !important;
      background-image: linear-gradient(90deg, #ffffff 0%, #ffde0a 100%) !important;
    }

    .driver {
      background: #ef9a99 !important;
      background-image: -webkit-linear-gradient(-90deg, #ffffff 0%, #31abe1 100%) !important;
      background-image: linear-gradient(-90deg, #ffffff 0%, #31abe1 100%) !important;
    }

    .amiable {
      background: #a5d6a7 !important;
      background-image: -webkit-linear-gradient(90deg, #ffffff 0%, #43b750 100%) !important;
      background-image: linear-gradient(90deg, #ffffff 0%, #43b750 100%) !important;
    }

    .expressive {
      background: #f9cc80 !important;
      background-image: -webkit-linear-gradient(-90deg, #ffffff 0%, #faa900 100%) !important;
      background-image: linear-gradient(-90deg, #ffffff 0%, #faa900 100%) !important;
    }

    .circle-label {
      text-align: center;
      font-size: 1.2em;
    }

    .value-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 4.5em 3.7em;
    }

    .value {
      position: absolute;
      color: #ff002e;
      font-size: 1.6em;
      -webkit-transform: translate3d(-48%, -48%, 0);
      -moz-transform: translate3d(-48%, -48%, 0);
      transform: translate3d(-48%, -48%, 0);

      .fa-certificate {
        filter: drop-shadow(0 0 2px #fff);
      }

      .fa-award {
        font-size: 0.6em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
      }
    }

    @media (max-width: 565.98px) {
      font-size: 0.85em;
    }
  }
`

interface SocialStylesDetailedOutputProps extends BlockDetailedOutputProps {
  field?: SocialStylesFieldResponse
}

export const detailedOutput: React.FC<SocialStylesDetailedOutputProps> = ({
  label,
  field,
  className,
  hideLabels,
}: SocialStylesDetailedOutputProps) => {
  let socialStyle

  if (!field?.value || field.value === 'Analytical') {
    socialStyle = (
      <React.Fragment>
        <p>
          You&apos;re thoughtful and detail-oriented. You prefer to take a measured, methodical
          approach to tasks. You carefully follow procedures and thoroughly weigh your options
          before changing course. You prefer to consider and eliminate potential risks before you
          pursue something. You feel confident knowing you&apos;ve got data to support your purpose.
          You can have a tendency to contain your emotions when dealing with others, which can come
          off as quiet. You&apos;re a strong problem solver and like to find new ways of answering
          old problems. To others you often appear calm, competent. It&apos;s not unusual for people
          to trust you to get a job done right.
        </p>
        <p>
          Many mathematicians, philosophers and scientists belong to this group. You&apos;re
          well-suited to careers that involve problem solving and allow some opportunity to work
          alone.
        </p>
      </React.Fragment>
    )
  } else if (field.value === 'Driver') {
    socialStyle = (
      <React.Fragment>
        <p>
          You&apos;re a go-getter. You&apos;re not afraid to respond to events quickly. It&apos;s
          not uncommon for others to look to you to take initiative or act as a leader. People see
          you as straightforward; you don&apos;t see a need to “beat around the bush.” You&apos;re
          most comfortable pursuing your goals when you can feel in charge and under your own
          direction. You&apos;re willing to take a risk and you don&apos;t shy away from a
          challenge. Others see you as confident, able to take charge and get things done. You can
          coordinate and work well with others.
        </p>

        <p>
          As a Driver, you&apos;re well-suited to careers that allow you opportunities to manage
          and/or oversee daily activities and take initiative.
        </p>
      </React.Fragment>
    )
  } else if (field.value === 'Amiable') {
    socialStyle = (
      <React.Fragment>
        <p>
          You&apos;re a people person. You&apos;re friendly. A good listener who gets along easily
          with others and who enjoys social contact. You like to take time to build relationships
          and value input from others when you make decisions. You&apos;re cooperative and others
          appreciate your loyalty and support. People may come to you for advice. You don&apos;t
          mind providing thoughtful feedback or positive comments about others. You make people feel
          comfortable. At times it may seem like you&apos;re the glue that holds a social group
          together.
        </p>

        <p>
          As an Amiable, you are best suited to careers in lower-stress, consistent settings.
          You&apos;ll likely enjoy positions most when you&apos;re able to work to your full
          potential without being called on for high-pressure decision making.
        </p>
      </React.Fragment>
    )
  } else if (field.value === 'Expressive') {
    socialStyle = (
      <React.Fragment>
        <p>
          You&apos;re creative. You&apos;re excited by a spirited exchange of ideas. You like an
          audience and your energy and enthusiasm can motivate others. You&apos;re comfortable in
          social situations and like to engage in friendly conversation, sometimes before getting
          down to the task at hand. You&apos;re likely to depend on feelings to help make decisions.
          When you have a choice to make you&apos;ll often “go with your gut.” You have a
          competitive side and tend to be ambitious. You&apos;re not afraid to share your dreams and
          what you want.
        </p>

        <p>
          Expressives are most successful in careers that allow them to interact with others, share
          ideas and be creative. As a member of this group, you&apos;re best-suited to professions
          where you work with others, collaborate on projects and have the opportunity to present
          your thoughts.
        </p>
      </React.Fragment>
    )
  }

  return (
    <div className={className}>
      {!hideLabels && <strong>{label || field?.label}</strong>}
      <StyledSocialStylesGraph x={field?.data?.x} y={field?.data?.y} />
      <h5>Your social style is: {field?.value || 'Analytical'}</h5>
      {socialStyle}
    </div>
  )
}

export interface SocialStylesValue {
  answers: {
    goesAlong: number
    quiet: number
    cooperative: number
    calm: number
    serious: number
    reserved: number
  }
  x: number
  y: number
  socialStyle: string
}

export interface SocialStylesFieldResponse extends FieldResponse {
  data: SocialStylesValue
}

const key = 'SocialStyles'

export default class SocialStylesField extends Field {
  static type? = key
  static detailedOutput = detailedOutput
  static enableStatistics = true
  static enableConditionalLogic = true

  static template: Partial<SocialStylesField> = {
    data: {
      config: {
        label: 'I would best describe myself as...',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
      advanced: {
        profile: {
          group: 'contact',
          key: 'socialstyle',
          label: 'Social Style',
        },
      },
    },
  }

  getComponent(): React.ElementType {
    return StyledSocialStyles
  }

  getValue(value: SocialStylesValue): string {
    return value ? capitalize(value.socialStyle) : null
  }

  getFakeValue(): FieldResponse {
    return {
      _id: this.id,
      label: this.data?.config?.label,
      type: (this.constructor as any).type,
      value: 'Analytical',
    }
  }

  getOptions(): Answer[] {
    return [
      { label: 'Analytical', value: 'Analytical' },
      { label: 'Driver', value: 'Driver' },
      { label: 'Expressive', value: 'Expressive' },
      { label: 'Amiable', value: 'Amiable' },
    ]
  }

  getFormSchema(context?: FrontendContext): Record<string, any> {
    return {
      schema: {
        title: this.getLabel(context),
        type: 'object',
        properties: {
          answers: {
            type: 'object',
            properties: {
              // todo: social style properties
            },
          },
          x: {
            type: 'number',
          },
          y: {
            type: 'number',
          },
          socialStyle: {
            type: 'string',
          },
        },
      },
      required: this.data?.config?.required,
    }
  }
}

BlockManager.registerBlockClass(SocialStylesField)
