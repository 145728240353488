import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle'
import { faArrows } from '@fortawesome/pro-solid-svg-icons/faArrows'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import SettingsSection from '../../../settings/SettingsSection'
import Field from '../../Field'
import withForm from '../../../../_shared/components/withForm'

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
})

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

class FieldOptions extends React.PureComponent {
  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newValue = reorder(this.props.value, result.source.index, result.destination.index)

    this.props.setValue(this.props, newValue)
  }

  getOptions() {
    const { pathway, value } = this.props
    const options = []
    pathway.getSteps().forEach((step) => {
      step.forEachBlock((block) => {
        if (block instanceof Field) {
          if (block.canRecap() && !value?.includes(block.id)) {
            options.push({
              label: block.data.config.label,
              value: block.id,
            })
          }
        }
      })
    })

    return options
  }

  addField = (blockId) => {
    const { value } = this.props
    const newValue = value ? Array.from(value) : []
    newValue.push(blockId)
    this.props.setValue(this.props, newValue)
  }

  delete = (index) => {
    const newValue = Array.from(this.props.value)
    newValue.splice(index, 1)
    this.props.setValue(this.props, newValue)
  }

  render() {
    const { value, pathway, className } = this.props

    return (
      <SettingsSection title="Fields" startOpen={true} className={className}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <ul className="selected-fields" ref={provided.innerRef}>
                {value?.map((blockId, index) => {
                  let block = pathway.getBlock(blockId)
                  if (!block) {
                    block = new Field({
                      id: blockId,
                      data: {
                        config: {
                          label: 'Deleted field',
                        },
                      },
                    })
                  }
                  return (
                    <Draggable key={block.id} draggableId={block.id} index={index}>
                      {(provided, snapshot) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div className="row align-items-center no-gutters">
                            <div className="col-auto">
                              <div className="move">
                                <FontAwesomeIcon icon={faArrows} />
                              </div>
                            </div>
                            <div className="col">
                              <div className="label">{block.getLabel(null, pathway)}</div>
                            </div>
                            <div className="col-auto">
                              <div
                                className="delete"
                                onClick={() => {
                                  this.delete(index)
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
          {(!value || value.length === 0) && (
            <div className="alert alert-warning">
              <strong>Which fields do you want to summarize?</strong>
            </div>
          )}

          <h6>Available Fields</h6>
          <ul className="available-fields">
            {this.getOptions().map((option, index) => (
              <li
                key={option.value}
                onClick={() => {
                  this.addField(option.value)
                }}
                // disabled={fields.includes(option.value)}
              >
                <div className="row align-items-center">
                  <div className="col">{option.label}</div>
                  <div className="col-auto">
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </DragDropContext>
      </SettingsSection>
    )
  }
}

const StyledFieldOptions = styled(FieldOptions)`
  .alert {
    strong {
      font-weight: 600;
    }
    font-size: 0.875em;
  }

  .selected-fields {
    margin: -1rem 0 1rem 0;
    padding: 0;
    list-style: none;
    li {
      padding: 0.75rem;
      border-bottom: 1px solid #eef1f3;
      margin: 0 -1rem;
      background: #fff;
    }
    .move {
      font-size: 0.875rem;
    }
    .delete,
    .move {
      color: #929292;
      padding: 0.1rem 0.25rem;
    }
    .delete {
      cursor: pointer;
      &:hover,
      &:focus {
        color: var(--danger);
      }
    }
    .label {
      padding: 0 0.5rem;
      font-size: 0.9375rem;
    }
  }

  .available-fields {
    margin: 1rem -1rem;
    padding: 0;
    list-style: none;
    font-size: 0.875em;

    > li {
      border-top: 1px solid #eef1f3;
      padding: 0.75em 1rem;
      background: #fff;
      cursor: pointer;
      &:hover {
        color: var(--primary);
      }
      &:last-child {
        border-bottom: 1px solid #eef1f3;
      }
      .fa-plus-circle {
        color: var(--primary);
        margin-right: 0.2em;
      }
    }
  }
`

export default withForm(StyledFieldOptions)
