import React from 'react'
import styled from 'styled-components'
import Field, { FieldConfig, FieldData } from '../../Field'
import RowBlock from '../../row/Row'
import SectionBlock from '../../section/Section'
import BlockManager from '../../BlockManager'
import { getBlockStyles } from '../../../getBlockStyles'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import classNames from 'classnames'
import LikertFormControl from './LikertFormControl'
import { FieldResponse } from '../../../../_shared/models/response/response.types'

export interface LikertProps {
  block: LikertField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class Likert extends React.PureComponent<LikertProps> {
  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)

    return (
      <LikertFormControl
        name={block.id}
        className={classNames('block', className)}
        label={label}
        showLabel={!block.data.config?.hideLabel}
        showLabels={block.data.config?.showLabels}
        label1={block.data.config?.label1}
        label2={block.data.config?.label2}
        label3={block.data.config?.label3}
        label4={block.data.config?.label4}
        label5={block.data.config?.label5}
      />
    )
  }
}

export const StyledLikert = styled(Likert)`
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

export interface LikertFieldConfig extends FieldConfig {
  label1: string
  label2: string
  label3: string
  label4: string
  label5: string
  showLabels?: boolean
}

export interface LikertFieldData extends FieldData {
  config?: LikertFieldConfig
}

export interface LikertFieldResponse extends FieldResponse {
  value: number
  data: {
    label: string
  }
}

const key = 'Likert'

export default class LikertField extends Field {
  static type? = key
  static enableStatistics = true
  static enableConditionalLogic = true
  declare data?: LikertFieldData
  // static usePrimarySettingsFormOnly = true

  static template: Partial<LikertField> = {
    data: {
      config: {
        label: 'Likert',
        label1: 'Strongly Disagree',
        label2: 'Disagree',
        label3: 'Neutral',
        label4: 'Agree',
        label5: 'Strongly Agree',
        showLabels: true,
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<LikertField>) {
    super(params)
    Object.assign(this, LikertField.template, params)
  }

  static getConditionalLogicValue(value: LikertFieldResponse): number {
    return value.value
  }

  getComponent(): React.ElementType {
    return StyledLikert
  }

  formToDoc(answer: number, context?: FrontendContext): LikertFieldResponse {
    const label = this.data.config['label' + answer]

    return {
      _id: this.id,
      label: this.getLabel(context),
      type: (this.constructor as any).type,
      value: Number(answer),
      data: {
        label: label || String(answer),
      },
    }
  }

  getOptions() {
    const options = []
    options.push({
      label: this.data.config.label1 || 1,
      value: 1,
    })
    options.push({
      label: this.data.config.label2 || 2,
      value: 2,
    })
    options.push({
      label: this.data.config.label3 || 3,
      value: 3,
    })
    options.push({
      label: this.data.config.label4 || 4,
      value: 4,
    })
    options.push({
      label: this.data.config.label5 || 5,
      value: 5,
    })
    return options
  }

  getValue(answer: LikertFieldResponse) {
    return String(answer.value)
  }

  docToForm(doc: LikertFieldResponse): number {
    return doc.value
  }

  getFormSchema(context: FrontendContext): Record<string, any> {
    return {
      schema: {
        title: this.getLabel(context),
        type: 'number',
      },
      required: this.data?.config?.required,
    }
  }
}

BlockManager.registerBlockClass(LikertField)
