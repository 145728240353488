import React from 'react'
import { faListUl } from '@fortawesome/pro-solid-svg-icons/faListUl'
import RecapBlock from './Recap'
import FieldOptions from './FieldOptions'
import SettingsSection from '../../../settings/SettingsSection'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import BlockManager from '../../BlockManager'

const Form = (props) => (
  <React.Fragment>
    <SettingsSection title="Labels">
      <Cardbox name="config.hideLabels" thin vertical showLabel={false} defaultValue={false}>
        <CardboxItem value={false} title="Show labels" />
        <CardboxItem value={true} title="Hide labels" />
      </Cardbox>
    </SettingsSection>
    <SettingsSection title="Format">
      <Cardbox name="config.format" thin vertical showLabel={false} defaultValue="simple">
        <CardboxItem
          value="simple"
          title="Simple"
          description="Show the user's answers in a simplified table"
        />
        <CardboxItem
          value="detailed"
          title="Detailed"
          description="Show a detailed answer summary, including graphics and feedback where applicable"
        />
      </Cardbox>
    </SettingsSection>
    {/* <SettingsSection title="Insights">
      <Cardbox
        name="config.insights"
        thin
        vertical
        showLabel={false}
        defaultValue={true}
      >
        <CardboxItem value={true} title="Enable Insights" />
        <CardboxItem value={false} title="Disable Insights" />
      </Cardbox>
    </SettingsSection> */}
    <FieldOptions name="config.blocks" pathway={props.pathway} />
  </React.Fragment>
)

class RecapBlockEditor extends RecapBlock {
  static editor = {
    label: 'Summary',
    icon: faListUl,
    category: 'content',
    settingsForm: Form,

    getSettingsSchema(schema) {
      schema.properties = {
        ...schema.properties,
        blocks: {
          type: 'array',
          title: 'Fields',
          items: {
            type: 'string',
          },
        },
        hideLabels: {
          type: 'boolean',
          title: 'Labels',
        },
        format: {
          type: 'string',
          enum: ['simple', 'detailed'],
        },
      }

      schema.required.push('blocks')
    },
  }
}

BlockManager.registerBlockClass(RecapBlockEditor)
