import React from 'react'
import PropTypes from 'prop-types'
import SettingsSection from '../SettingsSection'
import Input from '../../../_shared/components/Form/Input'

export const ScoringOptionsSchema = {
  scoring: {
    type: 'object',
    properties: {
      maxScore: {
        type: 'number',
      },
    },
  },
}

const ScoringOptions = ({ path }) => (
  <SettingsSection title="Scoring">
    <Input
      type="text"
      name={`${path}.maxScore`}
      label="Maximum Score"
      help="Optionally restrict the maximum score. This is useful if you want user's to obtain a higher score without selecting all of the possible answers."
    />
  </SettingsSection>
)

ScoringOptions.propTypes = {
  formData: PropTypes.object,
  options: PropTypes.array,
  path: PropTypes.string.isRequired,
}

export default ScoringOptions
