import React from 'react'
import { FieldResponse } from '../../_shared/models/response/response.types'
import BlockManager, { BlockSimpleOutputProps } from './BlockManager'
import Field from './Field'

export interface FieldOutputProps {
  field: FieldResponse
  simpleOutput?: React.ComponentType<BlockSimpleOutputProps>
}

const FieldOutput: React.FC<FieldOutputProps> = ({ field, simpleOutput }: FieldOutputProps) => {
  let Component: React.ComponentType<BlockSimpleOutputProps>

  if (!simpleOutput) {
    const BlockClass = BlockManager.getBlockClass(field.type) as typeof Field
    if (BlockClass) {
      Component = BlockClass.simpleOutput
    }
  } else {
    Component = simpleOutput
  }

  return (
    <React.Fragment>
      {Component ? (
        <Component answer={field} />
      ) : Array.isArray(field?.value) ? (
        field.value.join(', ')
      ) : (
        field.value
      )}
    </React.Fragment>
  )
}

export default FieldOutput
