import Field, { FieldConfig, FieldData } from '../../Field'
import React from 'react'
import styled from 'styled-components'
import Input from '../../../../_shared/components/Form/Input'
import classnames from 'classnames'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import BlockManager from '../../BlockManager'
import { FrontendContext } from '../../../../_shared/StepSubmission'
import { SetConfirmValue } from '../../../settings/ConfirmValue'

export interface FirstNameInputProps {
  block: FullNameField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}
class FullNameInput extends React.PureComponent<FirstNameInputProps> {
  componentDidMount() {
    SetConfirmValue(this.props)
  }

  render() {
    const { block, className, context } = this.props
    const label = block.getLabel(context)

    return (
      <Input
        name={block.id}
        className={classnames('block', className)}
        label={label}
        showLabel={!block.data.config.hideLabel}
        placeholder={block.data.config?.placeholder}
        autocomplete="name"
      />
    )
  }
}

export const StyledFullNameInput = styled(FullNameInput)`
  input {
    font-size: 1.066666666666667rem;
  }
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

export const key = 'FullName'

export interface FullNameFieldConfig extends FieldConfig {
  placeholder?: string
}

export interface FullNameFieldData extends FieldData {
  config?: FullNameFieldConfig
}

export default class FullNameField extends Field {
  static type? = key
  static enableConditionalLogic = true
  declare data: FullNameFieldData
  static template: Partial<FullNameField> = {
    data: {
      config: {
        label: 'Name',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
      advanced: {
        profile: {
          group: 'contact',
          key: 'name',
          label: 'Name',
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<FullNameField>) {
    super(params)
    Object.assign(this, FullNameField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledFullNameInput
  }
}

BlockManager.registerBlockClass(FullNameField)
