import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import FullNameField, { key } from './FullName'
import React from 'react'
import { PlaceholderSchema } from '../../commonSchemas'
import ConfirmValue, { ConfirmValueSchema } from '../../../settings/ConfirmValue'
import Input from '../../../../_shared/components/Form/Input'
import BlockManager from '../../BlockManager'

export const Form = (props) => (
  <React.Fragment>
    <ConfirmValue fieldKey={key} />

    <div className="section-content">
      <Input name="config.placeholder" label="Placeholder" />
    </div>
  </React.Fragment>
)

class FullNameFieldEditor extends FullNameField {
  static editor = {
    label: 'Full Name',
    icon: faUser,
    category: 'profile',
    settingsForm: Form,
    canConfirmValue: true,

    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        ...PlaceholderSchema,
        ...ConfirmValueSchema,
      })
    },
  }
}

BlockManager.registerBlockClass(FullNameFieldEditor)
