import React from 'react'
import { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar'
import DateField from './Date'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import BlockManager from '../../BlockManager'

export const Form = (props) => (
  <div className="section-content">
    <Cardbox name="config.format" thin vertical defaultValue="MM/DD/YYYY">
      <CardboxItem value="MM/DD/YYYY" title="US Standard" description="Accepts MM/DD/YYYY" />
      <CardboxItem value="DD/MM/YYYY" title="International" description="Accepts DD/MM/YYYY" />
    </Cardbox>
  </div>
)

class DateFieldEditor extends DateField {
  static editor = {
    label: 'Date',
    category: 'form',
    icon: faCalendar,
    settingsForm: Form,
    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        format: {
          type: 'string',
        },
      })
    },
  }
}

BlockManager.registerBlockClass(DateFieldEditor)
