import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird'
import styled from 'styled-components'

const StyledIcon = styled(FontAwesomeIcon)`
  animation-duration: 0.9s;
`

interface Props {
  className?: string
  delay?: string
}

const Loader: React.FC<Props> = (props) => {
  return (
    <div className={props.className + ' animated fadeIn'}>
      <StyledIcon icon={faSpinnerThird} spin />
    </div>
  )
}

export default styled(Loader)`
  color: var(--blue);
  font-size: 4rem;
  display: block;
  text-align: center;
  animation-duration: ${(props) => (props.delay ? props.delay : '1s')};
`
