import React from 'react'
import styled from 'styled-components'
import BlockManager from '../../BlockManager'
import CheckboxInput from '../../../../_shared/components/Form/Checkbox'
import classnames from 'classnames'
import { AnswersMultipleBlock } from '../../AnswersBlock'
import SectionBlock from '../../section/Section'
import RowBlock from '../../row/Row'
import { getBlockStyles } from '../../../getBlockStyles'
import { FrontendContext } from '../../../../_shared/StepSubmission'

export interface CheckboxProps {
  block: CheckboxField
  section: SectionBlock
  row: RowBlock
  className?: string
  context?: FrontendContext
}

class Checkbox extends React.PureComponent<CheckboxProps> {
  render() {
    const { block, className, context } = this.props
    return (
      <CheckboxInput
        className={classnames('block', className)}
        name={block.id}
        label={block.getLabel(context)}
        options={block.data.config.answers}
        showLabel={!block.data.config.hideLabel}
      />
    )
  }
}

const StyledCheckbox = styled(Checkbox)`
  position: relative;
  ${(props) => getBlockStyles(props.block.data?.appearance)}
  ${(props) => props.block.data?.advanced?.customCss};
`

const key = 'Checkbox'
export default class CheckboxField extends AnswersMultipleBlock {
  static type? = key
  static template: Partial<CheckboxField> = {
    data: {
      config: {
        label: 'Checkbox',
      },
      appearance: {
        margin: {
          mobile: {
            bottom: 1.5,
            bottomUnit: 'rem',
          },
        },
      },
    },
  }

  constructor(params: NonFunctionProperties<CheckboxField>) {
    super(params)
    Object.assign(this, CheckboxField.template, params)
  }

  getComponent(): React.ElementType {
    return StyledCheckbox
  }
}

BlockManager.registerBlockClass(CheckboxField)
