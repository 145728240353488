import { faDotCircle } from '@fortawesome/pro-solid-svg-icons/faDotCircle'
import BlockManager from '../../BlockManager'
import RadioField from './Radio'

class RadioFieldEditor extends RadioField {
  static editor = {
    label: 'Radio',
    category: 'form',
    icon: faDotCircle,
  }
}

BlockManager.registerBlockClass(RadioFieldEditor)
