import React, { useContext } from 'react'
import classnames from 'classnames'
import Block from './blocks/Block'
import SectionBlock from './blocks/section/Section'
import RowBlock from './blocks/row/Row'
import { FrontendContext } from '../_shared/StepSubmission'
import { ReactFrontendContext } from '../frontend/client/containers/StepViewContainer'
import { FormContext } from '../_shared/components/Form'
import SubmitButtonBlock from './blocks/content/Submit/Submit'

type BlockRendererProps = {
  block: Block
  section?: SectionBlock
  row?: RowBlock
  frontendContext?: FrontendContext
}

type BlockRendererState = {
  isVisible: boolean
  visibilityChanged: boolean
}

class BlockRenderer extends React.Component<BlockRendererProps, BlockRendererState> {
  constructor(props: BlockRendererProps) {
    const { block, frontendContext } = props
    super(props)
    this.state = {
      isVisible: block.isVisible(frontendContext?.response),
      visibilityChanged: false,
    }
  }

  componentDidUpdate() {
    this.updateVisibility()
  }

  updateVisibility() {
    const { block, frontendContext } = this.props
    const isVisible = block.isVisible(frontendContext?.response)

    const visibilityChanged = this.state.isVisible !== isVisible
    if (visibilityChanged) {
      this.setState({
        isVisible,
        visibilityChanged: true,
      })
    }
  }

  render() {
    const { block, section, row, frontendContext } = this.props

    const cssClass = block.data?.advanced?.cssClass
    const Component = block.getComponent()
    const isSubmitButton = block instanceof SubmitButtonBlock

    return (
      <FormContext.Consumer>
        {(formContext) => {
          return (
            <Component
              className={classnames(cssClass, {
                'animated fadeIn':
                  !isSubmitButton && this.state.visibilityChanged && this.state.isVisible,
                'd-none': !this.state.isVisible,
              })}
              isVisible={this.state.isVisible}
              context={frontendContext}
              block={block}
              section={section}
              row={row}
              formContext={formContext}
            />
          )
        }}
      </FormContext.Consumer>
    )
  }
}

// export default BlockRenderer

const FrontendContextProvider: React.FC<BlockRendererProps> = (props: BlockRendererProps) => {
  return (
    <ReactFrontendContext.Consumer>
      {(frontendContext) => <BlockRenderer {...props} frontendContext={frontendContext} />}
    </ReactFrontendContext.Consumer>
  )
}

export default FrontendContextProvider
