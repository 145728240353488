import { faGlobeAmericas } from '@fortawesome/pro-solid-svg-icons/faGlobeAmericas'
import BlockManager from '../../BlockManager'
import CountryField from './Country'

class CountryFieldEditor extends CountryField {
  static editor = {
    label: 'Country',
    category: 'form',
    icon: faGlobeAmericas,
  }
}

BlockManager.registerBlockClass(CountryFieldEditor)
