import React from 'react'
import { faSmile } from '@fortawesome/pro-solid-svg-icons/faSmile'
import LikertField from './Likert'
import Input from '../../../../_shared/components/Form/Input'
import BlockManager from '../../BlockManager'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import SettingsSection from '../../../settings/SettingsSection'

export const Form = (props) => (
  <>
    <div className="section-content">
      <Cardbox name="config.showLabels" thin vertical showLabel={false} defaultValue={false}>
        <CardboxItem
          value={true}
          title="Show answer labels"
          description="Show the individual labels for each answer"
        />
      </Cardbox>
      <Input type="text" name="config.label1" label="First Choice Label" />
      <Input type="text" name="config.label2" label="Second Choice Label" />
      <Input type="text" name="config.label3" label="Third Choice Label" />
      <Input type="text" name="config.label4" label="Fourth Choice Label" />
      <Input type="text" name="config.label5" label="Fifth Choice Label" />
    </div>
  </>
)

class LikertFieldBlockEditor extends LikertField {
  static editor = {
    label: 'Likert',
    category: 'form',
    icon: faSmile,
    settingsForm: Form,
    getSettingsSchema(schema) {
      Object.assign(schema.properties, {
        label1: {
          type: 'string',
        },
        label2: {
          type: 'string',
        },
        label3: {
          type: 'string',
        },
        label4: {
          type: 'string',
        },
        label5: {
          type: 'string',
        },
        showLabels: {
          type: 'boolean',
        },
      })
    },
  }
}
BlockManager.registerBlockClass(LikertFieldBlockEditor)
